// Loading Area
#loading-area {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 999999999;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 80px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
	
	.loader{
		width: 170px;
		height: 170px;
		margin: 30px auto 0;
		position: relative;

		.border{
			width: 170px;
			height: 170px;
			opacity: 0;
			border: 3px solid #02A3FE !important;
			border-radius: 100%;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
			animation: animate 1.8s infinite linear;
			
			&.one{
				animation-delay:200ms;
			}
			&.two{
				animation-delay:400ms;
			}
			&.three{
				animation-delay:600ms;
			}
			&.four{
				animation-delay:800ms;
			}
			&.five{
				animation-delay:1000ms;
			}
			&.seven{
				animation-delay:1200ms;
			}
			&.eight{
				animation-delay:1400ms;
			}
			&.nine{
				animation-delay:1600ms;
			}
		}
	}
}
@keyframes animate{
	0%{
		opacity: 0.0;
		width: 0;
		height: 0;
	}
	10%{
		opacity: 0.9;
	}
	100%{
		opacity: 0.0;
		width: 150px;
		height: 150px;
	}
}