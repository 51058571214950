.main-bnr{
	height: 920px;
	position: relative;
	background-image: url(../images/background/bg.jpg);
	display: flex;
    align-items: center;
    overflow: hidden;
	padding-top: 100px;
    padding-bottom: 30px;
	
	.container,
	.container-fluid{
		position: relative;
		z-index: 3;
	}
	h1{
		font-size: 70px;
		color: #fff;
		line-height: 1.4;
		margin-bottom: 10px;
		position: relative;
		
		.text-line{
			position: relative;
			&:after{
				content: "";
				top: 75px;
				z-index: -1;
				right: 5px;
				height: 34px;
				width: 380px;
				position: absolute;
				background-size: 100%;
				background-repeat: no-repeat;
				background-position: left top;
				background-image: url(../images/home-banner/shape2.svg);
			}
		}
	}
	.text{
		font-size: 24px;
		font-weight: 300;
		margin-bottom: 70px;
		line-height: 1.7;
		max-width: 600px;
		
		@media only screen and (max-width: 1191px){
			font-size: 18px;
			margin-bottom: 50px;
		}
		@include respond('tab-port'){
			font-size: 15px;
			margin-bottom: 30px;
		}
		@include respond('phone-land'){
			max-width: 375px;
		}
	}
	.shape1{
		position: absolute;
		left: 0;
		bottom: 0;
		z-index: 0;
		
		@include respond('laptop'){
			width: 200px;
		}
		@media only screen and (max-width: 1191px){
			width: 150px;
		}
		@include respond('phone'){
			width: 100px;
		}
	}
	@include respond('tab-land'){
		height: 820px;
		h1{
			font-size: 62px;
			
			.text-line{
				&:after{
					top: 65px;
					width: 335px;
				}
			}
		}
	}
	@media only screen and (max-width: 1191px){
		height: 720px;
		h1{
			font-size: 45px;
			
			.text-line{
				&:after{
					top: 48px;
					width: 245px;
				}
			}
		}
	}
	@include respond('tab-port'){
		height: 580px;
		padding-top: 70px;
		
		h1{
			font-size: 34px;
			margin-bottom: 5px;

			.text-line{
				&:after{
					top: 37px;
					right: 0px;
					width: 190px;
				}
			}
		}
		.video-btn{
			display: none;
		}
	}
	@include respond('phone-land'){
		height: auto;
		padding-top: 135px;
		padding-bottom: 50px;
	}
	@include respond('phone'){
		padding-top: 100px;
		
		h1{
			font-size: 30px;
			
			.text-line{
				&:after{
					top: 32px;
					width: 170px;
				}
			}
		}
	}
}

.dz-bnr-inr{
	.shape1{
		position: absolute;
		left: 0;
		bottom: 0;
		z-index: 0;
		
		@include respond('laptop'){
			width: 150px;
		}
		@media only screen and (max-width: 1191px){
			width: 150px;
		}
		@include respond('phone'){
			width: 100px;
		}
	}
}


.banner-media{
    position: relative;
	width: 610px;
	min-width: 610px;
	height: 585px;
	-webkit-animation: aniBnrMedia 5s infinite;
    animation: aniBnrMedia 5s infinite;
	
	@include respond('laptop'){
		width: 580px;
		min-width: 580px;
		height: 555px;
	}
	@media only screen and (max-width: 1380px){
		width: 525px;
		min-width: 525px;
		height: 500px;
		margin-left: -30px;
	}
	@media only screen and (max-width: 1191px){
		width: 475px;
		min-width: 475px;
		height: 450px;
		margin-left: -10px;
	}
	@include respond('tab-port'){
		width: 275px;
		min-width: 275px;
		height: 270px;
		margin-left: 20px;
	}
	@include respond('phone-land'){
		margin-left: auto;
		margin-right: auto;
		margin-top: 30px;
	}
	
	.media{
		width: 100%;
		min-width: 100%;
	}
	[class*="shape"]{
		position: absolute;
	}
	.shape1{
		width: 90px;
		top: 40px;
		right: 15px;
		left: auto;
		
		@media only screen and (max-width: 1380px){
			width: 50px;
		}
		@include respond('tab-port'){
			top: 25px;
			width: 35px;
		}
	}
	.shape2{
		left: 210px;
		top: 0px;
		width: 80px;
		-webkit-animation: dZwobble 4s infinite;
		animation: dZwobble 4s infinite;
		
		@media only screen and (max-width: 1380px){
			left: 200px;
			width: 50px;
		}
		@include respond('tab-port'){
			left: 100px;
			width: 25px;
		}
	}
	.shape3{
	    left: 0;
		top: 230px;
		width: 100px;
		-webkit-animation: dZwobble 5s infinite;
		animation: dZwobble 5s infinite;
		
		@media only screen and (max-width: 1380px){
			width: 50px;
			left: 30px;
		}
		@include respond('tab-port'){
			width: 25px;
			left: 30px;
			top: 120px;
		}
	}
	.shape4{
		top: 0;
		left: 360px;
		width: 60px;
		-webkit-animation: aniBnrShape4 5s infinite;
		animation: aniBnrShape4 5s infinite;
		
		@media only screen and (max-width: 1380px){
			left: 320px;
			width: 40px;
		}
		@include respond('tab-port'){
			left: 175px;
			width: 20px;
		}
	}
	.shape5{
		width: 35px;
		top: 180px;
		right: 0;
		-webkit-animation: aniBnrShape5 3s infinite linear;
		animation: aniBnrShape5 3s infinite linear;
		
		@media only screen and (max-width: 1380px){
			width: 25px;
		}
		@include respond('tab-port'){
			width: 15px;
			top: 90px;
		}
	}
	.shape6{
		left: 40px;
		bottom: 90px;
		width: 60px;
		-webkit-animation: aniBnrShape7 12s infinite linear;
		animation: aniBnrShape7 12s infinite linear;
		
		@media only screen and (max-width: 1380px){
			width: 40px;
		}
		@include respond('tab-port'){
			width: 25px;
			left: 30px;
			bottom: 15px;
		}
	}
	.shape7{
		bottom: 210px;
		right: 0;
		width: 35px;
		-webkit-animation: aniBnrShape7 12s infinite linear;
		animation: aniBnrShape7 12s infinite linear;
		
		@media only screen and (max-width: 1380px){
			width: 25px;
		}
		@include respond('tab-port'){
			bottom: 95px;
			width: 20px;
		}
	}
}
@keyframes dZwobble {
  0% {
    -webkit-transform: rotate(-1deg) translate(-5px, -5px);
    transform: rotate(-1deg) translate(-5px, -5px); 
	}
  50% {
    -webkit-transform: rotate(1deg) translate(5px, 5px);
    transform: rotate(1deg) translate(5px, 5px); 
	}
  100% {
    -webkit-transform: rotate(-1deg) translate(-5px, -5px);
    transform: rotate(-1deg) translate(-5px, -5px); 
		} 
}
@keyframes aniBnrShape2{
	100%{
		-webkit-transform: rotateY(360deg);
		transform: rotateY(360deg);
	}
}
@keyframes aniBnrShape3{
	100%{
		-webkit-transform: rotateX(-360deg);
		transform: rotateX(-360deg);
	}
}
@keyframes aniBnrShape4{
	0%{
		-webkit-transform: rotate(0) translate(2px, 2px) scale(1);
		transform: rotate(0) translate(2px, 2px) scale(1);
	}
	50%{
		-webkit-transform: rotate(50deg) translate(2px, 2px) scale(1.2);
		transform: rotate(50deg) translate(2px, 2px) scale(1.2);
	}
	100%{
		-webkit-transform: rotate(0) translate(2px, 2px) scale(1);
		transform: rotate(0) translate(2px, 2px) scale(1);
	}
}
@keyframes aniBnrShape5{
	0%{
		-webkit-transform: skewY(0);
		transform: skewY(0);
	}
	50%{
		-webkit-transform: skewY(20deg);
		transform: skewY(20deg);
	}
	100%{
		-webkit-transform: skewY(0);
		transform: skewY(0);
	}
}
@keyframes aniBnrShape7{
	100%{
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes aniBnrMedia{
	0%{
		-webkit-transform: rotate(1deg) translate(2px, 2px);
		transform: rotate(1deg) translate(2px, 2px);
	}
	50%{
		-webkit-transform: rotate(-1deg) translate(-2px, -2px);
		transform: rotate(-1deg) translate(-2px, -2px);
	}
	100%{
		-webkit-transform: rotate(1deg) translate(2px, 2px);
		transform: rotate(1deg) translate(2px, 2px);
	}
}
	
// list-style-1
.list-style-1{
	counter-reset: li;
	
	li{
		display: block;
		position: relative;
		font-size: 20px;
		font-weight: 500;
		color:#527D95;
		padding: 6px 10px 6px 50px;
		@include respond('phone-land'){
			font-size: 18px;
		}
		@include respond('phone'){
			font-size: 16px;
			padding: 6px 10px 6px 45px;
		}
		&:after{
			content: counter(li);
			background-image: url(../images/shape/polygon7.svg);
			height: 35px;
			width: 35px;
			position: absolute;
			left: 0;
			background-position: center -0.5px;
			counter-increment: li;
			text-align: center;
			line-height: 35px;
			color: #fff;
			font-size: 16px;
			@include respond('phone'){
				top: 2px;
			}
		}
	}
}

// coins-wrapper
.coins-wrapper{
	.list-style-1{
		margin-bottom: 50px;
		@include respond('phone'){
				margin-bottom: 30px;
		}
		
		
	}
	.sub-title{
		font-size: 28px;
		font-weight: 500;
		margin-bottom: 12px;
		@include respond('phone-land'){
			font-size: 20px;
		}
	}
	.flex-coin1,
	.flex-coin2,
	.flex-coin3,
	.flex-coin4,
	.flex-coin5{
		position: absolute;
		z-index: -1;
		
		img{
			width: 48px;
		}
		&:after{
			content: "";
			height: 136px;
			width: 45px;
			position: absolute;
			left: 2px;
			top: 5px;
			z-index: -1;
			border-radius: 30px 30px 0 0;
			opacity: 0.4;
		}
	}
	.flex-coin1{
		top: 200px;
		left: 5%;
		
		&:after{
			background: linear-gradient(180deg, #85EA09 0%, rgba(133, 233, 9, 0) 100%);
		}
	}
	.flex-coin2{
		bottom: 250px;
		left: 10%;
		
		&:after{
			background: linear-gradient(180deg, #F9A922 0%, rgba(250, 171, 35, 0) 100%);
		}
		@include custommq ($max:1700px){
			left: 4%;
		}
	}
	.flex-coin3{
		bottom: 160px;
		right: 12%;
		
		&:after{
			background: linear-gradient(180deg, #33B994 0%, rgba(50, 183, 147, 0) 100%);
		}
	}
	.flex-coin4{
		top: 65px;
		right: 10%;
		
		&:after{
			background: linear-gradient(180deg, #7895F1 0%, rgba(117, 146, 240, 0) 100%);
		}
	}
	.flex-coin5{
		top: 50%;
		right: 4%;
		transform: translateY(-50%);
		
		&:after{
			background:  linear-gradient(180deg, #14A7FA 0%, rgba(10, 125, 246, 0) 100%);
		}
	}
	.coin-wave{
		@include custommq ($max:1700px){
			display:none;
		}
	}
}
.coins-media-wrapper{
	position: relative;
    display: block;
    width: 537px;
    height: 529px;
    margin-left: auto;
    margin-right: auto;
	@include respond('tab-land'){
		width: 450px;
		height: 442px;
	}
	@include respond('tab-port'){
		width: 537px;
		height: 529px;
	}
	@include respond('phone-land'){
		width: 450px;
		height: 442px;
	}
	@include respond('phone'){
		width: 320px;
		height: 315px;
	}
	.main-circle1{
		z-index: -1;
		height: 100%;
		width: 100%;
		position: relative;
	}
	.circle-box{
		background-image: url(../images/shape/polygon2.svg);
		background-position: center;
		background-size: 98%;
		background-repeat: no-repeat;
		position: absolute;
		height: 100%;
		width: 100%;
	}
	.center-media{
		width: 190px;
		height: 190px;
		top: 50%;
		left: 50%;
		position: absolute;
		transform: translate(-50%, -50%);
	}
	.coin-list{
		height: 100%;
		width: 100%;
	
		li{
			position: absolute;
			left: 50%;
			top: 50%;
			margin-left: -29px;
			margin-top: -29px;
			width: 58px;
			height: 58px;
			img{
				width: 100%;
				@include respond('phone'){
				    transform: scale(0.75);
				}
			}
		}
	}
}

.contact-wrapper{
	.form-wrapper-box{
		margin-bottom: -180px;
		margin-top: 0;
	}
	.section-head{
		margin-bottom: 40px;
		.title{
			margin-bottom: 12px;
		}
		p {
			font-size: 18px;
			line-height: 1.6;
		}
		@include respond('phone'){
			margin-bottom: 30px;
			p{
				font-size: 16px;
			}
		}
	}
	@include respond('tab-port'){
		.form-wrapper-box{
			margin-bottom: 0;
		}
	}
}
.form-wrapper-box{
	border-radius: 20px;
	box-shadow: 0px 25px 50px rgba(9, 9, 21, 0.1);
	padding: 50px;
	background-color: #fff;
	
	@include respond('laptop'){
		padding: 40px;
	}
	@include respond('phone'){
		padding: 30px;
	}
	
	.section-head{
		margin-bottom: 30px;
		.title{
			margin-bottom: 5px;
		}
		p{
			font-weight: 400;
			font-size: 20px;
			color: #444444;
		}
		@include respond('laptop'){
			p{
				font-size: 18px;
			}
		}
		@include respond('tab-land'){
			p{
				font-size: 16px;
			}
		}
		@include respond('phone'){
			margin-bottom: 25px;
		}
	}
	.form-control{
		background-color: transparent;
		z-index: 0 !important;
		@include transitionMedium;
	}
	textarea.form-control{
		height: 150px;
		padding: 20px 20px;
	}
	.input-group {
		margin-bottom: 30px;
		
		@include respond('phone'){
			margin-bottom: 20px;
		}
	}
	.input-area{
		position: relative;
		
		label{
			position: absolute;
			font-size: 16px;
			font-weight: 400;
			top: 15px;
			left: 15px;
			color: #555555;
			@include transitionFast;
			background-color: #fff;
			display: block;
			padding: 0 10px;
			@include respond('tab-land'){
				font-size: 14px;
			}
		}
		&.focused{
			label{
				position: absolute;
				font-size: 13px;
				top: -8px;
				color: $primary;
				z-index: 2;
			}
		}
		@include respond('phone'){
			label{
				top: 12px;
			}
		}
	}
}

.content-bx{
	&.style-1{
		.dz-media{
			position: relative;
			z-index: 1;
			margin-left:60px;
			overflow: unset !important;
			
			.content-box{
				width: fit-content;
				padding: 15px 25px;
				border-radius: 8px;
				background: #fff;
				position: absolute;
				bottom: 70px;
				left: -60px;
				box-shadow: 0px 10px 20px rgba(1, 18, 111, 0.15);
				z-index:2;
				@include respond('phone'){
					bottom: 15px;
					left: 15px;
				}
			}
			@include respond('phone'){
				margin-left:0px;
			}
		}
		.inner-content{
			margin-left:20px;
			@include respond('phone'){
				margin-left:0px;
			}	
		}
	}
	&.style-2{
		overflow:hidden;
		.dz-media{
			width: 50vw;
			float: left;
			@include respond('tab-port'){
				width:100%;
			}
		}
	}
}

.crypto-box{
	align-items: center;
    display: flex;
    background: #fff;
    padding: 28px 30px;
    border-radius: 10px;
	box-shadow: 0px 10px 20px rgba(1, 18, 111, 0.15);
	@include transitionMedium;
	margin-right: 20px;
	position: relative;
	
	.crypto-media{
		width: 65px;
		height: 65px;
		overflow: hidden;
		border-radius: 50px;
	
		img{
			width:100%;
		}
	}
	.crypto-info{
		padding-left:15px;
		h5{
			font-weight: 500;
			margin: 0;
			line-height: 1.2;
			margin-bottom: 8px;
			@include transitionMedium;
			font-size: 20px;
			
			span{
				color:#8EA5C8;
				font-size:16px;
				font-weight:400;
				@include transitionMedium;
			}
		}
		.price{
			color: #1E1E26;
			font-size:16px;
			font-weight:400;
			line-height: 20px;
			@include transitionMedium;
			span{
				font-size:15px;
				margin-left: 5px;
				font-weight: 500;
			}
		}
	}
	
	.btn-square{
		width: 48px;
		height: 48px;
		padding: 0;
		position: absolute;
		right: -20px;
		top: 50%;
		transform: translateY(-50%);
	}
	@include respond('tab-land'){
	    padding: 20px 20px;
		margin-right: 15px;
		
		.crypto-media {
			width: 50px;
			height: 50px;
		}
		.crypto-info {
			padding-left: 12px;
			
			.price{
				font-size: 15px;
				span {
					font-size: 14px;
				}
			}
			h5{
				font-size: 18px;
			}
		}
		.btn-square{
			width: 40px;
			height: 40px;
			right: -15px;
		}
	}
}
.crypto-wrapper{
	margin-top: -62px;
	@include respond('tab-land'){
		margin-top: -42px;
	}
}	  


.call-to-action{
	.title {
		font-size:55px;
		@include respond('laptop'){
		font-size:52px;
		}
		@include respond('tab-land'){
			font-size:42px;
		}
		@include respond('phone'){
			font-size:24px;
		}
	}
}
.clients-logo{
	.logo-main{
		opacity:0.2;
		@include transitionMedium;
		
		&:hover{
			opacity:1;
		}
	}
}

.dz-meta{
	.post-author{
		img{
			border:2px solid #fff;
			border-radius:100%;
			width:50px;

		}
	}
}

.info-box{
	&.style-1{
	color:#fff !important;
	padding:75px;	
	background:var(--primary);
		@include respond('phone'){
			padding:65px;
		}
		@include respond('laptop'){
			padding:50px;
		}
		@include respond('phone'){
			padding:20px;
		}
	
	}
	.widget{
		ul{
			li{
			border-bottom: 1px solid rgba(255, 255, 255, 0.3);
			width: 80%;
			padding:20px 0;
				p{
				color:#fff;
				font-size:18px;
				font-weight:400;
				opacity:0.6;
				margin-bottom:5px;
				}
				h6 {
				font-size: 18px;
				font-weight: 500;
				}
			}
		}
	}
}


.dz-social{
	&.style-1{
		padding-top:4rem;
		.social-icon{
			display:flex;
			align-items:center;
			gap:15px;
			li{
				background:rgba(255,255,255,0.1);
				width:40px;
				height:40px;
				border-radius:8px;
				text-align:center;
				position:relative;
				z-index:1;
				a{
					color:#fff;
					text-align:center;
					color: #fff;
					text-align: center;
					line-height: 40px;
				}
				&:after {
					content: "";
					position: absolute;
					height: 40px;
					width: 0;
					background-color: var(--secondary);
					left: 0px;
					top: 0;
					z-index: -1;
					border-radius: var(--border-radius-base);
					-webkit-transition: all 0.5s;
					-ms-transition: all 0.5s;
					transition: all 0.5s;
					z-index:-1;
				}
				&:hover{
					&:after {
						width:100%;
					
					}
				}
			}
		}	
	}
}




.form-wrapper{
	&.style-1{
		padding: 6rem;
		@include respond('tab-port'){
			padding:50px;
		}
		@include respond('phone'){
			padding:20px;
		}
		@include respond('laptop'){
			padding:75px;
		}
		@include respond('tab-land'){
			padding:50px;
		}
		@include respond('phone-land'){
			padding:20px;
		}
	}
}

.content-inner{
	&.style-1{
		padding:85px  0 0 0  !important;
	
	}
}

.dz-meta{
	margin-bottom: 10px;
	
	ul{
		margin:0;
		padding:0;
		
		li{
			display: inline-block;
			position: relative;
			font-size: 15px;
			margin-right: 30px;
			
			&:after{
				content: "";
				height: 6px;
				width: 6px;
				background-color: var(--primary);
				position: absolute;
				top: 50%;
				right: -20px;
				border-radius: 50%;
				transform: translateY(-50%);
			}
			
			&:last-child{
				margin-right:0;
				padding-right: 0;
				&:after{
					content: none;
				}
			}
			i{
				font-size: 16px;
				margin-right: 2px;
				color: var(--primary);
				transform: scale(1.3);
				position: relative;
				top: 2px;
				padding-right: 0.2rem;
			}
			a{
				color:inherit;
			}
			@include respond('phone'){
				margin-right: 10px;
				padding-right: 10px;
			}
		}	
	}	
}


button.btn.btn-primary{
	&.style-1 {
		position: absolute;
		top: 5px;
		right: 5px;
		border-radius: 8px !important;
		padding: 13px;
		z-index:9;
	}
}
.contant-box{
	&.style-1{
		display:flex;
		align-items:center;
		
		
		@include respond('phone'){
			display:unset;
		}
	}
}

// .inner-content{
// 	&.style-1{
		
// 	}
// }
// .animated-cursor {
// 	position: absolute;
// 	width: 12px;
// 	height: 12px;
// 	border-radius: 50%;
// 	background-color: $black;
// 	// animation: pulse 1s ease-out infinite;
// 	pointer-events: none;
// 	transition: border-color 0.5s;
// 	background: #333;
// 	box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2);

// 	&:after{
// 		content:"";
// 		left: -9px;
// 		top: -9px;
// 		width: 30px;
// 		height: 30px;
// 		display: block;
// 		border: 1px solid var(--primary) !important;
// 		position: absolute;
// 		border-radius: 100px;
// 		z-index: 2;
// 		pointer-events: none;
// 		transition: width 0.3s, height 0.3s;
// 	}
// }

// @keyframes pulse {
// 	0% {
// 	  transform: scale(1);
// 	  opacity: 1;
// 	}
// 	50% {
// 	  transform: scale(1.5);
// 	  opacity: 0.5;
// 	}
// 	100% {
// 	  transform: scale(1);
// 	  opacity: 1;
// 	}
// }
// #pointer-dot {
// 	left: -4px;
// 	top: -4px;
// 	width: 12px;
// 	height: 12px;
// 	position: fixed;
// 	border-radius: 10px;
// 	z-index: 999998;
// 	pointer-events: none;
// 	transition: border-color 0.5s;
// 	background: #333;
// 	box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2);
// }
// #pointer-ring {
// 	left: -4px;
// 	top: -4px;
// 	width: 12px;
// 	height: 12px;
// 	position: fixed;
// 	border-radius: 10px;
// 	z-index: 999998;
// 	pointer-events: none;
// 	transition: border-color 0.5s;
// 	background: #333;
// 	box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2);
// }
.cursor {
	position: fixed;
	width: 30px;
	height: 30px;
	border: 1px solid var(--primary);
	border-radius: 50%;
	left: 0;
	top: 0;
	z-index: 999998;
	pointer-events: none;
	transform: translate(-50%, -50%);	
	transition: 0.10s;
	
}
.cursor2 {
	position: fixed;
	width: 12px;
	height: 12px;
	border: 1px solid #c6c6c6;
	border-radius: 50%;
	left: 0;
	top: 0;
	z-index: 999998;
	pointer-events: none;
	transform: translate(-50%, -50%);
	background: #333;	
	transition: 0.05s;
	box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2);
}

