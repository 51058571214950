@charset "UTF-8";
/*
    Name: Coiniy
    
    Table of Content:

    1. Abstract
    2. Bootstrap CSS
    3. Base
    4. Layout
    5. Components
    6. Pages
    
*/
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('../icons/font-awesome/css/all.min.css');
:root {
    --gradient: linear-gradient(to right, rgb(255, 64, 62) 0%, rgb(255, 129, 27) 100%);
    --gradient-sec: linear-gradient(to right, rgb(57, 26, 166) 0%, rgb(6, 163, 245) 100%);
    --primary: #f0aa1f;
    --secondary: #090915;
    --primary-hover: #db9c1d;
    --primary-dark: #004267;
    --rgba-primary-1: rgba(240, 170, 31, 0.1);
    --rgba-primary-2: rgba(240, 170, 31, 0.2);
    --rgba-primary-3: rgba(240, 170, 31, 0.3);
    --rgba-primary-4: rgba(240, 170, 31, 0.4);
    --rgba-primary-5: rgba(240, 170, 31, 0.5);
    --rgba-primary-6: rgba(240, 170, 31, 0.6);
    --rgba-primary-7: rgba(240, 170, 31, 0.7);
    --rgba-primary-8: rgba(240, 170, 31, 0.8);
    --rgba-primary-9: rgba(240, 170, 31, 0.9);
    --body-color: #52525e;
    --border-color: #cfdbed;
    --body-bg: #ffffff;
    --font-family-base: Poppins, sans-serif;
    --grey: #f4f6fa;
    --bg-image: '';
    --input-bg: #fff;
    --card-bg: #fff;
    --border-radius-base: 8px;
    --font-family-title: Poppins, sans-serif;
    --title: #1e1e26;
    --headings-font-weight: 600;
    --headings-font-weight2: 400;
    --headings-font-weight3: 300;
}

/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
    --bs-gray-100: #f8f9fa;
    --bs-gray-200: #e9ecef;
    --bs-gray-300: #dee2e6;
    --bs-gray-400: #ced4da;
    --bs-gray-500: #adb5bd;
    --bs-gray-600: #6c757d;
    --bs-gray-700: #495057;
    --bs-gray-800: #343a40;
    --bs-gray-900: #212529;
    --bs-primary: #02a3fe;
    --bs-secondary: #090915;
    --bs-success: #11b011;
    --bs-info: #00aeff;
    --bs-warning: #ffbb69;
    --bs-danger: #eb5757;
    --bs-light: #fffbf5;
    --bs-dark: #212529;
    --bs-primary-rgb: 2, 163, 254;
    --bs-secondary-rgb: 9, 9, 21;
    --bs-success-rgb: 17, 176, 17;
    --bs-info-rgb: 0, 174, 255;
    --bs-warning-rgb: 255, 187, 105;
    --bs-danger-rgb: 235, 87, 87;
    --bs-light-rgb: 255, 251, 245;
    --bs-dark-rgb: 33, 37, 41;
    --bs-white-rgb: 255, 255, 255;
    --bs-black-rgb: 0, 0, 0;
    --bs-body-color-rgb: 82, 82, 94;
    --bs-body-bg-rgb: 255, 255, 255;
    --bs-font-sans-serif: system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
        'Liberation Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    --bs-body-font-family: Poppins, sans-serif;
    --bs-body-font-size: 1rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.6;
    --bs-body-color: #52525e;
    --bs-body-bg: #ffffff;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
    :root {
        scroll-behavior: smooth;
    }
}

body {
    margin: 0;
    font-family: var(--bs-body-font-family);
    font-size: var(--bs-body-font-size);
    font-weight: var(--bs-body-font-weight);
    line-height: var(--bs-body-line-height);
    color: var(--bs-body-color);
    text-align: var(--bs-body-text-align);
    background-color: var(--bs-body-bg);
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
    margin: 1rem 0;
    color: inherit;
    background-color: currentColor;
    border: 0;
    opacity: 0.25;
}

hr:not([size]) {
    height: 1px;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
.comment-reply-title,
.wp-block-search .wp-block-search__label,
h5,
.h5,
h6,
.h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 600;
    line-height: 1.2;
    color: #1e1e26;
}

h1,
.h1 {
    font-size: calc(1.425rem + 2.1vw);
}
@media (min-width: 1200px) {
    h1,
    .h1 {
        font-size: 3rem;
    }
}

h2,
.h2 {
    font-size: calc(1.3625rem + 1.35vw);
}
@media (min-width: 1200px) {
    h2,
    .h2 {
        font-size: 2.375rem;
    }
}

h3,
.h3 {
    font-size: calc(1.3125rem + 0.75vw);
}
@media (min-width: 1200px) {
    h3,
    .h3 {
        font-size: 1.875rem;
    }
}

h4,
.h4,
.comment-reply-title,
.wp-block-search .wp-block-search__label {
    font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
    h4,
    .h4,
    .comment-reply-title,
    .wp-block-search .wp-block-search__label {
        font-size: 1.5rem;
    }
}

h5,
.h5 {
    font-size: 1.25rem;
}

h6,
.h6 {
    font-size: 1.125rem;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
    text-decoration: underline dotted;
    cursor: help;
    text-decoration-skip-ink: none;
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
}

ol,
ul {
    padding-left: 2rem !important;
}

ol,
ul,
dl {
    margin-top: 0 !important;
    margin-bottom: 1rem !important;
}

ol ol,
ul ul,
ol ul,
ul ol {
    margin-bottom: 0 !important;
}

dt {
    font-weight: 700;
}

dd {
    margin-bottom: 0.5rem;
    margin-left: 0;
}

blockquote {
    margin: 0 0 1rem;
}

b,
strong {
    font-weight: bolder;
}

small,
.small {
    font-size: 0.875em;
}

mark,
.mark {
    padding: 0.2em;
    background-color: #fcf8e3;
}

sub,
sup {
    position: relative;
    font-size: 0.75em;
    line-height: 0;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

a {
    color: #02a3fe;
    text-decoration: underline;
}
a:hover {
    color: #0282cb;
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none;
}

pre,
code,
kbd,
samp {
    font-family: var(--bs-font-monospace);
    font-size: 1em;
    direction: ltr /* rtl:ignore */;
    unicode-bidi: bidi-override;
}

pre {
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    font-size: 0.875em;
}
pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
}

code {
    font-size: 0.875em;
    color: #d63384;
    word-wrap: break-word;
}
a > code {
    color: inherit;
}

kbd {
    padding: 0.2rem 0.4rem;
    font-size: 0.875em;
    color: #ffffff;
    background-color: #212529;
    border-radius: 0.75rem;
}
kbd kbd {
    padding: 0;
    font-size: 1em;
    font-weight: 700;
}

figure {
    margin: 0 0 1rem;
}

img,
svg {
    vertical-align: middle;
}

table {
    caption-side: bottom;
    border-collapse: collapse;
}

caption {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: rgba(151, 151, 159, 0.39);
    text-align: left;
}

th {
    text-align: inherit;
    text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
}

label {
    display: inline-block;
}

button {
    border-radius: 0;
}

button:focus:not(:focus-visible) {
    outline: 0;
}

input,
button,
select,
optgroup,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button,
select {
    text-transform: none;
}

[role='button'] {
    cursor: pointer;
}

select {
    word-wrap: normal;
}
select:disabled {
    opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
    display: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
    -webkit-appearance: button;
}
button:not(:disabled),
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled) {
    cursor: pointer;
}

::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

textarea {
    resize: vertical;
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
}

legend {
    float: left;
    width: 100%;
    padding: 0;
    margin-bottom: 0.5rem;
    font-size: calc(1.275rem + 0.3vw);
    line-height: inherit;
}
@media (min-width: 1200px) {
    legend {
        font-size: 1.5rem;
    }
}
legend + * {
    clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
    padding: 0;
}

::-webkit-inner-spin-button {
    height: auto;
}

[type='search'] {
    outline-offset: -2px;
    -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
    padding: 0;
}

::file-selector-button {
    font: inherit;
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
}

output {
    display: inline-block;
}

iframe {
    border: 0;
}

summary {
    display: list-item;
    cursor: pointer;
}

progress {
    vertical-align: baseline;
}

[hidden] {
    display: none !important;
}

.lead {
    font-size: 1.25rem;
    font-weight: 300;
}

.display-1 {
    font-size: calc(1.625rem + 4.5vw);
    font-weight: 300;
    line-height: 1.2;
}
@media (min-width: 1200px) {
    .display-1 {
        font-size: 5rem;
    }
}

.display-2 {
    font-size: calc(1.575rem + 3.9vw);
    font-weight: 300;
    line-height: 1.2;
}
@media (min-width: 1200px) {
    .display-2 {
        font-size: 4.5rem;
    }
}

.display-3 {
    font-size: calc(1.525rem + 3.3vw);
    font-weight: 300;
    line-height: 1.2;
}
@media (min-width: 1200px) {
    .display-3 {
        font-size: 4rem;
    }
}

.display-4 {
    font-size: calc(1.475rem + 2.7vw);
    font-weight: 300;
    line-height: 1.2;
}
@media (min-width: 1200px) {
    .display-4 {
        font-size: 3.5rem;
    }
}

.display-5 {
    font-size: calc(1.425rem + 2.1vw);
    font-weight: 300;
    line-height: 1.2;
}
@media (min-width: 1200px) {
    .display-5 {
        font-size: 3rem;
    }
}

.display-6 {
    font-size: calc(1.375rem + 1.5vw);
    font-weight: 300;
    line-height: 1.2;
}
@media (min-width: 1200px) {
    .display-6 {
        font-size: 2.5rem;
    }
}

.list-unstyled {
    padding-left: 0;
    list-style: none;
}

.list-inline {
    padding-left: 0;
    list-style: none;
}

.list-inline-item {
    display: inline-block;
}
.list-inline-item:not(:last-child) {
    margin-right: 0.5rem;
}

.initialism {
    font-size: 0.875em;
    text-transform: uppercase;
}

.blockquote {
    margin-bottom: 1rem;
    font-size: 1.25rem;
}
.blockquote > :last-child {
    margin-bottom: 0;
}

.blockquote-footer {
    margin-top: -1rem;
    margin-bottom: 1rem;
    font-size: 0.875em;
    color: #6c757d;
}
.blockquote-footer::before {
    content: '\2014\00A0';
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.img-thumbnail {
    padding: 0.25rem;
    background-color: #ffffff;
    border: 1px solid #dee2e6;
    border-radius: 0.375rem;
    max-width: 100%;
    height: auto;
}

.figure {
    display: inline-block;
}

.figure-img {
    margin-bottom: 0.5rem;
    line-height: 1;
}

.figure-caption {
    font-size: 0.875em;
    color: #6c757d;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
    width: 100%;
    padding-right: var(--bs-gutter-x, 15px);
    padding-left: var(--bs-gutter-x, 15px);
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container,
    .container-sm {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container,
    .container-sm,
    .container-md {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container,
    .container-sm,
    .container-md,
    .container-lg {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl {
        max-width: 1250px;
    }
}

.row {
    --bs-gutter-x: 30px;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-1 * var(--bs-gutter-y));
    margin-right: calc(-0.5 * var(--bs-gutter-x));
    margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y);
}

.col {
    flex: 1 0 0%;
}

.row-cols-auto > * {
    flex: 0 0 auto;
    width: auto;
}

.row-cols-1 > * {
    flex: 0 0 auto;
    width: 100%;
}

.row-cols-2 > * {
    flex: 0 0 auto;
    width: 50%;
}

.row-cols-3 > * {
    flex: 0 0 auto;
    width: 33.33333%;
}

.row-cols-4 > * {
    flex: 0 0 auto;
    width: 25%;
}

.row-cols-5 > * {
    flex: 0 0 auto;
    width: 20%;
}

.row-cols-6 > * {
    flex: 0 0 auto;
    width: 16.66667%;
}

.col-auto {
    flex: 0 0 auto;
    width: auto;
}

.col-1 {
    flex: 0 0 auto;
    width: 8.33333%;
}

.col-2 {
    flex: 0 0 auto;
    width: 16.66667%;
}

.col-3 {
    flex: 0 0 auto;
    width: 25%;
}

.col-4 {
    flex: 0 0 auto;
    width: 33.33333%;
}

.col-5 {
    flex: 0 0 auto;
    width: 41.66667%;
}

.col-6 {
    flex: 0 0 auto;
    width: 50%;
}

.col-7 {
    flex: 0 0 auto;
    width: 58.33333%;
}

.col-8 {
    flex: 0 0 auto;
    width: 66.66667%;
}

.col-9 {
    flex: 0 0 auto;
    width: 75%;
}

.col-10 {
    flex: 0 0 auto;
    width: 83.33333%;
}

.col-11 {
    flex: 0 0 auto;
    width: 91.66667%;
}

.col-12 {
    flex: 0 0 auto;
    width: 100%;
}

.offset-1 {
    margin-left: 8.33333%;
}

.offset-2 {
    margin-left: 16.66667%;
}

.offset-3 {
    margin-left: 25%;
}

.offset-4 {
    margin-left: 33.33333%;
}

.offset-5 {
    margin-left: 41.66667%;
}

.offset-6 {
    margin-left: 50%;
}

.offset-7 {
    margin-left: 58.33333%;
}

.offset-8 {
    margin-left: 66.66667%;
}

.offset-9 {
    margin-left: 75%;
}

.offset-10 {
    margin-left: 83.33333%;
}

.offset-11 {
    margin-left: 91.66667%;
}

.g-0,
.gx-0 {
    --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
    --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
    --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
    --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
    --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
    --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
    --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
    --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
    --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
    --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
    --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
    --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
    .col-sm {
        flex: 1 0 0%;
    }
    .row-cols-sm-auto > * {
        flex: 0 0 auto;
        width: auto;
    }
    .row-cols-sm-1 > * {
        flex: 0 0 auto;
        width: 100%;
    }
    .row-cols-sm-2 > * {
        flex: 0 0 auto;
        width: 50%;
    }
    .row-cols-sm-3 > * {
        flex: 0 0 auto;
        width: 33.33333%;
    }
    .row-cols-sm-4 > * {
        flex: 0 0 auto;
        width: 25%;
    }
    .row-cols-sm-5 > * {
        flex: 0 0 auto;
        width: 20%;
    }
    .row-cols-sm-6 > * {
        flex: 0 0 auto;
        width: 16.66667%;
    }
    .col-sm-auto {
        flex: 0 0 auto;
        width: auto;
    }
    .col-sm-1 {
        flex: 0 0 auto;
        width: 8.33333%;
    }
    .col-sm-2 {
        flex: 0 0 auto;
        width: 16.66667%;
    }
    .col-sm-3 {
        flex: 0 0 auto;
        width: 25%;
    }
    .col-sm-4 {
        flex: 0 0 auto;
        width: 33.33333%;
    }
    .col-sm-5 {
        flex: 0 0 auto;
        width: 41.66667%;
    }
    .col-sm-6 {
        flex: 0 0 auto;
        width: 50%;
    }
    .col-sm-7 {
        flex: 0 0 auto;
        width: 58.33333%;
    }
    .col-sm-8 {
        flex: 0 0 auto;
        width: 66.66667%;
    }
    .col-sm-9 {
        flex: 0 0 auto;
        width: 75%;
    }
    .col-sm-10 {
        flex: 0 0 auto;
        width: 83.33333%;
    }
    .col-sm-11 {
        flex: 0 0 auto;
        width: 91.66667%;
    }
    .col-sm-12 {
        flex: 0 0 auto;
        width: 100%;
    }
    .offset-sm-0 {
        margin-left: 0;
    }
    .offset-sm-1 {
        margin-left: 8.33333%;
    }
    .offset-sm-2 {
        margin-left: 16.66667%;
    }
    .offset-sm-3 {
        margin-left: 25%;
    }
    .offset-sm-4 {
        margin-left: 33.33333%;
    }
    .offset-sm-5 {
        margin-left: 41.66667%;
    }
    .offset-sm-6 {
        margin-left: 50%;
    }
    .offset-sm-7 {
        margin-left: 58.33333%;
    }
    .offset-sm-8 {
        margin-left: 66.66667%;
    }
    .offset-sm-9 {
        margin-left: 75%;
    }
    .offset-sm-10 {
        margin-left: 83.33333%;
    }
    .offset-sm-11 {
        margin-left: 91.66667%;
    }
    .g-sm-0,
    .gx-sm-0 {
        --bs-gutter-x: 0;
    }
    .g-sm-0,
    .gy-sm-0 {
        --bs-gutter-y: 0;
    }
    .g-sm-1,
    .gx-sm-1 {
        --bs-gutter-x: 0.25rem;
    }
    .g-sm-1,
    .gy-sm-1 {
        --bs-gutter-y: 0.25rem;
    }
    .g-sm-2,
    .gx-sm-2 {
        --bs-gutter-x: 0.5rem;
    }
    .g-sm-2,
    .gy-sm-2 {
        --bs-gutter-y: 0.5rem;
    }
    .g-sm-3,
    .gx-sm-3 {
        --bs-gutter-x: 1rem;
    }
    .g-sm-3,
    .gy-sm-3 {
        --bs-gutter-y: 1rem;
    }
    .g-sm-4,
    .gx-sm-4 {
        --bs-gutter-x: 1.5rem;
    }
    .g-sm-4,
    .gy-sm-4 {
        --bs-gutter-y: 1.5rem;
    }
    .g-sm-5,
    .gx-sm-5 {
        --bs-gutter-x: 3rem;
    }
    .g-sm-5,
    .gy-sm-5 {
        --bs-gutter-y: 3rem;
    }
}

@media (min-width: 768px) {
    .col-md {
        flex: 1 0 0%;
    }
    .row-cols-md-auto > * {
        flex: 0 0 auto;
        width: auto;
    }
    .row-cols-md-1 > * {
        flex: 0 0 auto;
        width: 100%;
    }
    .row-cols-md-2 > * {
        flex: 0 0 auto;
        width: 50%;
    }
    .row-cols-md-3 > * {
        flex: 0 0 auto;
        width: 33.33333%;
    }
    .row-cols-md-4 > * {
        flex: 0 0 auto;
        width: 25%;
    }
    .row-cols-md-5 > * {
        flex: 0 0 auto;
        width: 20%;
    }
    .row-cols-md-6 > * {
        flex: 0 0 auto;
        width: 16.66667%;
    }
    .col-md-auto {
        flex: 0 0 auto;
        width: auto;
    }
    .col-md-1 {
        flex: 0 0 auto;
        width: 8.33333%;
    }
    .col-md-2 {
        flex: 0 0 auto;
        width: 16.66667%;
    }
    .col-md-3 {
        flex: 0 0 auto;
        width: 25%;
    }
    .col-md-4 {
        flex: 0 0 auto;
        width: 33.33333%;
    }
    .col-md-5 {
        flex: 0 0 auto;
        width: 41.66667%;
    }
    .col-md-6 {
        flex: 0 0 auto;
        width: 50%;
    }
    .col-md-7 {
        flex: 0 0 auto;
        width: 58.33333%;
    }
    .col-md-8 {
        flex: 0 0 auto;
        width: 66.66667%;
    }
    .col-md-9 {
        flex: 0 0 auto;
        width: 75%;
    }
    .col-md-10 {
        flex: 0 0 auto;
        width: 83.33333%;
    }
    .col-md-11 {
        flex: 0 0 auto;
        width: 91.66667%;
    }
    .col-md-12 {
        flex: 0 0 auto;
        width: 100%;
    }
    .offset-md-0 {
        margin-left: 0;
    }
    .offset-md-1 {
        margin-left: 8.33333%;
    }
    .offset-md-2 {
        margin-left: 16.66667%;
    }
    .offset-md-3 {
        margin-left: 25%;
    }
    .offset-md-4 {
        margin-left: 33.33333%;
    }
    .offset-md-5 {
        margin-left: 41.66667%;
    }
    .offset-md-6 {
        margin-left: 50%;
    }
    .offset-md-7 {
        margin-left: 58.33333%;
    }
    .offset-md-8 {
        margin-left: 66.66667%;
    }
    .offset-md-9 {
        margin-left: 75%;
    }
    .offset-md-10 {
        margin-left: 83.33333%;
    }
    .offset-md-11 {
        margin-left: 91.66667%;
    }
    .g-md-0,
    .gx-md-0 {
        --bs-gutter-x: 0;
    }
    .g-md-0,
    .gy-md-0 {
        --bs-gutter-y: 0;
    }
    .g-md-1,
    .gx-md-1 {
        --bs-gutter-x: 0.25rem;
    }
    .g-md-1,
    .gy-md-1 {
        --bs-gutter-y: 0.25rem;
    }
    .g-md-2,
    .gx-md-2 {
        --bs-gutter-x: 0.5rem;
    }
    .g-md-2,
    .gy-md-2 {
        --bs-gutter-y: 0.5rem;
    }
    .g-md-3,
    .gx-md-3 {
        --bs-gutter-x: 1rem;
    }
    .g-md-3,
    .gy-md-3 {
        --bs-gutter-y: 1rem;
    }
    .g-md-4,
    .gx-md-4 {
        --bs-gutter-x: 1.5rem;
    }
    .g-md-4,
    .gy-md-4 {
        --bs-gutter-y: 1.5rem;
    }
    .g-md-5,
    .gx-md-5 {
        --bs-gutter-x: 3rem;
    }
    .g-md-5,
    .gy-md-5 {
        --bs-gutter-y: 3rem;
    }
}

@media (min-width: 992px) {
    .col-lg {
        flex: 1 0 0%;
    }
    .row-cols-lg-auto > * {
        flex: 0 0 auto;
        width: auto;
    }
    .row-cols-lg-1 > * {
        flex: 0 0 auto;
        width: 100%;
    }
    .row-cols-lg-2 > * {
        flex: 0 0 auto;
        width: 50%;
    }
    .row-cols-lg-3 > * {
        flex: 0 0 auto;
        width: 33.33333%;
    }
    .row-cols-lg-4 > * {
        flex: 0 0 auto;
        width: 25%;
    }
    .row-cols-lg-5 > * {
        flex: 0 0 auto;
        width: 20%;
    }
    .row-cols-lg-6 > * {
        flex: 0 0 auto;
        width: 16.66667%;
    }
    .col-lg-auto {
        flex: 0 0 auto;
        width: auto;
    }
    .col-lg-1 {
        flex: 0 0 auto;
        width: 8.33333%;
    }
    .col-lg-2 {
        flex: 0 0 auto;
        width: 16.66667%;
    }
    .col-lg-3 {
        flex: 0 0 auto;
        width: 25%;
    }
    .col-lg-4 {
        flex: 0 0 auto;
        width: 33.33333%;
    }
    .col-lg-5 {
        flex: 0 0 auto;
        width: 41.66667%;
    }
    .col-lg-6 {
        flex: 0 0 auto;
        width: 50%;
    }
    .col-lg-7 {
        flex: 0 0 auto;
        width: 58.33333%;
    }
    .col-lg-8 {
        flex: 0 0 auto;
        width: 66.66667%;
    }
    .col-lg-9 {
        flex: 0 0 auto;
        width: 75%;
    }
    .col-lg-10 {
        flex: 0 0 auto;
        width: 83.33333%;
    }
    .col-lg-11 {
        flex: 0 0 auto;
        width: 91.66667%;
    }
    .col-lg-12 {
        flex: 0 0 auto;
        width: 100%;
    }
    .offset-lg-0 {
        margin-left: 0;
    }
    .offset-lg-1 {
        margin-left: 8.33333%;
    }
    .offset-lg-2 {
        margin-left: 16.66667%;
    }
    .offset-lg-3 {
        margin-left: 25%;
    }
    .offset-lg-4 {
        margin-left: 33.33333%;
    }
    .offset-lg-5 {
        margin-left: 41.66667%;
    }
    .offset-lg-6 {
        margin-left: 50%;
    }
    .offset-lg-7 {
        margin-left: 58.33333%;
    }
    .offset-lg-8 {
        margin-left: 66.66667%;
    }
    .offset-lg-9 {
        margin-left: 75%;
    }
    .offset-lg-10 {
        margin-left: 83.33333%;
    }
    .offset-lg-11 {
        margin-left: 91.66667%;
    }
    .g-lg-0,
    .gx-lg-0 {
        --bs-gutter-x: 0;
    }
    .g-lg-0,
    .gy-lg-0 {
        --bs-gutter-y: 0;
    }
    .g-lg-1,
    .gx-lg-1 {
        --bs-gutter-x: 0.25rem;
    }
    .g-lg-1,
    .gy-lg-1 {
        --bs-gutter-y: 0.25rem;
    }
    .g-lg-2,
    .gx-lg-2 {
        --bs-gutter-x: 0.5rem;
    }
    .g-lg-2,
    .gy-lg-2 {
        --bs-gutter-y: 0.5rem;
    }
    .g-lg-3,
    .gx-lg-3 {
        --bs-gutter-x: 1rem;
    }
    .g-lg-3,
    .gy-lg-3 {
        --bs-gutter-y: 1rem;
    }
    .g-lg-4,
    .gx-lg-4 {
        --bs-gutter-x: 1.5rem;
    }
    .g-lg-4,
    .gy-lg-4 {
        --bs-gutter-y: 1.5rem;
    }
    .g-lg-5,
    .gx-lg-5 {
        --bs-gutter-x: 3rem;
    }
    .g-lg-5,
    .gy-lg-5 {
        --bs-gutter-y: 3rem;
    }
}

@media (min-width: 1200px) {
    .col-xl {
        flex: 1 0 0%;
    }
    .row-cols-xl-auto > * {
        flex: 0 0 auto;
        width: auto;
    }
    .row-cols-xl-1 > * {
        flex: 0 0 auto;
        width: 100%;
    }
    .row-cols-xl-2 > * {
        flex: 0 0 auto;
        width: 50%;
    }
    .row-cols-xl-3 > * {
        flex: 0 0 auto;
        width: 33.33333%;
    }
    .row-cols-xl-4 > * {
        flex: 0 0 auto;
        width: 25%;
    }
    .row-cols-xl-5 > * {
        flex: 0 0 auto;
        width: 20%;
    }
    .row-cols-xl-6 > * {
        flex: 0 0 auto;
        width: 16.66667%;
    }
    .col-xl-auto {
        flex: 0 0 auto;
        width: auto;
    }
    .col-xl-1 {
        flex: 0 0 auto;
        width: 8.33333%;
    }
    .col-xl-2 {
        flex: 0 0 auto;
        width: 16.66667%;
    }
    .col-xl-3 {
        flex: 0 0 auto;
        width: 25%;
    }
    .col-xl-4 {
        flex: 0 0 auto;
        width: 33.33333%;
    }
    .col-xl-5 {
        flex: 0 0 auto;
        width: 41.66667%;
    }
    .col-xl-6 {
        flex: 0 0 auto;
        width: 50%;
    }
    .col-xl-7 {
        flex: 0 0 auto;
        width: 58.33333%;
    }
    .col-xl-8 {
        flex: 0 0 auto;
        width: 66.66667%;
    }
    .col-xl-9 {
        flex: 0 0 auto;
        width: 75%;
    }
    .col-xl-10 {
        flex: 0 0 auto;
        width: 83.33333%;
    }
    .col-xl-11 {
        flex: 0 0 auto;
        width: 91.66667%;
    }
    .col-xl-12 {
        flex: 0 0 auto;
        width: 100%;
    }
    .offset-xl-0 {
        margin-left: 0;
    }
    .offset-xl-1 {
        margin-left: 8.33333%;
    }
    .offset-xl-2 {
        margin-left: 16.66667%;
    }
    .offset-xl-3 {
        margin-left: 25%;
    }
    .offset-xl-4 {
        margin-left: 33.33333%;
    }
    .offset-xl-5 {
        margin-left: 41.66667%;
    }
    .offset-xl-6 {
        margin-left: 50%;
    }
    .offset-xl-7 {
        margin-left: 58.33333%;
    }
    .offset-xl-8 {
        margin-left: 66.66667%;
    }
    .offset-xl-9 {
        margin-left: 75%;
    }
    .offset-xl-10 {
        margin-left: 83.33333%;
    }
    .offset-xl-11 {
        margin-left: 91.66667%;
    }
    .g-xl-0,
    .gx-xl-0 {
        --bs-gutter-x: 0;
    }
    .g-xl-0,
    .gy-xl-0 {
        --bs-gutter-y: 0;
    }
    .g-xl-1,
    .gx-xl-1 {
        --bs-gutter-x: 0.25rem;
    }
    .g-xl-1,
    .gy-xl-1 {
        --bs-gutter-y: 0.25rem;
    }
    .g-xl-2,
    .gx-xl-2 {
        --bs-gutter-x: 0.5rem;
    }
    .g-xl-2,
    .gy-xl-2 {
        --bs-gutter-y: 0.5rem;
    }
    .g-xl-3,
    .gx-xl-3 {
        --bs-gutter-x: 1rem;
    }
    .g-xl-3,
    .gy-xl-3 {
        --bs-gutter-y: 1rem;
    }
    .g-xl-4,
    .gx-xl-4 {
        --bs-gutter-x: 1.5rem;
    }
    .g-xl-4,
    .gy-xl-4 {
        --bs-gutter-y: 1.5rem;
    }
    .g-xl-5,
    .gx-xl-5 {
        --bs-gutter-x: 3rem;
    }
    .g-xl-5,
    .gy-xl-5 {
        --bs-gutter-y: 3rem;
    }
}

@media (min-width: 1440) {
    .col-xxl {
        flex: 1 0 0%;
    }
    .row-cols-xxl-auto > * {
        flex: 0 0 auto;
        width: auto;
    }
    .row-cols-xxl-1 > * {
        flex: 0 0 auto;
        width: 100%;
    }
    .row-cols-xxl-2 > * {
        flex: 0 0 auto;
        width: 50%;
    }
    .row-cols-xxl-3 > * {
        flex: 0 0 auto;
        width: 33.33333%;
    }
    .row-cols-xxl-4 > * {
        flex: 0 0 auto;
        width: 25%;
    }
    .row-cols-xxl-5 > * {
        flex: 0 0 auto;
        width: 20%;
    }
    .row-cols-xxl-6 > * {
        flex: 0 0 auto;
        width: 16.66667%;
    }
    .col-xxl-auto {
        flex: 0 0 auto;
        width: auto;
    }
    .col-xxl-1 {
        flex: 0 0 auto;
        width: 8.33333%;
    }
    .col-xxl-2 {
        flex: 0 0 auto;
        width: 16.66667%;
    }
    .col-xxl-3 {
        flex: 0 0 auto;
        width: 25%;
    }
    .col-xxl-4 {
        flex: 0 0 auto;
        width: 33.33333%;
    }
    .col-xxl-5 {
        flex: 0 0 auto;
        width: 41.66667%;
    }
    .col-xxl-6 {
        flex: 0 0 auto;
        width: 50%;
    }
    .col-xxl-7 {
        flex: 0 0 auto;
        width: 58.33333%;
    }
    .col-xxl-8 {
        flex: 0 0 auto;
        width: 66.66667%;
    }
    .col-xxl-9 {
        flex: 0 0 auto;
        width: 75%;
    }
    .col-xxl-10 {
        flex: 0 0 auto;
        width: 83.33333%;
    }
    .col-xxl-11 {
        flex: 0 0 auto;
        width: 91.66667%;
    }
    .col-xxl-12 {
        flex: 0 0 auto;
        width: 100%;
    }
    .offset-xxl-0 {
        margin-left: 0;
    }
    .offset-xxl-1 {
        margin-left: 8.33333%;
    }
    .offset-xxl-2 {
        margin-left: 16.66667%;
    }
    .offset-xxl-3 {
        margin-left: 25%;
    }
    .offset-xxl-4 {
        margin-left: 33.33333%;
    }
    .offset-xxl-5 {
        margin-left: 41.66667%;
    }
    .offset-xxl-6 {
        margin-left: 50%;
    }
    .offset-xxl-7 {
        margin-left: 58.33333%;
    }
    .offset-xxl-8 {
        margin-left: 66.66667%;
    }
    .offset-xxl-9 {
        margin-left: 75%;
    }
    .offset-xxl-10 {
        margin-left: 83.33333%;
    }
    .offset-xxl-11 {
        margin-left: 91.66667%;
    }
    .g-xxl-0,
    .gx-xxl-0 {
        --bs-gutter-x: 0;
    }
    .g-xxl-0,
    .gy-xxl-0 {
        --bs-gutter-y: 0;
    }
    .g-xxl-1,
    .gx-xxl-1 {
        --bs-gutter-x: 0.25rem;
    }
    .g-xxl-1,
    .gy-xxl-1 {
        --bs-gutter-y: 0.25rem;
    }
    .g-xxl-2,
    .gx-xxl-2 {
        --bs-gutter-x: 0.5rem;
    }
    .g-xxl-2,
    .gy-xxl-2 {
        --bs-gutter-y: 0.5rem;
    }
    .g-xxl-3,
    .gx-xxl-3 {
        --bs-gutter-x: 1rem;
    }
    .g-xxl-3,
    .gy-xxl-3 {
        --bs-gutter-y: 1rem;
    }
    .g-xxl-4,
    .gx-xxl-4 {
        --bs-gutter-x: 1.5rem;
    }
    .g-xxl-4,
    .gy-xxl-4 {
        --bs-gutter-y: 1.5rem;
    }
    .g-xxl-5,
    .gx-xxl-5 {
        --bs-gutter-x: 3rem;
    }
    .g-xxl-5,
    .gy-xxl-5 {
        --bs-gutter-y: 3rem;
    }
}

.table {
    --bs-table-bg: transparent;
    --bs-table-accent-bg: transparent;
    --bs-table-striped-color: #52525e;
    --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
    --bs-table-active-color: #52525e;
    --bs-table-active-bg: rgba(0, 0, 0, 0.1);
    --bs-table-hover-color: #52525e;
    --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
    width: 100%;
    margin-bottom: 1rem;
    color: #52525e;
    vertical-align: top;
    border-color: #cfdbed;
}
.table > :not(caption) > * > * {
    padding: 0.5rem 0.5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
    vertical-align: inherit;
}
.table > thead {
    vertical-align: bottom;
}
.table > :not(:first-child) {
    border-top: 2px solid currentColor;
}

.caption-top {
    caption-side: top;
}

.table-sm > :not(caption) > * > * {
    padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
    border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
    border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
    border-bottom-width: 0;
}

.table-borderless > :not(:first-child) {
    border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
    --bs-table-accent-bg: var(--bs-table-striped-bg);
    color: var(--bs-table-striped-color);
}

.table-active {
    --bs-table-accent-bg: var(--bs-table-active-bg);
    color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
    --bs-table-accent-bg: var(--bs-table-hover-bg);
    color: var(--bs-table-hover-color);
}

.table-primary {
    --bs-table-bg: #ccedff;
    --bs-table-striped-bg: #c2e1f2;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #b8d5e6;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #bddbec;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #b8d5e6;
}

.table-secondary {
    --bs-table-bg: #ceced0;
    --bs-table-striped-bg: #c4c4c6;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #b9b9bb;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #bfbfc0;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #b9b9bb;
}

.table-success {
    --bs-table-bg: #cfefcf;
    --bs-table-striped-bg: #c5e3c5;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #bad7ba;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #bfddbf;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #bad7ba;
}

.table-info {
    --bs-table-bg: #ccefff;
    --bs-table-striped-bg: #c2e3f2;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #b8d7e6;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #bdddec;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #b8d7e6;
}

.table-warning {
    --bs-table-bg: #fff1e1;
    --bs-table-striped-bg: #f2e5d6;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #e6d9cb;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #ecdfd0;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #e6d9cb;
}

.table-danger {
    --bs-table-bg: #fbdddd;
    --bs-table-striped-bg: #eed2d2;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #e2c7c7;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #e8cccc;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #e2c7c7;
}

.table-light {
    --bs-table-bg: #fffbf5;
    --bs-table-striped-bg: #f2eee9;
    --bs-table-striped-color: #000;
    --bs-table-active-bg: #e6e2dd;
    --bs-table-active-color: #000;
    --bs-table-hover-bg: #ece8e3;
    --bs-table-hover-color: #000;
    color: #000;
    border-color: #e6e2dd;
}

.table-dark {
    --bs-table-bg: #212529;
    --bs-table-striped-bg: #2c3034;
    --bs-table-striped-color: #ffffff;
    --bs-table-active-bg: #373b3e;
    --bs-table-active-color: #ffffff;
    --bs-table-hover-bg: #323539;
    --bs-table-hover-color: #ffffff;
    color: #ffffff;
    border-color: #373b3e;
}

.table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
    .table-responsive-sm {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
}

@media (max-width: 767.98px) {
    .table-responsive-md {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
}

@media (max-width: 991.98px) {
    .table-responsive-lg {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
}

@media (max-width: 1199.98px) {
    .table-responsive-xl {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
}

@media (max-width: 1439.98) {
    .table-responsive-xxl {
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
}

.form-label {
    margin-bottom: 0.5rem;
}

.col-form-label {
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.6;
}

.col-form-label-lg {
    padding-top: calc(0.5rem + 1px);
    padding-bottom: calc(0.5rem + 1px);
    font-size: 1.25rem;
}

.col-form-label-sm {
    padding-top: calc(0.25rem + 1px);
    padding-bottom: calc(0.25rem + 1px);
    font-size: 0.875rem;
}

.form-text {
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: rgba(151, 151, 159, 0.39);
}

.form-control,
.wp-block-categories-dropdown select,
.wp-block-archives-dropdown select,
.comment-respond .comment-form p.comment-form-author input,
.comment-respond .comment-form p.comment-form-email input,
.comment-respond .comment-form p.comment-form-url input,
.comment-respond .comment-form p textarea {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6;
    color: #52525e;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
    .form-control,
    .wp-block-categories-dropdown select,
    .wp-block-archives-dropdown select,
    .comment-respond .comment-form p.comment-form-author input,
    .comment-respond .comment-form p.comment-form-email input,
    .comment-respond .comment-form p.comment-form-url input,
    .comment-respond .comment-form p textarea {
        transition: none;
    }
}
.form-control[type='file'],
.wp-block-categories-dropdown select[type='file'],
.wp-block-archives-dropdown select[type='file'],
.comment-respond .comment-form p.comment-form-author input[type='file'],
.comment-respond .comment-form p.comment-form-email input[type='file'],
.comment-respond .comment-form p.comment-form-url input[type='file'],
.comment-respond .comment-form p textarea[type='file'] {
    overflow: hidden;
}
.form-control[type='file']:not(:disabled):not([readonly]),
.wp-block-categories-dropdown select[type='file']:not(:disabled):not([readonly]),
.wp-block-archives-dropdown select[type='file']:not(:disabled):not([readonly]),
.comment-respond .comment-form p.comment-form-author input[type='file']:not(:disabled):not([readonly]),
.comment-respond .comment-form p.comment-form-email input[type='file']:not(:disabled):not([readonly]),
.comment-respond .comment-form p.comment-form-url input[type='file']:not(:disabled):not([readonly]),
.comment-respond .comment-form p textarea[type='file']:not(:disabled):not([readonly]) {
    cursor: pointer;
}
.form-control:focus,
.wp-block-categories-dropdown select:focus,
.wp-block-archives-dropdown select:focus,
.comment-respond .comment-form p.comment-form-author input:focus,
.comment-respond .comment-form p.comment-form-email input:focus,
.comment-respond .comment-form p.comment-form-url input:focus,
.comment-respond .comment-form p textarea:focus {
    color: #52525e;
    background-color: #ffffff;
    border-color: #81d1ff;
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(2, 163, 254, 0.25);
}
.form-control::-webkit-date-and-time-value,
.wp-block-categories-dropdown select::-webkit-date-and-time-value,
.wp-block-archives-dropdown select::-webkit-date-and-time-value,
.comment-respond .comment-form p.comment-form-author input::-webkit-date-and-time-value,
.comment-respond .comment-form p.comment-form-email input::-webkit-date-and-time-value,
.comment-respond .comment-form p.comment-form-url input::-webkit-date-and-time-value,
.comment-respond .comment-form p textarea::-webkit-date-and-time-value {
    height: 1.6em;
}
.form-control::placeholder,
.wp-block-categories-dropdown select::placeholder,
.wp-block-archives-dropdown select::placeholder,
.comment-respond .comment-form p.comment-form-author input::placeholder,
.comment-respond .comment-form p.comment-form-email input::placeholder,
.comment-respond .comment-form p.comment-form-url input::placeholder,
.comment-respond .comment-form p textarea::placeholder {
    color: #6c757d;
    opacity: 1;
}
.form-control:disabled,
.wp-block-categories-dropdown select:disabled,
.wp-block-archives-dropdown select:disabled,
.comment-respond .comment-form p.comment-form-author input:disabled,
.comment-respond .comment-form p.comment-form-email input:disabled,
.comment-respond .comment-form p.comment-form-url input:disabled,
.comment-respond .comment-form p textarea:disabled,
.form-control[readonly],
.wp-block-categories-dropdown select[readonly],
.wp-block-archives-dropdown select[readonly],
.comment-respond .comment-form p.comment-form-author input[readonly],
.comment-respond .comment-form p.comment-form-email input[readonly],
.comment-respond .comment-form p.comment-form-url input[readonly],
.comment-respond .comment-form p textarea[readonly] {
    background-color: #e9ecef;
    opacity: 1;
}
.form-control::file-selector-button,
.wp-block-categories-dropdown select::file-selector-button,
.wp-block-archives-dropdown select::file-selector-button,
.comment-respond .comment-form p.comment-form-author input::file-selector-button,
.comment-respond .comment-form p.comment-form-email input::file-selector-button,
.comment-respond .comment-form p.comment-form-url input::file-selector-button,
.comment-respond .comment-form p textarea::file-selector-button {
    padding: 0.375rem 0.75rem;
    margin: -0.375rem -0.75rem;
    margin-inline-end: 0.75rem;
    color: #52525e;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
    .form-control::file-selector-button,
    .wp-block-categories-dropdown select::file-selector-button,
    .wp-block-archives-dropdown select::file-selector-button,
    .comment-respond .comment-form p.comment-form-author input::file-selector-button,
    .comment-respond .comment-form p.comment-form-email input::file-selector-button,
    .comment-respond .comment-form p.comment-form-url input::file-selector-button,
    .comment-respond .comment-form p textarea::file-selector-button {
        transition: none;
    }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button,
.wp-block-categories-dropdown select:hover:not(:disabled):not([readonly])::file-selector-button,
.wp-block-archives-dropdown select:hover:not(:disabled):not([readonly])::file-selector-button,
.comment-respond .comment-form p.comment-form-author input:hover:not(:disabled):not([readonly])::file-selector-button,
.comment-respond .comment-form p.comment-form-email input:hover:not(:disabled):not([readonly])::file-selector-button,
.comment-respond .comment-form p.comment-form-url input:hover:not(:disabled):not([readonly])::file-selector-button,
.comment-respond .comment-form p textarea:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #dde0e3;
}
.form-control::-webkit-file-upload-button,
.wp-block-categories-dropdown select::-webkit-file-upload-button,
.wp-block-archives-dropdown select::-webkit-file-upload-button,
.comment-respond .comment-form p.comment-form-author input::-webkit-file-upload-button,
.comment-respond .comment-form p.comment-form-email input::-webkit-file-upload-button,
.comment-respond .comment-form p.comment-form-url input::-webkit-file-upload-button,
.comment-respond .comment-form p textarea::-webkit-file-upload-button {
    padding: 0.375rem 0.75rem;
    margin: -0.375rem -0.75rem;
    margin-inline-end: 0.75rem;
    color: #52525e;
    background-color: #e9ecef;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width: 1px;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
    .form-control::-webkit-file-upload-button,
    .wp-block-categories-dropdown select::-webkit-file-upload-button,
    .wp-block-archives-dropdown select::-webkit-file-upload-button,
    .comment-respond .comment-form p.comment-form-author input::-webkit-file-upload-button,
    .comment-respond .comment-form p.comment-form-email input::-webkit-file-upload-button,
    .comment-respond .comment-form p.comment-form-url input::-webkit-file-upload-button,
    .comment-respond .comment-form p textarea::-webkit-file-upload-button {
        transition: none;
    }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button,
.wp-block-categories-dropdown select:hover:not(:disabled):not([readonly])::-webkit-file-upload-button,
.wp-block-archives-dropdown select:hover:not(:disabled):not([readonly])::-webkit-file-upload-button,
.comment-respond .comment-form p.comment-form-author input:hover:not(:disabled):not([readonly])::-webkit-file-upload-button,
.comment-respond .comment-form p.comment-form-email input:hover:not(:disabled):not([readonly])::-webkit-file-upload-button,
.comment-respond .comment-form p.comment-form-url input:hover:not(:disabled):not([readonly])::-webkit-file-upload-button,
.comment-respond .comment-form p textarea:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: #dde0e3;
}

.form-control-plaintext {
    display: block;
    width: 100%;
    padding: 0.375rem 0;
    margin-bottom: 0;
    line-height: 1.6;
    color: #52525e;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0;
}
.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0;
}

.form-control-sm {
    min-height: calc(1.6em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    border-radius: 0.75rem;
}
.form-control-sm::file-selector-button {
    padding: 0.25rem 0.5rem;
    margin: -0.25rem -0.5rem;
    margin-inline-end: 0.5rem;
}
.form-control-sm::-webkit-file-upload-button {
    padding: 0.25rem 0.5rem;
    margin: -0.25rem -0.5rem;
    margin-inline-end: 0.5rem;
}

.form-control-lg {
    min-height: calc(1.6em + 1rem + 2px);
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    border-radius: 1rem;
}
.form-control-lg::file-selector-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    margin-inline-end: 1rem;
}
.form-control-lg::-webkit-file-upload-button {
    padding: 0.5rem 1rem;
    margin: -0.5rem -1rem;
    margin-inline-end: 1rem;
}

textarea.form-control,
.comment-respond .comment-form p textarea {
    min-height: calc(1.6em + 0.75rem + 2px);
}

textarea.form-control-sm {
    min-height: calc(1.6em + 0.5rem + 2px);
}

textarea.form-control-lg {
    min-height: calc(1.6em + 1rem + 2px);
}

.form-control-color {
    width: 3rem;
    height: auto;
    padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer;
}
.form-control-color::-moz-color-swatch {
    height: 1.6em;
    border-radius: 0.375rem;
}
.form-control-color::-webkit-color-swatch {
    height: 1.6em;
    border-radius: 0.375rem;
}

.form-select,
select {
    display: block;
    width: 100%;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    -moz-padding-start: calc(0.75rem - 3px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6;
    color: #52525e;
    background-color: #ffffff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
}
@media (prefers-reduced-motion: reduce) {
    .form-select,
    select {
        transition: none;
    }
}
.form-select:focus,
select:focus {
    border-color: #81d1ff;
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(2, 163, 254, 0.25);
}
.form-select[multiple],
select[multiple],
.form-select[size]:not([size='1']),
select[size]:not([size='1']) {
    padding-right: 0.75rem;
    background-image: none;
}
.form-select:disabled,
select:disabled {
    background-color: #e9ecef;
}
.form-select:-moz-focusring,
select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #52525e;
}

.form-select-sm {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    font-size: 0.875rem;
    border-radius: 0.75rem;
}

.form-select-lg {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    font-size: 1.25rem;
    border-radius: 1rem;
}

.form-check {
    display: block;
    min-height: 1.6rem;
    padding-left: 1.5em;
    margin-bottom: 0.125rem;
}
.form-check .form-check-input {
    float: left;
    margin-left: -1.5em;
}

.form-check-input {
    width: 1em;
    height: 1em;
    margin-top: 0.3em;
    vertical-align: top;
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0, 0, 0, 0.25);
    appearance: none;
    color-adjust: exact;
}
.form-check-input[type='checkbox'] {
    border-radius: 0.25em;
}
.form-check-input[type='radio'] {
    border-radius: 50%;
}
.form-check-input:active {
    filter: brightness(90%);
}
.form-check-input:focus {
    border-color: #81d1ff;
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(2, 163, 254, 0.25);
}
.form-check-input:checked {
    background-color: #02a3fe;
    border-color: #02a3fe;
}
.form-check-input:checked[type='checkbox'] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type='radio'] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ffffff'/%3e%3c/svg%3e");
}
.form-check-input[type='checkbox']:indeterminate {
    background-color: #02a3fe;
    border-color: #02a3fe;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
    opacity: 0.5;
}

.form-switch {
    padding-left: 2.5em;
}
.form-switch .form-check-input {
    width: 2em;
    margin-left: -2.5em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2em;
    transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
    .form-switch .form-check-input {
        transition: none;
    }
}
.form-switch .form-check-input:focus {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2381d1ff'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
    background-position: right center;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");
}

.form-check-inline {
    display: inline-block;
    margin-right: 1rem;
}

.btn-check {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
}
.btn-check[disabled] + .btn,
.btn-check:disabled + .btn {
    pointer-events: none;
    filter: none;
    opacity: 0.65;
}

.form-range {
    width: 100%;
    height: 1rem;
    padding: 0;
    background-color: transparent;
    appearance: none;
}
.form-range:focus {
    outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px #ffffff, 0 0 0 0rem rgba(2, 163, 254, 0.25);
}
.form-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #ffffff, 0 0 0 0rem rgba(2, 163, 254, 0.25);
}
.form-range::-moz-focus-outer {
    border: 0;
}
.form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #02a3fe;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
}
@media (prefers-reduced-motion: reduce) {
    .form-range::-webkit-slider-thumb {
        transition: none;
    }
}
.form-range::-webkit-slider-thumb:active {
    background-color: #b3e3ff;
}
.form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
}
.form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #02a3fe;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;
}
@media (prefers-reduced-motion: reduce) {
    .form-range::-moz-range-thumb {
        transition: none;
    }
}
.form-range::-moz-range-thumb:active {
    background-color: #b3e3ff;
}
.form-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem;
}
.form-range:disabled {
    pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd;
}
.form-range:disabled::-moz-range-thumb {
    background-color: #adb5bd;
}

.form-floating {
    position: relative;
}
.form-floating > .form-control,
.wp-block-categories-dropdown .form-floating > select,
.wp-block-archives-dropdown .form-floating > select,
.comment-respond .comment-form p.comment-form-author .form-floating > input,
.comment-respond .comment-form p.comment-form-email .form-floating > input,
.comment-respond .comment-form p.comment-form-url .form-floating > input,
.comment-respond .comment-form p .form-floating > textarea,
.form-floating > .form-select,
.form-floating > select {
    height: calc(3.5rem + 2px);
    line-height: 1.25;
}
.form-floating > label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 1rem 0.75rem;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
    .form-floating > label {
        transition: none;
    }
}
.form-floating > .form-control,
.wp-block-categories-dropdown .form-floating > select,
.wp-block-archives-dropdown .form-floating > select,
.comment-respond .comment-form p.comment-form-author .form-floating > input,
.comment-respond .comment-form p.comment-form-email .form-floating > input,
.comment-respond .comment-form p.comment-form-url .form-floating > input,
.comment-respond .comment-form p .form-floating > textarea {
    padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder,
.wp-block-categories-dropdown .form-floating > select::placeholder,
.wp-block-archives-dropdown .form-floating > select::placeholder,
.comment-respond .comment-form p.comment-form-author .form-floating > input::placeholder,
.comment-respond .comment-form p.comment-form-email .form-floating > input::placeholder,
.comment-respond .comment-form p.comment-form-url .form-floating > input::placeholder,
.comment-respond .comment-form p .form-floating > textarea::placeholder {
    color: transparent;
}
.form-floating > .form-control:focus,
.wp-block-categories-dropdown .form-floating > select:focus,
.wp-block-archives-dropdown .form-floating > select:focus,
.comment-respond .comment-form p.comment-form-author .form-floating > input:focus,
.comment-respond .comment-form p.comment-form-email .form-floating > input:focus,
.comment-respond .comment-form p.comment-form-url .form-floating > input:focus,
.comment-respond .comment-form p .form-floating > textarea:focus,
.form-floating > .form-control:not(:placeholder-shown),
.wp-block-categories-dropdown .form-floating > select:not(:placeholder-shown),
.wp-block-archives-dropdown .form-floating > select:not(:placeholder-shown),
.comment-respond .comment-form p.comment-form-author .form-floating > input:not(:placeholder-shown),
.comment-respond .comment-form p.comment-form-email .form-floating > input:not(:placeholder-shown),
.comment-respond .comment-form p.comment-form-url .form-floating > input:not(:placeholder-shown),
.comment-respond .comment-form p .form-floating > textarea:not(:placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.wp-block-categories-dropdown .form-floating > select:-webkit-autofill,
.wp-block-archives-dropdown .form-floating > select:-webkit-autofill,
.comment-respond .comment-form p.comment-form-author .form-floating > input:-webkit-autofill,
.comment-respond .comment-form p.comment-form-email .form-floating > input:-webkit-autofill,
.comment-respond .comment-form p.comment-form-url .form-floating > input:-webkit-autofill,
.comment-respond .comment-form p .form-floating > textarea:-webkit-autofill {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
}

.form-floating > .form-select,
.form-floating > select {
    padding-top: 1.625rem;
    padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.wp-block-categories-dropdown .form-floating > select:focus ~ label,
.wp-block-archives-dropdown .form-floating > select:focus ~ label,
.comment-respond .comment-form p.comment-form-author .form-floating > input:focus ~ label,
.comment-respond .comment-form p.comment-form-email .form-floating > input:focus ~ label,
.comment-respond .comment-form p.comment-form-url .form-floating > input:focus ~ label,
.comment-respond .comment-form p .form-floating > textarea:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.wp-block-categories-dropdown .form-floating > select:not(:placeholder-shown) ~ label,
.wp-block-archives-dropdown .form-floating > select:not(:placeholder-shown) ~ label,
.comment-respond .comment-form p.comment-form-author .form-floating > input:not(:placeholder-shown) ~ label,
.comment-respond .comment-form p.comment-form-email .form-floating > input:not(:placeholder-shown) ~ label,
.comment-respond .comment-form p.comment-form-url .form-floating > input:not(:placeholder-shown) ~ label,
.comment-respond .comment-form p .form-floating > textarea:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label,
.form-floating > select ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label,
.wp-block-categories-dropdown .form-floating > select:-webkit-autofill ~ label,
.wp-block-archives-dropdown .form-floating > select:-webkit-autofill ~ label,
.comment-respond .comment-form p.comment-form-author .form-floating > input:-webkit-autofill ~ label,
.comment-respond .comment-form p.comment-form-email .form-floating > input:-webkit-autofill ~ label,
.comment-respond .comment-form p.comment-form-url .form-floating > input:-webkit-autofill ~ label,
.comment-respond .comment-form p .form-floating > textarea:-webkit-autofill ~ label {
    opacity: 0.65;
    transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}
.input-group > .form-control,
.wp-block-categories-dropdown .input-group > select,
.wp-block-archives-dropdown .input-group > select,
.comment-respond .comment-form p.comment-form-author .input-group > input,
.comment-respond .comment-form p.comment-form-email .input-group > input,
.comment-respond .comment-form p.comment-form-url .input-group > input,
.comment-respond .comment-form p .input-group > textarea,
.input-group > .form-select,
.input-group > select {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
}
.input-group > .form-control:focus,
.wp-block-categories-dropdown .input-group > select:focus,
.wp-block-archives-dropdown .input-group > select:focus,
.comment-respond .comment-form p.comment-form-author .input-group > input:focus,
.comment-respond .comment-form p.comment-form-email .input-group > input:focus,
.comment-respond .comment-form p.comment-form-url .input-group > input:focus,
.comment-respond .comment-form p .input-group > textarea:focus,
.input-group > .form-select:focus,
.input-group > select:focus {
    z-index: 3;
}
.input-group .btn {
    position: relative;
    z-index: 2;
}
.input-group .btn:focus {
    z-index: 3;
}

.input-group-text {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6;
    color: #52525e;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
}

.input-group-lg > .form-control,
.wp-block-categories-dropdown .input-group-lg > select,
.wp-block-archives-dropdown .input-group-lg > select,
.comment-respond .comment-form p.comment-form-author .input-group-lg > input,
.comment-respond .comment-form p.comment-form-email .input-group-lg > input,
.comment-respond .comment-form p.comment-form-url .input-group-lg > input,
.comment-respond .comment-form p .input-group-lg > textarea,
.input-group-lg > .form-select,
.input-group-lg > select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    border-radius: 1rem;
}

.input-group-sm > .form-control,
.wp-block-categories-dropdown .input-group-sm > select,
.wp-block-archives-dropdown .input-group-sm > select,
.comment-respond .comment-form p.comment-form-author .input-group-sm > input,
.comment-respond .comment-form p.comment-form-email .input-group-sm > input,
.comment-respond .comment-form p.comment-form-url .input-group-sm > input,
.comment-respond .comment-form p .input-group-sm > textarea,
.input-group-sm > .form-select,
.input-group-sm > select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    border-radius: 0.75rem;
}

.input-group-lg > .form-select,
.input-group-lg > select,
.input-group-sm > .form-select,
.input-group-sm > select {
    padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n + 3) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group.has-validation > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n + 4) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group
    > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(
        .invalid-feedback
    ) {
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #11b011;
}

.valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.875rem;
    color: #000;
    background-color: rgba(17, 176, 17, 0.9);
    border-radius: 0.375rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
    display: block;
}

.was-validated .form-control:valid,
.was-validated .wp-block-categories-dropdown select:valid,
.wp-block-categories-dropdown .was-validated select:valid,
.was-validated .wp-block-archives-dropdown select:valid,
.wp-block-archives-dropdown .was-validated select:valid,
.was-validated .comment-respond .comment-form p.comment-form-author input:valid,
.comment-respond .comment-form p.comment-form-author .was-validated input:valid,
.was-validated .comment-respond .comment-form p.comment-form-email input:valid,
.comment-respond .comment-form p.comment-form-email .was-validated input:valid,
.was-validated .comment-respond .comment-form p.comment-form-url input:valid,
.comment-respond .comment-form p.comment-form-url .was-validated input:valid,
.was-validated .comment-respond .comment-form p textarea:valid,
.comment-respond .comment-form p .was-validated textarea:valid,
.form-control.is-valid,
.wp-block-categories-dropdown select.is-valid,
.wp-block-archives-dropdown select.is-valid,
.comment-respond .comment-form p.comment-form-author input.is-valid,
.comment-respond .comment-form p.comment-form-email input.is-valid,
.comment-respond .comment-form p.comment-form-url input.is-valid,
.comment-respond .comment-form p textarea.is-valid {
    border-color: #11b011;
    padding-right: calc(1.6em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2311B011' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.4em + 0.1875rem) center;
    background-size: calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}
.was-validated .form-control:valid:focus,
.was-validated .wp-block-categories-dropdown select:valid:focus,
.wp-block-categories-dropdown .was-validated select:valid:focus,
.was-validated .wp-block-archives-dropdown select:valid:focus,
.wp-block-archives-dropdown .was-validated select:valid:focus,
.was-validated .comment-respond .comment-form p.comment-form-author input:valid:focus,
.comment-respond .comment-form p.comment-form-author .was-validated input:valid:focus,
.was-validated .comment-respond .comment-form p.comment-form-email input:valid:focus,
.comment-respond .comment-form p.comment-form-email .was-validated input:valid:focus,
.was-validated .comment-respond .comment-form p.comment-form-url input:valid:focus,
.comment-respond .comment-form p.comment-form-url .was-validated input:valid:focus,
.was-validated .comment-respond .comment-form p textarea:valid:focus,
.comment-respond .comment-form p .was-validated textarea:valid:focus,
.form-control.is-valid:focus,
.wp-block-categories-dropdown select.is-valid:focus,
.wp-block-archives-dropdown select.is-valid:focus,
.comment-respond .comment-form p.comment-form-author input.is-valid:focus,
.comment-respond .comment-form p.comment-form-email input.is-valid:focus,
.comment-respond .comment-form p.comment-form-url input.is-valid:focus,
.comment-respond .comment-form p textarea.is-valid:focus {
    border-color: #11b011;
    box-shadow: 0 0 0 0rem rgba(17, 176, 17, 0.25);
}

.was-validated textarea.form-control:valid,
.was-validated .comment-respond .comment-form p textarea:valid,
.comment-respond .comment-form p .was-validated textarea:valid,
textarea.form-control.is-valid,
.comment-respond .comment-form p textarea.is-valid {
    padding-right: calc(1.6em + 0.75rem);
    background-position: top calc(0.4em + 0.1875rem) right calc(0.4em + 0.1875rem);
}

.was-validated .form-select:valid,
.was-validated select:valid,
.form-select.is-valid,
select.is-valid {
    border-color: #11b011;
}
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated select:valid:not([multiple]):not([size]),
.was-validated .form-select:valid:not([multiple])[size='1'],
.was-validated select:valid:not([multiple])[size='1'],
.form-select.is-valid:not([multiple]):not([size]),
select.is-valid:not([multiple]):not([size]),
.form-select.is-valid:not([multiple])[size='1'],
select.is-valid:not([multiple])[size='1'] {
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2311B011' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}
.was-validated .form-select:valid:focus,
.was-validated select:valid:focus,
.form-select.is-valid:focus,
select.is-valid:focus {
    border-color: #11b011;
    box-shadow: 0 0 0 0rem rgba(17, 176, 17, 0.25);
}

.was-validated .form-check-input:valid,
.form-check-input.is-valid {
    border-color: #11b011;
}
.was-validated .form-check-input:valid:checked,
.form-check-input.is-valid:checked {
    background-color: #11b011;
}
.was-validated .form-check-input:valid:focus,
.form-check-input.is-valid:focus {
    box-shadow: 0 0 0 0rem rgba(17, 176, 17, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
    color: #11b011;
}

.form-check-inline .form-check-input ~ .valid-feedback {
    margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid,
.was-validated .input-group .wp-block-categories-dropdown select:valid,
.wp-block-categories-dropdown .was-validated .input-group select:valid,
.was-validated .input-group .wp-block-archives-dropdown select:valid,
.wp-block-archives-dropdown .was-validated .input-group select:valid,
.was-validated .input-group .comment-respond .comment-form p.comment-form-author input:valid,
.comment-respond .comment-form p.comment-form-author .was-validated .input-group input:valid,
.was-validated .input-group .comment-respond .comment-form p.comment-form-email input:valid,
.comment-respond .comment-form p.comment-form-email .was-validated .input-group input:valid,
.was-validated .input-group .comment-respond .comment-form p.comment-form-url input:valid,
.comment-respond .comment-form p.comment-form-url .was-validated .input-group input:valid,
.was-validated .input-group .comment-respond .comment-form p textarea:valid,
.comment-respond .comment-form p .was-validated .input-group textarea:valid,
.input-group .form-control.is-valid,
.input-group .wp-block-categories-dropdown select.is-valid,
.wp-block-categories-dropdown .input-group select.is-valid,
.input-group .wp-block-archives-dropdown select.is-valid,
.wp-block-archives-dropdown .input-group select.is-valid,
.input-group .comment-respond .comment-form p.comment-form-author input.is-valid,
.comment-respond .comment-form p.comment-form-author .input-group input.is-valid,
.input-group .comment-respond .comment-form p.comment-form-email input.is-valid,
.comment-respond .comment-form p.comment-form-email .input-group input.is-valid,
.input-group .comment-respond .comment-form p.comment-form-url input.is-valid,
.comment-respond .comment-form p.comment-form-url .input-group input.is-valid,
.input-group .comment-respond .comment-form p textarea.is-valid,
.comment-respond .comment-form p .input-group textarea.is-valid,
.was-validated .input-group .form-select:valid,
.was-validated .input-group select:valid,
.input-group .form-select.is-valid,
.input-group select.is-valid {
    z-index: 1;
}
.was-validated .input-group .form-control:valid:focus,
.was-validated .input-group .wp-block-categories-dropdown select:valid:focus,
.wp-block-categories-dropdown .was-validated .input-group select:valid:focus,
.was-validated .input-group .wp-block-archives-dropdown select:valid:focus,
.wp-block-archives-dropdown .was-validated .input-group select:valid:focus,
.was-validated .input-group .comment-respond .comment-form p.comment-form-author input:valid:focus,
.comment-respond .comment-form p.comment-form-author .was-validated .input-group input:valid:focus,
.was-validated .input-group .comment-respond .comment-form p.comment-form-email input:valid:focus,
.comment-respond .comment-form p.comment-form-email .was-validated .input-group input:valid:focus,
.was-validated .input-group .comment-respond .comment-form p.comment-form-url input:valid:focus,
.comment-respond .comment-form p.comment-form-url .was-validated .input-group input:valid:focus,
.was-validated .input-group .comment-respond .comment-form p textarea:valid:focus,
.comment-respond .comment-form p .was-validated .input-group textarea:valid:focus,
.input-group .form-control.is-valid:focus,
.input-group .wp-block-categories-dropdown select.is-valid:focus,
.wp-block-categories-dropdown .input-group select.is-valid:focus,
.input-group .wp-block-archives-dropdown select.is-valid:focus,
.wp-block-archives-dropdown .input-group select.is-valid:focus,
.input-group .comment-respond .comment-form p.comment-form-author input.is-valid:focus,
.comment-respond .comment-form p.comment-form-author .input-group input.is-valid:focus,
.input-group .comment-respond .comment-form p.comment-form-email input.is-valid:focus,
.comment-respond .comment-form p.comment-form-email .input-group input.is-valid:focus,
.input-group .comment-respond .comment-form p.comment-form-url input.is-valid:focus,
.comment-respond .comment-form p.comment-form-url .input-group input.is-valid:focus,
.input-group .comment-respond .comment-form p textarea.is-valid:focus,
.comment-respond .comment-form p .input-group textarea.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.was-validated .input-group select:valid:focus,
.input-group .form-select.is-valid:focus,
.input-group select.is-valid:focus {
    z-index: 3;
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #eb5757;
}

.invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: 0.1rem;
    font-size: 0.875rem;
    color: #000;
    background-color: rgba(235, 87, 87, 0.9);
    border-radius: 0.375rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
    display: block;
}

.was-validated .form-control:invalid,
.was-validated .wp-block-categories-dropdown select:invalid,
.wp-block-categories-dropdown .was-validated select:invalid,
.was-validated .wp-block-archives-dropdown select:invalid,
.wp-block-archives-dropdown .was-validated select:invalid,
.was-validated .comment-respond .comment-form p.comment-form-author input:invalid,
.comment-respond .comment-form p.comment-form-author .was-validated input:invalid,
.was-validated .comment-respond .comment-form p.comment-form-email input:invalid,
.comment-respond .comment-form p.comment-form-email .was-validated input:invalid,
.was-validated .comment-respond .comment-form p.comment-form-url input:invalid,
.comment-respond .comment-form p.comment-form-url .was-validated input:invalid,
.was-validated .comment-respond .comment-form p textarea:invalid,
.comment-respond .comment-form p .was-validated textarea:invalid,
.form-control.is-invalid,
.wp-block-categories-dropdown select.is-invalid,
.wp-block-archives-dropdown select.is-invalid,
.comment-respond .comment-form p.comment-form-author input.is-invalid,
.comment-respond .comment-form p.comment-form-email input.is-invalid,
.comment-respond .comment-form p.comment-form-url input.is-invalid,
.comment-respond .comment-form p textarea.is-invalid {
    border-color: #eb5757;
    padding-right: calc(1.6em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23EB5757'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23EB5757' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.4em + 0.1875rem) center;
    background-size: calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}
.was-validated .form-control:invalid:focus,
.was-validated .wp-block-categories-dropdown select:invalid:focus,
.wp-block-categories-dropdown .was-validated select:invalid:focus,
.was-validated .wp-block-archives-dropdown select:invalid:focus,
.wp-block-archives-dropdown .was-validated select:invalid:focus,
.was-validated .comment-respond .comment-form p.comment-form-author input:invalid:focus,
.comment-respond .comment-form p.comment-form-author .was-validated input:invalid:focus,
.was-validated .comment-respond .comment-form p.comment-form-email input:invalid:focus,
.comment-respond .comment-form p.comment-form-email .was-validated input:invalid:focus,
.was-validated .comment-respond .comment-form p.comment-form-url input:invalid:focus,
.comment-respond .comment-form p.comment-form-url .was-validated input:invalid:focus,
.was-validated .comment-respond .comment-form p textarea:invalid:focus,
.comment-respond .comment-form p .was-validated textarea:invalid:focus,
.form-control.is-invalid:focus,
.wp-block-categories-dropdown select.is-invalid:focus,
.wp-block-archives-dropdown select.is-invalid:focus,
.comment-respond .comment-form p.comment-form-author input.is-invalid:focus,
.comment-respond .comment-form p.comment-form-email input.is-invalid:focus,
.comment-respond .comment-form p.comment-form-url input.is-invalid:focus,
.comment-respond .comment-form p textarea.is-invalid:focus {
    border-color: #eb5757;
    box-shadow: 0 0 0 0rem rgba(235, 87, 87, 0.25);
}

.was-validated textarea.form-control:invalid,
.was-validated .comment-respond .comment-form p textarea:invalid,
.comment-respond .comment-form p .was-validated textarea:invalid,
textarea.form-control.is-invalid,
.comment-respond .comment-form p textarea.is-invalid {
    padding-right: calc(1.6em + 0.75rem);
    background-position: top calc(0.4em + 0.1875rem) right calc(0.4em + 0.1875rem);
}

.was-validated .form-select:invalid,
.was-validated select:invalid,
.form-select.is-invalid,
select.is-invalid {
    border-color: #eb5757;
}
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size='1'],
.was-validated select:invalid:not([multiple])[size='1'],
.form-select.is-invalid:not([multiple]):not([size]),
select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size='1'],
select.is-invalid:not([multiple])[size='1'] {
    padding-right: 4.125rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23EB5757'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23EB5757' stroke='none'/%3e%3c/svg%3e");
    background-position: right 0.75rem center, center right 2.25rem;
    background-size: 16px 12px, calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}
.was-validated .form-select:invalid:focus,
.was-validated select:invalid:focus,
.form-select.is-invalid:focus,
select.is-invalid:focus {
    border-color: #eb5757;
    box-shadow: 0 0 0 0rem rgba(235, 87, 87, 0.25);
}

.was-validated .form-check-input:invalid,
.form-check-input.is-invalid {
    border-color: #eb5757;
}
.was-validated .form-check-input:invalid:checked,
.form-check-input.is-invalid:checked {
    background-color: #eb5757;
}
.was-validated .form-check-input:invalid:focus,
.form-check-input.is-invalid:focus {
    box-shadow: 0 0 0 0rem rgba(235, 87, 87, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
    color: #eb5757;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
    margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid,
.was-validated .input-group .wp-block-categories-dropdown select:invalid,
.wp-block-categories-dropdown .was-validated .input-group select:invalid,
.was-validated .input-group .wp-block-archives-dropdown select:invalid,
.wp-block-archives-dropdown .was-validated .input-group select:invalid,
.was-validated .input-group .comment-respond .comment-form p.comment-form-author input:invalid,
.comment-respond .comment-form p.comment-form-author .was-validated .input-group input:invalid,
.was-validated .input-group .comment-respond .comment-form p.comment-form-email input:invalid,
.comment-respond .comment-form p.comment-form-email .was-validated .input-group input:invalid,
.was-validated .input-group .comment-respond .comment-form p.comment-form-url input:invalid,
.comment-respond .comment-form p.comment-form-url .was-validated .input-group input:invalid,
.was-validated .input-group .comment-respond .comment-form p textarea:invalid,
.comment-respond .comment-form p .was-validated .input-group textarea:invalid,
.input-group .form-control.is-invalid,
.input-group .wp-block-categories-dropdown select.is-invalid,
.wp-block-categories-dropdown .input-group select.is-invalid,
.input-group .wp-block-archives-dropdown select.is-invalid,
.wp-block-archives-dropdown .input-group select.is-invalid,
.input-group .comment-respond .comment-form p.comment-form-author input.is-invalid,
.comment-respond .comment-form p.comment-form-author .input-group input.is-invalid,
.input-group .comment-respond .comment-form p.comment-form-email input.is-invalid,
.comment-respond .comment-form p.comment-form-email .input-group input.is-invalid,
.input-group .comment-respond .comment-form p.comment-form-url input.is-invalid,
.comment-respond .comment-form p.comment-form-url .input-group input.is-invalid,
.input-group .comment-respond .comment-form p textarea.is-invalid,
.comment-respond .comment-form p .input-group textarea.is-invalid,
.was-validated .input-group .form-select:invalid,
.was-validated .input-group select:invalid,
.input-group .form-select.is-invalid,
.input-group select.is-invalid {
    z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus,
.was-validated .input-group .wp-block-categories-dropdown select:invalid:focus,
.wp-block-categories-dropdown .was-validated .input-group select:invalid:focus,
.was-validated .input-group .wp-block-archives-dropdown select:invalid:focus,
.wp-block-archives-dropdown .was-validated .input-group select:invalid:focus,
.was-validated .input-group .comment-respond .comment-form p.comment-form-author input:invalid:focus,
.comment-respond .comment-form p.comment-form-author .was-validated .input-group input:invalid:focus,
.was-validated .input-group .comment-respond .comment-form p.comment-form-email input:invalid:focus,
.comment-respond .comment-form p.comment-form-email .was-validated .input-group input:invalid:focus,
.was-validated .input-group .comment-respond .comment-form p.comment-form-url input:invalid:focus,
.comment-respond .comment-form p.comment-form-url .was-validated .input-group input:invalid:focus,
.was-validated .input-group .comment-respond .comment-form p textarea:invalid:focus,
.comment-respond .comment-form p .was-validated .input-group textarea:invalid:focus,
.input-group .form-control.is-invalid:focus,
.input-group .wp-block-categories-dropdown select.is-invalid:focus,
.wp-block-categories-dropdown .input-group select.is-invalid:focus,
.input-group .wp-block-archives-dropdown select.is-invalid:focus,
.wp-block-archives-dropdown .input-group select.is-invalid:focus,
.input-group .comment-respond .comment-form p.comment-form-author input.is-invalid:focus,
.comment-respond .comment-form p.comment-form-author .input-group input.is-invalid:focus,
.input-group .comment-respond .comment-form p.comment-form-email input.is-invalid:focus,
.comment-respond .comment-form p.comment-form-email .input-group input.is-invalid:focus,
.input-group .comment-respond .comment-form p.comment-form-url input.is-invalid:focus,
.comment-respond .comment-form p.comment-form-url .input-group input.is-invalid:focus,
.input-group .comment-respond .comment-form p textarea.is-invalid:focus,
.comment-respond .comment-form p .input-group textarea.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.was-validated .input-group select:invalid:focus,
.input-group .form-select.is-invalid:focus,
.input-group select.is-invalid:focus {
    z-index: 3;
}

.btn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.6;
    color: #52525e;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.375rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
    .btn {
        transition: none;
    }
}
.btn:hover {
    color: #52525e;
}
.btn-check:focus + .btn,
.btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(2, 163, 254, 0.25);
}
.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
    pointer-events: none;
    opacity: 0.65;
}

.btn-primary {
    color: #000;
    background-color: #02a3fe;
    border-color: #02a3fe;
}
.btn-primary:hover {
    color: #000;
    background-color: #28b1fe;
    border-color: #1bacfe;
}
.btn-check:focus + .btn-primary,
.btn-primary:focus {
    color: #000;
    background-color: #28b1fe;
    border-color: #1bacfe;
    box-shadow: 0 0 0 0rem rgba(2, 139, 216, 0.5);
}
.btn-check:checked + .btn-primary,
.btn-check:active + .btn-primary,
.btn-primary:active,
.btn-primary.active,
.show > .btn-primary.dropdown-toggle {
    color: #000;
    background-color: #35b5fe;
    border-color: #1bacfe;
}
.btn-check:checked + .btn-primary:focus,
.btn-check:active + .btn-primary:focus,
.btn-primary:active:focus,
.btn-primary.active:focus,
.show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0rem rgba(2, 139, 216, 0.5);
}
.btn-primary:disabled,
.btn-primary.disabled {
    color: #000;
    background-color: #02a3fe;
    border-color: #02a3fe;
}

.btn-secondary {
    color: #ffffff;
    background-color: #090915;
    border-color: #090915;
}
.btn-secondary:hover {
    color: #ffffff;
    background-color: #080812;
    border-color: #070711;
}
.btn-check:focus + .btn-secondary,
.btn-secondary:focus {
    color: #ffffff;
    background-color: #080812;
    border-color: #070711;
    box-shadow: 0 0 0 0rem rgba(46, 46, 56, 0.5);
}
.btn-check:checked + .btn-secondary,
.btn-check:active + .btn-secondary,
.btn-secondary:active,
.btn-secondary.active,
.show > .btn-secondary.dropdown-toggle {
    color: #ffffff;
    background-color: #070711;
    border-color: #070710;
}
.btn-check:checked + .btn-secondary:focus,
.btn-check:active + .btn-secondary:focus,
.btn-secondary:active:focus,
.btn-secondary.active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0rem rgba(46, 46, 56, 0.5);
}
.btn-secondary:disabled,
.btn-secondary.disabled {
    color: #ffffff;
    background-color: #090915;
    border-color: #090915;
}

.btn-success {
    color: #000;
    background-color: #11b011;
    border-color: #11b011;
}
.btn-success:hover {
    color: #000;
    background-color: #35bc35;
    border-color: #29b829;
}
.btn-check:focus + .btn-success,
.btn-success:focus {
    color: #000;
    background-color: #35bc35;
    border-color: #29b829;
    box-shadow: 0 0 0 0rem rgba(14, 150, 14, 0.5);
}
.btn-check:checked + .btn-success,
.btn-check:active + .btn-success,
.btn-success:active,
.btn-success.active,
.show > .btn-success.dropdown-toggle {
    color: #000;
    background-color: #41c041;
    border-color: #29b829;
}
.btn-check:checked + .btn-success:focus,
.btn-check:active + .btn-success:focus,
.btn-success:active:focus,
.btn-success.active:focus,
.show > .btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0rem rgba(14, 150, 14, 0.5);
}
.btn-success:disabled,
.btn-success.disabled {
    color: #000;
    background-color: #11b011;
    border-color: #11b011;
}

.btn-info {
    color: #000;
    background-color: #00aeff;
    border-color: #00aeff;
}
.btn-info:hover {
    color: #000;
    background-color: #26baff;
    border-color: #1ab6ff;
}
.btn-check:focus + .btn-info,
.btn-info:focus {
    color: #000;
    background-color: #26baff;
    border-color: #1ab6ff;
    box-shadow: 0 0 0 0rem rgba(0, 148, 217, 0.5);
}
.btn-check:checked + .btn-info,
.btn-check:active + .btn-info,
.btn-info:active,
.btn-info.active,
.show > .btn-info.dropdown-toggle {
    color: #000;
    background-color: #33beff;
    border-color: #1ab6ff;
}
.btn-check:checked + .btn-info:focus,
.btn-check:active + .btn-info:focus,
.btn-info:active:focus,
.btn-info.active:focus,
.show > .btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0rem rgba(0, 148, 217, 0.5);
}
.btn-info:disabled,
.btn-info.disabled {
    color: #000;
    background-color: #00aeff;
    border-color: #00aeff;
}

.btn-warning {
    color: #000;
    background-color: #ffbb69;
    border-color: #ffbb69;
}
.btn-warning:hover {
    color: #000;
    background-color: #ffc580;
    border-color: #ffc278;
}
.btn-check:focus + .btn-warning,
.btn-warning:focus {
    color: #000;
    background-color: #ffc580;
    border-color: #ffc278;
    box-shadow: 0 0 0 0rem rgba(217, 159, 89, 0.5);
}
.btn-check:checked + .btn-warning,
.btn-check:active + .btn-warning,
.btn-warning:active,
.btn-warning.active,
.show > .btn-warning.dropdown-toggle {
    color: #000;
    background-color: #ffc987;
    border-color: #ffc278;
}
.btn-check:checked + .btn-warning:focus,
.btn-check:active + .btn-warning:focus,
.btn-warning:active:focus,
.btn-warning.active:focus,
.show > .btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0rem rgba(217, 159, 89, 0.5);
}
.btn-warning:disabled,
.btn-warning.disabled {
    color: #000;
    background-color: #ffbb69;
    border-color: #ffbb69;
}

.btn-danger {
    color: #000;
    background-color: #eb5757;
    border-color: #eb5757;
}
.btn-danger:hover {
    color: #000;
    background-color: #ee7070;
    border-color: #ed6868;
}
.btn-check:focus + .btn-danger,
.btn-danger:focus {
    color: #000;
    background-color: #ee7070;
    border-color: #ed6868;
    box-shadow: 0 0 0 0rem rgba(200, 74, 74, 0.5);
}
.btn-check:checked + .btn-danger,
.btn-check:active + .btn-danger,
.btn-danger:active,
.btn-danger.active,
.show > .btn-danger.dropdown-toggle {
    color: #000;
    background-color: #ef7979;
    border-color: #ed6868;
}
.btn-check:checked + .btn-danger:focus,
.btn-check:active + .btn-danger:focus,
.btn-danger:active:focus,
.btn-danger.active:focus,
.show > .btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0rem rgba(200, 74, 74, 0.5);
}
.btn-danger:disabled,
.btn-danger.disabled {
    color: #000;
    background-color: #eb5757;
    border-color: #eb5757;
}

.btn-light {
    color: #000;
    background-color: #fffbf5;
    border-color: #fffbf5;
}
.btn-light:hover {
    color: #000;
    background-color: #fffcf7;
    border-color: #fffbf6;
}
.btn-check:focus + .btn-light,
.btn-light:focus {
    color: #000;
    background-color: #fffcf7;
    border-color: #fffbf6;
    box-shadow: 0 0 0 0rem rgba(217, 213, 208, 0.5);
}
.btn-check:checked + .btn-light,
.btn-check:active + .btn-light,
.btn-light:active,
.btn-light.active,
.show > .btn-light.dropdown-toggle {
    color: #000;
    background-color: #fffcf7;
    border-color: #fffbf6;
}
.btn-check:checked + .btn-light:focus,
.btn-check:active + .btn-light:focus,
.btn-light:active:focus,
.btn-light.active:focus,
.show > .btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0rem rgba(217, 213, 208, 0.5);
}
.btn-light:disabled,
.btn-light.disabled {
    color: #000;
    background-color: #fffbf5;
    border-color: #fffbf5;
}

.btn-dark {
    color: #ffffff;
    background-color: #212529;
    border-color: #212529;
}
.btn-dark:hover {
    color: #ffffff;
    background-color: #1c1f23;
    border-color: #1a1e21;
}
.btn-check:focus + .btn-dark,
.btn-dark:focus {
    color: #ffffff;
    background-color: #1c1f23;
    border-color: #1a1e21;
    box-shadow: 0 0 0 0rem rgba(66, 70, 73, 0.5);
}
.btn-check:checked + .btn-dark,
.btn-check:active + .btn-dark,
.btn-dark:active,
.btn-dark.active,
.show > .btn-dark.dropdown-toggle {
    color: #ffffff;
    background-color: #1a1e21;
    border-color: #191c1f;
}
.btn-check:checked + .btn-dark:focus,
.btn-check:active + .btn-dark:focus,
.btn-dark:active:focus,
.btn-dark.active:focus,
.show > .btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0rem rgba(66, 70, 73, 0.5);
}
.btn-dark:disabled,
.btn-dark.disabled {
    color: #ffffff;
    background-color: #212529;
    border-color: #212529;
}

.btn-outline-primary {
    color: #02a3fe;
    border-color: #02a3fe;
}
.btn-outline-primary:hover {
    color: #000;
    background-color: #02a3fe;
    border-color: #02a3fe;
}
.btn-check:focus + .btn-outline-primary,
.btn-outline-primary:focus {
    box-shadow: 0 0 0 0rem rgba(2, 163, 254, 0.5);
}
.btn-check:checked + .btn-outline-primary,
.btn-check:active + .btn-outline-primary,
.btn-outline-primary:active,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show {
    color: #000;
    background-color: #02a3fe;
    border-color: #02a3fe;
}
.btn-check:checked + .btn-outline-primary:focus,
.btn-check:active + .btn-outline-primary:focus,
.btn-outline-primary:active:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 0rem rgba(2, 163, 254, 0.5);
}
.btn-outline-primary:disabled,
.btn-outline-primary.disabled {
    color: #02a3fe;
    background-color: transparent;
}

.btn-outline-secondary {
    color: #090915;
    border-color: #090915;
}
.btn-outline-secondary:hover {
    color: #ffffff;
    background-color: #090915;
    border-color: #090915;
}
.btn-check:focus + .btn-outline-secondary,
.btn-outline-secondary:focus {
    box-shadow: 0 0 0 0rem rgba(9, 9, 21, 0.5);
}
.btn-check:checked + .btn-outline-secondary,
.btn-check:active + .btn-outline-secondary,
.btn-outline-secondary:active,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show {
    color: #ffffff;
    background-color: #090915;
    border-color: #090915;
}
.btn-check:checked + .btn-outline-secondary:focus,
.btn-check:active + .btn-outline-secondary:focus,
.btn-outline-secondary:active:focus,
.btn-outline-secondary.active:focus,
.btn-outline-secondary.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 0rem rgba(9, 9, 21, 0.5);
}
.btn-outline-secondary:disabled,
.btn-outline-secondary.disabled {
    color: #090915;
    background-color: transparent;
}

.btn-outline-success {
    color: #11b011;
    border-color: #11b011;
}
.btn-outline-success:hover {
    color: #000;
    background-color: #11b011;
    border-color: #11b011;
}
.btn-check:focus + .btn-outline-success,
.btn-outline-success:focus {
    box-shadow: 0 0 0 0rem rgba(17, 176, 17, 0.5);
}
.btn-check:checked + .btn-outline-success,
.btn-check:active + .btn-outline-success,
.btn-outline-success:active,
.btn-outline-success.active,
.btn-outline-success.dropdown-toggle.show {
    color: #000;
    background-color: #11b011;
    border-color: #11b011;
}
.btn-check:checked + .btn-outline-success:focus,
.btn-check:active + .btn-outline-success:focus,
.btn-outline-success:active:focus,
.btn-outline-success.active:focus,
.btn-outline-success.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 0rem rgba(17, 176, 17, 0.5);
}
.btn-outline-success:disabled,
.btn-outline-success.disabled {
    color: #11b011;
    background-color: transparent;
}

.btn-outline-info {
    color: #00aeff;
    border-color: #00aeff;
}
.btn-outline-info:hover {
    color: #000;
    background-color: #00aeff;
    border-color: #00aeff;
}
.btn-check:focus + .btn-outline-info,
.btn-outline-info:focus {
    box-shadow: 0 0 0 0rem rgba(0, 174, 255, 0.5);
}
.btn-check:checked + .btn-outline-info,
.btn-check:active + .btn-outline-info,
.btn-outline-info:active,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show {
    color: #000;
    background-color: #00aeff;
    border-color: #00aeff;
}
.btn-check:checked + .btn-outline-info:focus,
.btn-check:active + .btn-outline-info:focus,
.btn-outline-info:active:focus,
.btn-outline-info.active:focus,
.btn-outline-info.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 0rem rgba(0, 174, 255, 0.5);
}
.btn-outline-info:disabled,
.btn-outline-info.disabled {
    color: #00aeff;
    background-color: transparent;
}

.btn-outline-warning {
    color: #ffbb69;
    border-color: #ffbb69;
}
.btn-outline-warning:hover {
    color: #000;
    background-color: #ffbb69;
    border-color: #ffbb69;
}
.btn-check:focus + .btn-outline-warning,
.btn-outline-warning:focus {
    box-shadow: 0 0 0 0rem rgba(255, 187, 105, 0.5);
}
.btn-check:checked + .btn-outline-warning,
.btn-check:active + .btn-outline-warning,
.btn-outline-warning:active,
.btn-outline-warning.active,
.btn-outline-warning.dropdown-toggle.show {
    color: #000;
    background-color: #ffbb69;
    border-color: #ffbb69;
}
.btn-check:checked + .btn-outline-warning:focus,
.btn-check:active + .btn-outline-warning:focus,
.btn-outline-warning:active:focus,
.btn-outline-warning.active:focus,
.btn-outline-warning.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 0rem rgba(255, 187, 105, 0.5);
}
.btn-outline-warning:disabled,
.btn-outline-warning.disabled {
    color: #ffbb69;
    background-color: transparent;
}

.btn-outline-danger {
    color: #eb5757;
    border-color: #eb5757;
}
.btn-outline-danger:hover {
    color: #000;
    background-color: #eb5757;
    border-color: #eb5757;
}
.btn-check:focus + .btn-outline-danger,
.btn-outline-danger:focus {
    box-shadow: 0 0 0 0rem rgba(235, 87, 87, 0.5);
}
.btn-check:checked + .btn-outline-danger,
.btn-check:active + .btn-outline-danger,
.btn-outline-danger:active,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show {
    color: #000;
    background-color: #eb5757;
    border-color: #eb5757;
}
.btn-check:checked + .btn-outline-danger:focus,
.btn-check:active + .btn-outline-danger:focus,
.btn-outline-danger:active:focus,
.btn-outline-danger.active:focus,
.btn-outline-danger.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 0rem rgba(235, 87, 87, 0.5);
}
.btn-outline-danger:disabled,
.btn-outline-danger.disabled {
    color: #eb5757;
    background-color: transparent;
}

.btn-outline-light {
    color: #fffbf5;
    border-color: #fffbf5;
}
.btn-outline-light:hover {
    color: #000;
    background-color: #fffbf5;
    border-color: #fffbf5;
}
.btn-check:focus + .btn-outline-light,
.btn-outline-light:focus {
    box-shadow: 0 0 0 0rem rgba(255, 251, 245, 0.5);
}
.btn-check:checked + .btn-outline-light,
.btn-check:active + .btn-outline-light,
.btn-outline-light:active,
.btn-outline-light.active,
.btn-outline-light.dropdown-toggle.show {
    color: #000;
    background-color: #fffbf5;
    border-color: #fffbf5;
}
.btn-check:checked + .btn-outline-light:focus,
.btn-check:active + .btn-outline-light:focus,
.btn-outline-light:active:focus,
.btn-outline-light.active:focus,
.btn-outline-light.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 0rem rgba(255, 251, 245, 0.5);
}
.btn-outline-light:disabled,
.btn-outline-light.disabled {
    color: #fffbf5;
    background-color: transparent;
}

.btn-outline-dark {
    color: #212529;
    border-color: #212529;
}
.btn-outline-dark:hover {
    color: #ffffff;
    background-color: #212529;
    border-color: #212529;
}
.btn-check:focus + .btn-outline-dark,
.btn-outline-dark:focus {
    box-shadow: 0 0 0 0rem rgba(33, 37, 41, 0.5);
}
.btn-check:checked + .btn-outline-dark,
.btn-check:active + .btn-outline-dark,
.btn-outline-dark:active,
.btn-outline-dark.active,
.btn-outline-dark.dropdown-toggle.show {
    color: #ffffff;
    background-color: #212529;
    border-color: #212529;
}
.btn-check:checked + .btn-outline-dark:focus,
.btn-check:active + .btn-outline-dark:focus,
.btn-outline-dark:active:focus,
.btn-outline-dark.active:focus,
.btn-outline-dark.dropdown-toggle.show:focus {
    box-shadow: 0 0 0 0rem rgba(33, 37, 41, 0.5);
}
.btn-outline-dark:disabled,
.btn-outline-dark.disabled {
    color: #212529;
    background-color: transparent;
}

.btn-link {
    font-weight: 400;
    color: #02a3fe;
    text-decoration: underline;
}
.btn-link:hover {
    color: #0282cb;
}
.btn-link:disabled,
.btn-link.disabled {
    color: #6c757d;
}

.btn-lg,
.btn-group-lg > .btn {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    border-radius: 1rem;
}

.btn-sm,
.btn-group-sm > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    border-radius: 0.75rem;
}

.fade {
    transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
    .fade {
        transition: none;
    }
}
.fade:not(.show) {
    opacity: 0;
}

.collapse:not(.show) {
    display: none;
}

.collapsing {
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
    .collapsing {
        transition: none;
    }
}
.collapsing.collapse-horizontal {
    width: 0;
    height: auto;
    transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
    .collapsing.collapse-horizontal {
        transition: none;
    }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
    position: relative;
}

.dropdown-toggle {
    white-space: nowrap;
}
.dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
    margin-left: 0;
}

.dropdown-menu {
    position: absolute;
    z-index: 1000;
    display: none;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0;
    font-size: 1rem;
    color: #52525e;
    text-align: left;
    list-style: none;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.375rem;
}
.dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: 0.125rem;
}

.dropdown-menu-start {
    --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0;
}

.dropdown-menu-end {
    --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto;
}

@media (min-width: 576px) {
    .dropdown-menu-sm-start {
        --bs-position: start;
    }
    .dropdown-menu-sm-start[data-bs-popper] {
        right: auto;
        left: 0;
    }
    .dropdown-menu-sm-end {
        --bs-position: end;
    }
    .dropdown-menu-sm-end[data-bs-popper] {
        right: 0;
        left: auto;
    }
}

@media (min-width: 768px) {
    .dropdown-menu-md-start {
        --bs-position: start;
    }
    .dropdown-menu-md-start[data-bs-popper] {
        right: auto;
        left: 0;
    }
    .dropdown-menu-md-end {
        --bs-position: end;
    }
    .dropdown-menu-md-end[data-bs-popper] {
        right: 0;
        left: auto;
    }
}

@media (min-width: 992px) {
    .dropdown-menu-lg-start {
        --bs-position: start;
    }
    .dropdown-menu-lg-start[data-bs-popper] {
        right: auto;
        left: 0;
    }
    .dropdown-menu-lg-end {
        --bs-position: end;
    }
    .dropdown-menu-lg-end[data-bs-popper] {
        right: 0;
        left: auto;
    }
}

@media (min-width: 1200px) {
    .dropdown-menu-xl-start {
        --bs-position: start;
    }
    .dropdown-menu-xl-start[data-bs-popper] {
        right: auto;
        left: 0;
    }
    .dropdown-menu-xl-end {
        --bs-position: end;
    }
    .dropdown-menu-xl-end[data-bs-popper] {
        right: 0;
        left: auto;
    }
}

@media (min-width: 1440) {
    .dropdown-menu-xxl-start {
        --bs-position: start;
    }
    .dropdown-menu-xxl-start[data-bs-popper] {
        right: auto;
        left: 0;
    }
    .dropdown-menu-xxl-end {
        --bs-position: end;
    }
    .dropdown-menu-xxl-end[data-bs-popper] {
        right: 0;
        left: auto;
    }
}

.dropup .dropdown-menu[data-bs-popper] {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border-top: 0;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid;
    border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
    margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: 0.125rem;
}

.dropend .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border-top: 0.3em solid transparent;
    border-right: 0;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
}

.dropend .dropdown-toggle:empty::after {
    margin-left: 0;
}

.dropend .dropdown-toggle::after {
    vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: 0.125rem;
}

.dropstart .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
}

.dropstart .dropdown-toggle::after {
    display: none;
}

.dropstart .dropdown-toggle::before {
    display: inline-block;
    margin-right: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border-top: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-bottom: 0.3em solid transparent;
}

.dropstart .dropdown-toggle:empty::after {
    margin-left: 0;
}

.dropstart .dropdown-toggle::before {
    vertical-align: 0;
}

.dropdown-divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}
.dropdown-item:hover,
.dropdown-item:focus {
    color: #1e2125;
    background-color: #e9ecef;
}
.dropdown-item.active,
.dropdown-item:active {
    color: #ffffff;
    text-decoration: none;
    background-color: #02a3fe;
}
.dropdown-item.disabled,
.dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent;
}

.dropdown-menu.show {
    display: block;
}

.dropdown-header {
    display: block;
    padding: 0.5rem 1rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    color: #6c757d;
    white-space: nowrap;
}

.dropdown-item-text {
    display: block;
    padding: 0.25rem 1rem;
    color: #212529;
}

.dropdown-menu-dark {
    color: #dee2e6;
    background-color: #343a40;
    border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item {
    color: #dee2e6;
}
.dropdown-menu-dark .dropdown-item:hover,
.dropdown-menu-dark .dropdown-item:focus {
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.15);
}
.dropdown-menu-dark .dropdown-item.active,
.dropdown-menu-dark .dropdown-item:active {
    color: #ffffff;
    background-color: #02a3fe;
}
.dropdown-menu-dark .dropdown-item.disabled,
.dropdown-menu-dark .dropdown-item:disabled {
    color: #adb5bd;
}
.dropdown-menu-dark .dropdown-divider {
    border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item-text {
    color: #dee2e6;
}
.dropdown-menu-dark .dropdown-header {
    color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
    z-index: 1;
}

.btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.btn-toolbar .input-group {
    width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
    margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.btn-group > .btn:nth-child(n + 3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
    padding-right: 0.5625rem;
    padding-left: 0.5625rem;
}
.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropend .dropdown-toggle-split::after {
    margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
    margin-right: 0;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split {
    padding-right: 0.375rem;
    padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
}

.btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
    width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.nav-link {
    display: block;
    padding: 0.5rem 1rem;
    color: #02a3fe;
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
    .nav-link {
        transition: none;
    }
}
.nav-link:hover,
.nav-link:focus {
    color: #0282cb;
}
.nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default;
}

.nav-tabs {
    border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
    margin-bottom: -1px;
    background: none;
    border: 1px solid transparent;
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
    border-color: #e9ecef #e9ecef #dee2e6;
    isolation: isolate;
}
.nav-tabs .nav-link.disabled {
    color: #6c757d;
    background-color: transparent;
    border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #ffffff;
    border-color: #dee2e6 #dee2e6 #ffffff;
}
.nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.nav-pills .nav-link {
    background: none;
    border: 0;
    border-radius: 0.375rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    color: #ffffff;
    background-color: #02a3fe;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
    flex: 1 1 auto;
    text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
    width: 100%;
}

.tab-content > .tab-pane {
    display: none;
}

.tab-content > .active {
    display: block;
}

.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between;
}

.navbar-brand {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    text-decoration: none;
    white-space: nowrap;
}

.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}
.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
}
.navbar-nav .dropdown-menu {
    position: static;
}

.navbar-text {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
}

.navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.375rem;
    transition: box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
    .navbar-toggler {
        transition: none;
    }
}
.navbar-toggler:hover {
    text-decoration: none;
}
.navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 0rem;
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}

.navbar-nav-scroll {
    max-height: var(--bs-scroll-height, 75vh);
    overflow-y: auto;
}

@media (min-width: 576px) {
    .navbar-expand-sm {
        flex-wrap: nowrap;
        justify-content: flex-start;
    }
    .navbar-expand-sm .navbar-nav {
        flex-direction: row;
    }
    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
    .navbar-expand-sm .navbar-nav-scroll {
        overflow: visible;
    }
    .navbar-expand-sm .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }
    .navbar-expand-sm .navbar-toggler {
        display: none;
    }
    .navbar-expand-sm .offcanvas-header {
        display: none;
    }
    .navbar-expand-sm .offcanvas {
        position: inherit;
        bottom: 0;
        z-index: 1000;
        flex-grow: 1;
        visibility: visible !important;
        background-color: transparent;
        border-right: 0;
        border-left: 0;
        transition: none;
        transform: none;
    }
    .navbar-expand-sm .offcanvas-top,
    .navbar-expand-sm .offcanvas-bottom {
        height: auto;
        border-top: 0;
        border-bottom: 0;
    }
    .navbar-expand-sm .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible;
    }
}

@media (min-width: 768px) {
    .navbar-expand-md {
        flex-wrap: nowrap;
        justify-content: flex-start;
    }
    .navbar-expand-md .navbar-nav {
        flex-direction: row;
    }
    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
    .navbar-expand-md .navbar-nav-scroll {
        overflow: visible;
    }
    .navbar-expand-md .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }
    .navbar-expand-md .navbar-toggler {
        display: none;
    }
    .navbar-expand-md .offcanvas-header {
        display: none;
    }
    .navbar-expand-md .offcanvas {
        position: inherit;
        bottom: 0;
        z-index: 1000;
        flex-grow: 1;
        visibility: visible !important;
        background-color: transparent;
        border-right: 0;
        border-left: 0;
        transition: none;
        transform: none;
    }
    .navbar-expand-md .offcanvas-top,
    .navbar-expand-md .offcanvas-bottom {
        height: auto;
        border-top: 0;
        border-bottom: 0;
    }
    .navbar-expand-md .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible;
    }
}

@media (min-width: 992px) {
    .navbar-expand-lg {
        flex-wrap: nowrap;
        justify-content: flex-start;
    }
    .navbar-expand-lg .navbar-nav {
        flex-direction: row;
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
    .navbar-expand-lg .navbar-nav-scroll {
        overflow: visible;
    }
    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }
    .navbar-expand-lg .navbar-toggler {
        display: none;
    }
    .navbar-expand-lg .offcanvas-header {
        display: none;
    }
    .navbar-expand-lg .offcanvas {
        position: inherit;
        bottom: 0;
        z-index: 1000;
        flex-grow: 1;
        visibility: visible !important;
        background-color: transparent;
        border-right: 0;
        border-left: 0;
        transition: none;
        transform: none;
    }
    .navbar-expand-lg .offcanvas-top,
    .navbar-expand-lg .offcanvas-bottom {
        height: auto;
        border-top: 0;
        border-bottom: 0;
    }
    .navbar-expand-lg .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible;
    }
}

@media (min-width: 1200px) {
    .navbar-expand-xl {
        flex-wrap: nowrap;
        justify-content: flex-start;
    }
    .navbar-expand-xl .navbar-nav {
        flex-direction: row;
    }
    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
    .navbar-expand-xl .navbar-nav-scroll {
        overflow: visible;
    }
    .navbar-expand-xl .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }
    .navbar-expand-xl .navbar-toggler {
        display: none;
    }
    .navbar-expand-xl .offcanvas-header {
        display: none;
    }
    .navbar-expand-xl .offcanvas {
        position: inherit;
        bottom: 0;
        z-index: 1000;
        flex-grow: 1;
        visibility: visible !important;
        background-color: transparent;
        border-right: 0;
        border-left: 0;
        transition: none;
        transform: none;
    }
    .navbar-expand-xl .offcanvas-top,
    .navbar-expand-xl .offcanvas-bottom {
        height: auto;
        border-top: 0;
        border-bottom: 0;
    }
    .navbar-expand-xl .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible;
    }
}

@media (min-width: 1440) {
    .navbar-expand-xxl {
        flex-wrap: nowrap;
        justify-content: flex-start;
    }
    .navbar-expand-xxl .navbar-nav {
        flex-direction: row;
    }
    .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
    .navbar-expand-xxl .navbar-nav-scroll {
        overflow: visible;
    }
    .navbar-expand-xxl .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }
    .navbar-expand-xxl .navbar-toggler {
        display: none;
    }
    .navbar-expand-xxl .offcanvas-header {
        display: none;
    }
    .navbar-expand-xxl .offcanvas {
        position: inherit;
        bottom: 0;
        z-index: 1000;
        flex-grow: 1;
        visibility: visible !important;
        background-color: transparent;
        border-right: 0;
        border-left: 0;
        transition: none;
        transform: none;
    }
    .navbar-expand-xxl .offcanvas-top,
    .navbar-expand-xxl .offcanvas-bottom {
        height: auto;
        border-top: 0;
        border-bottom: 0;
    }
    .navbar-expand-xxl .offcanvas-body {
        display: flex;
        flex-grow: 0;
        padding: 0;
        overflow-y: visible;
    }
}

.navbar-expand {
    flex-wrap: nowrap;
    justify-content: flex-start;
}
.navbar-expand .navbar-nav {
    flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}
.navbar-expand .navbar-nav-scroll {
    overflow: visible;
}
.navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
}
.navbar-expand .navbar-toggler {
    display: none;
}
.navbar-expand .offcanvas-header {
    display: none;
}
.navbar-expand .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
}
.navbar-expand .offcanvas-top,
.navbar-expand .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
}
.navbar-expand .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
}

.navbar-light .navbar-brand {
    color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.55);
    border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
    color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
    color: #ffffff;
}
.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
    color: #ffffff;
}

.navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
    color: #ffffff;
}

.navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, 0.55);
    border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
    color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
    color: #ffffff;
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.375rem;
}
.card > hr {
    margin-right: 0;
    margin-left: 0;
}
.card > .list-group {
    border-top: inherit;
    border-bottom: inherit;
}
.card > .list-group:first-child {
    border-top-width: 0;
    border-top-left-radius: calc(0.375rem - 1px);
    border-top-right-radius: calc(0.375rem - 1px);
}
.card > .list-group:last-child {
    border-bottom-width: 0;
    border-bottom-right-radius: calc(0.375rem - 1px);
    border-bottom-left-radius: calc(0.375rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
    border-top: 0;
}

.card-body {
    flex: 1 1 auto;
    padding: 1rem 1rem;
}

.card-title {
    margin-bottom: 0.5rem;
}

.card-subtitle {
    margin-top: -0.25rem;
    margin-bottom: 0;
}

.card-text:last-child {
    margin-bottom: 0;
}

.card-link + .card-link {
    margin-left: 1rem;
}

.card-header {
    padding: 0.5rem 1rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
    border-radius: calc(0.375rem - 1px) calc(0.375rem - 1px) 0 0;
}

.card-footer {
    padding: 0.5rem 1rem;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
    border-radius: 0 0 calc(0.375rem - 1px) calc(0.375rem - 1px);
}

.card-header-tabs {
    margin-right: -0.5rem;
    margin-bottom: -0.5rem;
    margin-left: -0.5rem;
    border-bottom: 0;
}

.card-header-pills {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1rem;
    border-radius: calc(0.375rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
    width: 100%;
}

.card-img,
.card-img-top {
    border-top-left-radius: calc(0.375rem - 1px);
    border-top-right-radius: calc(0.375rem - 1px);
}

.card-img,
.card-img-bottom {
    border-bottom-right-radius: calc(0.375rem - 1px);
    border-bottom-left-radius: calc(0.375rem - 1px);
}

.card-group > .card {
    margin-bottom: 15px;
}

@media (min-width: 576px) {
    .card-group {
        display: flex;
        flex-flow: row wrap;
    }
    .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0;
    }
    .card-group > .card + .card {
        margin-left: 0;
        border-left: 0;
    }
    .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .card-group > .card:not(:last-child) .card-img-top,
    .card-group > .card:not(:last-child) .card-header {
        border-top-right-radius: 0;
    }
    .card-group > .card:not(:last-child) .card-img-bottom,
    .card-group > .card:not(:last-child) .card-footer {
        border-bottom-right-radius: 0;
    }
    .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .card-group > .card:not(:first-child) .card-img-top,
    .card-group > .card:not(:first-child) .card-header {
        border-top-left-radius: 0;
    }
    .card-group > .card:not(:first-child) .card-img-bottom,
    .card-group > .card:not(:first-child) .card-footer {
        border-bottom-left-radius: 0;
    }
}

.accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1rem 1.25rem;
    font-size: 1rem;
    color: #52525e;
    text-align: left;
    background-color: #ffffff;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
    .accordion-button {
        transition: none;
    }
}
.accordion-button:not(.collapsed) {
    color: #0293e5;
    background-color: #e6f6ff;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}
.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230293e5'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    transform: rotate(-180deg);
}
.accordion-button::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: '';
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%2352525E'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
    .accordion-button::after {
        transition: none;
    }
}
.accordion-button:hover {
    z-index: 2;
}
.accordion-button:focus {
    z-index: 3;
    border-color: #81d1ff;
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(2, 163, 254, 0.25);
}

.accordion-header {
    margin-bottom: 0;
}

.accordion-item {
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.125);
}
.accordion-item:first-of-type {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
}
.accordion-item:first-of-type .accordion-button {
    border-top-left-radius: calc(0.375rem - 1px);
    border-top-right-radius: calc(0.375rem - 1px);
}
.accordion-item:not(:first-of-type) {
    border-top: 0;
}
.accordion-item:last-of-type {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
}
.accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: calc(0.375rem - 1px);
    border-bottom-left-radius: calc(0.375rem - 1px);
}
.accordion-item:last-of-type .accordion-collapse {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
}

.accordion-body {
    padding: 1rem 1.25rem;
}

.accordion-flush .accordion-collapse {
    border-width: 0;
}

.accordion-flush .accordion-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
    border-top: 0;
}
.accordion-flush .accordion-item:last-child {
    border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
    border-radius: 0;
}

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0;
    margin-bottom: 1rem;
    list-style: none;
}

.breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: 0.5rem;
    color: #6c757d;
    content: var(--bs-breadcrumb-divider, '/') /* rtl: var(--bs-breadcrumb-divider, "/") */;
}

.breadcrumb-item.active {
    color: #6c757d;
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
}

.page-link {
    position: relative;
    display: block;
    color: #02a3fe;
    text-decoration: none;
    background-color: #ffffff;
    border: 1px solid #dee2e6;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
    .page-link {
        transition: none;
    }
}
.page-link:hover {
    z-index: 2;
    color: #0282cb;
    background-color: #e9ecef;
    border-color: #dee2e6;
}
.page-link:focus {
    z-index: 3;
    color: #0282cb;
    background-color: #e9ecef;
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(2, 163, 254, 0.25);
}

.page-item:not(:first-child) .page-link {
    margin-left: -1px;
}

.page-item.active .page-link {
    z-index: 3;
    color: #ffffff;
    background-color: #02a3fe;
    border-color: #02a3fe;
}

.page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    background-color: #ffffff;
    border-color: #dee2e6;
}

.page-link {
    padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
}

.page-item:last-child .page-link {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
}

.pagination-lg .page-link {
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
}

.pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
}

.pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

.pagination-sm .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
}

.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
}

.pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
}

.badge {
    display: inline-block;
    padding: 0.35em 0.65em;
    font-size: 0.75em;
    font-weight: 700;
    line-height: 1;
    color: #ffffff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.375rem;
}
.badge:empty {
    display: none;
}

.btn .badge {
    position: relative;
    top: -1px;
}

.alert {
    position: relative;
    padding: 1rem 1rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.375rem;
}

.alert-heading {
    color: inherit;
}

.alert-link {
    font-weight: 700;
}

.alert-dismissible {
    padding-right: 3rem;
}
.alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1.25rem 1rem;
}

.alert-primary {
    color: #016298;
    background-color: #ccedff;
    border-color: #b3e3ff;
}
.alert-primary .alert-link {
    color: #014e7a;
}

.alert-secondary {
    color: #05050d;
    background-color: #ceced0;
    border-color: #b5b5b9;
}
.alert-secondary .alert-link {
    color: #04040a;
}

.alert-success {
    color: #0a6a0a;
    background-color: #cfefcf;
    border-color: #b8e7b8;
}
.alert-success .alert-link {
    color: #085508;
}

.alert-info {
    color: #006899;
    background-color: #ccefff;
    border-color: #b3e7ff;
}
.alert-info .alert-link {
    color: #00537a;
}

.alert-warning {
    color: #664b2a;
    background-color: #fff1e1;
    border-color: #ffebd2;
}
.alert-warning .alert-link {
    color: #523c22;
}

.alert-danger {
    color: #8d3434;
    background-color: #fbdddd;
    border-color: #f9cdcd;
}
.alert-danger .alert-link {
    color: #712a2a;
}

.alert-light {
    color: #666462;
    background-color: #fffefd;
    border-color: #fffefc;
}
.alert-light .alert-link {
    color: #52504e;
}

.alert-dark {
    color: #141619;
    background-color: #d3d3d4;
    border-color: #bcbebf;
}
.alert-dark .alert-link {
    color: #101214;
}

@keyframes progress-bar-stripes {
    0% {
        background-position-x: 1rem;
    }
}

.progress {
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: 0.75rem;
    background-color: #e9ecef;
    border-radius: 0.375rem;
}

.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #ffffff;
    text-align: center;
    white-space: nowrap;
    background-color: #02a3fe;
    transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
    .progress-bar {
        transition: none;
    }
}

.progress-bar-striped {
    background-image: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0.15) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.15) 50%,
        rgba(255, 255, 255, 0.15) 75%,
        transparent 75%,
        transparent
    );
    background-size: 1rem 1rem;
}

.progress-bar-animated {
    animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
        animation: none;
    }
}

.list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: 0.375rem;
}

.list-group-numbered {
    list-style-type: none;
    counter-reset: section;
}
.list-group-numbered > li::before {
    content: counters(section, '.') '. ';
    counter-increment: section;
}

.list-group-item-action {
    width: 100%;
    color: #495057;
    text-align: inherit;
}
.list-group-item-action:hover,
.list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa;
}
.list-group-item-action:active {
    color: #52525e;
    background-color: #e9ecef;
}

.list-group-item {
    position: relative;
    display: block;
    padding: 0.5rem 1rem;
    color: #212529;
    text-decoration: none;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
}
.list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit;
}
.list-group-item.disabled,
.list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #ffffff;
}
.list-group-item.active {
    z-index: 2;
    color: #ffffff;
    background-color: #02a3fe;
    border-color: #02a3fe;
}
.list-group-item + .list-group-item {
    border-top-width: 0;
}
.list-group-item + .list-group-item.active {
    margin-top: -1px;
    border-top-width: 1px;
}

.list-group-horizontal {
    flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.375rem;
    border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.375rem;
    border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
    margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
}

@media (min-width: 576px) {
    .list-group-horizontal-sm {
        flex-direction: row;
    }
    .list-group-horizontal-sm > .list-group-item:first-child {
        border-bottom-left-radius: 0.375rem;
        border-top-right-radius: 0;
    }
    .list-group-horizontal-sm > .list-group-item:last-child {
        border-top-right-radius: 0.375rem;
        border-bottom-left-radius: 0;
    }
    .list-group-horizontal-sm > .list-group-item.active {
        margin-top: 0;
    }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }
    .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

@media (min-width: 768px) {
    .list-group-horizontal-md {
        flex-direction: row;
    }
    .list-group-horizontal-md > .list-group-item:first-child {
        border-bottom-left-radius: 0.375rem;
        border-top-right-radius: 0;
    }
    .list-group-horizontal-md > .list-group-item:last-child {
        border-top-right-radius: 0.375rem;
        border-bottom-left-radius: 0;
    }
    .list-group-horizontal-md > .list-group-item.active {
        margin-top: 0;
    }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }
    .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

@media (min-width: 992px) {
    .list-group-horizontal-lg {
        flex-direction: row;
    }
    .list-group-horizontal-lg > .list-group-item:first-child {
        border-bottom-left-radius: 0.375rem;
        border-top-right-radius: 0;
    }
    .list-group-horizontal-lg > .list-group-item:last-child {
        border-top-right-radius: 0.375rem;
        border-bottom-left-radius: 0;
    }
    .list-group-horizontal-lg > .list-group-item.active {
        margin-top: 0;
    }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }
    .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

@media (min-width: 1200px) {
    .list-group-horizontal-xl {
        flex-direction: row;
    }
    .list-group-horizontal-xl > .list-group-item:first-child {
        border-bottom-left-radius: 0.375rem;
        border-top-right-radius: 0;
    }
    .list-group-horizontal-xl > .list-group-item:last-child {
        border-top-right-radius: 0.375rem;
        border-bottom-left-radius: 0;
    }
    .list-group-horizontal-xl > .list-group-item.active {
        margin-top: 0;
    }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }
    .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

@media (min-width: 1440) {
    .list-group-horizontal-xxl {
        flex-direction: row;
    }
    .list-group-horizontal-xxl > .list-group-item:first-child {
        border-bottom-left-radius: 0.375rem;
        border-top-right-radius: 0;
    }
    .list-group-horizontal-xxl > .list-group-item:last-child {
        border-top-right-radius: 0.375rem;
        border-bottom-left-radius: 0;
    }
    .list-group-horizontal-xxl > .list-group-item.active {
        margin-top: 0;
    }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item {
        border-top-width: 1px;
        border-left-width: 0;
    }
    .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px;
    }
}

.list-group-flush {
    border-radius: 0;
}
.list-group-flush > .list-group-item {
    border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
    border-bottom-width: 0;
}

.list-group-item-primary {
    color: #016298;
    background-color: #ccedff;
}
.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
    color: #016298;
    background-color: #b8d5e6;
}
.list-group-item-primary.list-group-item-action.active {
    color: #ffffff;
    background-color: #016298;
    border-color: #016298;
}

.list-group-item-secondary {
    color: #05050d;
    background-color: #ceced0;
}
.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
    color: #05050d;
    background-color: #b9b9bb;
}
.list-group-item-secondary.list-group-item-action.active {
    color: #ffffff;
    background-color: #05050d;
    border-color: #05050d;
}

.list-group-item-success {
    color: #0a6a0a;
    background-color: #cfefcf;
}
.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
    color: #0a6a0a;
    background-color: #bad7ba;
}
.list-group-item-success.list-group-item-action.active {
    color: #ffffff;
    background-color: #0a6a0a;
    border-color: #0a6a0a;
}

.list-group-item-info {
    color: #006899;
    background-color: #ccefff;
}
.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
    color: #006899;
    background-color: #b8d7e6;
}
.list-group-item-info.list-group-item-action.active {
    color: #ffffff;
    background-color: #006899;
    border-color: #006899;
}

.list-group-item-warning {
    color: #664b2a;
    background-color: #fff1e1;
}
.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
    color: #664b2a;
    background-color: #e6d9cb;
}
.list-group-item-warning.list-group-item-action.active {
    color: #ffffff;
    background-color: #664b2a;
    border-color: #664b2a;
}

.list-group-item-danger {
    color: #8d3434;
    background-color: #fbdddd;
}
.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
    color: #8d3434;
    background-color: #e2c7c7;
}
.list-group-item-danger.list-group-item-action.active {
    color: #ffffff;
    background-color: #8d3434;
    border-color: #8d3434;
}

.list-group-item-light {
    color: #666462;
    background-color: #fffefd;
}
.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
    color: #666462;
    background-color: #e6e5e4;
}
.list-group-item-light.list-group-item-action.active {
    color: #ffffff;
    background-color: #666462;
    border-color: #666462;
}

.list-group-item-dark {
    color: #141619;
    background-color: #d3d3d4;
}
.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
    color: #141619;
    background-color: #bebebf;
}
.list-group-item-dark.list-group-item-action.active {
    color: #ffffff;
    background-color: #141619;
    border-color: #141619;
}

.btn-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 0.25em 0.25em;
    color: #000;
    background: transparent
        url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
        center/1em auto no-repeat;
    border: 0;
    border-radius: 0.375rem;
    opacity: 0.5;
}
.btn-close:hover {
    color: #000;
    text-decoration: none;
    opacity: 0.75;
}
.btn-close:focus {
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(2, 163, 254, 0.25);
    opacity: 1;
}
.btn-close:disabled,
.btn-close.disabled {
    pointer-events: none;
    user-select: none;
    opacity: 0.25;
}

.btn-close-white {
    filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
    width: 350px;
    max-width: 100%;
    font-size: 0.875rem;
    pointer-events: auto;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    border-radius: 0.375rem;
}
.toast.showing {
    opacity: 0;
}
.toast:not(.show) {
    display: none;
}

.toast-container {
    width: max-content;
    max-width: 100%;
    pointer-events: none;
}
.toast-container > :not(:last-child) {
    margin-bottom: 15px;
}

.toast-header {
    display: flex;
    align-items: center;
    padding: 0.5rem 0.75rem;
    color: #6c757d;
    background-color: rgba(255, 255, 255, 0.85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    border-top-left-radius: calc(0.375rem - 1px);
    border-top-right-radius: calc(0.375rem - 1px);
}
.toast-header .btn-close {
    margin-right: -0.375rem;
    margin-left: 0.75rem;
}

.toast-body {
    padding: 0.75rem;
    word-wrap: break-word;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1055;
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
}
.modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
        transition: none;
    }
}
.modal.show .modal-dialog {
    transform: none;
}
.modal.modal-static .modal-dialog {
    transform: scale(1.02);
}

.modal-dialog-scrollable {
    height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
    overflow-y: auto;
}

.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem);
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 1rem;
    outline: 0;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100vw;
    height: 100vh;
    background-color: #000;
}
.modal-backdrop.fade {
    opacity: 0;
}
.modal-backdrop.show {
    opacity: 0.5;
}

.modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #cfdbed;
    border-top-left-radius: calc(1rem - 1px);
    border-top-right-radius: calc(1rem - 1px);
}
.modal-header .btn-close {
    padding: 0.5rem 0.5rem;
    margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.6;
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
}

.modal-footer {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-end;
    padding: 0.75rem;
    border-top: 1px solid #cfdbed;
    border-bottom-right-radius: calc(1rem - 1px);
    border-bottom-left-radius: calc(1rem - 1px);
}
.modal-footer > * {
    margin: 0.25rem;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto;
    }
    .modal-dialog-scrollable {
        height: calc(100% - 3.5rem);
    }
    .modal-dialog-centered {
        min-height: calc(100% - 3.5rem);
    }
    .modal-sm {
        max-width: 300px;
    }
}

@media (min-width: 992px) {
    .modal-lg,
    .modal-xl {
        max-width: 800px;
    }
}

@media (min-width: 1200px) {
    .modal-xl {
        max-width: 1140px;
    }
}

.modal-fullscreen {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
}
.modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
}
.modal-fullscreen .modal-header {
    border-radius: 0;
}
.modal-fullscreen .modal-body {
    overflow-y: auto;
}
.modal-fullscreen .modal-footer {
    border-radius: 0;
}

@media (max-width: 575.98px) {
    .modal-fullscreen-sm-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0;
    }
    .modal-fullscreen-sm-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0;
    }
    .modal-fullscreen-sm-down .modal-header {
        border-radius: 0;
    }
    .modal-fullscreen-sm-down .modal-body {
        overflow-y: auto;
    }
    .modal-fullscreen-sm-down .modal-footer {
        border-radius: 0;
    }
}

@media (max-width: 767.98px) {
    .modal-fullscreen-md-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0;
    }
    .modal-fullscreen-md-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0;
    }
    .modal-fullscreen-md-down .modal-header {
        border-radius: 0;
    }
    .modal-fullscreen-md-down .modal-body {
        overflow-y: auto;
    }
    .modal-fullscreen-md-down .modal-footer {
        border-radius: 0;
    }
}

@media (max-width: 991.98px) {
    .modal-fullscreen-lg-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0;
    }
    .modal-fullscreen-lg-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0;
    }
    .modal-fullscreen-lg-down .modal-header {
        border-radius: 0;
    }
    .modal-fullscreen-lg-down .modal-body {
        overflow-y: auto;
    }
    .modal-fullscreen-lg-down .modal-footer {
        border-radius: 0;
    }
}

@media (max-width: 1199.98px) {
    .modal-fullscreen-xl-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0;
    }
    .modal-fullscreen-xl-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0;
    }
    .modal-fullscreen-xl-down .modal-header {
        border-radius: 0;
    }
    .modal-fullscreen-xl-down .modal-body {
        overflow-y: auto;
    }
    .modal-fullscreen-xl-down .modal-footer {
        border-radius: 0;
    }
}

@media (max-width: 1439.98) {
    .modal-fullscreen-xxl-down {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0;
    }
    .modal-fullscreen-xxl-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0;
    }
    .modal-fullscreen-xxl-down .modal-header {
        border-radius: 0;
    }
    .modal-fullscreen-xxl-down .modal-body {
        overflow-y: auto;
    }
    .modal-fullscreen-xxl-down .modal-footer {
        border-radius: 0;
    }
}

.tooltip {
    position: absolute;
    z-index: 1080;
    display: block;
    margin: 0;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.6;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    opacity: 0;
}
.tooltip.show {
    opacity: 0.9;
}
.tooltip .tooltip-arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem;
}
.tooltip .tooltip-arrow::before {
    position: absolute;
    content: '';
    border-color: transparent;
    border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[data-popper-placement^='top'] {
    padding: 0.4rem 0;
}
.bs-tooltip-top .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^='top'] .tooltip-arrow {
    bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^='top'] .tooltip-arrow::before {
    top: -1px;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #000;
}

.bs-tooltip-end,
.bs-tooltip-auto[data-popper-placement^='right'] {
    padding: 0 0.4rem;
}
.bs-tooltip-end .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^='right'] .tooltip-arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem;
}
.bs-tooltip-end .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^='right'] .tooltip-arrow::before {
    right: -1px;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: #000;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[data-popper-placement^='bottom'] {
    padding: 0.4rem 0;
}
.bs-tooltip-bottom .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^='bottom'] .tooltip-arrow {
    top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^='bottom'] .tooltip-arrow::before {
    bottom: -1px;
    border-width: 0 0.4rem 0.4rem;
    border-bottom-color: #000;
}

.bs-tooltip-start,
.bs-tooltip-auto[data-popper-placement^='left'] {
    padding: 0 0.4rem;
}
.bs-tooltip-start .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^='left'] .tooltip-arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem;
}
.bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^='left'] .tooltip-arrow::before {
    left: -1px;
    border-width: 0.4rem 0 0.4rem 0.4rem;
    border-left-color: #000;
}

.tooltip-inner {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: #ffffff;
    text-align: center;
    background-color: #000;
    border-radius: 0.375rem;
}

.popover {
    position: absolute;
    top: 0;
    left: 0 /* rtl:ignore */;
    z-index: 1070;
    display: block;
    max-width: 276px;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.6;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 1rem;
}
.popover .popover-arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
}
.popover .popover-arrow::before,
.popover .popover-arrow::after {
    position: absolute;
    display: block;
    content: '';
    border-color: transparent;
    border-style: solid;
}

.bs-popover-top > .popover-arrow,
.bs-popover-auto[data-popper-placement^='top'] > .popover-arrow {
    bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^='top'] > .popover-arrow::before {
    bottom: 0;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^='top'] > .popover-arrow::after {
    bottom: 1px;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: #ffffff;
}

.bs-popover-end > .popover-arrow,
.bs-popover-auto[data-popper-placement^='right'] > .popover-arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
}
.bs-popover-end > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^='right'] > .popover-arrow::before {
    left: 0;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-end > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^='right'] > .popover-arrow::after {
    left: 1px;
    border-width: 0.5rem 0.5rem 0.5rem 0;
    border-right-color: #ffffff;
}

.bs-popover-bottom > .popover-arrow,
.bs-popover-auto[data-popper-placement^='bottom'] > .popover-arrow {
    top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^='bottom'] > .popover-arrow::before {
    top: 0;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^='bottom'] > .popover-arrow::after {
    top: 1px;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: #ffffff;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[data-popper-placement^='bottom'] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: '';
    border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start > .popover-arrow,
.bs-popover-auto[data-popper-placement^='left'] > .popover-arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
}
.bs-popover-start > .popover-arrow::before,
.bs-popover-auto[data-popper-placement^='left'] > .popover-arrow::before {
    right: 0;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-start > .popover-arrow::after,
.bs-popover-auto[data-popper-placement^='left'] > .popover-arrow::after {
    right: 1px;
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #ffffff;
}

.popover-header {
    padding: 0.5rem 1rem;
    margin-bottom: 0;
    font-size: 1rem;
    color: #1e1e26;
    background-color: #f0f0f0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    border-top-left-radius: calc(1rem - 1px);
    border-top-right-radius: calc(1rem - 1px);
}
.popover-header:empty {
    display: none;
}

.popover-body {
    padding: 1rem 1rem;
    color: #52525e;
}

.carousel {
    position: relative;
}

.carousel.pointer-event {
    touch-action: pan-y;
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
}
.carousel-inner::after {
    display: block;
    clear: both;
    content: '';
}

.carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    backface-visibility: hidden;
    transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
    .carousel-item {
        transition: none;
    }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
    display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
    transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
    transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
    opacity: 0;
    transition-property: opacity;
    transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
    z-index: 1;
    opacity: 1;
}

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
    z-index: 0;
    opacity: 0;
    transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-start,
    .carousel-fade .active.carousel-item-end {
        transition: none;
    }
}

.carousel-control-prev,
.carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    padding: 0;
    color: #ffffff;
    text-align: center;
    background: none;
    border: 0;
    opacity: 0.5;
    transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
        transition: none;
    }
}
.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
    color: #ffffff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9;
}

.carousel-control-prev {
    left: 0;
}

.carousel-control-next {
    right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
    list-style: none;
}
.carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: 0.5;
    transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
    .carousel-indicators [data-bs-target] {
        transition: none;
    }
}
.carousel-indicators .active {
    opacity: 1;
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 1.25rem;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #ffffff;
    text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
    filter: invert(1) grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
    background-color: #000;
}

.carousel-dark .carousel-caption {
    color: #000;
}

@keyframes spinner-border {
    to {
        transform: rotate(360deg) /* rtl:ignore */;
    }
}

.spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: -0.125em;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em;
}

@keyframes spinner-grow {
    0% {
        transform: scale(0);
    }
    50% {
        opacity: 1;
        transform: none;
    }
}

.spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: -0.125em;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
    width: 1rem;
    height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
    .spinner-border,
    .spinner-grow {
        animation-duration: 1.5s;
    }
}

.offcanvas {
    position: fixed;
    bottom: 0;
    z-index: 1045;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    visibility: hidden;
    background-color: #ffffff;
    background-clip: padding-box;
    outline: 0;
    transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
    .offcanvas {
        transition: none;
    }
}

.offcanvas-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
}
.offcanvas-backdrop.fade {
    opacity: 0;
}
.offcanvas-backdrop.show {
    opacity: 0.5;
}

.offcanvas-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem;
}
.offcanvas-header .btn-close {
    padding: 0.5rem 0.5rem;
    margin-top: -0.5rem;
    margin-right: -0.5rem;
    margin-bottom: -0.5rem;
}

.offcanvas-title {
    margin-bottom: 0;
    line-height: 1.6;
}

.offcanvas-body {
    flex-grow: 1;
    padding: 1rem 1rem;
    overflow-y: auto;
}

.offcanvas-start {
    top: 0;
    left: 0;
    width: 400px;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    transform: translateX(-100%);
}

.offcanvas-end {
    top: 0;
    right: 0;
    width: 400px;
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    transform: translateX(100%);
}

.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: 30vh;
    max-height: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    transform: translateY(-100%);
}

.offcanvas-bottom {
    right: 0;
    left: 0;
    height: 30vh;
    max-height: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    transform: translateY(100%);
}

.offcanvas.show {
    transform: none;
}

.placeholder {
    display: inline-block;
    min-height: 1em;
    vertical-align: middle;
    cursor: wait;
    background-color: currentColor;
    opacity: 0.5;
}
.placeholder.btn::before {
    display: inline-block;
    content: '';
}

.placeholder-xs {
    min-height: 0.6em;
}

.placeholder-sm {
    min-height: 0.8em;
}

.placeholder-lg {
    min-height: 1.2em;
}

.placeholder-glow .placeholder {
    animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
    50% {
        opacity: 0.2;
    }
}

.placeholder-wave {
    mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
    mask-size: 200% 100%;
    animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
    100% {
        mask-position: -200% 0%;
    }
}

.clearfix::after {
    display: block;
    clear: both;
    content: '';
}

.link-primary {
    color: #02a3fe;
}
.link-primary:hover,
.link-primary:focus {
    color: #35b5fe;
}

.link-secondary {
    color: #090915;
}
.link-secondary:hover,
.link-secondary:focus {
    color: #070711;
}

.link-success {
    color: #11b011;
}
.link-success:hover,
.link-success:focus {
    color: #41c041;
}

.link-info {
    color: #00aeff;
}
.link-info:hover,
.link-info:focus {
    color: #33beff;
}

.link-warning {
    color: #ffbb69;
}
.link-warning:hover,
.link-warning:focus {
    color: #ffc987;
}

.link-danger {
    color: #eb5757;
}
.link-danger:hover,
.link-danger:focus {
    color: #ef7979;
}

.link-light {
    color: #fffbf5;
}
.link-light:hover,
.link-light:focus {
    color: #fffcf7;
}

.link-dark {
    color: #212529;
}
.link-dark:hover,
.link-dark:focus {
    color: #1a1e21;
}

.ratio {
    position: relative;
    width: 100%;
}
.ratio::before {
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: '';
}
.ratio > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.ratio-1x1 {
    --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
    --bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
    --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
    --bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}

.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
}

.sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
}

@media (min-width: 576px) {
    .sticky-sm-top {
        position: sticky;
        top: 0;
        z-index: 1020;
    }
}

@media (min-width: 768px) {
    .sticky-md-top {
        position: sticky;
        top: 0;
        z-index: 1020;
    }
}

@media (min-width: 992px) {
    .sticky-lg-top {
        position: sticky;
        top: 0;
        z-index: 1020;
    }
}

@media (min-width: 1200px) {
    .sticky-xl-top {
        position: sticky;
        top: 0;
        z-index: 1020;
    }
}

@media (min-width: 1440) {
    .sticky-xxl-top {
        position: sticky;
        top: 0;
        z-index: 1020;
    }
}

.hstack {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
}

.vstack {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
}

.stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    content: '';
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.vr {
    display: inline-block;
    align-self: stretch;
    width: 1px;
    min-height: 1em;
    background-color: currentColor;
    opacity: 0.25;
}

.align-baseline {
    vertical-align: baseline !important;
}

.align-top {
    vertical-align: top !important;
}

.align-middle {
    vertical-align: middle !important;
}

.align-bottom {
    vertical-align: bottom !important;
}

.align-text-bottom {
    vertical-align: text-bottom !important;
}

.align-text-top {
    vertical-align: text-top !important;
}

.float-start {
    float: left !important;
}

.float-end {
    float: right !important;
}

.float-none {
    float: none !important;
}

.opacity-0 {
    opacity: 0 !important;
}

.opacity-25 {
    opacity: 0.25 !important;
}

.opacity-50 {
    opacity: 0.5 !important;
}

.opacity-75 {
    opacity: 0.75 !important;
}

.opacity-100 {
    opacity: 1 !important;
}

.overflow-auto {
    overflow: auto !important;
}

.overflow-hidden {
    overflow: hidden !important;
}

.overflow-visible {
    overflow: visible !important;
}

.overflow-scroll {
    overflow: scroll !important;
}

.d-inline {
    display: inline !important;
}

.d-inline-block {
    display: inline-block !important;
}

.d-block {
    display: block !important;
}

.d-grid {
    display: grid !important;
}

.d-table {
    display: table !important;
}

.d-table-row {
    display: table-row !important;
}

.d-table-cell {
    display: table-cell !important;
}

.d-flex {
    display: flex !important;
}

.d-inline-flex {
    display: inline-flex !important;
}

.d-none {
    display: none !important;
}

.shadow {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
    box-shadow: none !important;
}

.position-static {
    position: static !important;
}

.position-relative {
    position: relative !important;
}

.position-absolute {
    position: absolute !important;
}

.position-fixed {
    position: fixed !important;
}

.position-sticky {
    position: sticky !important;
}

.top-0 {
    top: 0 !important;
}

.top-50 {
    top: 50% !important;
}

.top-100 {
    top: 100% !important;
}

.bottom-0 {
    bottom: 0 !important;
}

.bottom-50 {
    bottom: 50% !important;
}

.bottom-100 {
    bottom: 100% !important;
}

.start-0 {
    left: 0 !important;
}

.start-50 {
    left: 50% !important;
}

.start-100 {
    left: 100% !important;
}

.end-0 {
    right: 0 !important;
}

.end-50 {
    right: 50% !important;
}

.end-100 {
    right: 100% !important;
}

.translate-middle {
    transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
    transform: translateX(-50%) !important;
}

.translate-middle-y {
    transform: translateY(-50%) !important;
}

.border {
    border: 1px solid #cfdbed !important;
}

.border-0 {
    border: 0 !important;
}

.border-top {
    border-top: 1px solid #cfdbed !important;
}

.border-top-0 {
    border-top: 0 !important;
}

.border-end {
    border-right: 1px solid #cfdbed !important;
}

.border-end-0 {
    border-right: 0 !important;
}

.border-bottom {
    border-bottom: 1px solid #cfdbed !important;
}

.border-bottom-0 {
    border-bottom: 0 !important;
}

.border-start {
    border-left: 1px solid #cfdbed !important;
}

.border-start-0 {
    border-left: 0 !important;
}

.border-primary {
    border-color: #02a3fe !important;
}

.border-secondary {
    border-color: #090915 !important;
}

.border-success {
    border-color: #11b011 !important;
}

.border-info {
    border-color: #00aeff !important;
}

.border-warning {
    border-color: #ffbb69 !important;
}

.border-danger {
    border-color: #eb5757 !important;
}

.border-light {
    border-color: #fffbf5 !important;
}

.border-dark {
    border-color: #212529 !important;
}

.border-white {
    border-color: #ffffff !important;
}

.border-1 {
    border-width: 1px !important;
}

.border-2 {
    border-width: 2px !important;
}

.border-3 {
    border-width: 3px !important;
}

.border-4 {
    border-width: 4px !important;
}

.border-5 {
    border-width: 5px !important;
}

.w-25 {
    width: 25% !important;
}

.w-50 {
    width: 50% !important;
}

.w-75 {
    width: 75% !important;
}

.w-100 {
    width: 100% !important;
}

.w-auto {
    width: auto !important;
}

.mw-100 {
    max-width: 100% !important;
}

.vw-100 {
    width: 100vw !important;
}

.min-vw-100 {
    min-width: 100vw !important;
}

.h-25 {
    height: 25% !important;
}

.h-50 {
    height: 50% !important;
}

.h-75 {
    height: 75% !important;
}

.h-100 {
    height: 100% !important;
}

.h-auto {
    height: auto !important;
}

.mh-100 {
    max-height: 100% !important;
}

.vh-100 {
    height: 100vh !important;
}

.min-vh-100 {
    min-height: 100vh !important;
}

.flex-fill {
    flex: 1 1 auto !important;
}

.flex-row {
    flex-direction: row !important;
}

.flex-column {
    flex-direction: column !important;
}

.flex-row-reverse {
    flex-direction: row-reverse !important;
}

.flex-column-reverse {
    flex-direction: column-reverse !important;
}

.flex-grow-0 {
    flex-grow: 0 !important;
}

.flex-grow-1 {
    flex-grow: 1 !important;
}

.flex-shrink-0 {
    flex-shrink: 0 !important;
}

.flex-shrink-1 {
    flex-shrink: 1 !important;
}

.flex-wrap {
    flex-wrap: wrap !important;
}

.flex-nowrap {
    flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
}

.gap-0 {
    gap: 0 !important;
}

.gap-1 {
    gap: 0.25rem !important;
}

.gap-2 {
    gap: 0.5rem !important;
}

.gap-3 {
    gap: 1rem !important;
}

.gap-4 {
    gap: 1.5rem !important;
}

.gap-5 {
    gap: 3rem !important;
}

.justify-content-start {
    justify-content: flex-start !important;
}

.justify-content-end {
    justify-content: flex-end !important;
}

.justify-content-center {
    justify-content: center !important;
}

.justify-content-between {
    justify-content: space-between !important;
}

.justify-content-around {
    justify-content: space-around !important;
}

.justify-content-evenly {
    justify-content: space-evenly !important;
}

.align-items-start {
    align-items: flex-start !important;
}

.align-items-end {
    align-items: flex-end !important;
}

.align-items-center {
    align-items: center !important;
}

.align-items-baseline {
    align-items: baseline !important;
}

.align-items-stretch {
    align-items: stretch !important;
}

.align-content-start {
    align-content: flex-start !important;
}

.align-content-end {
    align-content: flex-end !important;
}

.align-content-center {
    align-content: center !important;
}

.align-content-between {
    align-content: space-between !important;
}

.align-content-around {
    align-content: space-around !important;
}

.align-content-stretch {
    align-content: stretch !important;
}

.align-self-auto {
    align-self: auto !important;
}

.align-self-start {
    align-self: flex-start !important;
}

.align-self-end {
    align-self: flex-end !important;
}

.align-self-center {
    align-self: center !important;
}

.align-self-baseline {
    align-self: baseline !important;
}

.align-self-stretch {
    align-self: stretch !important;
}

.order-first {
    order: -1 !important;
}

.order-0 {
    order: 0 !important;
}

.order-1 {
    order: 1 !important;
}

.order-2 {
    order: 2 !important;
}

.order-3 {
    order: 3 !important;
}

.order-4 {
    order: 4 !important;
}

.order-5 {
    order: 5 !important;
}

.order-last {
    order: 6 !important;
}

.m-0 {
    margin: 0 !important;
}

.m-1 {
    margin: 0.25rem !important;
}

.m-2 {
    margin: 0.5rem !important;
}

.m-3 {
    margin: 1rem !important;
}

.m-4 {
    margin: 1.5rem !important;
}

.m-5 {
    margin: 3rem !important;
}

.m-auto {
    margin: auto !important;
}

.mx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.mx-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
}

.mx-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
}

.mx-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
}

.mx-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
}

.mx-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
}

.mx-auto {
    margin-right: auto !important;
    margin-left: auto !important;
}

.my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
}

.my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
}

.my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}

.my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
}

.my-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
}

.my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mt-1 {
    margin-top: 0.25rem !important;
}

.mt-2 {
    margin-top: 0.5rem !important;
}

.mt-3 {
    margin-top: 1rem !important;
}

.mt-4 {
    margin-top: 1.5rem !important;
}

.mt-5 {
    margin-top: 3rem !important;
}

.mt-auto {
    margin-top: auto !important;
}

.me-0 {
    margin-right: 0 !important;
}

.me-1 {
    margin-right: 0.25rem !important;
}

.me-2 {
    margin-right: 0.5rem !important;
}

.me-3 {
    margin-right: 1rem !important;
}

.me-4 {
    margin-right: 1.5rem !important;
}

.me-5 {
    margin-right: 3rem !important;
}

.me-auto {
    margin-right: auto !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mb-1 {
    margin-bottom: 0.25rem !important;
}

.mb-2 {
    margin-bottom: 0.5rem !important;
}

.mb-3 {
    margin-bottom: 1rem !important;
}

.mb-4 {
    margin-bottom: 1.5rem !important;
}

.mb-5 {
    margin-bottom: 3rem !important;
}

.mb-auto {
    margin-bottom: auto !important;
}

.ms-0 {
    margin-left: 0 !important;
}

.ms-1 {
    margin-left: 0.25rem !important;
}

.ms-2 {
    margin-left: 0.5rem !important;
}

.ms-3 {
    margin-left: 1rem !important;
}

.ms-4 {
    margin-left: 1.5rem !important;
}

.ms-5 {
    margin-left: 3rem !important;
}

.ms-auto {
    margin-left: auto !important;
}

.p-0 {
    padding: 0 !important;
}

.p-1 {
    padding: 0.25rem !important;
}

.p-2 {
    padding: 0.5rem !important;
}

.p-3 {
    padding: 1rem !important;
}

.p-4 {
    padding: 1.5rem !important;
}

.p-5 {
    padding: 3rem !important;
}

.px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.px-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
}

.px-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
}

.px-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
}

.px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
}

.px-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
}

.py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
}

.py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}

.py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
}

.py-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.pt-1 {
    padding-top: 0.25rem !important;
}

.pt-2 {
    padding-top: 0.5rem !important;
}

.pt-3 {
    padding-top: 1rem !important;
}

.pt-4 {
    padding-top: 1.5rem !important;
}

.pt-5 {
    padding-top: 3rem !important;
}

.pe-0 {
    padding-right: 0 !important;
}

.pe-1 {
    padding-right: 0.25rem !important;
}

.pe-2 {
    padding-right: 0.5rem !important;
}

.pe-3 {
    padding-right: 1rem !important;
}

.pe-4 {
    padding-right: 1.5rem !important;
}

.pe-5 {
    padding-right: 3rem !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.pb-1 {
    padding-bottom: 0.25rem !important;
}

.pb-2 {
    padding-bottom: 0.5rem !important;
}

.pb-3 {
    padding-bottom: 1rem !important;
}

.pb-4 {
    padding-bottom: 1.5rem !important;
}

.pb-5 {
    padding-bottom: 3rem !important;
}

.ps-0 {
    padding-left: 0 !important;
}

.ps-1 {
    padding-left: 0.25rem !important;
}

.ps-2 {
    padding-left: 0.5rem !important;
}

.ps-3 {
    padding-left: 1rem !important;
}

.ps-4 {
    padding-left: 1.5rem !important;
}

.ps-5 {
    padding-left: 3rem !important;
}

.font-monospace {
    font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
    font-size: calc(1.425rem + 2.1vw) !important;
}

.fs-2 {
    font-size: calc(1.3625rem + 1.35vw) !important;
}

.fs-3 {
    font-size: calc(1.3125rem + 0.75vw) !important;
}

.fs-4 {
    font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
    font-size: 1.25rem !important;
}

.fs-6 {
    font-size: 1.125rem !important;
}

.fst-italic {
    font-style: italic !important;
}

.fst-normal {
    font-style: normal !important;
}

.fw-light {
    font-weight: 300 !important;
}

.fw-lighter {
    font-weight: lighter !important;
}

.fw-normal {
    font-weight: 400 !important;
}

.fw-bold {
    font-weight: 700 !important;
}

.fw-bolder {
    font-weight: bolder !important;
}

.lh-1 {
    line-height: 1 !important;
}

.lh-sm {
    line-height: 1.25 !important;
}

.lh-base {
    line-height: 1.6 !important;
}

.lh-lg {
    line-height: 2 !important;
}

.text-start {
    text-align: left !important;
}

.text-end {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

.text-decoration-none {
    text-decoration: none !important;
}

.text-decoration-underline {
    text-decoration: underline !important;
}

.text-decoration-line-through {
    text-decoration: line-through !important;
}

.text-lowercase {
    text-transform: lowercase !important;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.text-capitalize {
    text-transform: capitalize !important;
}

.text-wrap {
    white-space: normal !important;
}

.text-nowrap {
    white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
    word-wrap: break-word !important;
    word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
    --bs-text-opacity: 1;
    color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
    --bs-text-opacity: 1;
    color: rgba(151, 151, 159, 0.39) !important;
}

.text-black-50 {
    --bs-text-opacity: 1;
    color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
    --bs-text-opacity: 1;
    color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
    --bs-text-opacity: 1;
    color: inherit !important;
}

.text-opacity-25 {
    --bs-text-opacity: 0.25;
}

.text-opacity-50 {
    --bs-text-opacity: 0.5;
}

.text-opacity-75 {
    --bs-text-opacity: 0.75;
}

.text-opacity-100 {
    --bs-text-opacity: 1;
}

.bg-primary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
    --bs-bg-opacity: 1;
    background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
    --bs-bg-opacity: 1;
    background-color: transparent !important;
}

.bg-opacity-10 {
    --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
    --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
    --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
    --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
    --bs-bg-opacity: 1;
}

.bg-gradient {
    background-image: var(--bs-gradient) !important;
}

.user-select-all {
    user-select: all !important;
}

.user-select-auto {
    user-select: auto !important;
}

.user-select-none {
    user-select: none !important;
}

.pe-none {
    pointer-events: none !important;
}

.pe-auto {
    pointer-events: auto !important;
}

.rounded {
    border-radius: 0.375rem !important;
}

.rounded-0 {
    border-radius: 0 !important;
}

.rounded-1 {
    border-radius: 0.75rem !important;
}

.rounded-2 {
    border-radius: 0.375rem !important;
}

.rounded-3 {
    border-radius: 1rem !important;
}

.rounded-circle {
    border-radius: 50% !important;
}

.rounded-pill {
    border-radius: 50rem !important;
}

.rounded-top {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
}

.rounded-end {
    border-top-right-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
}

.rounded-bottom {
    border-bottom-right-radius: 0.375rem !important;
    border-bottom-left-radius: 0.375rem !important;
}

.rounded-start {
    border-bottom-left-radius: 0.375rem !important;
    border-top-left-radius: 0.375rem !important;
}

.visible {
    visibility: visible !important;
}

.invisible {
    visibility: hidden !important;
}

@media (min-width: 576px) {
    .float-sm-start {
        float: left !important;
    }
    .float-sm-end {
        float: right !important;
    }
    .float-sm-none {
        float: none !important;
    }
    .d-sm-inline {
        display: inline !important;
    }
    .d-sm-inline-block {
        display: inline-block !important;
    }
    .d-sm-block {
        display: block !important;
    }
    .d-sm-grid {
        display: grid !important;
    }
    .d-sm-table {
        display: table !important;
    }
    .d-sm-table-row {
        display: table-row !important;
    }
    .d-sm-table-cell {
        display: table-cell !important;
    }
    .d-sm-flex {
        display: flex !important;
    }
    .d-sm-inline-flex {
        display: inline-flex !important;
    }
    .d-sm-none {
        display: none !important;
    }
    .flex-sm-fill {
        flex: 1 1 auto !important;
    }
    .flex-sm-row {
        flex-direction: row !important;
    }
    .flex-sm-column {
        flex-direction: column !important;
    }
    .flex-sm-row-reverse {
        flex-direction: row-reverse !important;
    }
    .flex-sm-column-reverse {
        flex-direction: column-reverse !important;
    }
    .flex-sm-grow-0 {
        flex-grow: 0 !important;
    }
    .flex-sm-grow-1 {
        flex-grow: 1 !important;
    }
    .flex-sm-shrink-0 {
        flex-shrink: 0 !important;
    }
    .flex-sm-shrink-1 {
        flex-shrink: 1 !important;
    }
    .flex-sm-wrap {
        flex-wrap: wrap !important;
    }
    .flex-sm-nowrap {
        flex-wrap: nowrap !important;
    }
    .flex-sm-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }
    .gap-sm-0 {
        gap: 0 !important;
    }
    .gap-sm-1 {
        gap: 0.25rem !important;
    }
    .gap-sm-2 {
        gap: 0.5rem !important;
    }
    .gap-sm-3 {
        gap: 1rem !important;
    }
    .gap-sm-4 {
        gap: 1.5rem !important;
    }
    .gap-sm-5 {
        gap: 3rem !important;
    }
    .justify-content-sm-start {
        justify-content: flex-start !important;
    }
    .justify-content-sm-end {
        justify-content: flex-end !important;
    }
    .justify-content-sm-center {
        justify-content: center !important;
    }
    .justify-content-sm-between {
        justify-content: space-between !important;
    }
    .justify-content-sm-around {
        justify-content: space-around !important;
    }
    .justify-content-sm-evenly {
        justify-content: space-evenly !important;
    }
    .align-items-sm-start {
        align-items: flex-start !important;
    }
    .align-items-sm-end {
        align-items: flex-end !important;
    }
    .align-items-sm-center {
        align-items: center !important;
    }
    .align-items-sm-baseline {
        align-items: baseline !important;
    }
    .align-items-sm-stretch {
        align-items: stretch !important;
    }
    .align-content-sm-start {
        align-content: flex-start !important;
    }
    .align-content-sm-end {
        align-content: flex-end !important;
    }
    .align-content-sm-center {
        align-content: center !important;
    }
    .align-content-sm-between {
        align-content: space-between !important;
    }
    .align-content-sm-around {
        align-content: space-around !important;
    }
    .align-content-sm-stretch {
        align-content: stretch !important;
    }
    .align-self-sm-auto {
        align-self: auto !important;
    }
    .align-self-sm-start {
        align-self: flex-start !important;
    }
    .align-self-sm-end {
        align-self: flex-end !important;
    }
    .align-self-sm-center {
        align-self: center !important;
    }
    .align-self-sm-baseline {
        align-self: baseline !important;
    }
    .align-self-sm-stretch {
        align-self: stretch !important;
    }
    .order-sm-first {
        order: -1 !important;
    }
    .order-sm-0 {
        order: 0 !important;
    }
    .order-sm-1 {
        order: 1 !important;
    }
    .order-sm-2 {
        order: 2 !important;
    }
    .order-sm-3 {
        order: 3 !important;
    }
    .order-sm-4 {
        order: 4 !important;
    }
    .order-sm-5 {
        order: 5 !important;
    }
    .order-sm-last {
        order: 6 !important;
    }
    .m-sm-0 {
        margin: 0 !important;
    }
    .m-sm-1 {
        margin: 0.25rem !important;
    }
    .m-sm-2 {
        margin: 0.5rem !important;
    }
    .m-sm-3 {
        margin: 1rem !important;
    }
    .m-sm-4 {
        margin: 1.5rem !important;
    }
    .m-sm-5 {
        margin: 3rem !important;
    }
    .m-sm-auto {
        margin: auto !important;
    }
    .mx-sm-0 {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
    .mx-sm-1 {
        margin-right: 0.25rem !important;
        margin-left: 0.25rem !important;
    }
    .mx-sm-2 {
        margin-right: 0.5rem !important;
        margin-left: 0.5rem !important;
    }
    .mx-sm-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important;
    }
    .mx-sm-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
    }
    .mx-sm-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important;
    }
    .mx-sm-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
    .my-sm-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
    .my-sm-1 {
        margin-top: 0.25rem !important;
        margin-bottom: 0.25rem !important;
    }
    .my-sm-2 {
        margin-top: 0.5rem !important;
        margin-bottom: 0.5rem !important;
    }
    .my-sm-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }
    .my-sm-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important;
    }
    .my-sm-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important;
    }
    .my-sm-auto {
        margin-top: auto !important;
        margin-bottom: auto !important;
    }
    .mt-sm-0 {
        margin-top: 0 !important;
    }
    .mt-sm-1 {
        margin-top: 0.25rem !important;
    }
    .mt-sm-2 {
        margin-top: 0.5rem !important;
    }
    .mt-sm-3 {
        margin-top: 1rem !important;
    }
    .mt-sm-4 {
        margin-top: 1.5rem !important;
    }
    .mt-sm-5 {
        margin-top: 3rem !important;
    }
    .mt-sm-auto {
        margin-top: auto !important;
    }
    .me-sm-0 {
        margin-right: 0 !important;
    }
    .me-sm-1 {
        margin-right: 0.25rem !important;
    }
    .me-sm-2 {
        margin-right: 0.5rem !important;
    }
    .me-sm-3 {
        margin-right: 1rem !important;
    }
    .me-sm-4 {
        margin-right: 1.5rem !important;
    }
    .me-sm-5 {
        margin-right: 3rem !important;
    }
    .me-sm-auto {
        margin-right: auto !important;
    }
    .mb-sm-0 {
        margin-bottom: 0 !important;
    }
    .mb-sm-1 {
        margin-bottom: 0.25rem !important;
    }
    .mb-sm-2 {
        margin-bottom: 0.5rem !important;
    }
    .mb-sm-3 {
        margin-bottom: 1rem !important;
    }
    .mb-sm-4 {
        margin-bottom: 1.5rem !important;
    }
    .mb-sm-5 {
        margin-bottom: 3rem !important;
    }
    .mb-sm-auto {
        margin-bottom: auto !important;
    }
    .ms-sm-0 {
        margin-left: 0 !important;
    }
    .ms-sm-1 {
        margin-left: 0.25rem !important;
    }
    .ms-sm-2 {
        margin-left: 0.5rem !important;
    }
    .ms-sm-3 {
        margin-left: 1rem !important;
    }
    .ms-sm-4 {
        margin-left: 1.5rem !important;
    }
    .ms-sm-5 {
        margin-left: 3rem !important;
    }
    .ms-sm-auto {
        margin-left: auto !important;
    }
    .p-sm-0 {
        padding: 0 !important;
    }
    .p-sm-1 {
        padding: 0.25rem !important;
    }
    .p-sm-2 {
        padding: 0.5rem !important;
    }
    .p-sm-3 {
        padding: 1rem !important;
    }
    .p-sm-4 {
        padding: 1.5rem !important;
    }
    .p-sm-5 {
        padding: 3rem !important;
    }
    .px-sm-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
    .px-sm-1 {
        padding-right: 0.25rem !important;
        padding-left: 0.25rem !important;
    }
    .px-sm-2 {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }
    .px-sm-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }
    .px-sm-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }
    .px-sm-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important;
    }
    .py-sm-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    .py-sm-1 {
        padding-top: 0.25rem !important;
        padding-bottom: 0.25rem !important;
    }
    .py-sm-2 {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }
    .py-sm-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }
    .py-sm-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
    }
    .py-sm-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;
    }
    .pt-sm-0 {
        padding-top: 0 !important;
    }
    .pt-sm-1 {
        padding-top: 0.25rem !important;
    }
    .pt-sm-2 {
        padding-top: 0.5rem !important;
    }
    .pt-sm-3 {
        padding-top: 1rem !important;
    }
    .pt-sm-4 {
        padding-top: 1.5rem !important;
    }
    .pt-sm-5 {
        padding-top: 3rem !important;
    }
    .pe-sm-0 {
        padding-right: 0 !important;
    }
    .pe-sm-1 {
        padding-right: 0.25rem !important;
    }
    .pe-sm-2 {
        padding-right: 0.5rem !important;
    }
    .pe-sm-3 {
        padding-right: 1rem !important;
    }
    .pe-sm-4 {
        padding-right: 1.5rem !important;
    }
    .pe-sm-5 {
        padding-right: 3rem !important;
    }
    .pb-sm-0 {
        padding-bottom: 0 !important;
    }
    .pb-sm-1 {
        padding-bottom: 0.25rem !important;
    }
    .pb-sm-2 {
        padding-bottom: 0.5rem !important;
    }
    .pb-sm-3 {
        padding-bottom: 1rem !important;
    }
    .pb-sm-4 {
        padding-bottom: 1.5rem !important;
    }
    .pb-sm-5 {
        padding-bottom: 3rem !important;
    }
    .ps-sm-0 {
        padding-left: 0 !important;
    }
    .ps-sm-1 {
        padding-left: 0.25rem !important;
    }
    .ps-sm-2 {
        padding-left: 0.5rem !important;
    }
    .ps-sm-3 {
        padding-left: 1rem !important;
    }
    .ps-sm-4 {
        padding-left: 1.5rem !important;
    }
    .ps-sm-5 {
        padding-left: 3rem !important;
    }
    .text-sm-start {
        text-align: left !important;
    }
    .text-sm-end {
        text-align: right !important;
    }
    .text-sm-center {
        text-align: center !important;
    }
}

@media (min-width: 768px) {
    .float-md-start {
        float: left !important;
    }
    .float-md-end {
        float: right !important;
    }
    .float-md-none {
        float: none !important;
    }
    .d-md-inline {
        display: inline !important;
    }
    .d-md-inline-block {
        display: inline-block !important;
    }
    .d-md-block {
        display: block !important;
    }
    .d-md-grid {
        display: grid !important;
    }
    .d-md-table {
        display: table !important;
    }
    .d-md-table-row {
        display: table-row !important;
    }
    .d-md-table-cell {
        display: table-cell !important;
    }
    .d-md-flex {
        display: flex !important;
    }
    .d-md-inline-flex {
        display: inline-flex !important;
    }
    .d-md-none {
        display: none !important;
    }
    .flex-md-fill {
        flex: 1 1 auto !important;
    }
    .flex-md-row {
        flex-direction: row !important;
    }
    .flex-md-column {
        flex-direction: column !important;
    }
    .flex-md-row-reverse {
        flex-direction: row-reverse !important;
    }
    .flex-md-column-reverse {
        flex-direction: column-reverse !important;
    }
    .flex-md-grow-0 {
        flex-grow: 0 !important;
    }
    .flex-md-grow-1 {
        flex-grow: 1 !important;
    }
    .flex-md-shrink-0 {
        flex-shrink: 0 !important;
    }
    .flex-md-shrink-1 {
        flex-shrink: 1 !important;
    }
    .flex-md-wrap {
        flex-wrap: wrap !important;
    }
    .flex-md-nowrap {
        flex-wrap: nowrap !important;
    }
    .flex-md-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }
    .gap-md-0 {
        gap: 0 !important;
    }
    .gap-md-1 {
        gap: 0.25rem !important;
    }
    .gap-md-2 {
        gap: 0.5rem !important;
    }
    .gap-md-3 {
        gap: 1rem !important;
    }
    .gap-md-4 {
        gap: 1.5rem !important;
    }
    .gap-md-5 {
        gap: 3rem !important;
    }
    .justify-content-md-start {
        justify-content: flex-start !important;
    }
    .justify-content-md-end {
        justify-content: flex-end !important;
    }
    .justify-content-md-center {
        justify-content: center !important;
    }
    .justify-content-md-between {
        justify-content: space-between !important;
    }
    .justify-content-md-around {
        justify-content: space-around !important;
    }
    .justify-content-md-evenly {
        justify-content: space-evenly !important;
    }
    .align-items-md-start {
        align-items: flex-start !important;
    }
    .align-items-md-end {
        align-items: flex-end !important;
    }
    .align-items-md-center {
        align-items: center !important;
    }
    .align-items-md-baseline {
        align-items: baseline !important;
    }
    .align-items-md-stretch {
        align-items: stretch !important;
    }
    .align-content-md-start {
        align-content: flex-start !important;
    }
    .align-content-md-end {
        align-content: flex-end !important;
    }
    .align-content-md-center {
        align-content: center !important;
    }
    .align-content-md-between {
        align-content: space-between !important;
    }
    .align-content-md-around {
        align-content: space-around !important;
    }
    .align-content-md-stretch {
        align-content: stretch !important;
    }
    .align-self-md-auto {
        align-self: auto !important;
    }
    .align-self-md-start {
        align-self: flex-start !important;
    }
    .align-self-md-end {
        align-self: flex-end !important;
    }
    .align-self-md-center {
        align-self: center !important;
    }
    .align-self-md-baseline {
        align-self: baseline !important;
    }
    .align-self-md-stretch {
        align-self: stretch !important;
    }
    .order-md-first {
        order: -1 !important;
    }
    .order-md-0 {
        order: 0 !important;
    }
    .order-md-1 {
        order: 1 !important;
    }
    .order-md-2 {
        order: 2 !important;
    }
    .order-md-3 {
        order: 3 !important;
    }
    .order-md-4 {
        order: 4 !important;
    }
    .order-md-5 {
        order: 5 !important;
    }
    .order-md-last {
        order: 6 !important;
    }
    .m-md-0 {
        margin: 0 !important;
    }
    .m-md-1 {
        margin: 0.25rem !important;
    }
    .m-md-2 {
        margin: 0.5rem !important;
    }
    .m-md-3 {
        margin: 1rem !important;
    }
    .m-md-4 {
        margin: 1.5rem !important;
    }
    .m-md-5 {
        margin: 3rem !important;
    }
    .m-md-auto {
        margin: auto !important;
    }
    .mx-md-0 {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
    .mx-md-1 {
        margin-right: 0.25rem !important;
        margin-left: 0.25rem !important;
    }
    .mx-md-2 {
        margin-right: 0.5rem !important;
        margin-left: 0.5rem !important;
    }
    .mx-md-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important;
    }
    .mx-md-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
    }
    .mx-md-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important;
    }
    .mx-md-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
    .my-md-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
    .my-md-1 {
        margin-top: 0.25rem !important;
        margin-bottom: 0.25rem !important;
    }
    .my-md-2 {
        margin-top: 0.5rem !important;
        margin-bottom: 0.5rem !important;
    }
    .my-md-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }
    .my-md-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important;
    }
    .my-md-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important;
    }
    .my-md-auto {
        margin-top: auto !important;
        margin-bottom: auto !important;
    }
    .mt-md-0 {
        margin-top: 0 !important;
    }
    .mt-md-1 {
        margin-top: 0.25rem !important;
    }
    .mt-md-2 {
        margin-top: 0.5rem !important;
    }
    .mt-md-3 {
        margin-top: 1rem !important;
    }
    .mt-md-4 {
        margin-top: 1.5rem !important;
    }
    .mt-md-5 {
        margin-top: 3rem !important;
    }
    .mt-md-auto {
        margin-top: auto !important;
    }
    .me-md-0 {
        margin-right: 0 !important;
    }
    .me-md-1 {
        margin-right: 0.25rem !important;
    }
    .me-md-2 {
        margin-right: 0.5rem !important;
    }
    .me-md-3 {
        margin-right: 1rem !important;
    }
    .me-md-4 {
        margin-right: 1.5rem !important;
    }
    .me-md-5 {
        margin-right: 3rem !important;
    }
    .me-md-auto {
        margin-right: auto !important;
    }
    .mb-md-0 {
        margin-bottom: 0 !important;
    }
    .mb-md-1 {
        margin-bottom: 0.25rem !important;
    }
    .mb-md-2 {
        margin-bottom: 0.5rem !important;
    }
    .mb-md-3 {
        margin-bottom: 1rem !important;
    }
    .mb-md-4 {
        margin-bottom: 1.5rem !important;
    }
    .mb-md-5 {
        margin-bottom: 3rem !important;
    }
    .mb-md-auto {
        margin-bottom: auto !important;
    }
    .ms-md-0 {
        margin-left: 0 !important;
    }
    .ms-md-1 {
        margin-left: 0.25rem !important;
    }
    .ms-md-2 {
        margin-left: 0.5rem !important;
    }
    .ms-md-3 {
        margin-left: 1rem !important;
    }
    .ms-md-4 {
        margin-left: 1.5rem !important;
    }
    .ms-md-5 {
        margin-left: 3rem !important;
    }
    .ms-md-auto {
        margin-left: auto !important;
    }
    .p-md-0 {
        padding: 0 !important;
    }
    .p-md-1 {
        padding: 0.25rem !important;
    }
    .p-md-2 {
        padding: 0.5rem !important;
    }
    .p-md-3 {
        padding: 1rem !important;
    }
    .p-md-4 {
        padding: 1.5rem !important;
    }
    .p-md-5 {
        padding: 3rem !important;
    }
    .px-md-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
    .px-md-1 {
        padding-right: 0.25rem !important;
        padding-left: 0.25rem !important;
    }
    .px-md-2 {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }
    .px-md-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }
    .px-md-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }
    .px-md-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important;
    }
    .py-md-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    .py-md-1 {
        padding-top: 0.25rem !important;
        padding-bottom: 0.25rem !important;
    }
    .py-md-2 {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }
    .py-md-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }
    .py-md-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
    }
    .py-md-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;
    }
    .pt-md-0 {
        padding-top: 0 !important;
    }
    .pt-md-1 {
        padding-top: 0.25rem !important;
    }
    .pt-md-2 {
        padding-top: 0.5rem !important;
    }
    .pt-md-3 {
        padding-top: 1rem !important;
    }
    .pt-md-4 {
        padding-top: 1.5rem !important;
    }
    .pt-md-5 {
        padding-top: 3rem !important;
    }
    .pe-md-0 {
        padding-right: 0 !important;
    }
    .pe-md-1 {
        padding-right: 0.25rem !important;
    }
    .pe-md-2 {
        padding-right: 0.5rem !important;
    }
    .pe-md-3 {
        padding-right: 1rem !important;
    }
    .pe-md-4 {
        padding-right: 1.5rem !important;
    }
    .pe-md-5 {
        padding-right: 3rem !important;
    }
    .pb-md-0 {
        padding-bottom: 0 !important;
    }
    .pb-md-1 {
        padding-bottom: 0.25rem !important;
    }
    .pb-md-2 {
        padding-bottom: 0.5rem !important;
    }
    .pb-md-3 {
        padding-bottom: 1rem !important;
    }
    .pb-md-4 {
        padding-bottom: 1.5rem !important;
    }
    .pb-md-5 {
        padding-bottom: 3rem !important;
    }
    .ps-md-0 {
        padding-left: 0 !important;
    }
    .ps-md-1 {
        padding-left: 0.25rem !important;
    }
    .ps-md-2 {
        padding-left: 0.5rem !important;
    }
    .ps-md-3 {
        padding-left: 1rem !important;
    }
    .ps-md-4 {
        padding-left: 1.5rem !important;
    }
    .ps-md-5 {
        padding-left: 3rem !important;
    }
    .text-md-start {
        text-align: left !important;
    }
    .text-md-end {
        text-align: right !important;
    }
    .text-md-center {
        text-align: center !important;
    }
}

@media (min-width: 992px) {
    .float-lg-start {
        float: left !important;
    }
    .float-lg-end {
        float: right !important;
    }
    .float-lg-none {
        float: none !important;
    }
    .d-lg-inline {
        display: inline !important;
    }
    .d-lg-inline-block {
        display: inline-block !important;
    }
    .d-lg-block {
        display: block !important;
    }
    .d-lg-grid {
        display: grid !important;
    }
    .d-lg-table {
        display: table !important;
    }
    .d-lg-table-row {
        display: table-row !important;
    }
    .d-lg-table-cell {
        display: table-cell !important;
    }
    .d-lg-flex {
        display: flex !important;
    }
    .d-lg-inline-flex {
        display: inline-flex !important;
    }
    .d-lg-none {
        display: none !important;
    }
    .flex-lg-fill {
        flex: 1 1 auto !important;
    }
    .flex-lg-row {
        flex-direction: row !important;
    }
    .flex-lg-column {
        flex-direction: column !important;
    }
    .flex-lg-row-reverse {
        flex-direction: row-reverse !important;
    }
    .flex-lg-column-reverse {
        flex-direction: column-reverse !important;
    }
    .flex-lg-grow-0 {
        flex-grow: 0 !important;
    }
    .flex-lg-grow-1 {
        flex-grow: 1 !important;
    }
    .flex-lg-shrink-0 {
        flex-shrink: 0 !important;
    }
    .flex-lg-shrink-1 {
        flex-shrink: 1 !important;
    }
    .flex-lg-wrap {
        flex-wrap: wrap !important;
    }
    .flex-lg-nowrap {
        flex-wrap: nowrap !important;
    }
    .flex-lg-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }
    .gap-lg-0 {
        gap: 0 !important;
    }
    .gap-lg-1 {
        gap: 0.25rem !important;
    }
    .gap-lg-2 {
        gap: 0.5rem !important;
    }
    .gap-lg-3 {
        gap: 1rem !important;
    }
    .gap-lg-4 {
        gap: 1.5rem !important;
    }
    .gap-lg-5 {
        gap: 3rem !important;
    }
    .justify-content-lg-start {
        justify-content: flex-start !important;
    }
    .justify-content-lg-end {
        justify-content: flex-end !important;
    }
    .justify-content-lg-center {
        justify-content: center !important;
    }
    .justify-content-lg-between {
        justify-content: space-between !important;
    }
    .justify-content-lg-around {
        justify-content: space-around !important;
    }
    .justify-content-lg-evenly {
        justify-content: space-evenly !important;
    }
    .align-items-lg-start {
        align-items: flex-start !important;
    }
    .align-items-lg-end {
        align-items: flex-end !important;
    }
    .align-items-lg-center {
        align-items: center !important;
    }
    .align-items-lg-baseline {
        align-items: baseline !important;
    }
    .align-items-lg-stretch {
        align-items: stretch !important;
    }
    .align-content-lg-start {
        align-content: flex-start !important;
    }
    .align-content-lg-end {
        align-content: flex-end !important;
    }
    .align-content-lg-center {
        align-content: center !important;
    }
    .align-content-lg-between {
        align-content: space-between !important;
    }
    .align-content-lg-around {
        align-content: space-around !important;
    }
    .align-content-lg-stretch {
        align-content: stretch !important;
    }
    .align-self-lg-auto {
        align-self: auto !important;
    }
    .align-self-lg-start {
        align-self: flex-start !important;
    }
    .align-self-lg-end {
        align-self: flex-end !important;
    }
    .align-self-lg-center {
        align-self: center !important;
    }
    .align-self-lg-baseline {
        align-self: baseline !important;
    }
    .align-self-lg-stretch {
        align-self: stretch !important;
    }
    .order-lg-first {
        order: -1 !important;
    }
    .order-lg-0 {
        order: 0 !important;
    }
    .order-lg-1 {
        order: 1 !important;
    }
    .order-lg-2 {
        order: 2 !important;
    }
    .order-lg-3 {
        order: 3 !important;
    }
    .order-lg-4 {
        order: 4 !important;
    }
    .order-lg-5 {
        order: 5 !important;
    }
    .order-lg-last {
        order: 6 !important;
    }
    .m-lg-0 {
        margin: 0 !important;
    }
    .m-lg-1 {
        margin: 0.25rem !important;
    }
    .m-lg-2 {
        margin: 0.5rem !important;
    }
    .m-lg-3 {
        margin: 1rem !important;
    }
    .m-lg-4 {
        margin: 1.5rem !important;
    }
    .m-lg-5 {
        margin: 3rem !important;
    }
    .m-lg-auto {
        margin: auto !important;
    }
    .mx-lg-0 {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
    .mx-lg-1 {
        margin-right: 0.25rem !important;
        margin-left: 0.25rem !important;
    }
    .mx-lg-2 {
        margin-right: 0.5rem !important;
        margin-left: 0.5rem !important;
    }
    .mx-lg-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important;
    }
    .mx-lg-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
    }
    .mx-lg-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important;
    }
    .mx-lg-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
    .my-lg-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
    .my-lg-1 {
        margin-top: 0.25rem !important;
        margin-bottom: 0.25rem !important;
    }
    .my-lg-2 {
        margin-top: 0.5rem !important;
        margin-bottom: 0.5rem !important;
    }
    .my-lg-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }
    .my-lg-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important;
    }
    .my-lg-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important;
    }
    .my-lg-auto {
        margin-top: auto !important;
        margin-bottom: auto !important;
    }
    .mt-lg-0 {
        margin-top: 0 !important;
    }
    .mt-lg-1 {
        margin-top: 0.25rem !important;
    }
    .mt-lg-2 {
        margin-top: 0.5rem !important;
    }
    .mt-lg-3 {
        margin-top: 1rem !important;
    }
    .mt-lg-4 {
        margin-top: 1.5rem !important;
    }
    .mt-lg-5 {
        margin-top: 3rem !important;
    }
    .mt-lg-auto {
        margin-top: auto !important;
    }
    .me-lg-0 {
        margin-right: 0 !important;
    }
    .me-lg-1 {
        margin-right: 0.25rem !important;
    }
    .me-lg-2 {
        margin-right: 0.5rem !important;
    }
    .me-lg-3 {
        margin-right: 1rem !important;
    }
    .me-lg-4 {
        margin-right: 1.5rem !important;
    }
    .me-lg-5 {
        margin-right: 3rem !important;
    }
    .me-lg-auto {
        margin-right: auto !important;
    }
    .mb-lg-0 {
        margin-bottom: 0 !important;
    }
    .mb-lg-1 {
        margin-bottom: 0.25rem !important;
    }
    .mb-lg-2 {
        margin-bottom: 0.5rem !important;
    }
    .mb-lg-3 {
        margin-bottom: 1rem !important;
    }
    .mb-lg-4 {
        margin-bottom: 1.5rem !important;
    }
    .mb-lg-5 {
        margin-bottom: 3rem !important;
    }
    .mb-lg-auto {
        margin-bottom: auto !important;
    }
    .ms-lg-0 {
        margin-left: 0 !important;
    }
    .ms-lg-1 {
        margin-left: 0.25rem !important;
    }
    .ms-lg-2 {
        margin-left: 0.5rem !important;
    }
    .ms-lg-3 {
        margin-left: 1rem !important;
    }
    .ms-lg-4 {
        margin-left: 1.5rem !important;
    }
    .ms-lg-5 {
        margin-left: 3rem !important;
    }
    .ms-lg-auto {
        margin-left: auto !important;
    }
    .p-lg-0 {
        padding: 0 !important;
    }
    .p-lg-1 {
        padding: 0.25rem !important;
    }
    .p-lg-2 {
        padding: 0.5rem !important;
    }
    .p-lg-3 {
        padding: 1rem !important;
    }
    .p-lg-4 {
        padding: 1.5rem !important;
    }
    .p-lg-5 {
        padding: 3rem !important;
    }
    .px-lg-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
    .px-lg-1 {
        padding-right: 0.25rem !important;
        padding-left: 0.25rem !important;
    }
    .px-lg-2 {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }
    .px-lg-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }
    .px-lg-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }
    .px-lg-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important;
    }
    .py-lg-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    .py-lg-1 {
        padding-top: 0.25rem !important;
        padding-bottom: 0.25rem !important;
    }
    .py-lg-2 {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }
    .py-lg-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }
    .py-lg-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
    }
    .py-lg-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;
    }
    .pt-lg-0 {
        padding-top: 0 !important;
    }
    .pt-lg-1 {
        padding-top: 0.25rem !important;
    }
    .pt-lg-2 {
        padding-top: 0.5rem !important;
    }
    .pt-lg-3 {
        padding-top: 1rem !important;
    }
    .pt-lg-4 {
        padding-top: 1.5rem !important;
    }
    .pt-lg-5 {
        padding-top: 3rem !important;
    }
    .pe-lg-0 {
        padding-right: 0 !important;
    }
    .pe-lg-1 {
        padding-right: 0.25rem !important;
    }
    .pe-lg-2 {
        padding-right: 0.5rem !important;
    }
    .pe-lg-3 {
        padding-right: 1rem !important;
    }
    .pe-lg-4 {
        padding-right: 1.5rem !important;
    }
    .pe-lg-5 {
        padding-right: 3rem !important;
    }
    .pb-lg-0 {
        padding-bottom: 0 !important;
    }
    .pb-lg-1 {
        padding-bottom: 0.25rem !important;
    }
    .pb-lg-2 {
        padding-bottom: 0.5rem !important;
    }
    .pb-lg-3 {
        padding-bottom: 1rem !important;
    }
    .pb-lg-4 {
        padding-bottom: 1.5rem !important;
    }
    .pb-lg-5 {
        padding-bottom: 3rem !important;
    }
    .ps-lg-0 {
        padding-left: 0 !important;
    }
    .ps-lg-1 {
        padding-left: 0.25rem !important;
    }
    .ps-lg-2 {
        padding-left: 0.5rem !important;
    }
    .ps-lg-3 {
        padding-left: 1rem !important;
    }
    .ps-lg-4 {
        padding-left: 1.5rem !important;
    }
    .ps-lg-5 {
        padding-left: 3rem !important;
    }
    .text-lg-start {
        text-align: left !important;
    }
    .text-lg-end {
        text-align: right !important;
    }
    .text-lg-center {
        text-align: center !important;
    }
}

@media (min-width: 1200px) {
    .float-xl-start {
        float: left !important;
    }
    .float-xl-end {
        float: right !important;
    }
    .float-xl-none {
        float: none !important;
    }
    .d-xl-inline {
        display: inline !important;
    }
    .d-xl-inline-block {
        display: inline-block !important;
    }
    .d-xl-block {
        display: block !important;
    }
    .d-xl-grid {
        display: grid !important;
    }
    .d-xl-table {
        display: table !important;
    }
    .d-xl-table-row {
        display: table-row !important;
    }
    .d-xl-table-cell {
        display: table-cell !important;
    }
    .d-xl-flex {
        display: flex !important;
    }
    .d-xl-inline-flex {
        display: inline-flex !important;
    }
    .d-xl-none {
        display: none !important;
    }
    .flex-xl-fill {
        flex: 1 1 auto !important;
    }
    .flex-xl-row {
        flex-direction: row !important;
    }
    .flex-xl-column {
        flex-direction: column !important;
    }
    .flex-xl-row-reverse {
        flex-direction: row-reverse !important;
    }
    .flex-xl-column-reverse {
        flex-direction: column-reverse !important;
    }
    .flex-xl-grow-0 {
        flex-grow: 0 !important;
    }
    .flex-xl-grow-1 {
        flex-grow: 1 !important;
    }
    .flex-xl-shrink-0 {
        flex-shrink: 0 !important;
    }
    .flex-xl-shrink-1 {
        flex-shrink: 1 !important;
    }
    .flex-xl-wrap {
        flex-wrap: wrap !important;
    }
    .flex-xl-nowrap {
        flex-wrap: nowrap !important;
    }
    .flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }
    .gap-xl-0 {
        gap: 0 !important;
    }
    .gap-xl-1 {
        gap: 0.25rem !important;
    }
    .gap-xl-2 {
        gap: 0.5rem !important;
    }
    .gap-xl-3 {
        gap: 1rem !important;
    }
    .gap-xl-4 {
        gap: 1.5rem !important;
    }
    .gap-xl-5 {
        gap: 3rem !important;
    }
    .justify-content-xl-start {
        justify-content: flex-start !important;
    }
    .justify-content-xl-end {
        justify-content: flex-end !important;
    }
    .justify-content-xl-center {
        justify-content: center !important;
    }
    .justify-content-xl-between {
        justify-content: space-between !important;
    }
    .justify-content-xl-around {
        justify-content: space-around !important;
    }
    .justify-content-xl-evenly {
        justify-content: space-evenly !important;
    }
    .align-items-xl-start {
        align-items: flex-start !important;
    }
    .align-items-xl-end {
        align-items: flex-end !important;
    }
    .align-items-xl-center {
        align-items: center !important;
    }
    .align-items-xl-baseline {
        align-items: baseline !important;
    }
    .align-items-xl-stretch {
        align-items: stretch !important;
    }
    .align-content-xl-start {
        align-content: flex-start !important;
    }
    .align-content-xl-end {
        align-content: flex-end !important;
    }
    .align-content-xl-center {
        align-content: center !important;
    }
    .align-content-xl-between {
        align-content: space-between !important;
    }
    .align-content-xl-around {
        align-content: space-around !important;
    }
    .align-content-xl-stretch {
        align-content: stretch !important;
    }
    .align-self-xl-auto {
        align-self: auto !important;
    }
    .align-self-xl-start {
        align-self: flex-start !important;
    }
    .align-self-xl-end {
        align-self: flex-end !important;
    }
    .align-self-xl-center {
        align-self: center !important;
    }
    .align-self-xl-baseline {
        align-self: baseline !important;
    }
    .align-self-xl-stretch {
        align-self: stretch !important;
    }
    .order-xl-first {
        order: -1 !important;
    }
    .order-xl-0 {
        order: 0 !important;
    }
    .order-xl-1 {
        order: 1 !important;
    }
    .order-xl-2 {
        order: 2 !important;
    }
    .order-xl-3 {
        order: 3 !important;
    }
    .order-xl-4 {
        order: 4 !important;
    }
    .order-xl-5 {
        order: 5 !important;
    }
    .order-xl-last {
        order: 6 !important;
    }
    .m-xl-0 {
        margin: 0 !important;
    }
    .m-xl-1 {
        margin: 0.25rem !important;
    }
    .m-xl-2 {
        margin: 0.5rem !important;
    }
    .m-xl-3 {
        margin: 1rem !important;
    }
    .m-xl-4 {
        margin: 1.5rem !important;
    }
    .m-xl-5 {
        margin: 3rem !important;
    }
    .m-xl-auto {
        margin: auto !important;
    }
    .mx-xl-0 {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
    .mx-xl-1 {
        margin-right: 0.25rem !important;
        margin-left: 0.25rem !important;
    }
    .mx-xl-2 {
        margin-right: 0.5rem !important;
        margin-left: 0.5rem !important;
    }
    .mx-xl-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important;
    }
    .mx-xl-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
    }
    .mx-xl-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important;
    }
    .mx-xl-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
    .my-xl-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
    .my-xl-1 {
        margin-top: 0.25rem !important;
        margin-bottom: 0.25rem !important;
    }
    .my-xl-2 {
        margin-top: 0.5rem !important;
        margin-bottom: 0.5rem !important;
    }
    .my-xl-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }
    .my-xl-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important;
    }
    .my-xl-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important;
    }
    .my-xl-auto {
        margin-top: auto !important;
        margin-bottom: auto !important;
    }
    .mt-xl-0 {
        margin-top: 0 !important;
    }
    .mt-xl-1 {
        margin-top: 0.25rem !important;
    }
    .mt-xl-2 {
        margin-top: 0.5rem !important;
    }
    .mt-xl-3 {
        margin-top: 1rem !important;
    }
    .mt-xl-4 {
        margin-top: 1.5rem !important;
    }
    .mt-xl-5 {
        margin-top: 3rem !important;
    }
    .mt-xl-auto {
        margin-top: auto !important;
    }
    .me-xl-0 {
        margin-right: 0 !important;
    }
    .me-xl-1 {
        margin-right: 0.25rem !important;
    }
    .me-xl-2 {
        margin-right: 0.5rem !important;
    }
    .me-xl-3 {
        margin-right: 1rem !important;
    }
    .me-xl-4 {
        margin-right: 1.5rem !important;
    }
    .me-xl-5 {
        margin-right: 3rem !important;
    }
    .me-xl-auto {
        margin-right: auto !important;
    }
    .mb-xl-0 {
        margin-bottom: 0 !important;
    }
    .mb-xl-1 {
        margin-bottom: 0.25rem !important;
    }
    .mb-xl-2 {
        margin-bottom: 0.5rem !important;
    }
    .mb-xl-3 {
        margin-bottom: 1rem !important;
    }
    .mb-xl-4 {
        margin-bottom: 1.5rem !important;
    }
    .mb-xl-5 {
        margin-bottom: 3rem !important;
    }
    .mb-xl-auto {
        margin-bottom: auto !important;
    }
    .ms-xl-0 {
        margin-left: 0 !important;
    }
    .ms-xl-1 {
        margin-left: 0.25rem !important;
    }
    .ms-xl-2 {
        margin-left: 0.5rem !important;
    }
    .ms-xl-3 {
        margin-left: 1rem !important;
    }
    .ms-xl-4 {
        margin-left: 1.5rem !important;
    }
    .ms-xl-5 {
        margin-left: 3rem !important;
    }
    .ms-xl-auto {
        margin-left: auto !important;
    }
    .p-xl-0 {
        padding: 0 !important;
    }
    .p-xl-1 {
        padding: 0.25rem !important;
    }
    .p-xl-2 {
        padding: 0.5rem !important;
    }
    .p-xl-3 {
        padding: 1rem !important;
    }
    .p-xl-4 {
        padding: 1.5rem !important;
    }
    .p-xl-5 {
        padding: 3rem !important;
    }
    .px-xl-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
    .px-xl-1 {
        padding-right: 0.25rem !important;
        padding-left: 0.25rem !important;
    }
    .px-xl-2 {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }
    .px-xl-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }
    .px-xl-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }
    .px-xl-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important;
    }
    .py-xl-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    .py-xl-1 {
        padding-top: 0.25rem !important;
        padding-bottom: 0.25rem !important;
    }
    .py-xl-2 {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }
    .py-xl-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }
    .py-xl-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
    }
    .py-xl-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;
    }
    .pt-xl-0 {
        padding-top: 0 !important;
    }
    .pt-xl-1 {
        padding-top: 0.25rem !important;
    }
    .pt-xl-2 {
        padding-top: 0.5rem !important;
    }
    .pt-xl-3 {
        padding-top: 1rem !important;
    }
    .pt-xl-4 {
        padding-top: 1.5rem !important;
    }
    .pt-xl-5 {
        padding-top: 3rem !important;
    }
    .pe-xl-0 {
        padding-right: 0 !important;
    }
    .pe-xl-1 {
        padding-right: 0.25rem !important;
    }
    .pe-xl-2 {
        padding-right: 0.5rem !important;
    }
    .pe-xl-3 {
        padding-right: 1rem !important;
    }
    .pe-xl-4 {
        padding-right: 1.5rem !important;
    }
    .pe-xl-5 {
        padding-right: 3rem !important;
    }
    .pb-xl-0 {
        padding-bottom: 0 !important;
    }
    .pb-xl-1 {
        padding-bottom: 0.25rem !important;
    }
    .pb-xl-2 {
        padding-bottom: 0.5rem !important;
    }
    .pb-xl-3 {
        padding-bottom: 1rem !important;
    }
    .pb-xl-4 {
        padding-bottom: 1.5rem !important;
    }
    .pb-xl-5 {
        padding-bottom: 3rem !important;
    }
    .ps-xl-0 {
        padding-left: 0 !important;
    }
    .ps-xl-1 {
        padding-left: 0.25rem !important;
    }
    .ps-xl-2 {
        padding-left: 0.5rem !important;
    }
    .ps-xl-3 {
        padding-left: 1rem !important;
    }
    .ps-xl-4 {
        padding-left: 1.5rem !important;
    }
    .ps-xl-5 {
        padding-left: 3rem !important;
    }
    .text-xl-start {
        text-align: left !important;
    }
    .text-xl-end {
        text-align: right !important;
    }
    .text-xl-center {
        text-align: center !important;
    }
}

@media (min-width: 1440) {
    .float-xxl-start {
        float: left !important;
    }
    .float-xxl-end {
        float: right !important;
    }
    .float-xxl-none {
        float: none !important;
    }
    .d-xxl-inline {
        display: inline !important;
    }
    .d-xxl-inline-block {
        display: inline-block !important;
    }
    .d-xxl-block {
        display: block !important;
    }
    .d-xxl-grid {
        display: grid !important;
    }
    .d-xxl-table {
        display: table !important;
    }
    .d-xxl-table-row {
        display: table-row !important;
    }
    .d-xxl-table-cell {
        display: table-cell !important;
    }
    .d-xxl-flex {
        display: flex !important;
    }
    .d-xxl-inline-flex {
        display: inline-flex !important;
    }
    .d-xxl-none {
        display: none !important;
    }
    .flex-xxl-fill {
        flex: 1 1 auto !important;
    }
    .flex-xxl-row {
        flex-direction: row !important;
    }
    .flex-xxl-column {
        flex-direction: column !important;
    }
    .flex-xxl-row-reverse {
        flex-direction: row-reverse !important;
    }
    .flex-xxl-column-reverse {
        flex-direction: column-reverse !important;
    }
    .flex-xxl-grow-0 {
        flex-grow: 0 !important;
    }
    .flex-xxl-grow-1 {
        flex-grow: 1 !important;
    }
    .flex-xxl-shrink-0 {
        flex-shrink: 0 !important;
    }
    .flex-xxl-shrink-1 {
        flex-shrink: 1 !important;
    }
    .flex-xxl-wrap {
        flex-wrap: wrap !important;
    }
    .flex-xxl-nowrap {
        flex-wrap: nowrap !important;
    }
    .flex-xxl-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }
    .gap-xxl-0 {
        gap: 0 !important;
    }
    .gap-xxl-1 {
        gap: 0.25rem !important;
    }
    .gap-xxl-2 {
        gap: 0.5rem !important;
    }
    .gap-xxl-3 {
        gap: 1rem !important;
    }
    .gap-xxl-4 {
        gap: 1.5rem !important;
    }
    .gap-xxl-5 {
        gap: 3rem !important;
    }
    .justify-content-xxl-start {
        justify-content: flex-start !important;
    }
    .justify-content-xxl-end {
        justify-content: flex-end !important;
    }
    .justify-content-xxl-center {
        justify-content: center !important;
    }
    .justify-content-xxl-between {
        justify-content: space-between !important;
    }
    .justify-content-xxl-around {
        justify-content: space-around !important;
    }
    .justify-content-xxl-evenly {
        justify-content: space-evenly !important;
    }
    .align-items-xxl-start {
        align-items: flex-start !important;
    }
    .align-items-xxl-end {
        align-items: flex-end !important;
    }
    .align-items-xxl-center {
        align-items: center !important;
    }
    .align-items-xxl-baseline {
        align-items: baseline !important;
    }
    .align-items-xxl-stretch {
        align-items: stretch !important;
    }
    .align-content-xxl-start {
        align-content: flex-start !important;
    }
    .align-content-xxl-end {
        align-content: flex-end !important;
    }
    .align-content-xxl-center {
        align-content: center !important;
    }
    .align-content-xxl-between {
        align-content: space-between !important;
    }
    .align-content-xxl-around {
        align-content: space-around !important;
    }
    .align-content-xxl-stretch {
        align-content: stretch !important;
    }
    .align-self-xxl-auto {
        align-self: auto !important;
    }
    .align-self-xxl-start {
        align-self: flex-start !important;
    }
    .align-self-xxl-end {
        align-self: flex-end !important;
    }
    .align-self-xxl-center {
        align-self: center !important;
    }
    .align-self-xxl-baseline {
        align-self: baseline !important;
    }
    .align-self-xxl-stretch {
        align-self: stretch !important;
    }
    .order-xxl-first {
        order: -1 !important;
    }
    .order-xxl-0 {
        order: 0 !important;
    }
    .order-xxl-1 {
        order: 1 !important;
    }
    .order-xxl-2 {
        order: 2 !important;
    }
    .order-xxl-3 {
        order: 3 !important;
    }
    .order-xxl-4 {
        order: 4 !important;
    }
    .order-xxl-5 {
        order: 5 !important;
    }
    .order-xxl-last {
        order: 6 !important;
    }
    .m-xxl-0 {
        margin: 0 !important;
    }
    .m-xxl-1 {
        margin: 0.25rem !important;
    }
    .m-xxl-2 {
        margin: 0.5rem !important;
    }
    .m-xxl-3 {
        margin: 1rem !important;
    }
    .m-xxl-4 {
        margin: 1.5rem !important;
    }
    .m-xxl-5 {
        margin: 3rem !important;
    }
    .m-xxl-auto {
        margin: auto !important;
    }
    .mx-xxl-0 {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
    .mx-xxl-1 {
        margin-right: 0.25rem !important;
        margin-left: 0.25rem !important;
    }
    .mx-xxl-2 {
        margin-right: 0.5rem !important;
        margin-left: 0.5rem !important;
    }
    .mx-xxl-3 {
        margin-right: 1rem !important;
        margin-left: 1rem !important;
    }
    .mx-xxl-4 {
        margin-right: 1.5rem !important;
        margin-left: 1.5rem !important;
    }
    .mx-xxl-5 {
        margin-right: 3rem !important;
        margin-left: 3rem !important;
    }
    .mx-xxl-auto {
        margin-right: auto !important;
        margin-left: auto !important;
    }
    .my-xxl-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
    .my-xxl-1 {
        margin-top: 0.25rem !important;
        margin-bottom: 0.25rem !important;
    }
    .my-xxl-2 {
        margin-top: 0.5rem !important;
        margin-bottom: 0.5rem !important;
    }
    .my-xxl-3 {
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }
    .my-xxl-4 {
        margin-top: 1.5rem !important;
        margin-bottom: 1.5rem !important;
    }
    .my-xxl-5 {
        margin-top: 3rem !important;
        margin-bottom: 3rem !important;
    }
    .my-xxl-auto {
        margin-top: auto !important;
        margin-bottom: auto !important;
    }
    .mt-xxl-0 {
        margin-top: 0 !important;
    }
    .mt-xxl-1 {
        margin-top: 0.25rem !important;
    }
    .mt-xxl-2 {
        margin-top: 0.5rem !important;
    }
    .mt-xxl-3 {
        margin-top: 1rem !important;
    }
    .mt-xxl-4 {
        margin-top: 1.5rem !important;
    }
    .mt-xxl-5 {
        margin-top: 3rem !important;
    }
    .mt-xxl-auto {
        margin-top: auto !important;
    }
    .me-xxl-0 {
        margin-right: 0 !important;
    }
    .me-xxl-1 {
        margin-right: 0.25rem !important;
    }
    .me-xxl-2 {
        margin-right: 0.5rem !important;
    }
    .me-xxl-3 {
        margin-right: 1rem !important;
    }
    .me-xxl-4 {
        margin-right: 1.5rem !important;
    }
    .me-xxl-5 {
        margin-right: 3rem !important;
    }
    .me-xxl-auto {
        margin-right: auto !important;
    }
    .mb-xxl-0 {
        margin-bottom: 0 !important;
    }
    .mb-xxl-1 {
        margin-bottom: 0.25rem !important;
    }
    .mb-xxl-2 {
        margin-bottom: 0.5rem !important;
    }
    .mb-xxl-3 {
        margin-bottom: 1rem !important;
    }
    .mb-xxl-4 {
        margin-bottom: 1.5rem !important;
    }
    .mb-xxl-5 {
        margin-bottom: 3rem !important;
    }
    .mb-xxl-auto {
        margin-bottom: auto !important;
    }
    .ms-xxl-0 {
        margin-left: 0 !important;
    }
    .ms-xxl-1 {
        margin-left: 0.25rem !important;
    }
    .ms-xxl-2 {
        margin-left: 0.5rem !important;
    }
    .ms-xxl-3 {
        margin-left: 1rem !important;
    }
    .ms-xxl-4 {
        margin-left: 1.5rem !important;
    }
    .ms-xxl-5 {
        margin-left: 3rem !important;
    }
    .ms-xxl-auto {
        margin-left: auto !important;
    }
    .p-xxl-0 {
        padding: 0 !important;
    }
    .p-xxl-1 {
        padding: 0.25rem !important;
    }
    .p-xxl-2 {
        padding: 0.5rem !important;
    }
    .p-xxl-3 {
        padding: 1rem !important;
    }
    .p-xxl-4 {
        padding: 1.5rem !important;
    }
    .p-xxl-5 {
        padding: 3rem !important;
    }
    .px-xxl-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
    .px-xxl-1 {
        padding-right: 0.25rem !important;
        padding-left: 0.25rem !important;
    }
    .px-xxl-2 {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }
    .px-xxl-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important;
    }
    .px-xxl-4 {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
    }
    .px-xxl-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important;
    }
    .py-xxl-0 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    .py-xxl-1 {
        padding-top: 0.25rem !important;
        padding-bottom: 0.25rem !important;
    }
    .py-xxl-2 {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }
    .py-xxl-3 {
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }
    .py-xxl-4 {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
    }
    .py-xxl-5 {
        padding-top: 3rem !important;
        padding-bottom: 3rem !important;
    }
    .pt-xxl-0 {
        padding-top: 0 !important;
    }
    .pt-xxl-1 {
        padding-top: 0.25rem !important;
    }
    .pt-xxl-2 {
        padding-top: 0.5rem !important;
    }
    .pt-xxl-3 {
        padding-top: 1rem !important;
    }
    .pt-xxl-4 {
        padding-top: 1.5rem !important;
    }
    .pt-xxl-5 {
        padding-top: 3rem !important;
    }
    .pe-xxl-0 {
        padding-right: 0 !important;
    }
    .pe-xxl-1 {
        padding-right: 0.25rem !important;
    }
    .pe-xxl-2 {
        padding-right: 0.5rem !important;
    }
    .pe-xxl-3 {
        padding-right: 1rem !important;
    }
    .pe-xxl-4 {
        padding-right: 1.5rem !important;
    }
    .pe-xxl-5 {
        padding-right: 3rem !important;
    }
    .pb-xxl-0 {
        padding-bottom: 0 !important;
    }
    .pb-xxl-1 {
        padding-bottom: 0.25rem !important;
    }
    .pb-xxl-2 {
        padding-bottom: 0.5rem !important;
    }
    .pb-xxl-3 {
        padding-bottom: 1rem !important;
    }
    .pb-xxl-4 {
        padding-bottom: 1.5rem !important;
    }
    .pb-xxl-5 {
        padding-bottom: 3rem !important;
    }
    .ps-xxl-0 {
        padding-left: 0 !important;
    }
    .ps-xxl-1 {
        padding-left: 0.25rem !important;
    }
    .ps-xxl-2 {
        padding-left: 0.5rem !important;
    }
    .ps-xxl-3 {
        padding-left: 1rem !important;
    }
    .ps-xxl-4 {
        padding-left: 1.5rem !important;
    }
    .ps-xxl-5 {
        padding-left: 3rem !important;
    }
    .text-xxl-start {
        text-align: left !important;
    }
    .text-xxl-end {
        text-align: right !important;
    }
    .text-xxl-center {
        text-align: center !important;
    }
}

@media (min-width: 1200px) {
    .fs-1 {
        font-size: 3rem !important;
    }
    .fs-2 {
        font-size: 2.375rem !important;
    }
    .fs-3 {
        font-size: 1.875rem !important;
    }
    .fs-4 {
        font-size: 1.5rem !important;
    }
}

@media print {
    .d-print-inline {
        display: inline !important;
    }
    .d-print-inline-block {
        display: inline-block !important;
    }
    .d-print-block {
        display: block !important;
    }
    .d-print-grid {
        display: grid !important;
    }
    .d-print-table {
        display: table !important;
    }
    .d-print-table-row {
        display: table-row !important;
    }
    .d-print-table-cell {
        display: table-cell !important;
    }
    .d-print-flex {
        display: flex !important;
    }
    .d-print-inline-flex {
        display: inline-flex !important;
    }
    .d-print-none {
        display: none !important;
    }
}

* {
    outline: none;
    padding: 0;
}
*::after {
    margin: 0;
    padding: 0;
}
*::before {
    margin: 0;
    padding: 0;
}

body {
    line-height: 1.6;
    background-color: var(--body-bg);
    color: var(--body-color);
    font-size: 1rem;
    font-family: var(--font-family-base);
}
body.fixed {
    overflow: hidden;
    position: fixed;
}
@media only screen and (max-width: 1280px) {
    body {
        font-size: 14px;
    }
}

h1 a,
.h1 a,
h2 a,
.h2 a,
h3 a,
.h3 a,
h4 a,
.h4 a,
.comment-reply-title a,
.wp-block-search .wp-block-search__label a,
h5 a,
.h5 a,
h6 a,
.h6 a {
    color: var(--title);
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
.comment-reply-title,
.wp-block-search .wp-block-search__label,
h5,
.h5,
h6,
.h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: var(--font-family-title);
    font-weight: 600;
    color: var(--title);
    line-height: 1.4;
}

h1,
.h1,
.h1 {
    font-size: 3rem;
}
@media only screen and (max-width: 1680px) {
    h1,
    .h1,
    .h1 {
        font-size: 3rem;
    }
}
@media only screen and (max-width: 1280px) {
    h1,
    .h1,
    .h1 {
        font-size: 2.813rem;
    }
}
@media only screen and (max-width: 767px) {
    h1,
    .h1,
    .h1 {
        font-size: 2.625rem;
    }
}
@media only screen and (max-width: 575px) {
    h1,
    .h1,
    .h1 {
        font-size: 2.25rem;
    }
}

h2,
.h2,
.h2 {
    font-size: 2.375rem;
}
@media only screen and (max-width: 1680px) {
    h2,
    .h2,
    .h2 {
        font-size: 2.625rem;
    }
}
@media only screen and (max-width: 1280px) {
    h2,
    .h2,
    .h2 {
        font-size: 2.375rem;
    }
}
@media only screen and (max-width: 767px) {
    h2,
    .h2,
    .h2 {
        font-size: 2rem;
    }
}
@media only screen and (max-width: 575px) {
    h2,
    .h2,
    .h2 {
        font-size: 1.65rem;
    }
}

h3,
.h3,
.h3 {
    font-size: 1.875rem;
}
@media only screen and (max-width: 1680px) {
    h3,
    .h3,
    .h3 {
        font-size: 2rem;
    }
}
@media only screen and (max-width: 1280px) {
    h3,
    .h3,
    .h3 {
        font-size: 1.875rem;
    }
}
@media only screen and (max-width: 767px) {
    h3,
    .h3,
    .h3 {
        font-size: 1.625rem;
    }
}
@media only screen and (max-width: 575px) {
    h3,
    .h3,
    .h3 {
        font-size: 1.225rem;
    }
}

h4,
.h4,
.comment-reply-title,
.wp-block-search .wp-block-search__label,
.h4 {
    font-size: 1.5rem;
}
@media only screen and (max-width: 767px) {
    h4,
    .h4,
    .comment-reply-title,
    .wp-block-search .wp-block-search__label,
    .h4 {
        font-size: 1.25rem;
    }
}

h5,
.h5,
.h5 {
    font-size: 1.25rem;
}
@media only screen and (max-width: 767px) {
    h5,
    .h5,
    .h5 {
        font-size: 1.125rem;
    }
}

h6,
.h6,
.h6 {
    font-size: 1.125rem;
}
@media only screen and (max-width: 767px) {
    h6,
    .h6,
    .h6 {
        font-size: 1rem;
    }
}

ul {
    padding: 0;
    margin: 0;
}

img {
    border-style: none;
    height: auto;
    max-width: 100%;
    vertical-align: middle;
}

a {
    text-decoration: none;
    outline: none;
    color: var(--primary);
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
}
a:hover,
a:focus,
a.active {
    text-decoration: none;
    outline: none;
    color: var(--primary-hover);
}

.text-primary {
    color: var(--primary) !important;
}

.text-secondary {
    color: var(--secondary) !important;
}

.bg-primary {
    background-color: var(--primary) !important;
}

.bg-secondary {
    background-color: var(--secondary) !important;
}

.bg-blue {
    background-color: #060340 !important;
}

b,
strong,
.strong {
    font-weight: 700;
}

.h-0 {
    height: 0;
}

#main-wrapper {
    opacity: 0;
    transition: all 0.25s ease-in;
    overflow: hidden;
    position: relative;
    z-index: 1;
    margin-top: 60px;
}
#main-wrapper.show {
    opacity: 1;
}

.content-body {
    margin-left: 65px;
    margin-top: 18px;
    margin-right: 6px;
    margin-bottom: 45px;
}

.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
    padding-left: 100px;
    padding-right: 100px;
}
@media only screen and (max-width: 1480px) {
    .container-fluid,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl {
        padding-left: 50px;
        padding-right: 50px;
    }
}
@media only screen and (max-width: 1280px) {
    .container-fluid,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl {
        padding-left: 20px;
        padding-right: 20px;
    }
}
@media only screen and (max-width: 575px) {
    .container-fluid,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.bg-grey {
    background-color: var(--grey);
}

.text-black {
    color: #071c35;
}

::selection {
    color: #ffffff;
    background: var(--primary);
}

.modal-backdrop {
    z-index: 99999;
}

.modal {
    z-index: 100000;
}

/* Flaticon */
[class*='flaticon-'] {
    font-family: 'Flaticon';
}

@media only screen and (max-width: 991px) {
    .dz-order-1 {
        order: 1;
        margin-top: 20px;
    }
}

.gradient {
    background-image: var(--gradient);
}

.gradient-sec {
    background-image: var(--gradient-sec);
}

.content-inner {
    padding-top: 120px;
    padding-bottom: 90px;
}
@media only screen and (max-width: 1480px) {
    .content-inner {
        padding-top: 100px;
        padding-bottom: 70px;
    }
}
@media only screen and (max-width: 991px) {
    .content-inner {
        padding-top: 70px;
        padding-bottom: 40px;
    }
}
@media only screen and (max-width: 767px) {
    .content-inner {
        padding-top: 50px;
        padding-bottom: 20px;
    }
}

.content-inner-1 {
    padding-top: 100px;
    padding-bottom: 100px;
}
@media only screen and (max-width: 1480px) {
    .content-inner-1 {
        padding-top: 70px;
        padding-bottom: 70px;
    }
}
@media only screen and (max-width: 767px) {
    .content-inner-1 {
        padding-top: 50px;
        padding-bottom: 50px;
    }
}

.content-inner-2 {
    padding-top: 100px;
    padding-bottom: 0;
}
@media only screen and (max-width: 1480px) {
    .content-inner-2 {
        padding-top: 70px;
    }
}

.content-inner-3 {
    padding-top: 60px;
    padding-bottom: 30px;
}
@media only screen and (max-width: 991px) {
    .content-inner-3 {
        padding-top: 50px;
        padding-bottom: 20px;
    }
}

.mfp-bg {
    z-index: 99999;
}

.mfp-wrap {
    z-index: 100000;
}

.rounded {
    border-radius: var(--border-radius-base) !important;
}

.height-md {
    height: 370px;
}

.height-lg {
    height: 600px;
}
@media only screen and (max-width: 575px) {
    .height-lg {
        height: 400px;
    }
}

.border-primary {
    border-color: var(--primary) !important;
}

.border-bottom {
    border-bottom: 1px solid #eee !important;
}

.border {
    border: 1px solid #dee2e6 !important;
}

.bg-light {
    background-color: #e9f7ff !important;
}

#pointer-dot {
    left: -4px;
    top: -4px;
    width: 12px;
    height: 12px;
    position: fixed;
    border-radius: 10px;
    z-index: 999998;
    pointer-events: none;
    transition: border-color 0.5s;
    background: #333;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2);
}

#pointer-ring {
    left: 0;
    top: 0;
    width: 25px;
    height: 25px;
    display: block;
    border: 1px solid var(--primary) !important;
    position: fixed;
    border-radius: 100px;
    z-index: 999999;
    pointer-events: none;
    transition: width 0.3s, height 0.3s;
}

#pointer-ring.active {
    width: 50px;
    height: 50px;
    opacity: 0.5;
}

#pointer-ring.active + #pointer-dot {
    opacity: 0;
}

#loading-area {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 999999999;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 80px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}
#loading-area .loader {
    width: 170px;
    height: 170px;
    margin: 30px auto 0;
    position: relative;
}
#loading-area .loader .border {
    width: 170px;
    height: 170px;
    opacity: 0;
    border: 3px solid #02a3fe !important;
    border-radius: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    animation: animate 1.8s infinite linear;
}
#loading-area .loader .border.one {
    animation-delay: 200ms;
}
#loading-area .loader .border.two {
    animation-delay: 400ms;
}
#loading-area .loader .border.three {
    animation-delay: 600ms;
}
#loading-area .loader .border.four {
    animation-delay: 800ms;
}
#loading-area .loader .border.five {
    animation-delay: 1000ms;
}
#loading-area .loader .border.seven {
    animation-delay: 1200ms;
}
#loading-area .loader .border.eight {
    animation-delay: 1400ms;
}
#loading-area .loader .border.nine {
    animation-delay: 1600ms;
}

@keyframes animate {
    0% {
        opacity: 0;
        width: 0;
        height: 0;
    }
    10% {
        opacity: 0.9;
    }
    100% {
        opacity: 0;
        width: 150px;
        height: 150px;
    }
}

.font-12 {
    font-size: 12px;
}

.font-13 {
    font-size: 13px;
}

.font-14 {
    font-size: 14px;
}

.font-15 {
    font-size: 15px;
}

.font-16 {
    font-size: 16px;
}

.font-18 {
    font-size: 18px;
}

.font-20 {
    font-size: 20px;
}

.font-22 {
    font-size: 22px;
}

.font-24 {
    font-size: 24px;
}

.font-26 {
    font-size: 26px;
}

.font-0 {
    font-size: 0px !important;
}

.font-5 {
    font-size: 5px !important;
}

.font-10 {
    font-size: 10px !important;
}

.font-15 {
    font-size: 15px !important;
}

.font-20 {
    font-size: 20px !important;
}

.font-25 {
    font-size: 25px !important;
}

.font-30 {
    font-size: 30px !important;
}

.font-35 {
    font-size: 35px !important;
}

.font-40 {
    font-size: 40px !important;
}

.font-45 {
    font-size: 45px !important;
}

.font-50 {
    font-size: 50px !important;
}

.font-55 {
    font-size: 55px !important;
}

.font-60 {
    font-size: 60px !important;
}

.font-65 {
    font-size: 65px !important;
}

.font-70 {
    font-size: 70px !important;
}

.font-75 {
    font-size: 75px !important;
}

.font-80 {
    font-size: 80px !important;
}

.font-85 {
    font-size: 85px !important;
}

.font-90 {
    font-size: 90px !important;
}

.font-95 {
    font-size: 95px !important;
}

.font-100 {
    font-size: 100px !important;
}

.font-w0 {
    font-weight: 0 !important;
}

.font-w100 {
    font-weight: 100 !important;
}

.font-w200 {
    font-weight: 200 !important;
}

.font-w300 {
    font-weight: 300 !important;
}

.font-w400 {
    font-weight: 400 !important;
}

.font-w500 {
    font-weight: 500 !important;
}

.font-w600 {
    font-weight: 600 !important;
}

.font-w700 {
    font-weight: 700 !important;
}

.font-w800 {
    font-weight: 800 !important;
}

.font-w900 {
    font-weight: 900 !important;
}

.p-a0 {
    padding: 0px;
}

.p-l0 {
    padding-left: 0px;
}

.p-r0 {
    padding-right: 0px;
}

.p-t0 {
    padding-top: 0px;
}

.p-b0 {
    padding-bottom: 0px;
}

.p-lr0 {
    padding-left: 0px;
    padding-right: 0px;
}

.p-tb0 {
    padding-bottom: 0px;
    padding-top: 0px;
}

.m-a0 {
    margin: 0px;
}

.m-l0 {
    margin-left: 0px;
}

.m-r0 {
    margin-right: 0px;
}

.m-t0 {
    margin-top: 0px;
}

.m-b0 {
    margin-bottom: 0px;
}

.m-lr0 {
    margin-left: 0px;
    margin-right: 0px;
}

.m-tb0 {
    margin-bottom: 0px;
    margin-top: 0px;
}

.p-a5 {
    padding: 5px;
}

.p-l5 {
    padding-left: 5px;
}

.p-r5 {
    padding-right: 5px;
}

.p-t5 {
    padding-top: 5px;
}

.p-b5 {
    padding-bottom: 5px;
}

.p-lr5 {
    padding-left: 5px;
    padding-right: 5px;
}

.p-tb5 {
    padding-bottom: 5px;
    padding-top: 5px;
}

.m-a5 {
    margin: 5px;
}

.m-l5 {
    margin-left: 5px;
}

.m-r5 {
    margin-right: 5px;
}

.m-t5 {
    margin-top: 5px;
}

.m-b5 {
    margin-bottom: 5px;
}

.m-lr5 {
    margin-left: 5px;
    margin-right: 5px;
}

.m-tb5 {
    margin-bottom: 5px;
    margin-top: 5px;
}

.p-a10 {
    padding: 10px;
}

.p-l10 {
    padding-left: 10px;
}

.p-r10 {
    padding-right: 10px;
}

.p-t10 {
    padding-top: 10px;
}

.p-b10 {
    padding-bottom: 10px;
}

.p-lr10 {
    padding-left: 10px;
    padding-right: 10px;
}

.p-tb10 {
    padding-bottom: 10px;
    padding-top: 10px;
}

.m-a10 {
    margin: 10px;
}

.m-l10 {
    margin-left: 10px;
}

.m-r10 {
    margin-right: 10px;
}

.m-t10 {
    margin-top: 10px;
}

.m-b10 {
    margin-bottom: 10px;
}

.m-lr10 {
    margin-left: 10px;
    margin-right: 10px;
}

.m-tb10 {
    margin-bottom: 10px;
    margin-top: 10px;
}

.p-a15 {
    padding: 15px;
}

.p-l15 {
    padding-left: 15px;
}

.p-r15 {
    padding-right: 15px;
}

.p-t15 {
    padding-top: 15px;
}

.p-b15 {
    padding-bottom: 15px;
}

.p-lr15 {
    padding-left: 15px;
    padding-right: 15px;
}

.p-tb15 {
    padding-bottom: 15px;
    padding-top: 15px;
}

.m-a15 {
    margin: 15px;
}

.m-l15 {
    margin-left: 15px;
}

.m-r15 {
    margin-right: 15px;
}

.m-t15 {
    margin-top: 15px;
}

.m-b15 {
    margin-bottom: 15px;
}

.m-lr15 {
    margin-left: 15px;
    margin-right: 15px;
}

.m-tb15 {
    margin-bottom: 15px;
    margin-top: 15px;
}

.p-a20 {
    padding: 20px;
}

.p-l20 {
    padding-left: 20px;
}

.p-r20 {
    padding-right: 20px;
}

.p-t20 {
    padding-top: 20px;
}

.p-b20 {
    padding-bottom: 20px;
}

.p-lr20 {
    padding-left: 20px;
    padding-right: 20px;
}

.p-tb20 {
    padding-bottom: 20px;
    padding-top: 20px;
}

.m-a20 {
    margin: 20px;
}

.m-l20 {
    margin-left: 20px;
}

.m-r20 {
    margin-right: 20px;
}

.m-t20 {
    margin-top: 20px;
}

.m-b20 {
    margin-bottom: 20px;
}

.m-lr20 {
    margin-left: 20px;
    margin-right: 20px;
}

.m-tb20 {
    margin-bottom: 20px;
    margin-top: 20px;
}

.p-a25 {
    padding: 25px;
}

.p-l25 {
    padding-left: 25px;
}

.p-r25 {
    padding-right: 25px;
}

.p-t25 {
    padding-top: 25px;
}

.p-b25 {
    padding-bottom: 25px;
}

.p-lr25 {
    padding-left: 25px;
    padding-right: 25px;
}

.p-tb25 {
    padding-bottom: 25px;
    padding-top: 25px;
}

.m-a25 {
    margin: 25px;
}

.m-l25 {
    margin-left: 25px;
}

.m-r25 {
    margin-right: 25px;
}

.m-t25 {
    margin-top: 25px;
}

.m-b25 {
    margin-bottom: 25px;
}

.m-lr25 {
    margin-left: 25px;
    margin-right: 25px;
}

.m-tb25 {
    margin-bottom: 25px;
    margin-top: 25px;
}

.p-a30 {
    padding: 30px;
}

.p-l30 {
    padding-left: 30px;
}

.p-r30 {
    padding-right: 30px;
}

.p-t30 {
    padding-top: 30px;
}

.p-b30 {
    padding-bottom: 30px;
}

.p-lr30 {
    padding-left: 30px;
    padding-right: 30px;
}

.p-tb30 {
    padding-bottom: 30px;
    padding-top: 30px;
}

.m-a30 {
    margin: 30px;
}

.m-l30 {
    margin-left: 30px;
}

.m-r30 {
    margin-right: 30px;
}

.m-t30 {
    margin-top: 30px;
}

.m-b30 {
    margin-bottom: 30px;
}

.m-lr30 {
    margin-left: 30px;
    margin-right: 30px;
}

.m-tb30 {
    margin-bottom: 30px;
    margin-top: 30px;
}

.p-a35 {
    padding: 35px;
}

.p-l35 {
    padding-left: 35px;
}

.p-r35 {
    padding-right: 35px;
}

.p-t35 {
    padding-top: 35px;
}

.p-b35 {
    padding-bottom: 35px;
}

.p-lr35 {
    padding-left: 35px;
    padding-right: 35px;
}

.p-tb35 {
    padding-bottom: 35px;
    padding-top: 35px;
}

.m-a35 {
    margin: 35px;
}

.m-l35 {
    margin-left: 35px;
}

.m-r35 {
    margin-right: 35px;
}

.m-t35 {
    margin-top: 35px;
}

.m-b35 {
    margin-bottom: 35px;
}

.m-lr35 {
    margin-left: 35px;
    margin-right: 35px;
}

.m-tb35 {
    margin-bottom: 35px;
    margin-top: 35px;
}

.p-a40 {
    padding: 40px;
}

.p-l40 {
    padding-left: 40px;
}

.p-r40 {
    padding-right: 40px;
}

.p-t40 {
    padding-top: 40px;
}

.p-b40 {
    padding-bottom: 40px;
}

.p-lr40 {
    padding-left: 40px;
    padding-right: 40px;
}

.p-tb40 {
    padding-bottom: 40px;
    padding-top: 40px;
}

.m-a40 {
    margin: 40px;
}

.m-l40 {
    margin-left: 40px;
}

.m-r40 {
    margin-right: 40px;
}

.m-t40 {
    margin-top: 40px;
}

.m-b40 {
    margin-bottom: 40px;
}

.m-lr40 {
    margin-left: 40px;
    margin-right: 40px;
}

.m-tb40 {
    margin-bottom: 40px;
    margin-top: 40px;
}

.p-a45 {
    padding: 45px;
}

.p-l45 {
    padding-left: 45px;
}

.p-r45 {
    padding-right: 45px;
}

.p-t45 {
    padding-top: 45px;
}

.p-b45 {
    padding-bottom: 45px;
}

.p-lr45 {
    padding-left: 45px;
    padding-right: 45px;
}

.p-tb45 {
    padding-bottom: 45px;
    padding-top: 45px;
}

.m-a45 {
    margin: 45px;
}

.m-l45 {
    margin-left: 45px;
}

.m-r45 {
    margin-right: 45px;
}

.m-t45 {
    margin-top: 45px;
}

.m-b45 {
    margin-bottom: 45px;
}

.m-lr45 {
    margin-left: 45px;
    margin-right: 45px;
}

.m-tb45 {
    margin-bottom: 45px;
    margin-top: 45px;
}

.p-a50 {
    padding: 50px;
}

.p-l50 {
    padding-left: 50px;
}

.p-r50 {
    padding-right: 50px;
}

.p-t50 {
    padding-top: 50px;
}

.p-b50 {
    padding-bottom: 50px;
}

.p-lr50 {
    padding-left: 50px;
    padding-right: 50px;
}

.p-tb50 {
    padding-bottom: 50px;
    padding-top: 50px;
}

.m-a50 {
    margin: 50px;
}

.m-l50 {
    margin-left: 50px;
}

.m-r50 {
    margin-right: 50px;
}

.m-t50 {
    margin-top: 50px;
}

.m-b50 {
    margin-bottom: 50px;
}

.m-lr50 {
    margin-left: 50px;
    margin-right: 50px;
}

.m-tb50 {
    margin-bottom: 50px;
    margin-top: 50px;
}

.p-a55 {
    padding: 55px;
}

.p-l55 {
    padding-left: 55px;
}

.p-r55 {
    padding-right: 55px;
}

.p-t55 {
    padding-top: 55px;
}

.p-b55 {
    padding-bottom: 55px;
}

.p-lr55 {
    padding-left: 55px;
    padding-right: 55px;
}

.p-tb55 {
    padding-bottom: 55px;
    padding-top: 55px;
}

.m-a55 {
    margin: 55px;
}

.m-l55 {
    margin-left: 55px;
}

.m-r55 {
    margin-right: 55px;
}

.m-t55 {
    margin-top: 55px;
}

.m-b55 {
    margin-bottom: 55px;
}

.m-lr55 {
    margin-left: 55px;
    margin-right: 55px;
}

.m-tb55 {
    margin-bottom: 55px;
    margin-top: 55px;
}

.p-a60 {
    padding: 60px;
}

.p-l60 {
    padding-left: 60px;
}

.p-r60 {
    padding-right: 60px;
}

.p-t60 {
    padding-top: 60px;
}

.p-b60 {
    padding-bottom: 60px;
}

.p-lr60 {
    padding-left: 60px;
    padding-right: 60px;
}

.p-tb60 {
    padding-bottom: 60px;
    padding-top: 60px;
}

.m-a60 {
    margin: 60px;
}

.m-l60 {
    margin-left: 60px;
}

.m-r60 {
    margin-right: 60px;
}

.m-t60 {
    margin-top: 60px;
}

.m-b60 {
    margin-bottom: 60px;
}

.m-lr60 {
    margin-left: 60px;
    margin-right: 60px;
}

.m-tb60 {
    margin-bottom: 60px;
    margin-top: 60px;
}

.p-a65 {
    padding: 65px;
}

.p-l65 {
    padding-left: 65px;
}

.p-r65 {
    padding-right: 65px;
}

.p-t65 {
    padding-top: 65px;
}

.p-b65 {
    padding-bottom: 65px;
}

.p-lr65 {
    padding-left: 65px;
    padding-right: 65px;
}

.p-tb65 {
    padding-bottom: 65px;
    padding-top: 65px;
}

.m-a65 {
    margin: 65px;
}

.m-l65 {
    margin-left: 65px;
}

.m-r65 {
    margin-right: 65px;
}

.m-t65 {
    margin-top: 65px;
}

.m-b65 {
    margin-bottom: 65px;
}

.m-lr65 {
    margin-left: 65px;
    margin-right: 65px;
}

.m-tb65 {
    margin-bottom: 65px;
    margin-top: 65px;
}

.p-a70 {
    padding: 70px;
}

.p-l70 {
    padding-left: 70px;
}

.p-r70 {
    padding-right: 70px;
}

.p-t70 {
    padding-top: 70px;
}

.p-b70 {
    padding-bottom: 70px;
}

.p-lr70 {
    padding-left: 70px;
    padding-right: 70px;
}

.p-tb70 {
    padding-bottom: 70px;
    padding-top: 70px;
}

.m-a70 {
    margin: 70px;
}

.m-l70 {
    margin-left: 70px;
}

.m-r70 {
    margin-right: 70px;
}

.m-t70 {
    margin-top: 70px;
}

.m-b70 {
    margin-bottom: 70px;
}

.m-lr70 {
    margin-left: 70px;
    margin-right: 70px;
}

.m-tb70 {
    margin-bottom: 70px;
    margin-top: 70px;
}

.p-a75 {
    padding: 75px;
}

.p-l75 {
    padding-left: 75px;
}

.p-r75 {
    padding-right: 75px;
}

.p-t75 {
    padding-top: 75px;
}

.p-b75 {
    padding-bottom: 75px;
}

.p-lr75 {
    padding-left: 75px;
    padding-right: 75px;
}

.p-tb75 {
    padding-bottom: 75px;
    padding-top: 75px;
}

.m-a75 {
    margin: 75px;
}

.m-l75 {
    margin-left: 75px;
}

.m-r75 {
    margin-right: 75px;
}

.m-t75 {
    margin-top: 75px;
}

.m-b75 {
    margin-bottom: 75px;
}

.m-lr75 {
    margin-left: 75px;
    margin-right: 75px;
}

.m-tb75 {
    margin-bottom: 75px;
    margin-top: 75px;
}

.p-a80 {
    padding: 80px;
}

.p-l80 {
    padding-left: 80px;
}

.p-r80 {
    padding-right: 80px;
}

.p-t80 {
    padding-top: 80px;
}

.p-b80 {
    padding-bottom: 80px;
}

.p-lr80 {
    padding-left: 80px;
    padding-right: 80px;
}

.p-tb80 {
    padding-bottom: 80px;
    padding-top: 80px;
}

.m-a80 {
    margin: 80px;
}

.m-l80 {
    margin-left: 80px;
}

.m-r80 {
    margin-right: 80px;
}

.m-t80 {
    margin-top: 80px;
}

.m-b80 {
    margin-bottom: 80px;
}

.m-lr80 {
    margin-left: 80px;
    margin-right: 80px;
}

.m-tb80 {
    margin-bottom: 80px;
    margin-top: 80px;
}

.p-a85 {
    padding: 85px;
}

.p-l85 {
    padding-left: 85px;
}

.p-r85 {
    padding-right: 85px;
}

.p-t85 {
    padding-top: 85px;
}

.p-b85 {
    padding-bottom: 85px;
}

.p-lr85 {
    padding-left: 85px;
    padding-right: 85px;
}

.p-tb85 {
    padding-bottom: 85px;
    padding-top: 85px;
}

.m-a85 {
    margin: 85px;
}

.m-l85 {
    margin-left: 85px;
}

.m-r85 {
    margin-right: 85px;
}

.m-t85 {
    margin-top: 85px;
}

.m-b85 {
    margin-bottom: 85px;
}

.m-lr85 {
    margin-left: 85px;
    margin-right: 85px;
}

.m-tb85 {
    margin-bottom: 85px;
    margin-top: 85px;
}

.p-a90 {
    padding: 90px;
}

.p-l90 {
    padding-left: 90px;
}

.p-r90 {
    padding-right: 90px;
}

.p-t90 {
    padding-top: 90px;
}

.p-b90 {
    padding-bottom: 90px;
}

.p-lr90 {
    padding-left: 90px;
    padding-right: 90px;
}

.p-tb90 {
    padding-bottom: 90px;
    padding-top: 90px;
}

.m-a90 {
    margin: 90px;
}

.m-l90 {
    margin-left: 90px;
}

.m-r90 {
    margin-right: 90px;
}

.m-t90 {
    margin-top: 90px;
}

.m-b90 {
    margin-bottom: 90px;
}

.m-lr90 {
    margin-left: 90px;
    margin-right: 90px;
}

.m-tb90 {
    margin-bottom: 90px;
    margin-top: 90px;
}

.p-a95 {
    padding: 95px;
}

.p-l95 {
    padding-left: 95px;
}

.p-r95 {
    padding-right: 95px;
}

.p-t95 {
    padding-top: 95px;
}

.p-b95 {
    padding-bottom: 95px;
}

.p-lr95 {
    padding-left: 95px;
    padding-right: 95px;
}

.p-tb95 {
    padding-bottom: 95px;
    padding-top: 95px;
}

.m-a95 {
    margin: 95px;
}

.m-l95 {
    margin-left: 95px;
}

.m-r95 {
    margin-right: 95px;
}

.m-t95 {
    margin-top: 95px;
}

.m-b95 {
    margin-bottom: 95px;
}

.m-lr95 {
    margin-left: 95px;
    margin-right: 95px;
}

.m-tb95 {
    margin-bottom: 95px;
    margin-top: 95px;
}

.p-a100 {
    padding: 100px;
}

.p-l100 {
    padding-left: 100px;
}

.p-r100 {
    padding-right: 100px;
}

.p-t100 {
    padding-top: 100px;
}

.p-b100 {
    padding-bottom: 100px;
}

.p-lr100 {
    padding-left: 100px;
    padding-right: 100px;
}

.p-tb100 {
    padding-bottom: 100px;
    padding-top: 100px;
}

.m-a100 {
    margin: 100px;
}

.m-l100 {
    margin-left: 100px;
}

.m-r100 {
    margin-right: 100px;
}

.m-t100 {
    margin-top: 100px;
}

.m-b100 {
    margin-bottom: 100px;
}

.m-lr100 {
    margin-left: 100px;
    margin-right: 100px;
}

.m-tb100 {
    margin-bottom: 100px;
    margin-top: 100px;
}

@media only screen and (max-width: 1200px) {
    .m-lg-t0 {
        margin-top: 0px;
    }
    .m-lg-b0 {
        margin-bottom: 0px;
    }
    .m-lg-t5 {
        margin-top: 5px;
    }
    .m-lg-b5 {
        margin-bottom: 5px;
    }
    .m-lg-t10 {
        margin-top: 10px;
    }
    .m-lg-b10 {
        margin-bottom: 10px;
    }
    .m-lg-t15 {
        margin-top: 15px;
    }
    .m-lg-b15 {
        margin-bottom: 15px;
    }
    .m-lg-t20 {
        margin-top: 20px;
    }
    .m-lg-b20 {
        margin-bottom: 20px;
    }
    .m-lg-t25 {
        margin-top: 25px;
    }
    .m-lg-b25 {
        margin-bottom: 25px;
    }
    .m-lg-t30 {
        margin-top: 30px;
    }
    .m-lg-b30 {
        margin-bottom: 30px;
    }
    .m-lg-t35 {
        margin-top: 35px;
    }
    .m-lg-b35 {
        margin-bottom: 35px;
    }
    .m-lg-t40 {
        margin-top: 40px;
    }
    .m-lg-b40 {
        margin-bottom: 40px;
    }
    .m-lg-t45 {
        margin-top: 45px;
    }
    .m-lg-b45 {
        margin-bottom: 45px;
    }
    .m-lg-t50 {
        margin-top: 50px;
    }
    .m-lg-b50 {
        margin-bottom: 50px;
    }
    .m-lg-t55 {
        margin-top: 55px;
    }
    .m-lg-b55 {
        margin-bottom: 55px;
    }
    .m-lg-t60 {
        margin-top: 60px;
    }
    .m-lg-b60 {
        margin-bottom: 60px;
    }
    .m-lg-t65 {
        margin-top: 65px;
    }
    .m-lg-b65 {
        margin-bottom: 65px;
    }
    .m-lg-t70 {
        margin-top: 70px;
    }
    .m-lg-b70 {
        margin-bottom: 70px;
    }
    .m-lg-t75 {
        margin-top: 75px;
    }
    .m-lg-b75 {
        margin-bottom: 75px;
    }
    .m-lg-t80 {
        margin-top: 80px;
    }
    .m-lg-b80 {
        margin-bottom: 80px;
    }
    .m-lg-t85 {
        margin-top: 85px;
    }
    .m-lg-b85 {
        margin-bottom: 85px;
    }
    .m-lg-t90 {
        margin-top: 90px;
    }
    .m-lg-b90 {
        margin-bottom: 90px;
    }
    .m-lg-t95 {
        margin-top: 95px;
    }
    .m-lg-b95 {
        margin-bottom: 95px;
    }
    .m-lg-t100 {
        margin-top: 100px;
    }
    .m-lg-b100 {
        margin-bottom: 100px;
    }
}

@media only screen and (max-width: 991px) {
    .m-md-t0 {
        margin-top: 0px;
    }
    .m-md-b0 {
        margin-bottom: 0px;
    }
    .m-md-t5 {
        margin-top: 5px;
    }
    .m-md-b5 {
        margin-bottom: 5px;
    }
    .m-md-t10 {
        margin-top: 10px;
    }
    .m-md-b10 {
        margin-bottom: 10px;
    }
    .m-md-t15 {
        margin-top: 15px;
    }
    .m-md-b15 {
        margin-bottom: 15px;
    }
    .m-md-t20 {
        margin-top: 20px;
    }
    .m-md-b20 {
        margin-bottom: 20px;
    }
    .m-md-t25 {
        margin-top: 25px;
    }
    .m-md-b25 {
        margin-bottom: 25px;
    }
    .m-md-t30 {
        margin-top: 30px;
    }
    .m-md-b30 {
        margin-bottom: 30px;
    }
    .m-md-t35 {
        margin-top: 35px;
    }
    .m-md-b35 {
        margin-bottom: 35px;
    }
    .m-md-t40 {
        margin-top: 40px;
    }
    .m-md-b40 {
        margin-bottom: 40px;
    }
    .m-md-t45 {
        margin-top: 45px;
    }
    .m-md-b45 {
        margin-bottom: 45px;
    }
    .m-md-t50 {
        margin-top: 50px;
    }
    .m-md-b50 {
        margin-bottom: 50px;
    }
    .m-md-t55 {
        margin-top: 55px;
    }
    .m-md-b55 {
        margin-bottom: 55px;
    }
    .m-md-t60 {
        margin-top: 60px;
    }
    .m-md-b60 {
        margin-bottom: 60px;
    }
    .m-md-t65 {
        margin-top: 65px;
    }
    .m-md-b65 {
        margin-bottom: 65px;
    }
    .m-md-t70 {
        margin-top: 70px;
    }
    .m-md-b70 {
        margin-bottom: 70px;
    }
    .m-md-t75 {
        margin-top: 75px;
    }
    .m-md-b75 {
        margin-bottom: 75px;
    }
    .m-md-t80 {
        margin-top: 80px;
    }
    .m-md-b80 {
        margin-bottom: 80px;
    }
    .m-md-t85 {
        margin-top: 85px;
    }
    .m-md-b85 {
        margin-bottom: 85px;
    }
    .m-md-t90 {
        margin-top: 90px;
    }
    .m-md-b90 {
        margin-bottom: 90px;
    }
    .m-md-t95 {
        margin-top: 95px;
    }
    .m-md-b95 {
        margin-bottom: 95px;
    }
    .m-md-t100 {
        margin-top: 100px;
    }
    .m-md-b100 {
        margin-bottom: 100px;
    }
}

@media only screen and (max-width: 767px) {
    .m-sm-t0 {
        margin-top: 0px;
    }
    .m-sm-b0 {
        margin-bottom: 0px;
    }
    .m-sm-t5 {
        margin-top: 5px;
    }
    .m-sm-b5 {
        margin-bottom: 5px;
    }
    .m-sm-t10 {
        margin-top: 10px;
    }
    .m-sm-b10 {
        margin-bottom: 10px;
    }
    .m-sm-t15 {
        margin-top: 15px;
    }
    .m-sm-b15 {
        margin-bottom: 15px;
    }
    .m-sm-t20 {
        margin-top: 20px;
    }
    .m-sm-b20 {
        margin-bottom: 20px;
    }
    .m-sm-t25 {
        margin-top: 25px;
    }
    .m-sm-b25 {
        margin-bottom: 25px;
    }
    .m-sm-t30 {
        margin-top: 30px;
    }
    .m-sm-b30 {
        margin-bottom: 30px;
    }
    .m-sm-t35 {
        margin-top: 35px;
    }
    .m-sm-b35 {
        margin-bottom: 35px;
    }
    .m-sm-t40 {
        margin-top: 40px;
    }
    .m-sm-b40 {
        margin-bottom: 40px;
    }
    .m-sm-t45 {
        margin-top: 45px;
    }
    .m-sm-b45 {
        margin-bottom: 45px;
    }
    .m-sm-t50 {
        margin-top: 50px;
    }
    .m-sm-b50 {
        margin-bottom: 50px;
    }
    .m-sm-t55 {
        margin-top: 55px;
    }
    .m-sm-b55 {
        margin-bottom: 55px;
    }
    .m-sm-t60 {
        margin-top: 60px;
    }
    .m-sm-b60 {
        margin-bottom: 60px;
    }
    .m-sm-t65 {
        margin-top: 65px;
    }
    .m-sm-b65 {
        margin-bottom: 65px;
    }
    .m-sm-t70 {
        margin-top: 70px;
    }
    .m-sm-b70 {
        margin-bottom: 70px;
    }
    .m-sm-t75 {
        margin-top: 75px;
    }
    .m-sm-b75 {
        margin-bottom: 75px;
    }
    .m-sm-t80 {
        margin-top: 80px;
    }
    .m-sm-b80 {
        margin-bottom: 80px;
    }
    .m-sm-t85 {
        margin-top: 85px;
    }
    .m-sm-b85 {
        margin-bottom: 85px;
    }
    .m-sm-t90 {
        margin-top: 90px;
    }
    .m-sm-b90 {
        margin-bottom: 90px;
    }
    .m-sm-t95 {
        margin-top: 95px;
    }
    .m-sm-b95 {
        margin-bottom: 95px;
    }
    .m-sm-t100 {
        margin-top: 100px;
    }
    .m-sm-b100 {
        margin-bottom: 100px;
    }
}

@media only screen and (max-width: 576px) {
    .m-xs-t0 {
        margin-top: 0px;
    }
    .m-xs-b0 {
        margin-bottom: 0px;
    }
    .m-xs-t5 {
        margin-top: 5px;
    }
    .m-xs-b5 {
        margin-bottom: 5px;
    }
    .m-xs-t10 {
        margin-top: 10px;
    }
    .m-xs-b10 {
        margin-bottom: 10px;
    }
    .m-xs-t15 {
        margin-top: 15px;
    }
    .m-xs-b15 {
        margin-bottom: 15px;
    }
    .m-xs-t20 {
        margin-top: 20px;
    }
    .m-xs-b20 {
        margin-bottom: 20px;
    }
    .m-xs-t25 {
        margin-top: 25px;
    }
    .m-xs-b25 {
        margin-bottom: 25px;
    }
    .m-xs-t30 {
        margin-top: 30px;
    }
    .m-xs-b30 {
        margin-bottom: 30px;
    }
    .m-xs-t35 {
        margin-top: 35px;
    }
    .m-xs-b35 {
        margin-bottom: 35px;
    }
    .m-xs-t40 {
        margin-top: 40px;
    }
    .m-xs-b40 {
        margin-bottom: 40px;
    }
    .m-xs-t45 {
        margin-top: 45px;
    }
    .m-xs-b45 {
        margin-bottom: 45px;
    }
    .m-xs-t50 {
        margin-top: 50px;
    }
    .m-xs-b50 {
        margin-bottom: 50px;
    }
    .m-xs-t55 {
        margin-top: 55px;
    }
    .m-xs-b55 {
        margin-bottom: 55px;
    }
    .m-xs-t60 {
        margin-top: 60px;
    }
    .m-xs-b60 {
        margin-bottom: 60px;
    }
    .m-xs-t65 {
        margin-top: 65px;
    }
    .m-xs-b65 {
        margin-bottom: 65px;
    }
    .m-xs-t70 {
        margin-top: 70px;
    }
    .m-xs-b70 {
        margin-bottom: 70px;
    }
    .m-xs-t75 {
        margin-top: 75px;
    }
    .m-xs-b75 {
        margin-bottom: 75px;
    }
    .m-xs-t80 {
        margin-top: 80px;
    }
    .m-xs-b80 {
        margin-bottom: 80px;
    }
    .m-xs-t85 {
        margin-top: 85px;
    }
    .m-xs-b85 {
        margin-bottom: 85px;
    }
    .m-xs-t90 {
        margin-top: 90px;
    }
    .m-xs-b90 {
        margin-bottom: 90px;
    }
    .m-xs-t95 {
        margin-top: 95px;
    }
    .m-xs-b95 {
        margin-bottom: 95px;
    }
    .m-xs-t100 {
        margin-top: 100px;
    }
    .m-xs-b100 {
        margin-bottom: 100px;
    }
}

.m-auto {
    margin: auto;
}

.max-w50 {
    max-width: 50px;
}

.max-w60 {
    max-width: 60px;
}

.max-w80 {
    max-width: 80px;
}

.max-w100 {
    max-width: 100px;
}

.max-w200 {
    max-width: 200px;
}

.max-w300 {
    max-width: 300px;
}

.max-w400 {
    max-width: 400px;
}

.max-w500 {
    max-width: 500px;
}

.max-w600 {
    max-width: 600px;
}

.max-w700 {
    max-width: 700px;
}

.max-w800 {
    max-width: 800px;
}

.max-w900 {
    max-width: 900px;
}

.max-w1000 {
    max-width: 1000px;
}

.sticky-top {
    top: 120px;
}

.img-cover {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

section {
    position: relative;
    z-index: 1;
}

.section-head {
    margin-bottom: 30px;
}
@media only screen and (max-width: 767px) {
    .section-head {
        margin-bottom: 20px;
    }
}
.section-head h1,
.section-head .h1,
.section-head h2,
.section-head .h2,
.section-head h3,
.section-head .h3,
.section-head h4,
.section-head .h4,
.section-head .comment-reply-title,
.section-head .wp-block-search .wp-block-search__label,
.wp-block-search .section-head .wp-block-search__label,
.section-head h5,
.section-head .h5,
.section-head h6,
.section-head .h6 {
    font-family: var(--font-family-title);
}
.section-head h2.title,
.section-head .title.h2 {
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 1.2;
}
@media only screen and (max-width: 1480px) {
    .section-head h2.title,
    .section-head .title.h2 {
        font-size: 2.5rem;
    }
}
@media only screen and (max-width: 1280px) {
    .section-head h2.title,
    .section-head .title.h2 {
        font-size: 42px;
    }
}
@media only screen and (max-width: 575px) {
    .section-head h2.title,
    .section-head .title.h2 {
        font-size: 32px;
    }
}
.section-head h3.title,
.section-head .title.h3 {
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 1.2;
}
@media only screen and (max-width: 1480px) {
    .section-head h3.title,
    .section-head .title.h3 {
        font-size: 2.5rem;
    }
}
@media only screen and (max-width: 1280px) {
    .section-head h3.title,
    .section-head .title.h3 {
        font-size: 32px;
    }
}
@media only screen and (max-width: 575px) {
    .section-head h3.title,
    .section-head .title.h3 {
        font-size: 28px;
    }
}
.section-head h4.title,
.section-head .title.h4,
.section-head .title.comment-reply-title,
.section-head .wp-block-search .title.wp-block-search__label,
.wp-block-search .section-head .title.wp-block-search__label {
    font-size: 35px;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 5px;
}
@media only screen and (max-width: 1480px) {
    .section-head h4.title,
    .section-head .title.h4,
    .section-head .title.comment-reply-title,
    .section-head .wp-block-search .title.wp-block-search__label,
    .wp-block-search .section-head .title.wp-block-search__label {
        font-size: 36px;
    }
}
@media only screen and (max-width: 575px) {
    .section-head h4.title,
    .section-head .title.h4,
    .section-head .title.comment-reply-title,
    .section-head .wp-block-search .title.wp-block-search__label,
    .wp-block-search .section-head .title.wp-block-search__label {
        font-size: 30px;
    }
}
.section-head p {
    font-size: 20px;
    line-height: 1.5;
    font-weight: 400;
}
@media only screen and (max-width: 767px) {
    .section-head p {
        font-size: 14px;
    }
}
.section-head.text-center p {
    max-width: 753px;
    margin-left: auto;
    margin-right: auto;
}
.section-head.m-b30 {
    margin-bottom: 30px;
}
.section-head.style-2 .title {
    font-size: 60px;
    line-height: 1.2;
    margin-bottom: 15px;
}
@media only screen and (max-width: 1680px) {
    .section-head.style-2 .title {
        font-size: 48px;
    }
}
@media only screen and (max-width: 1199px) {
    .section-head.style-2 .title {
        font-size: 40px;
    }
}
@media only screen and (max-width: 991px) {
    .section-head.style-2 .title br {
        display: none;
    }
}
@media only screen and (max-width: 767px) {
    .section-head.style-2 .title {
        font-size: 32px;
    }
}
@media only screen and (max-width: 575px) {
    .section-head.style-2 .title {
        font-size: 28px;
    }
}
.section-head.style-2 .sub-title {
    text-transform: unset;
    font-size: 22px;
    font-weight: 400;
}
@media only screen and (max-width: 1680px) {
    .section-head.style-2 .sub-title {
        font-size: 18px;
    }
}
@media only screen and (max-width: 1280px) {
    .section-head.style-2 .sub-title {
        font-size: 16px;
    }
}
.theme-dark .section-head.style-2 .sub-title.text-primary {
    color: #52e8d6 !important;
}
.theme-dark .section-head.style-2 .sub-title.text-dark {
    color: #fff !important;
}

.row.spno,
.spno {
    margin-left: 0;
    margin-right: 0;
}
.row.spno [class*='col'],
.row.spno [class*='col'],
.spno [class*='col'],
.spno [class*='col'] {
    padding-left: 0;
    padding-right: 0;
}

.row.sp4,
.sp4 {
    margin-left: -4px;
    margin-right: -4px;
}
.row.sp4 [class*='col'],
.row.sp4 [class*='col'],
.sp4 [class*='col'],
.sp4 [class*='col'] {
    padding-left: 4px;
    padding-right: 4px;
}

.row.sp15,
.sp15 {
    margin-left: -7px;
    margin-right: -7px;
}
.row.sp15 [class*='col'],
.row.sp15 [class*='col'],
.sp15 [class*='col'],
.sp15 [class*='col'] {
    padding-left: 7px;
    padding-right: 7px;
}

.row.sp10,
.sp10 {
    margin-left: -10px;
    margin-right: -10px;
}
.row.sp10 [class*='col'],
.row.sp10 [class*='col'],
.sp10 [class*='col'],
.sp10 [class*='col'] {
    padding-left: 10px;
    padding-right: 10px;
}

.rounded-xl {
    border-radius: 50% !important;
    overflow: hidden;
}

.rounded-lg {
    border-radius: 20px !important;
    overflow: hidden;
}

.rounded-md {
    border-radius: 8px !important;
    overflow: hidden;
}

.rounded-sm {
    border-radius: 4px !important;
    overflow: hidden;
}

.text-maroon {
    color: #9e0168;
}

.text-orange {
    color: #ff8853;
}

.text-yellow {
    color: #efd430;
}

.text-skyblue {
    color: #16c784;
}

.text-red {
    color: #f22462;
}

.text-green {
    color: #16c784;
}

.text-blue {
    color: #131045;
}

.bg-maroon {
    background-color: #9e0168;
}

.bg-orange {
    background-color: #ff8853;
}

.bg-yellow {
    background-color: #efd430;
}

.bg-skyblue {
    background-color: #00aeff;
}

.bg-red {
    background-color: #f22462;
}

.bg-green {
    background-color: #16c784;
}

.bg-blue {
    background-color: #131045;
}

.scale8 {
    transform: scale(1.85);
    -moz-transform: scale(1.85);
    -webkit-transform: scale(1.85);
    -ms-transform: scale(1.85);
    -o-transform: scale(1.85);
}

.scale7 {
    transform: scale(1.7);
    -moz-transform: scale(1.7);
    -webkit-transform: scale(1.7);
    -ms-transform: scale(1.7);
    -o-transform: scale(1.7);
}

.scale5 {
    transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -ms-transform: scale(1.5);
    -o-transform: scale(1.5);
}

.scale3 {
    transform: scale(1.3);
    -moz-transform: scale(1.3);
    -webkit-transform: scale(1.3);
    -ms-transform: scale(1.3);
    -o-transform: scale(1.3);
}

.scale2 {
    transform: scale(1.2);
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
}

.scale08 {
    transform: scale(0.8);
    -moz-transform: scale(0.8);
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
}

.scale05 {
    transform: scale(0.5);
    -moz-transform: scale(0.5);
    -webkit-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
}

.tl {
    transform-origin: left;
    -moz-transform-origin: left;
    -webkit-transform-origin: left;
    -ms-transform-origin: left;
    -o-transform-origin: left;
}

.shadow-none {
    box-shadow: unset;
}

@media only screen and (min-width: 1200px) and (max-width: 1440px) {
    .col-xxl-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }
    .col-xxl-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }
    .col-xxl-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }
    .col-xxl-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }
    .col-xxl-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }
    .col-xxl-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }
    .col-xxl-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }
    .col-xxl-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }
    .col-xxl-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }
    .col-xxl-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }
    .col-xxl-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }
    .col-xxl-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }
}

:root {
    --header-height: 80px;
}

.logo-header {
    display: table;
    float: left;
    vertical-align: middle;
    padding: 0;
    color: #efbb20;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    width: 180px;
    height: var(--header-height);
    position: relative;
    z-index: 9;
}
.logo-header a {
    display: table-cell;
    vertical-align: middle;
}
.logo-header img {
    height: var(--header-height);
    max-width: 180px;
    object-fit: contain;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
}
@media only screen and (max-width: 991px) {
    .logo-header img {
        max-width: 180px;
        height: 80px;
    }
}
@media only screen and (max-width: 575px) {
    .logo-header img {
        max-width: 150px;
    }
}
.logo-header .logo-light {
    display: none;
}
.logo-header span {
    font-size: 20px;
    letter-spacing: 20px;
}

.top-bar {
    background-color: var(--primary);
    color: #212529;
    padding: 8px 0;
    position: relative;
}
.top-bar .dz-topbar-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: -15px;
    margin-right: -15px;
}
.top-bar .dz-topbar-left {
    padding-left: 15px;
}
.top-bar .dz-topbar-right {
    display: flex;
    align-items: center;
    padding-right: 15px;
}
.top-bar .dz-topbar-right ul {
    margin-left: 15px;
}
.top-bar .top-info li {
    display: inline-flex;
    padding-right: 15px;
}
.top-bar .top-info p {
    color: #ffffff;
    margin-bottom: 0;
    font-size: 0.8125rem;
    font-weight: 500;
}
.top-bar .top-info p.label {
    color: #bcfaf2;
    margin-right: 4px;
    font-weight: 400;
}
@media only screen and (max-width: 991px) {
    .top-bar {
        display: none;
    }
}

.site-header {
    position: relative;
    z-index: 9999;
}
.site-header .main-bar {
    background: var(--card-bg);
    width: 100%;
    position: relative;
    box-shadow: 0px 0 20px rgba(21, 50, 93, 0.1);
    padding: 12px 0;
}
@media only screen and (max-width: 575px) {
    .site-header .main-bar {
        padding: 0 !important;
    }
}
.site-header .container {
    position: relative;
}
.site-header .extra-nav {
    float: right;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 9;
    height: var(--header-height);
    padding-left: 30px;
}
@media only screen and (max-width: 1280px) {
    .site-header .extra-nav {
        padding-left: 20px;
    }
}
@media only screen and (max-width: 767px) {
    .site-header .extra-nav {
        display: none;
    }
}
.site-header .extra-nav .extra-cell {
    display: flex;
    align-items: center;
}
.site-header .extra-nav .extra-cell > * {
    margin-left: 10px;
}
.site-header .extra-nav #quik-search-btn {
    color: #fff;
    cursor: pointer;
    margin-right: 0px;
    border: 0;
    width: 45px;
    height: 45px;
    background: var(--secondary);
    line-height: 45px;
    text-align: center;
    font-size: 18px;
    border-radius: var(--border-radius-base);
}
@media only screen and (max-width: 575px) {
    .site-header .extra-nav .btn {
        padding-left: 15px;
        padding-right: 15px;
    }
}
.site-header .extra-nav ul li {
    display: inline-block;
}
.site-header .extra-nav ul li .icon {
    padding: 0;
    background: transparent;
    min-width: 40px;
    text-align: center;
    height: 40px;
    line-height: 40px;
    border-radius: 4px;
}
.site-header .extra-nav .extra-svg {
    display: flex;
    margin: 0 -5px;
}
.site-header .extra-nav .extra-svg .tp-user {
    height: 45px;
    width: 45px;
    border: 1px solid var(--primary);
    line-height: 40px;
    text-align: center;
    border-radius: 8px;
    margin: 0 5px;
}

.navbar-toggler {
    border: 0 solid #efbb20;
    font-size: 16px;
    line-height: 24px;
    margin: 40px 0 39px 15px;
    padding: 0;
    float: right;
    outline: none !important;
}
@media only screen and (max-width: 991px) {
    .navbar-toggler {
        margin: 18px 0px 16px 15px;
    }
}

.navicon {
    width: 45px;
    height: 45px;
    background: var(--primary);
    box-shadow: 0 5px 15px -10px var(--primary);
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    border-radius: var(--border-radius-base);
}
.navicon span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    border-radius: 1px;
    opacity: 1;
    background: #fff;
    left: 10px;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
}
.navicon span:nth-child(1) {
    top: 13px;
    width: 22px;
}
.navicon span:nth-child(2) {
    top: 22px;
    width: 25px;
}
.navicon span:nth-child(3) {
    top: 32px;
    width: 20px;
}
.navicon.open span:nth-child(1) {
    top: 23px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
}
.navicon.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
}
.navicon.open span:nth-child(3) {
    top: 23px;
    width: 23px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
}

.header-tb .navicon span {
    background: #fff;
}

.header-nav {
    padding: 0;
}
@media only screen and (max-width: 991px) {
    .header-nav {
        overflow-y: scroll;
        position: fixed;
        width: 60px;
        left: -280px;
        height: 100vh !important;
        -webkit-transition: all 0.8s;
        -ms-transition: all 0.8s;
        transition: all 0.8s;
        top: 0;
        background-color: #ffffff;
        margin: 0;
        z-index: 99;
    }
}
@media only screen and (max-width: 991px) {
    .header-nav.show {
        left: -1px;
        -webkit-transition: all 1s;
        -ms-transition: all 1s;
        transition: all 1s;
        margin: 0;
        width: 280px;
    }
}
.header-nav .logo-header {
    display: none;
}
@media only screen and (max-width: 991px) {
    .header-nav .logo-header {
        display: block;
        padding: 20px 20px;
        float: none;
        height: auto;
        width: 240px;
    }
    .header-nav .logo-header img {
        height: 50px;
    }
    .header-nav .logo-header .logo-dark {
        display: block !important;
    }
}
.header-nav .mo-footer {
    display: none;
    padding: 30px 20px;
    text-align: center;
}
@media only screen and (max-width: 991px) {
    .header-nav .mo-footer {
        display: flex;
        padding: 30px 20px;
        text-align: center;
        gap: 15px;
    }
    .header-nav .mo-footer .dz-theme-mode {
        position: absolute;
        right: 15px;
        top: 28px;
        z-index: 14;
        border: 1px solid #cfdbed;
        border: 1px solid var(--border-color);
    }
}
.header-nav .nav {
    float: right;
    padding: 0;
    font-size: 0;
}
.header-nav .nav > li {
    margin: 0px;
    font-weight: 400;
    position: relative;
}
.header-nav .nav > li.has-mega-menu {
    position: inherit;
}
@media only screen and (max-width: 991px) {
    .header-nav .nav > li {
        border-bottom: 1px solid var(--border-color);
        width: 100%;
    }
}
.header-nav .nav > li.menu-item-has-children > a:after,
.header-nav .nav > li.sub-menu-down > a:after {
    content: '\f078';
    font-size: 9px;
    margin-left: 5px;
    margin-top: 0;
    vertical-align: middle;
    font-weight: 900;
    font-family: 'Font Awesome 5 Free';
}
@media only screen and (max-width: 991px) {
    .header-nav .nav > li.menu-item-has-children > a:after,
    .header-nav .nav > li.sub-menu-down > a:after {
        content: '\f054';
        position: absolute;
        background-color: var(--primary);
        color: #fff;
        height: 30px;
        line-height: 30px;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        width: 30px;
        z-index: 3;
        float: right;
        font-size: 14px;
        margin: 0;
        border-radius: var(--border-radius-base);
    }
}
@media only screen and (max-width: 991px) {
    .header-nav .nav > li.menu-item-has-children > a:after:before,
    .header-nav .nav > li.sub-menu-down > a:after:before {
        content: '\f078';
    }
}
@media only screen and (max-width: 991px) {
    .header-nav .nav > li.menu-item-has-children.open > a:after,
    .header-nav .nav > li.sub-menu-down.open > a:after {
        content: '\f078';
        background: var(--title);
    }
}
.header-nav .nav > li > a {
    color: var(--body-color);
    font-size: 16px;
    padding: 32px 15px;
    font-family: var(--font-family-title);
    cursor: pointer;
    font-weight: 500;
    display: inline-block;
    position: relative;
    border-width: 0 0 0 1px;
    text-transform: capitalize;
    line-height: 1;
}
@media only screen and (max-width: 1280px) {
    .header-nav .nav > li > a {
        padding-left: 10px;
        padding-right: 10px;
        font-size: 14px;
    }
}
@media only screen and (max-width: 991px) {
    .header-nav .nav > li > a {
        padding: 15px 00;
        display: block;
    }
}
.theme-dark .header-nav .nav > li > a {
    color: #fff;
}
.header-nav .nav > li > a span {
    display: inline-block;
}
.header-nav .nav > li .mega-menu,
.header-nav .nav > li .sub-menu {
    background-color: #fff;
    display: block;
    left: 0;
    list-style: none;
    opacity: 0;
    padding: 15px 0;
    position: absolute;
    visibility: hidden;
    width: 220px;
    z-index: 10;
    margin-top: 20px;
    text-align: left;
    border-radius: 0 var(--border-radius-base) var(--border-radius-base) var(--border-radius-base);
    -webkit-transition: all 1s;
    -ms-transition: all 1s;
    transition: all 1s;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.05);
}
@media only screen and (max-width: 991px) {
    .header-nav .nav > li .mega-menu,
    .header-nav .nav > li .sub-menu {
        display: none;
        position: static;
        visibility: visible;
        width: auto;
        background: transparent;
        box-shadow: none;
        margin: 0;
        border-top: 1px solid #eee;
        padding: 10px 0;
        -webkit-transition: none;
        -ms-transition: none;
        transition: none;
    }
    .header-nav .nav > li .mega-menu > li > a i,
    .header-nav .nav > li .sub-menu > li > a i {
        color: #fff !important;
        height: 30px;
        line-height: 30px;
        right: 0;
        text-align: center;
        width: 30px;
        z-index: 3;
        font-size: 14px !important;
        margin: 0 !important;
        background: #aaa;
    }
}
.header-nav .nav > li .mega-menu .menu-item-has-children > a:before,
.header-nav .nav > li .sub-menu .menu-item-has-children > a:before {
    content: '\f054';
    font-weight: 900;
    font-family: 'Font Awesome 5 Free';
    float: right;
    font-size: 10px;
    margin-top: 2px;
}
@media only screen and (max-width: 991px) {
    .header-nav .nav > li .mega-menu .menu-item-has-children.open > a:before,
    .header-nav .nav > li .sub-menu .menu-item-has-children.open > a:before {
        content: '\f054';
    }
}
@media only screen and (max-width: 991px) {
    .header-nav .nav > li .mega-menu,
    .header-nav .nav > li .sub-menu {
        display: none;
        position: static;
        visibility: visible;
        width: auto;
        background: transparent;
        box-shadow: none;
        margin: 0;
        border-top: 1px solid #eee;
        padding: 0 0;
        -webkit-transition: none;
        -ms-transition: none;
        transition: none;
        opacity: 1;
    }
    .header-nav .nav > li .mega-menu > li > a i,
    .header-nav .nav > li .sub-menu > li > a i {
        display: none;
    }
    .header-nav .nav > li .mega-menu .menu-item-has-children > a:before,
    .header-nav .nav > li .mega-menu > li > a i,
    .header-nav .nav > li .sub-menu .menu-item-has-children > a:before,
    .header-nav .nav > li .sub-menu > li > a i {
        color: #fff !important;
        font-size: 14px;
        content: '\f054';
        height: 30px;
        line-height: 30px;
        right: 0;
        text-align: center;
        width: 30px;
        z-index: 3;
        margin: 0 !important;
        background: var(--primary);
        border-radius: var(--border-radius-base);
    }
    .header-nav .nav > li .mega-menu .menu-item-has-children.open > a:before,
    .header-nav .nav > li .sub-menu .menu-item-has-children.open > a:before {
        content: '\f078';
        background: #333;
    }
}
.header-nav .nav > li .mega-menu li,
.header-nav .nav > li .sub-menu li {
    position: relative;
    transition: 0.6s;
}
.header-nav .nav > li .mega-menu li a,
.header-nav .nav > li .sub-menu li a {
    color: #212529;
    display: block;
    font-size: 15px;
    padding: 10px 20px;
    line-height: 1.3;
    text-transform: capitalize;
    position: relative;
    transition: all 0.15s linear;
    -webkit-transition: all 0.15s linear;
    -moz-transition: all 0.15s linear;
    -o-transition: all 0.15s linear;
    font-weight: 400;
}
@media only screen and (max-width: 991px) {
    .header-nav .nav > li .mega-menu li a,
    .header-nav .nav > li .sub-menu li a {
        padding: 4px 0 4px 15px;
        display: block;
        line-height: 30px;
        position: relative;
        border: 1px solid #eee;
        border-width: 0 0 1px 0px;
    }
    .header-nav .nav > li .mega-menu li a:after,
    .header-nav .nav > li .sub-menu li a:after {
        content: '';
        font-family: 'Font Awesome 5 Free';
        width: auto;
        height: auto;
        background: 0 0 !important;
        top: 3px;
        color: #262f5a;
        font-size: 12px;
        position: absolute;
        left: 0;
        font-weight: 700;
    }
}
.header-nav .nav > li .mega-menu li:hover > a,
.header-nav .nav > li .sub-menu li:hover > a {
    color: var(--primary);
}
@media only screen and (max-width: 991px) {
    .header-nav .nav > li .mega-menu li:hover > a,
    .header-nav .nav > li .sub-menu li:hover > a {
        padding: 4px 0 4px 15px;
        background: transparent;
    }
}
.header-nav .nav > li .mega-menu li:hover > a:after,
.header-nav .nav > li .sub-menu li:hover > a:after {
    width: 10px;
}
.header-nav .nav > li .mega-menu li:after,
.header-nav .nav > li .sub-menu li:after {
    content: '';
    position: absolute;
    top: 20px;
    left: 6px;
    width: 0px;
    height: 2px;
    background: var(--primary);
    transition: 0.6s;
}
.header-nav .nav > li .mega-menu li:hover,
.header-nav .nav > li .sub-menu li:hover {
    margin-left: 10px;
}
.header-nav .nav > li .mega-menu li:hover:after,
.header-nav .nav > li .sub-menu li:hover:after {
    width: 10px;
}
.header-nav .nav > li .mega-menu li > .sub-menu,
.header-nav .nav > li .sub-menu li > .sub-menu {
    left: 220px;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
}
@media only screen and (max-width: 991px) {
    .header-nav .nav > li .mega-menu li > .sub-menu,
    .header-nav .nav > li .sub-menu li > .sub-menu {
        display: none;
    }
}
@media only screen and (max-width: 991px) {
    .header-nav .nav > li .mega-menu .mega-menu,
    .header-nav .nav > li .mega-menu .sub-menu,
    .header-nav .nav > li .sub-menu .mega-menu,
    .header-nav .nav > li .sub-menu .sub-menu {
        border-top: 0;
        padding-left: 20px;
    }
    .header-nav .nav > li .mega-menu .mega-menu li a:after,
    .header-nav .nav > li .mega-menu .sub-menu li a:after,
    .header-nav .nav > li .sub-menu .mega-menu li a:after,
    .header-nav .nav > li .sub-menu .sub-menu li a:after {
        width: 5px;
        content: none;
        height: 5px;
        background: #262f5a !important;
        border-radius: 4px;
        top: 13px;
    }
}
.header-nav .nav > li .sub-menu li > a > i {
    color: inherit;
    display: block;
    float: right;
    font-size: 18px;
    opacity: 1;
    margin-top: 0px;
}
@media only screen and (max-width: 991px) {
    .header-nav .nav > li .sub-menu li > a > i {
        display: none;
    }
}
.header-nav .nav > li .mega-menu {
    display: flex;
    right: 0px;
    width: 100%;
    z-index: 9;
    padding: 0;
}
.header-nav .nav > li .mega-menu > li {
    display: block;
    padding: 15px 0 15px;
    position: relative;
    vertical-align: top;
    width: 25%;
}
@media only screen and (max-width: 991px) {
    .header-nav .nav > li .mega-menu > li {
        width: 100%;
        padding: 0;
    }
}
.header-nav .nav > li .mega-menu > li > a {
    color: #212529;
    display: block;
    padding: 0 20px;
    font-size: 16px;
    font-weight: 600;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 15px;
}
.header-nav .nav > li .mega-menu > li > a > i {
    font-size: 14px;
    margin-right: 5px;
    text-align: center;
    width: 15px;
}
@media only screen and (max-width: 991px) {
    .header-nav .nav > li .mega-menu > li > a {
        display: none;
    }
}
.header-nav .nav > li .mega-menu > li:after {
    content: '';
    background-color: rgba(0, 0, 0, 0.1);
    position: absolute;
    right: 0px;
    top: 0px;
    display: block;
    width: 1px;
    height: 100%;
}
@media only screen and (max-width: 991px) {
    .header-nav .nav > li .mega-menu > li:after {
        content: none;
    }
}
.header-nav .nav > li .mega-menu > li:last-child:after {
    display: none;
}
.header-nav .nav > li .mega-menu > li:hover > a {
    background-color: transparent;
}
.header-nav .nav > li .mega-menu > li li i {
    font-size: 14px;
    margin-right: 5px;
    text-align: center;
    width: 15px;
}
@media only screen and (max-width: 991px) {
    .header-nav .nav > li .mega-menu {
        display: none;
        padding: 10px 0;
    }
}
.header-nav .nav > li.active > a {
    color: var(--primary);
}
.header-nav .nav > li:hover > a {
    color: var(--primary);
}
.header-nav .nav > li:hover > .mega-menu,
.header-nav .nav > li:hover > .sub-menu {
    opacity: 1;
    visibility: visible;
    margin-top: 0;
    -webkit-transition: all 1s;
    -ms-transition: all 1s;
    transition: all 1s;
}
@media only screen and (max-width: 991px) {
    .header-nav .nav > li:hover > .mega-menu,
    .header-nav .nav > li:hover > .sub-menu {
        -webkit-transition: none;
        -ms-transition: none;
        transition: none;
    }
}
.header-nav .nav > li:hover > .mega-menu li:hover > .sub-menu,
.header-nav .nav > li:hover > .sub-menu li:hover > .sub-menu {
    opacity: 1;
    visibility: visible;
    margin-top: 0;
    top: -1px;
}
.header-nav .nav > li.open .mega-menu,
.header-nav .nav > li.open .sub-menu {
    display: block;
    opacity: 1;
    -webkit-transition: none;
    -ms-transition: none;
    transition: none;
}
.header-nav .nav > li.open .open .mega-menu,
.header-nav .nav > li.open .open .sub-menu {
    display: block;
    opacity: 1;
    -webkit-transition: none;
    -ms-transition: none;
    transition: none;
}
.header-nav .nav > li a.active {
    color: var(--primary);
}
@media only screen and (max-width: 1480px) {
    .header-nav .nav > li:nth-last-child(4) .sub-menu .sub-menu,
    .header-nav .nav > li:nth-last-child(3) .sub-menu .sub-menu,
    .header-nav .nav > li:nth-last-child(2) .sub-menu .sub-menu,
    .header-nav .nav > li:last-child .sub-menu .sub-menu {
        left: -220px;
    }
}
@media only screen and (max-width: 991px) {
    .header-nav .nav {
        float: none;
        padding: 0 20px;
    }
}
.header-nav .sidebar-footer {
    display: none;
}
@media only screen and (max-width: 991px) {
    .header-nav .sidebar-footer {
        display: block;
        padding-top: 30px;
        padding-bottom: 20px;
        margin-top: auto;
        width: 100%;
        text-align: center;
    }
}
.header-nav .sidebar-footer .dz-login-register {
    text-align: center;
    padding-bottom: 10px;
}
.header-nav .dz-social-icon {
    text-align: center;
    display: none;
}
.header-nav .dz-social-icon li {
    display: inline-block;
    margin: 0 2px;
}
.header-nav .dz-social-icon a {
    width: 40px;
    height: 40px;
    padding: 0;
    border: 1px solid rgba(0, 0, 0, 0.1);
    line-height: 38px;
    text-align: center;
    border-radius: var(--border-radius-base);
}
@media only screen and (max-width: 991px) {
    .header-nav .dz-social-icon {
        display: block;
        padding-top: 40px;
        padding-bottom: 20px;
    }
}

.header-transparent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}
.header-transparent .main-bar {
    background: transparent;
    box-shadow: none;
}
.header-transparent .container-fluid,
.header-transparent .container-sm,
.header-transparent .container-md,
.header-transparent .container-lg,
.header-transparent .container-xl {
    padding-left: 80px;
    padding-right: 80px;
}
@media only screen and (max-width: 1280px) {
    .header-transparent .container-fluid,
    .header-transparent .container-sm,
    .header-transparent .container-md,
    .header-transparent .container-lg,
    .header-transparent .container-xl {
        padding-left: 15px;
        padding-right: 15px;
    }
}
.header-transparent .side-menu-btn .menu-icon-in span {
    background-color: #ffffff;
}
.header-transparent .is-fixed .side-menu-btn .menu-icon-in span {
    background-color: #212529;
}
.header-transparent .navbar-toggler span {
    background: #ffffff;
}
.header-transparent .is-fixed .nav > li > a {
    color: #212529;
}
.header-transparent .is-fixed .nav > li > a.active {
    color: var(--primary);
}
.header-transparent .top-bar {
    border-color: #ffffff;
}
.header-transparent .top-bar .dz-topbar-left li,
.header-transparent .top-bar .dz-topbar-left i,
.header-transparent .top-bar .dz-topbar-right li,
.header-transparent .top-bar .dz-topbar-right i {
    color: #ffffff;
}
.header-transparent + .page-content .dz-bnr-inr .dz-bnr-inr-entry {
    padding-top: 85px;
}
@media only screen and (max-width: 575px) {
    .header-transparent + .page-content .dz-bnr-inr .dz-bnr-inr-entry {
        padding-top: 65px;
    }
}
@media only screen and (min-width: 991px) {
    .header-transparent .nav > li > a {
        color: #fff;
    }
}
.header-transparent .logo-header .logo-light {
    display: block;
}
.header-transparent .logo-header .logo-dark {
    display: none;
}
.header-transparent .is-fixed .logo-header .logo-light {
    display: none;
}
.header-transparent .is-fixed .logo-header .logo-dark {
    display: block;
}

.is-fixed .main-bar {
    position: fixed;
    top: 65px;
    left: 0;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);
    background: var(--card-bg);
    transition: all 0.5s;
    padding: 0;
}

.is-fixed .logo-header img {
    padding: 10px 0;
}

@media only screen and (max-width: 991px) {
    .mo-left .header-nav {
        overflow-y: auto;
        position: fixed;
        width: 280px;
        left: -280px;
        height: 100vh !important;
        transition: all 0.5s;
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -o-transition: all 0.5s;
        top: 0;
        background-color: var(--card-bg);
        margin: 0;
        z-index: 99;
    }
    .mo-left .header-nav.nav-dark {
        background-color: #202020;
    }
    .mo-left .header-nav.show {
        left: -1px;
        transition: all 0.8s;
        -webkit-transition: all 0.8s;
        -moz-transition: all 0.8s;
        -o-transition: all 0.8s;
        margin: 0;
        width: 280px;
    }
    .mo-left .header-nav .logo-header {
        display: block;
        float: none;
        height: auto !important;
        max-width: 100%;
        padding: 30px 20px;
    }
    .mo-left .header-nav .logo-header img {
        max-width: unset;
        height: auto;
        vertical-align: middle;
        padding: 0;
        width: 100px !important;
    }
    .mo-left .header-nav li.open a {
        position: relative;
    }
    .mo-left .navbar-toggler.open {
        z-index: 99;
    }
    .mo-left .navbar-toggler.open:after {
        background-color: rgba(0, 0, 0, 0.6);
        content: '';
        left: 0;
        position: fixed;
        right: -20px;
        top: -63px;
        transform: scale(100);
        -o-transform: scale(100);
        -moz-transform: scale(100);
        -webkit-transform: scale(100);
        width: 100%;
        z-index: -1;
        transition: all 0.5s;
        transform-origin: top right;
        margin: 0 0px 0px 20px;
        -webkit-box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.6);
        box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.6);
    }
    .mo-left .navbar-toggler.open span {
        background: #fff;
    }
}

.text-black .nav > li > a {
    color: #212529;
}

.text-black .navbar-toggler span {
    background: #212529;
}

.main-bar {
    -webkit-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
    -khtml-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
    -moz-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
    -ms-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
    -o-transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
    transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) 0s;
}

.is-fixed .main-bar {
    -webkit-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
    -khtml-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
    -moz-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
    -ms-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
    -o-animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
    animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
}

@-moz-keyframes headerSlideDown {
    0% {
        margin-top: -150px;
    }
    100% {
        margin-top: 0;
    }
}

@-ms-keyframes headerSlideDown {
    0% {
        margin-top: -150px;
    }
    100% {
        margin-top: 0;
    }
}

@-webkit-keyframes headerSlideDown {
    0% {
        margin-top: -150px;
    }
    100% {
        margin-top: 0;
    }
}

@keyframes headerSlideDown {
    0% {
        margin-top: -150px;
    }
    100% {
        margin-top: 0;
    }
}

.box-shadow-none .main-bar {
    box-shadow: none;
    padding: 0;
}

.box-shadow-none .header-nav ul li a.active {
    color: var(--primary);
}

.dz-bnr-inr {
    height: 450px;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 2;
    width: 100%;
}
@media only screen and (max-width: 991px) {
    .dz-bnr-inr {
        height: 350px;
    }
}
@media only screen and (max-width: 767px) {
    .dz-bnr-inr {
        height: 280px;
    }
}
@media only screen and (max-width: 575px) {
    .dz-bnr-inr {
        height: 300px;
    }
}
.dz-bnr-inr .container {
    display: table;
    height: 100%;
}
.dz-bnr-inr .dz-bnr-inr-entry {
    height: 450px;
    vertical-align: middle;
    display: table-cell;
}
@media only screen and (max-width: 991px) {
    .dz-bnr-inr .dz-bnr-inr-entry {
        height: 350px;
    }
}
@media only screen and (max-width: 767px) {
    .dz-bnr-inr .dz-bnr-inr-entry {
        height: 280px;
    }
}
@media only screen and (max-width: 575px) {
    .dz-bnr-inr .dz-bnr-inr-entry {
        height: 230px;
    }
}
.dz-bnr-inr h1,
.dz-bnr-inr .h1 {
    font-size: 4.0625rem;
    margin-bottom: 0px;
    line-height: 1.2;
    color: #fff;
    text-transform: capitalize;
    text-shadow: 0px 30px 70px #002622;
}
@media only screen and (max-width: 1191px) {
    .dz-bnr-inr h1,
    .dz-bnr-inr .h1 {
        font-size: 48px;
    }
}
@media only screen and (max-width: 991px) {
    .dz-bnr-inr h1,
    .dz-bnr-inr .h1 {
        font-size: 36px;
    }
}
@media only screen and (max-width: 767px) {
    .dz-bnr-inr h1,
    .dz-bnr-inr .h1 {
        font-size: 30px;
    }
}
@media only screen and (max-width: 575px) {
    .dz-bnr-inr h1,
    .dz-bnr-inr .h1 {
        font-size: 26px;
    }
}
.dz-bnr-inr::after {
    content: '';
    height: 20%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,7db9e8+100&0.5+0,0+100 */
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.5) 0%, rgba(125, 185, 232, 0) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5) 0%, rgba(125, 185, 232, 0) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(125, 185, 232, 0) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#80000000', endColorstr='#007db9e8',GradientType=0 );
    /* IE6-9 */
}
.dz-bnr-inr .breadcrumb {
    background: rgba(255, 255, 255, 0.1) !important;
}
.dz-bnr-inr .breadcrumb-row ul {
    margin-bottom: 0;
    box-shadow: 0px 4px 100px rgba(21, 50, 93, 0.2);
}
.dz-bnr-inr .breadcrumb-row ul li:active {
    color: #fff !important;
}

.breadcrumb-row ul {
    background: var(--card-bg);
    padding: 0;
    display: inline-block;
    padding: 10px 22px;
    border-radius: var(--border-radius-base);
}
@media only screen and (max-width: 575px) {
    .breadcrumb-row ul {
        padding: 10px 18px;
    }
}
.breadcrumb-row ul li {
    padding: 0;
    margin-right: 3px;
    display: inline-block;
    font-size: 15px;
    font-weight: 500;
    text-transform: capitalize;
    color: var(--title);
}
.breadcrumb-row ul li.active {
    color: var(--primary);
}
.breadcrumb-row ul li a {
    color: var(--primary);
}
.breadcrumb-row ul li i {
    color: var(--primary);
    margin-right: 10px;
}
@media only screen and (max-width: 575px) {
    .breadcrumb-row ul li {
        font-size: 14px;
    }
}
.breadcrumb-row ul .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.8rem;
}
.breadcrumb-row ul .breadcrumb-item + .breadcrumb-item::before {
    content: '\f101';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    color: var(--primary);
    padding-right: 0.8rem;
}
@media only screen and (max-width: 575px) {
    .breadcrumb-row ul .breadcrumb-item + .breadcrumb-item {
        padding-left: 8px;
    }
    .breadcrumb-row ul .breadcrumb-item + .breadcrumb-item::before {
        font-size: 12px;
        padding-right: 8px;
        margin-top: 2px;
    }
}

.page-title {
    padding: 30px 0;
}
.page-title.dashboard {
    background: transparent;
    margin-left: 75px;
    padding: 20px 0 0px;
}
.page-title .page-title-content p {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0px;
}
.page-title .page-title-content p span {
    font-weight: 500;
    color: var(--primary);
}

.breadcrumb-row ul li.active {
    color: #f2f2f2 !important;
    font-weight: 500;
}

.breadcrumbs {
    display: flex;
    justify-content: flex-end;
}
@media only screen and (max-width: 575px) {
    .breadcrumbs {
        justify-content: flex-start;
    }
}
.breadcrumbs li a {
    color: rgba(151, 151, 159, 0.39);
    display: inline-block;
    margin-left: 15px;
    font-size: 14px;
}
.breadcrumbs li:first-child a {
    margin-left: 0px;
}
.breadcrumbs li.active a {
    color: #fff !important;
}

footer {
    color: #9595b1;
}
footer .footer-logo {
    margin-bottom: 25px;
}
footer .footer-logo img {
    max-width: 220px;
}
footer .footer-title {
    margin: 0;
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 20px;
    position: relative;
    line-height: 1.2;
}
footer .footer-top {
    padding: 70px 0px 30px;
    background-color: var(--secondary);
}
footer .footer-top .widget {
    margin-bottom: 40px;
}
@media only screen and (max-width: 767px) {
    footer .footer-top {
        padding: 50px 0 0;
    }
}
footer .footer-top + .footer-bottom .footer-inner {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}
footer .footer-bottom {
    background-color: var(--secondary);
    font-size: 14px;
}
footer .footer-bottom .footer-inner {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding: 20px 0;
}
footer .footer-bottom p {
    margin-bottom: 0;
}
footer .footer-bottom .copyright-text a {
    color: #02a3fe;
}
@media only screen and (max-width: 991px) {
    footer .footer-bottom .text-start,
    footer .footer-bottom .text-end {
        text-align: center !important;
    }
    footer .footer-bottom .text-end {
        margin-top: 15px;
    }
}
@media only screen and (max-width: 575px) {
    footer .footer-top {
        padding: 45px 0px 0px;
    }
    footer .footer-bottom .text-end {
        margin-top: 10px;
    }
}
footer .widget_services ul li a {
    display: block;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
}
footer .widget_about {
    margin-right: 70px;
}
@media only screen and (max-width: 1191px) {
    footer .widget_about {
        margin-right: 0;
    }
}
footer.footer-dark {
    background: var(--secondary);
    --title: #fff;
}
footer.footer-dark .footer-bottom {
    border-color: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.5);
}
footer.footer-dark .footer-top {
    color: rgba(255, 255, 255, 0.6);
}
footer.footer-dark .footer-category {
    background: var(--primary);
    border-color: rgba(255, 255, 255, 0.1);
}
footer.footer-dark .footer-category .toggle-items ul li a {
    color: rgba(255, 255, 255, 0.85);
}
footer.footer-dark .footer-category .toggle-items ul li a:hover {
    color: var(--secondary);
}
footer .ft-subscribe .form-control,
footer .ft-subscribe .wp-block-categories-dropdown select,
.wp-block-categories-dropdown footer .ft-subscribe select,
footer .ft-subscribe .wp-block-archives-dropdown select,
.wp-block-archives-dropdown footer .ft-subscribe select,
footer .ft-subscribe .comment-respond .comment-form p.comment-form-author input,
.comment-respond .comment-form p.comment-form-author footer .ft-subscribe input,
footer .ft-subscribe .comment-respond .comment-form p.comment-form-email input,
.comment-respond .comment-form p.comment-form-email footer .ft-subscribe input,
footer .ft-subscribe .comment-respond .comment-form p.comment-form-url input,
.comment-respond .comment-form p.comment-form-url footer .ft-subscribe input,
footer .ft-subscribe .comment-respond .comment-form p textarea,
.comment-respond .comment-form p footer .ft-subscribe textarea {
    background: rgba(255, 255, 255, 0.1);
    border: none;
    border-radius: 8px !important;
    color: #fff;
}
footer .ft-subscribe .form-control::placeholder,
footer .ft-subscribe .wp-block-categories-dropdown select::placeholder,
.wp-block-categories-dropdown footer .ft-subscribe select::placeholder,
footer .ft-subscribe .wp-block-archives-dropdown select::placeholder,
.wp-block-archives-dropdown footer .ft-subscribe select::placeholder,
footer .ft-subscribe .comment-respond .comment-form p.comment-form-author input::placeholder,
.comment-respond .comment-form p.comment-form-author footer .ft-subscribe input::placeholder,
footer .ft-subscribe .comment-respond .comment-form p.comment-form-email input::placeholder,
.comment-respond .comment-form p.comment-form-email footer .ft-subscribe input::placeholder,
footer .ft-subscribe .comment-respond .comment-form p.comment-form-url input::placeholder,
.comment-respond .comment-form p.comment-form-url footer .ft-subscribe input::placeholder,
footer .ft-subscribe .comment-respond .comment-form p textarea::placeholder,
.comment-respond .comment-form p footer .ft-subscribe textarea::placeholder {
    color: #fff !important;
}

.list-column ul {
    display: flex;
    flex-wrap: wrap;
}
.list-column ul li {
    flex: 0 0 50%;
    max-width: 50%;
}

.fb-link {
    list-style: none;
    margin: 0;
    padding: 0;
}
.fb-link li {
    display: inline-block;
}
.fb-link li a {
    color: #fff;
    position: relative;
}
.fb-link li a:after {
    content: '';
    background: var(--primary);
    width: 5px;
    height: 5px;
}

.footer-link {
    margin: 0 -15px;
}
.footer-link li {
    display: inline-block;
    font-family: 'Poppins', sans-serif;
    position: relative;
    padding: 0 15px 0 15px;
}
.footer-link li a {
    color: inherit;
}
@media only screen and (max-width: 575px) {
    .footer-link {
        margin: 0 -10px;
    }
    .footer-link li {
        padding: 0 10px;
    }
}

.ft-subscribe {
    position: relative;
}
.ft-subscribe .form-control,
.ft-subscribe .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .ft-subscribe select,
.ft-subscribe .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .ft-subscribe select,
.ft-subscribe .comment-respond .comment-form p.comment-form-author input,
.comment-respond .comment-form p.comment-form-author .ft-subscribe input,
.ft-subscribe .comment-respond .comment-form p.comment-form-email input,
.comment-respond .comment-form p.comment-form-email .ft-subscribe input,
.ft-subscribe .comment-respond .comment-form p.comment-form-url input,
.comment-respond .comment-form p.comment-form-url .ft-subscribe input,
.ft-subscribe .comment-respond .comment-form p textarea,
.comment-respond .comment-form p .ft-subscribe textarea {
    height: 55px;
}
.ft-subscribe .sub-btn {
    height: 45px;
    width: 45px;
    z-index: 1;
    border-radius: 10px;
    background-color: var(--primary);
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 5px;
    right: 5px;
}

.widget_links ul {
    margin-left: -15px;
    margin-right: -15px;
}
.widget_links ul li {
    display: block;
    padding-left: 15px;
    padding-right: 15px;
}
.widget_links ul li a {
    color: #9595b1;
    font-size: 14px;
    padding: 5px 0;
    display: block;
}
.widget_links ul li a:hover {
    color: #02a3fe;
}

.widget-social ul {
    margin-left: -9px;
    margin-right: -9px;
}
.widget-social ul li {
    padding-left: 9px;
    padding-right: 9px;
    display: inline-block;
}
.widget-social ul li a {
    color: #ffffff;
    font-size: 1rem;
    font-weight: 300;
    display: block;
    padding: 6px 0;
}
.widget-social ul li a img {
    margin-right: 12px;
}
@media only screen and (max-width: 575px) {
    .widget-social ul li a {
        font-size: 14px;
    }
    .widget-social ul li a img {
        width: 16px;
    }
}

.alert.alert-sm {
    padding: 5px 15px;
    font-size: 12px;
}

.alert.alert-lg {
    padding: 25px 15px;
    font-size: 16px;
}

.alert.alert-xl {
    padding: 35px 15px;
    font-size: 18px;
}

.alert[class*='alert-'] i {
    margin-right: 8px;
}

.alert[class*='alert-'] ul {
    padding-left: 25px;
    margin-top: 10px;
}

.card {
    border: 0px solid var(--border-color);
    margin-bottom: 30px;
    border-radius: 10px;
    box-shadow: 0px 10px 40px rgba(21, 50, 93, 0.1);
    background: var(--card-bg);
    height: calc(100% - 1.875rem);
}
@media only screen and (max-width: 575px) {
    .card {
        margin-bottom: 20px;
        height: calc(100% - 1.25rem);
    }
}
.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
    background: var(--card-bg);
    padding: 15px 30px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
@media only screen and (max-width: 575px) {
    .card-header {
        padding: 15px 15px;
    }
}
.card-title {
    font-size: 24px;
    margin-bottom: 0px;
}
.card .card-body {
    padding: 25px 30px;
}
@media only screen and (max-width: 575px) {
    .card .card-body {
        padding: 15px 15px;
    }
}
.card .card-footer {
    background-color: transparent;
    border-color: var(--border-color);
    padding: 15px 30px;
}
@media only screen and (max-width: 575px) {
    .card .card-footer {
        padding: 15px 15px;
    }
}
.card .card-footer.has-table {
    padding: 10px 25px;
}
@media only screen and (max-width: 575px) {
    .card .card-footer.has-table {
        padding: 10px 20px;
    }
}

.contact-bx.style-1 .input-group {
    margin-bottom: 30px;
}

.form-label {
    font-size: 14px;
    font-weight: 400;
    color: var(--title);
    margin-bottom: 6px;
    background: transparent;
    border-color: #cfdbed;
}

.form-control,
.wp-block-categories-dropdown select,
.wp-block-archives-dropdown select,
.comment-respond .comment-form p.comment-form-author input,
.comment-respond .comment-form p.comment-form-email input,
.comment-respond .comment-form p.comment-form-url input,
.comment-respond .comment-form p textarea {
    height: 60px;
    border: 1px solid #cfdbed;
    padding: 10px 20px;
    font-size: 15px;
    font-weight: 400;
    color: var(--title);
    transition: all 0.3s ease-in-out;
    background: var(--input-bg);
    border-radius: var(--border-radius-base);
    box-shadow: 0px 7px 15px rgba(61, 70, 83, 0.1);
}
.form-control .select,
.wp-block-categories-dropdown select .select,
.wp-block-archives-dropdown select .select,
.comment-respond .comment-form p.comment-form-author input .select,
.comment-respond .comment-form p.comment-form-email input .select,
.comment-respond .comment-form p.comment-form-url input .select,
.comment-respond .comment-form p textarea .select {
    padding: 12px 0;
}
.form-control span,
.wp-block-categories-dropdown select span,
.wp-block-archives-dropdown select span,
.comment-respond .comment-form p.comment-form-author input span,
.comment-respond .comment-form p.comment-form-email input span,
.comment-respond .comment-form p.comment-form-url input span,
.comment-respond .comment-form p textarea span {
    margin-top: 0;
}
.form-control.md,
.wp-block-categories-dropdown select.md,
.wp-block-archives-dropdown select.md,
.comment-respond .comment-form p.comment-form-author input.md,
.comment-respond .comment-form p.comment-form-email input.md,
.comment-respond .comment-form p.comment-form-url input.md,
.comment-respond .comment-form p textarea.md {
    height: 48px;
    border-radius: 10px;
}
.form-control::-webkit-input-placeholder,
.wp-block-categories-dropdown select::-webkit-input-placeholder,
.wp-block-archives-dropdown select::-webkit-input-placeholder,
.comment-respond .comment-form p.comment-form-author input::-webkit-input-placeholder,
.comment-respond .comment-form p.comment-form-email input::-webkit-input-placeholder,
.comment-respond .comment-form p.comment-form-url input::-webkit-input-placeholder,
.comment-respond .comment-form p textarea::-webkit-input-placeholder {
    color: #4d4a85;
}
.form-control:-ms-input-placeholder,
.wp-block-categories-dropdown select:-ms-input-placeholder,
.wp-block-archives-dropdown select:-ms-input-placeholder,
.comment-respond .comment-form p.comment-form-author input:-ms-input-placeholder,
.comment-respond .comment-form p.comment-form-email input:-ms-input-placeholder,
.comment-respond .comment-form p.comment-form-url input:-ms-input-placeholder,
.comment-respond .comment-form p textarea:-ms-input-placeholder {
    color: #4d4a85;
}
.form-control::placeholder,
.wp-block-categories-dropdown select::placeholder,
.wp-block-archives-dropdown select::placeholder,
.comment-respond .comment-form p.comment-form-author input::placeholder,
.comment-respond .comment-form p.comment-form-email input::placeholder,
.comment-respond .comment-form p.comment-form-url input::placeholder,
.comment-respond .comment-form p textarea::placeholder {
    color: var(--body-color);
}
.form-control:focus,
.wp-block-categories-dropdown select:focus,
.wp-block-archives-dropdown select:focus,
.comment-respond .comment-form p.comment-form-author input:focus,
.comment-respond .comment-form p.comment-form-email input:focus,
.comment-respond .comment-form p.comment-form-url input:focus,
.comment-respond .comment-form p textarea:focus,
.form-control:active,
.wp-block-categories-dropdown select:active,
.wp-block-archives-dropdown select:active,
.comment-respond .comment-form p.comment-form-author input:active,
.comment-respond .comment-form p.comment-form-email input:active,
.comment-respond .comment-form p.comment-form-url input:active,
.comment-respond .comment-form p textarea:active,
.form-control.active,
.wp-block-categories-dropdown select.active,
.wp-block-archives-dropdown select.active,
.comment-respond .comment-form p.comment-form-author input.active,
.comment-respond .comment-form p.comment-form-email input.active,
.comment-respond .comment-form p.comment-form-url input.active,
.comment-respond .comment-form p textarea.active {
    border-color: var(--primary);
}
@media only screen and (max-width: 575px) {
    .form-control,
    .wp-block-categories-dropdown select,
    .wp-block-archives-dropdown select,
    .comment-respond .comment-form p.comment-form-author input,
    .comment-respond .comment-form p.comment-form-email input,
    .comment-respond .comment-form p.comment-form-url input,
    .comment-respond .comment-form p textarea {
        height: 50px;
        font-size: 14px;
    }
}
.form-control.style-1,
.wp-block-categories-dropdown select.style-1,
.wp-block-archives-dropdown select.style-1,
.comment-respond .comment-form p.comment-form-author input.style-1,
.comment-respond .comment-form p.comment-form-email input.style-1,
.comment-respond .comment-form p.comment-form-url input.style-1,
.comment-respond .comment-form p textarea.style-1 {
    border: 1px solid rgba(0, 0, 0, 0.2) !important;
    background: #fff !important;
    border-radius: 8px !important;
    box-shadow: none !important;
}

textarea.form-control,
.comment-respond .comment-form p textarea {
    min-height: auto;
    height: unset;
}

textarea {
    height: 150px;
    resize: none;
}

input:-internal-autofill-selected {
    background: white !important;
    background-image: none !important;
    color: -internal-light-dark-color(black, white) !important;
}

.input-group-text {
    padding: 5px 15px;
    background: #ffffff;
    margin-bottom: 0px !important;
    color: #52525e;
}

.toggle {
    cursor: pointer;
    display: block;
}

.toggle-switch {
    display: inline-block;
    background: #f7f7f7;
    border-radius: 16px;
    width: 45px;
    height: 20px;
    position: relative;
    vertical-align: middle;
    transition: background 0.25s;
}
.toggle-switch:before,
.toggle-switch:after {
    content: '';
}
.toggle-switch:before {
    display: block;
    background: linear-gradient(to bottom, #ffffff 0%, #eee 100%);
    border-radius: 50%;
    width: 17px;
    height: 17px;
    position: absolute;
    top: 1px;
    left: 3px;
    transition: left 0.25s;
}
.toggle:hover .toggle-switch:before {
    background: linear-gradient(to bottom, #ffffff 0%, #ffffff 100%);
}
.toggle-checkbox:checked + .toggle-switch {
    background: var(--primary);
}
.toggle-checkbox:checked + .toggle-switch:before {
    left: 26px;
}

.toggle-checkbox {
    position: absolute;
    visibility: hidden;
}

.toggle-label {
    margin-left: 15px;
    position: relative;
    top: 2px;
    font-size: 16px;
    font-weight: 400;
}

.file-upload-wrapper {
    position: relative;
    width: 100%;
    height: calc(1.5em + 1rem + 2px);
    border: 1px solid #cfdbed;
    border-radius: 5px;
    color: rgba(151, 151, 159, 0.39);
}
.file-upload-wrapper:after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    background: #ffffff;
    padding: 4px 15px;
    display: block;
    width: calc(100% - 40px);
    pointer-events: none;
    z-index: 20;
    height: calc(1.5em + 1rem + 2px);
    line-height: 30px;
    border-radius: 5px 10px 10px 5px;
    font-weight: 400;
    overflow: hidden;
}
.file-upload-wrapper:before {
    content: 'Upload';
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    height: calc(1.5em + 1rem + 2px);
    background: var(--primary);
    color: #ffffff;
    font-weight: 400;
    z-index: 25;
    font-size: 14px;
    line-height: 40px;
    padding: 0 15px;
    text-transform: capitalize;
    pointer-events: none;
    border-radius: 0 5px 5px 0;
}
.file-upload-wrapper:hover:before {
    background: #0183cc;
}
.file-upload-wrapper input {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    height: calc(1.5em + 1rem + 2px);
    margin: 0;
    padding: 0;
    display: block;
    cursor: pointer;
    width: 100%;
}

#ui-datepicker-div {
    display: none;
    background: #fafafa;
    box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.1);
    margin-top: 0.25rem;
    border-radius: 0.5rem;
    padding: 0.5rem;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

.ui-datepicker-calendar thead th {
    padding: 0.25rem 0;
    text-align: center;
    font-size: 0.75rem;
    font-weight: 400;
    color: #52525e;
}

.ui-datepicker-calendar tbody td {
    width: 2.5rem;
    text-align: center;
    padding: 0;
}
.ui-datepicker-calendar tbody td a {
    display: block;
    border-radius: 0.25rem;
    line-height: 2rem;
    transition: 0.3s all;
    color: #52525e;
    font-size: 0.875rem;
    text-decoration: none;
}
.ui-datepicker-calendar tbody td a:hover {
    background-color: var(--primary);
    color: white;
}
.ui-datepicker-calendar tbody td a.ui-state-active {
    background-color: var(--primary);
    color: white;
}

.ui-datepicker-header a.ui-corner-all {
    cursor: pointer;
    position: absolute;
    top: 0;
    width: 2rem;
    height: 2rem;
    margin: 0.5rem;
    border-radius: 0.25rem;
    transition: 0.3s all;
}
.ui-datepicker-header a.ui-corner-all:hover {
    background-color: #9adaff;
    color: #ffffff;
}

.ui-datepicker-header a.ui-datepicker-prev {
    left: 0;
    background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==');
    background-repeat: no-repeat;
    background-size: 0.5rem;
    background-position: 50%;
    transform: rotate(180deg);
}

.ui-datepicker-header a.ui-datepicker-next {
    right: 0;
    background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMyIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDEzIDEzIj48cGF0aCBmaWxsPSIjNDI0NzcwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik03LjI4OCA2LjI5NkwzLjIwMiAyLjIxYS43MS43MSAwIDAgMSAuMDA3LS45OTljLjI4LS4yOC43MjUtLjI4Ljk5OS0uMDA3TDguODAzIDUuOGEuNjk1LjY5NSAwIDAgMSAuMjAyLjQ5Ni42OTUuNjk1IDAgMCAxLS4yMDIuNDk3bC00LjU5NSA0LjU5NWEuNzA0LjcwNCAwIDAgMS0xLS4wMDcuNzEuNzEgMCAwIDEtLjAwNi0uOTk5bDQuMDg2LTQuMDg2eiIvPjwvc3ZnPg==');
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: 50%;
}

.ui-datepicker-header a > span {
    display: none;
}

.ui-datepicker-title {
    text-align: center;
    line-height: 2rem;
    margin-bottom: 0.25rem;
    font-size: 0.875rem;
    font-weight: 500;
    padding-bottom: 0.25rem;
}

.ui-datepicker-week-col {
    color: #52525e;
    font-weight: 400;
    font-size: 0.75rem;
}

label.error {
    color: #eb5757;
    position: absolute;
    bottom: 0;
    margin-bottom: -22px;
    font-size: 12px;
    font-weight: 400;
}

.nice-select {
    display: flex;
    align-items: center;
    border-top-left-radius: 3px !important;
    border-bottom-left-radius: 3px !important;
}
.nice-select ul {
    width: 100% !important;
}

.nice-select .list {
    background: #212529;
}

.nice-select .option:hover,
.nice-select .option.focus,
.nice-select .option.selected.focus {
    background-color: #212529;
    color: var(--primary);
}

.form-select:not([class*='col-']):not([class*='form-control']):not(.input-group-btn),
select:not([class*='col-']):not([class*='form-control']):not(.input-group-btn) {
    width: 100%;
}

.label-title {
    font-weight: 600;
    color: var(--secondary);
    margin-bottom: 5px;
    font-size: 14px;
}

.form-select,
select {
    height: 48px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    padding: 10px 20px;
    font-size: 15px;
    font-weight: 400;
    color: #1e1e26;
    transition: all 0.3s ease-in-out;
    background-color: #fffbf5;
}
.form-select .dropdown-toggle,
select .dropdown-toggle {
    border: 0 !important;
    padding: 0;
    font-weight: normal;
    text-transform: unset;
    outline: none;
}
.form-select .dropdown-toggle:focus,
select .dropdown-toggle:focus {
    outline: 0 !important;
    outline-offset: 0;
}
.form-select .dropdown-toggle:after,
select .dropdown-toggle:after {
    content: none;
}
.form-select.show,
select.show {
    box-shadow: none !important;
    outline: 0px !important;
}
.form-select.show .dropdown-toggle,
select.show .dropdown-toggle {
    box-shadow: none !important;
    outline: 0px !important;
}
.form-select.show.btn-group .dropdown-menu.inner,
select.show.btn-group .dropdown-menu.inner {
    display: block;
}
.form-select .dropdown-menu,
select .dropdown-menu {
    border: 0;
    border-radius: 0;
    box-shadow: none;
    margin-top: -1px;
    padding: 10px 0;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);
    font-size: 14px;
}
.form-select .dropdown-menu ul li,
select .dropdown-menu ul li {
    padding: 0;
}
.form-select .dropdown-menu ul li a,
select .dropdown-menu ul li a {
    display: block;
    padding: 8px 20px;
    clear: both;
    font-weight: 400;
    color: #333;
    white-space: nowrap;
}
.form-select .dropdown-menu ul li a img,
select .dropdown-menu ul li a img {
    border-radius: 100%;
    -webkit-border-radius: 100%;
    margin-right: 5px;
}
.form-select .dropdown-menu ul li a:hover,
select .dropdown-menu ul li a:hover {
    background-color: #f2f2f4;
}
.form-select .dropdown-menu ul li.selected a,
select .dropdown-menu ul li.selected a {
    background-color: var(--primary);
    color: #ffffff;
}
.form-select:focus,
select:focus,
.form-select:active,
select:active,
.form-select.active,
select.active {
    border-color: var(--primary);
}

.form-select.btn-group,
select.btn-group {
    padding: 10px 20px;
    border-width: 1px;
}
.form-select.btn-group.show,
select.btn-group.show {
    box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1) !important;
    border-color: var(--primary);
}
.form-select.btn-group .form-control .dropdown-toggle.btn-default,
select.btn-group .form-control .dropdown-toggle.btn-default,
.form-select.btn-group .wp-block-categories-dropdown select .dropdown-toggle.btn-default,
.wp-block-categories-dropdown .form-select.btn-group select .dropdown-toggle.btn-default,
select.btn-group .wp-block-categories-dropdown select .dropdown-toggle.btn-default,
.wp-block-categories-dropdown select.btn-group select .dropdown-toggle.btn-default,
.form-select.btn-group .wp-block-archives-dropdown select .dropdown-toggle.btn-default,
.wp-block-archives-dropdown .form-select.btn-group select .dropdown-toggle.btn-default,
select.btn-group .wp-block-archives-dropdown select .dropdown-toggle.btn-default,
.wp-block-archives-dropdown select.btn-group select .dropdown-toggle.btn-default,
.form-select.btn-group .comment-respond .comment-form p.comment-form-author input .dropdown-toggle.btn-default,
.comment-respond .comment-form p.comment-form-author .form-select.btn-group input .dropdown-toggle.btn-default,
select.btn-group .comment-respond .comment-form p.comment-form-author input .dropdown-toggle.btn-default,
.comment-respond .comment-form p.comment-form-author select.btn-group input .dropdown-toggle.btn-default,
.form-select.btn-group .comment-respond .comment-form p.comment-form-email input .dropdown-toggle.btn-default,
.comment-respond .comment-form p.comment-form-email .form-select.btn-group input .dropdown-toggle.btn-default,
select.btn-group .comment-respond .comment-form p.comment-form-email input .dropdown-toggle.btn-default,
.comment-respond .comment-form p.comment-form-email select.btn-group input .dropdown-toggle.btn-default,
.form-select.btn-group .comment-respond .comment-form p.comment-form-url input .dropdown-toggle.btn-default,
.comment-respond .comment-form p.comment-form-url .form-select.btn-group input .dropdown-toggle.btn-default,
select.btn-group .comment-respond .comment-form p.comment-form-url input .dropdown-toggle.btn-default,
.comment-respond .comment-form p.comment-form-url select.btn-group input .dropdown-toggle.btn-default,
.form-select.btn-group .comment-respond .comment-form p textarea .dropdown-toggle.btn-default,
.comment-respond .comment-form p .form-select.btn-group textarea .dropdown-toggle.btn-default,
select.btn-group .comment-respond .comment-form p textarea .dropdown-toggle.btn-default,
.comment-respond .comment-form p select.btn-group textarea .dropdown-toggle.btn-default {
    padding: 0;
}
.form-select.btn-group .dropdown-toggle .caret,
select.btn-group .dropdown-toggle .caret {
    font-size: 10px;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
}
.form-select.btn-group .dropdown-toggle .caret:before,
select.btn-group .dropdown-toggle .caret:before {
    content: '\f078';
}

.custom-file-label {
    box-shadow: 0 0px 10px 4px rgba(31, 66, 135, 0.1);
    height: 45px;
    border-radius: 30px !important;
    padding: 10px 20px;
    font-size: 15px;
    border-color: transparent;
}
.custom-file-label:after {
    content: none !important;
}

.dz-form.radius-no .form-control,
.dz-form.radius-no .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .dz-form.radius-no select,
.dz-form.radius-no .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .dz-form.radius-no select,
.dz-form.radius-no .comment-respond .comment-form p.comment-form-author input,
.comment-respond .comment-form p.comment-form-author .dz-form.radius-no input,
.dz-form.radius-no .comment-respond .comment-form p.comment-form-email input,
.comment-respond .comment-form p.comment-form-email .dz-form.radius-no input,
.dz-form.radius-no .comment-respond .comment-form p.comment-form-url input,
.comment-respond .comment-form p.comment-form-url .dz-form.radius-no input,
.dz-form.radius-no .comment-respond .comment-form p textarea,
.comment-respond .comment-form p .dz-form.radius-no textarea {
    border-radius: 0;
}

.dz-form.style-1 textarea {
    height: 185px;
    padding: 15px ​20px;
}

.dz-form input::placeholder {
    color: #8ea5c8 !important;
}

.dz-form textarea::placeholder {
    color: #8ea5c8 !important;
}

.form-check-input {
    width: 1.125em;
    height: 1.125em;
    border: 2px solid var(--primary);
}

.form-check-label {
    font-size: 15px;
    color: var(--title);
}

.input-group {
    margin-bottom: 20px;
}

.wpcf7-form .row {
    --bs-gutter-x: 20px;
}

.checkout-form {
    margin-bottom: 40px;
}
.checkout-form .form-label {
    font-size: 14px;
    font-weight: 600;
    color: var(--primary);
}
.checkout-form .form-control,
.checkout-form .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .checkout-form select,
.checkout-form .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .checkout-form select,
.checkout-form .comment-respond .comment-form p.comment-form-author input,
.comment-respond .comment-form p.comment-form-author .checkout-form input,
.checkout-form .comment-respond .comment-form p.comment-form-email input,
.comment-respond .comment-form p.comment-form-email .checkout-form input,
.checkout-form .comment-respond .comment-form p.comment-form-url input,
.comment-respond .comment-form p.comment-form-url .checkout-form input,
.checkout-form .comment-respond .comment-form p textarea,
.comment-respond .comment-form p .checkout-form textarea {
    background: none;
    border: 1px solid transparent;
    border-bottom: 2px solid #e1e1e1;
    border-radius: 0;
    padding: 10px 0;
}
.checkout-form .form-control::placeholder,
.checkout-form .wp-block-categories-dropdown select::placeholder,
.wp-block-categories-dropdown .checkout-form select::placeholder,
.checkout-form .wp-block-archives-dropdown select::placeholder,
.wp-block-archives-dropdown .checkout-form select::placeholder,
.checkout-form .comment-respond .comment-form p.comment-form-author input::placeholder,
.comment-respond .comment-form p.comment-form-author .checkout-form input::placeholder,
.checkout-form .comment-respond .comment-form p.comment-form-email input::placeholder,
.comment-respond .comment-form p.comment-form-email .checkout-form input::placeholder,
.checkout-form .comment-respond .comment-form p.comment-form-url input::placeholder,
.comment-respond .comment-form p.comment-form-url .checkout-form input::placeholder,
.checkout-form .comment-respond .comment-form p textarea::placeholder,
.comment-respond .comment-form p .checkout-form textarea::placeholder {
    font-weight: 600;
    font-size: 16px;
    color: var(--seconday);
}

.input-group-text.checkout-text {
    background: none;
    border-color: transparent;
    position: relative;
    right: 60px;
}
.input-group-text.checkout-text i {
    font-size: 20px;
    font-weight: 600;
}

.form-control.default-select .btn,
.wp-block-categories-dropdown select.default-select .btn,
.wp-block-archives-dropdown select.default-select .btn,
.comment-respond .comment-form p.comment-form-author input.default-select .btn,
.comment-respond .comment-form p.comment-form-email input.default-select .btn,
.comment-respond .comment-form p.comment-form-url input.default-select .btn,
.comment-respond .comment-form p textarea.default-select .btn {
    background: transparent;
    border-width: 0 0 2px;
    border-radius: 0;
    padding: 15px 10px !important;
    border-color: #e1e1e1;
    padding: 10px 20px;
}
.form-control.default-select .btn:focus,
.wp-block-categories-dropdown select.default-select .btn:focus,
.wp-block-archives-dropdown select.default-select .btn:focus,
.comment-respond .comment-form p.comment-form-author input.default-select .btn:focus,
.comment-respond .comment-form p.comment-form-email input.default-select .btn:focus,
.comment-respond .comment-form p.comment-form-url input.default-select .btn:focus,
.comment-respond .comment-form p textarea.default-select .btn:focus {
    outline: 0 !important;
}

.form-control.default-select .filter-option-inner-inner,
.wp-block-categories-dropdown select.default-select .filter-option-inner-inner,
.wp-block-archives-dropdown select.default-select .filter-option-inner-inner,
.comment-respond .comment-form p.comment-form-author input.default-select .filter-option-inner-inner,
.comment-respond .comment-form p.comment-form-email input.default-select .filter-option-inner-inner,
.comment-respond .comment-form p.comment-form-url input.default-select .filter-option-inner-inner,
.comment-respond .comment-form p textarea.default-select .filter-option-inner-inner {
    font-weight: 600;
    font-size: 16px;
}

.shop-form .form-group {
    margin-bottom: 25px;
}
.shop-form .form-group .bootstrap-select {
    width: 100% !important;
}
.shop-form .form-group .bootstrap-select .btn {
    font-weight: 300;
    color: #000;
}
.shop-form .form-group .bootstrap-select .btn.btn-light {
    border: 1px solid rgba(0, 0, 0, 0.125);
}
.shop-form .form-group .bootstrap-select .btn.btn-light:hover {
    background-color: whitesmoke;
    border-color: 1px solid rgba(0, 0, 0, 0.125);
}
.shop-form .form-group .bootstrap-select .btn.btn-light:focus {
    outline: 0 !important;
}

@media only screen and (max-width: 991px) {
    .shop-form {
        margin-top: 25px;
    }
}

.login-area {
    border: 1px solid rgba(0, 0, 0, 0.125);
    padding: 30px;
    height: 100%;
    border-radius: 6px;
}

.dz-ajax-overlay {
    position: relative;
}
.dz-ajax-overlay:after {
    content: 'Loading...';
    height: 100%;
    width: 100%;
    background-color: #000;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 99;
    border-radius: 5px;
    opacity: 0.8;
    color: #fff;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 2px;
    font-weight: 500;
    font-size: 14px;
    font-style: italic;
}

.custom-radio {
    min-height: auto;
}
.custom-radio .form-check-label {
    font-size: 14px;
    font-weight: 500;
    color: #000;
    margin: 0 0 0 3px;
}
.custom-radio .form-check-input {
    height: 20px;
    width: 20px;
    margin-top: 3px;
}
@media only screen and (max-width: 575px) {
    .custom-radio .form-check-input {
        margin-top: 1px;
    }
}
.custom-radio .form-check-input[type='radio'] {
    width: 20px;
    min-width: 20px;
    height: 20px;
    position: relative;
    border-color: var(--primary);
    border-width: 1px;
    border-style: solid;
}
.custom-radio .form-check-input:checked[type='radio'] {
    background-image: none;
    background-color: white;
    border-color: var(--primary);
}
.custom-radio .form-check-input:checked[type='radio']:after {
    content: '';
    height: 12px;
    width: 12px;
    background-color: var(--primary);
    position: absolute;
    top: 3px;
    left: 3px;
    border-radius: 50%;
}

button:hover,
button:active,
button:focus {
    outline: 0;
}

.btn {
    font-size: 14px;
    padding: 15px 30px;
    line-height: 1.2;
    display: inline-flex;
    border-radius: var(--border-radius-base);
    font-weight: 400;
    position: relative;
    text-transform: capitalize;
    font-family: 'Poppins', sans-serif;
    align-items: center;
    overflow: hidden;
    z-index: 1;
    text-align: center;
    -webkit-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s;
    justify-content: center;
}
.btn.btn-lg,
.btn-group-lg > .btn {
    font-size: 16px;
    padding: 15px 30px;
    line-height: 1.4;
}
@media only screen and (max-width: 991px) {
    .btn.btn-lg,
    .btn-group-lg > .btn {
        font-size: 14px;
        padding: 15px 25px;
    }
}
.btn.btn-sm,
.btn-group-sm > .btn {
    font-size: 13px;
    padding: 8px 18px;
}
.btn.btn-xs {
    font-size: 12px;
    padding: 11px 25px;
}
.btn.btn-primary {
    color: #ffffff;
}
.btn.btn-primary:hover {
    color: #ffffff;
}
.btn.text-lg {
    font-size: 18px;
    padding: 10px 20px;
}
.btn:after {
    content: '';
    position: absolute;
    background-color: inherit;
    height: 100%;
    width: 7px;
    left: -12px;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
}
.btn:hover:after {
    transition: all 0.5s;
    border-right: 7px solid #fff;
    opacity: 0;
    width: 100%;
}

.btn-primary.btn-gradient {
    background: linear-gradient(90deg, #f39200 0%, #f37500 99.31%);
    border: 0;
}
.btn-primary.btn-gradient:hover {
    background-position: right center;
}

.btn-primary.btn-shadow {
    box-shadow: 0px 10px 25px rgba(243, 145, 0, 0.15);
}

.btn.outline-2 {
    border-width: 2px;
}

.btn-success,
.btn-danger,
.btn-warning {
    color: #fff;
}
.btn-success:hover,
.btn-danger:hover,
.btn-warning:hover {
    color: #fff;
}

.btn-outline-success:hover,
.btn-outline-danger:hover,
.btn-outline-warning:hover {
    color: #fff;
}

.btn-success.effect:after,
.btn-info.effect:after,
.btn-danger.effect:after,
.btn-warning.effect:after {
    background: var(--secondary);
}

.btn-primary.btn-shadow,
.btn-outline-primary.btn-shadow {
    box-shadow: 0px 4px 10px var(--rgba-primary-2);
}

.btn-secondary.btn-shadow {
    box-shadow: 0px 4px 10px #bbbbe2;
}

.btn-danger.btn-shadow {
    box-shadow: 0px 4px 10px #fad7d7;
}

.btn-danger.light {
    background-color: rgba(235, 87, 87, 0.1);
    color: #eb5757;
    border-color: transparent;
}
.btn-danger.light:hover {
    background-color: #eb5757;
    color: #ffffff;
    box-shadow: 0px 4px 10px #fad7d7;
}

.btn-rounded {
    border-radius: 30px !important;
}

.btn-link {
    text-transform: uppercase;
    font-family: var(--font-family-base);
    position: relative;
    z-index: 1;
    display: inline-block;
    -webkit-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s;
}

.shadow-primary {
    box-shadow: 0px 19px 29px rgba(220, 181, 145, 0.34);
}

.btn-info {
    color: #fff;
}
.btn-info:hover {
    color: #fff;
}

.btn-white {
    background-color: #fff;
    color: var(--primary);
}
.btn-white:hover {
    color: var(--primary);
}

.btn-secondary {
    background-color: var(--secondary);
    border-color: var(--secondary);
}
.btn-secondary:after {
    background-color: var(--primary);
}

.btn-primary {
    border-color: var(--primary);
    background-color: var(--primary);
}
.btn-primary.disabled,
.btn-primary:disabled,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover {
    border-color: var(--primary-hover);
    background-color: var(--primary-hover);
}
.btn-primary:after {
    background-color: var(--secondary);
}
.btn-primary.light {
    background-color: var(--rgba-primary-1);
    color: var(--primary);
    border-color: var(--rgba-primary-1);
}
.btn-primary.light:hover {
    background-color: var(--primary);
    color: #ffffff;
}
.btn-primary.light:hover svg path {
    fill: #ffffff;
}

.btn-link {
    color: var(--primary);
    text-decoration: none;
    text-transform: capitalize;
    font-weight: 500;
}
.btn-link:hover {
    color: var(--primary-hover);
}

.btn-outline-primary {
    color: var(--primary);
    border-color: var(--primary);
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:active,
.btn-outline-primary:focus,
.btn-outline-primary:hover {
    border-color: var(--primary-hover);
    background-color: var(--primary-hover);
    color: #ffffff;
}
.btn-outline-primary.text-primary:hover {
    color: #fff !important;
}

.btn-light {
    background-color: #8ea5c8;
    color: #ffffff;
}

.show.dropdown .dropdown-btn.btn-primary svg rect,
.show.dropdown .dropdown-btn.btn-primary svg path {
    fill: #fff;
}

.action-dropdown .dropdown-menu .dropdown-item {
    font-size: 15px;
}

.dropdown-btn {
    width: 36px;
    height: 36px;
    padding: 0;
    border: 0;
}
.dropdown-btn.btn-primary svg rect,
.dropdown-btn.btn-primary svg path {
    fill: var(--primary);
}
.dropdown-btn.btn-primary:hover svg rect,
.dropdown-btn.btn-primary:hover svg path {
    fill: #fff;
}

.video-btn.style-1 {
    display: flex;
    position: relative;
    align-items: center;
}
.video-btn.style-1 i {
    display: inline-block;
    height: 55px;
    width: 55px;
    min-width: 55px;
    border-radius: 55px;
    line-height: 53px;
    background: white;
    text-align: center;
    color: var(--primary);
    font-size: 20px;
    margin-right: 15px;
    border: 1px solid var(--primary);
}
.video-btn.style-1 .text {
    font-weight: 500;
    font-size: 18px;
    padding-right: 30px;
    margin: 0;
}
.video-btn.style-1:before {
    content: '';
    top: 0;
    left: 0;
    position: absolute;
    height: 55px;
    width: 55px;
    border-radius: 55px;
    background: #fff;
    z-index: -1;
    -webkit-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s;
    box-shadow: 1px 1.732px 55px 0px rgba(0, 0, 0, 0.1);
}
.video-btn.style-1:hover .text {
    color: #000;
}
.video-btn.style-1:hover:before {
    width: 100%;
}
.video-btn.style-1.video-btn-dark i {
    border: 1px solid rgba(255, 255, 255, 0.3);
    background: var(--secondary);
}
.video-btn.style-1.video-btn-dark .text {
    color: #fff;
}
.video-btn.style-1.video-btn-dark:before {
    background: var(--primary);
}
@media only screen and (min-width: 991px) {
    .video-btn.style-1.video-btn-dark {
        margin-top: 20px;
    }
}
@media only screen and (max-width: 575px) {
    .video-btn.style-1 {
        margin-top: 20px;
    }
}

.badge {
    padding: 6px;
    font-weight: var(--headings-font-weight);
    background-color: var(--primary);
    color: white;
    border-radius: 4px;
    align-self: center;
    font-family: var(--font-family-title);
    font-size: 11px;
    min-width: 22px;
    height: 22px;
    text-align: center;
}
.badge:hover {
    color: #fff;
}
.badge.badge-primary {
    background-color: var(--primary);
}
.badge.badge-secondary {
    background-color: #090915;
}
.badge.badge-info {
    background-color: #00aeff;
}
.badge.badge-success {
    background-color: #11b011;
}
.badge.badge-warning {
    background-color: #ffbb69;
}
.badge.badge-danger {
    background-color: #eb5757;
}
.badge.badge-light {
    background-color: #fffbf5;
}

.table-responsive-sm {
    min-width: 48rem;
}

.table {
    margin-bottom: 0px;
    font-weight: 400;
}
.table tr td,
.table tr th {
    padding: 10px 20px;
}
@media only screen and (max-width: 575px) {
    .table tr td,
    .table tr th {
        padding: 5px;
    }
}

.table.book-overview tr th {
    color: #1e1e26;
    font-size: 16px;
    font-weight: 500;
    width: 200px;
}

.table-striped thead tr th {
    padding-bottom: 15px !important;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #ffffff;
}

.table-striped tfoot {
    border-top: 2px solid #cfdbed;
}
.table-striped tfoot tr th {
    padding-top: 10px;
}

.table > :not(:first-child) {
    border-top: 1px solid #dee2e6;
}

.check-tbl {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.125);
    vertical-align: middle;
}
.check-tbl thead th {
    font-size: 16px;
    font-weight: 600;
    padding: 15px 15px;
    color: #fff;
    background: var(--secondary);
}
.check-tbl tbody td {
    padding: 15px;
    font-weight: 500;
    color: var(--secondary);
}
.check-tbl tbody td img {
    width: 100px;
    border-radius: 6px;
}
.check-tbl .product-item-close {
    text-align: right;
}
.check-tbl .product-item-close a {
    width: 40px;
    height: 40px;
    border-radius: 6px;
    background: var(--bs-danger);
    display: inline-block;
    color: #fff;
    text-align: center;
    line-height: 40px;
}
.check-tbl .product-item-totle {
    min-width: 180px;
}

.product-item-img {
    width: 100px;
    height: 100px;
}

.table-style-1 {
    border-collapse: separate;
}
.table-style-1 thead th {
    font-size: 15px;
    padding: 10px 25px;
    color: var(--primary);
    font-weight: 500;
    white-space: nowrap;
    border: 0;
}
@media only screen and (max-width: 575px) {
    .table-style-1 thead th {
        padding: 10px 20px;
    }
}
.table-style-1 tbody tr {
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
}
.table-style-1 tbody tr:hover {
    background-color: rgba(124, 165, 200, 0.1);
}
.table-style-1 tbody td {
    padding: 10px 25px;
    vertical-align: middle;
    font-size: 13px;
    color: var(--title);
    border: 0;
}
@media only screen and (max-width: 575px) {
    .table-style-1 tbody td {
        padding: 10px 20px;
    }
}
.table-style-1.border-table tbody tr:not(:last-child) td {
    border-bottom: 1px solid rgba(142, 165, 200, 0.3);
}
.table-style-1.data-end-right thead th:last-child,
.table-style-1.data-end-right tbody td:last-child {
    text-align: right;
}

.table-notifications td.data {
    width: 250px;
}
@media only screen and (max-width: 767px) {
    .table-notifications td.data {
        width: 180px;
    }
}

.trade-history-table .date {
    width: 180px;
    min-width: 180px;
}
@media only screen and (max-width: 575px) {
    .trade-history-table .date {
        width: 150px;
        min-width: 150px;
    }
}

.accordion .accordion-item {
    border: 1px solid rgba(142, 165, 200, 0.5);
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 20px;
}
@media only screen and (max-width: 1480px) {
    .accordion .accordion-item {
        margin-bottom: 15px;
    }
}
.accordion .accordion-item:last-child {
    margin-bottom: 0;
}
.accordion .accordion-item .accordion-header .accordion-button {
    padding: 18px 25px;
    color: var(--title);
    font-weight: 500;
    font-size: 18px;
    border-radius: 10px;
    border-color: var(--primary);
    background-color: var(--card-bg);
}
.accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) {
    box-shadow: none;
    background-color: var(--primary);
    color: #fff;
    border-radius: 0;
}
.accordion .accordion-item .accordion-header .accordion-button:not(.collapsed):after {
    color: #fff;
}
.accordion .accordion-item .accordion-header .accordion-button:after {
    background-image: none;
    content: '\f107';
    font-family: 'Font Awesome 6 free';
    font-weight: 900;
    color: var(--primary);
    width: auto;
    height: auto;
}
@media only screen and (max-width: 1480px) {
    .accordion .accordion-item .accordion-header .accordion-button {
        padding: 12px 20px;
    }
}
@media only screen and (max-width: 575px) {
    .accordion .accordion-item .accordion-header .accordion-button {
        font-size: 16px;
    }
}
.accordion .accordion-item .accordion-body {
    background-color: var(--card-bg);
    color: var(--title);
    padding: 18px 25px;
}
@media only screen and (max-width: 1480px) {
    .accordion .accordion-item .accordion-body {
        padding: 12px 20px;
    }
}

.dz-seprator {
    border-bottom: 1px solid #d9d9d9;
    margin: 80px 0;
}
@media only screen and (max-width: 1280px) {
    .dz-seprator {
        margin: 40px 0px 40px 0;
    }
}
@media only screen and (max-width: 991px) {
    .dz-seprator {
        margin: 30px 0px 30px 0;
    }
}
@media only screen and (max-width: 575px) {
    .dz-seprator {
        margin: 30px 0px 30px 0;
    }
}

.pagination {
    margin: 0 -5px;
}
.pagination .page-link {
    height: 45px;
    width: 45px;
    border-radius: var(--border-radius-base) !important;
    padding: 0;
    display: flex;
    align-items: center;
    background-color: #fff;
    justify-content: center;
    color: var(--title);
    font-weight: 500;
    border: 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.pagination .page-link svg path {
    fill: var(--title);
}
.pagination .page-link:hover,
.pagination .page-link:focus,
.pagination .page-link.active {
    background-color: var(--primary);
    color: #ffffff;
}
.pagination .page-link:hover svg path,
.pagination .page-link:focus svg path,
.pagination .page-link.active svg path {
    fill: #ffffff;
}
.pagination .page-item {
    padding: 0 5px;
}
.pagination.text-center {
    justify-content: center;
}

.list-circle li,
.list-angle-double li,
.list-square li,
.list-square-check li,
.list-check-circle li,
.list-check li,
.list-plus-circle li,
.list-arrow-left-circle li,
.list-arrow-right-circle li {
    position: relative;
    padding: 5px 5px 5px 30px;
    font-family: var(--font-family-title);
    font-size: 16px;
}
.list-circle li:before,
.list-angle-double li:before,
.list-square li:before,
.list-square-check li:before,
.list-check-circle li:before,
.list-check li:before,
.list-plus-circle li:before,
.list-arrow-left-circle li:before,
.list-arrow-right-circle li:before {
    position: absolute;
    left: 0;
    top: 8px;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
}

.list-circle.primary li:before,
.list-angle-double.primary li:before,
.list-square.primary li:before,
.list-square-check.primary li:before,
.list-check-circle.primary li:before,
.list-check.primary li:before,
.list-plus-circle.primary li:before,
.list-arrow-left-circle.primary li:before,
.list-arrow-right-circle.primary li:before {
    color: var(--primary);
}

.list-circle.white li:before,
.list-angle-double.white li:before,
.list-square.white li:before,
.list-square-check.white li:before,
.list-check-circle.white li:before,
.list-check.white li:before,
.list-plus-circle.white li:before,
.list-arrow-left-circle.white li:before,
.list-arrow-right-circle.white li:before {
    color: #fff;
}

.list-circle.gray li:before,
.list-angle-double.gray li:before,
.list-square.gray li:before,
.list-square-check.gray li:before,
.list-check-circle.gray li:before,
.list-check.gray li:before,
.list-plus-circle.gray li:before,
.list-arrow-left-circle.gray li:before,
.list-arrow-right-circle.gray li:before {
    color: #cbcbcb;
}

ul.list-circle li {
    padding: 5px 5px 5px 22px;
}
ul.list-circle li:before {
    content: '\f111';
    font-size: 6px;
    top: 15px;
}

ul.list-angle-double li:before {
    content: '\f101';
    font-size: 18px;
}

ul.list-square li:before {
    content: '\f45c';
    top: 50%;
    transform: translateY(-50%);
}

ul.list-check li:before {
    content: '\f00c';
    font-size: 16px;
}

ul.list-check-circle li:before {
    content: '\f058';
    font-size: 18px;
    font-weight: 500;
    top: 5px;
}

ul.list-plus-circle li:before {
    content: '\f055';
    font-size: 18px;
    top: 5px;
}

ul.list-arrow-left-circle li:before,
ul.list-arrow-right-circle li:before {
    font-size: 10px;
    top: 10px;
    height: 20px;
    width: 20px;
    background-color: #888888;
    border-radius: 50px;
    text-align: center;
    line-height: 21px;
}

ul.list-arrow-right-circle li:before {
    content: '\f054';
    padding-left: 1px;
}

ul.list-arrow-left-circle li:before {
    content: '\f053';
    padding-right: 1px;
}

ul.grid-2 {
    display: flex;
    flex-wrap: wrap;
}
ul.grid-2 li {
    width: 50%;
}
@media only screen and (max-width: 575px) {
    ul.grid-2 li {
        width: 100%;
    }
}

.list-square-check li {
    padding: 10px 15px 10px 60px;
    min-height: 45px;
    font-weight: 600;
    color: var(--title);
    margin-bottom: 15px;
}
.list-square-check li:before {
    content: '\f00c';
    top: 50%;
    height: 45px;
    width: 45px;
    line-height: 45px;
    background-color: var(--rgba-primary-1);
    color: #000;
    text-align: center;
    padding: 0 0 0 0;
    -webkit-transition: all 1s;
    -ms-transition: all 1s;
    transition: all 1s;
    transform: translateY(-50%);
}
.list-square-check li:hover:before {
    background-color: var(--primary);
    color: #fff;
}

.list-number-circle li {
    padding: 0px 15px 0px 70px;
    position: relative;
    margin-bottom: 30px;
}
.list-number-circle li:after {
    content: attr(data-text);
    position: absolute;
    left: 0;
    top: 0;
    height: 50px;
    width: 50px;
    line-height: 50px;
    text-align: center;
    border-radius: 50px;
    background: var(--primary);
    color: #fff;
    font-family: var(--font-family-title);
    font-size: 24px;
    font-weight: 600;
}
.list-number-circle li p {
    margin-bottom: 0;
}

.list-square-box li {
    padding: 0px 0 0px 25px;
    position: relative;
    margin-bottom: 30px;
}
.list-square-box li:after {
    content: '';
    position: absolute;
    left: 0;
    top: 10px;
    height: 12px;
    width: 12px;
    background: var(--primary);
}
.list-square-box li p {
    margin-bottom: 0;
    font-size: 17px;
}

.list-check-box li {
    padding: 20px 10px 20px 70px;
    position: relative;
    margin-bottom: 10px;
    color: #fff;
    background: var(--secondary);
    font-size: 24px;
    font-family: var(--font-family-title);
    border-radius: var(--border-radius-base);
    text-transform: uppercase;
    margin-right: 40px;
    -webkit-transition: all 1s;
    -ms-transition: all 1s;
    transition: all 1s;
    font-weight: 500;
}
.list-check-box li:after {
    content: '\f058';
    font-size: 25px;
    font-weight: 500;
    position: absolute;
    left: 30px;
    color: #fff;
    font-family: 'Font Awesome 5 Free';
    top: 50%;
    transform: translateY(-50%);
}
.list-check-box li:hover {
    background: var(--primary);
    margin-right: 0;
}
@media only screen and (max-width: 1200px) {
    .list-check-box li {
        font-size: 20px;
    }
}
@media only screen and (max-width: 575px) {
    .list-check-box li {
        font-size: 18px;
        padding: 20px 10px 20px 55px;
    }
    .list-check-box li:after {
        left: 20px;
    }
}

/* Light Gallery */
.lg-actions .lg-next,
.lg-actions .lg-prev,
.lg-sub-html,
.lg-toolbar {
    background-color: rgba(30, 30, 30, 0.6);
}

.lg-outer .lg-toogle-thumb,
.lg-outer .lg-thumb-outer,
.lg-outer .lg-img-wrap,
.lg-outer .lg-item {
    background-color: transparent;
}

.lg-backdrop {
    background-color: rgba(30, 30, 30, 0.9);
}

.lg-outer .lg-toogle-thumb,
.lg-actions .lg-next,
.lg-actions .lg-prev,
.lg-toolbar .lg-icon,
#lg-counter {
    color: #ffffff;
}

.lg-outer .lg-thumb-item.active,
.lg-outer .lg-thumb-item:hover {
    border-color: var(--primary);
}

.lightimg {
    cursor: pointer;
}

.progress-bx {
    overflow: hidden;
}
.progress-bx .progress-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}
.progress-bx .progress-info .title {
    margin-bottom: 0;
}
.progress-bx .progress-info .progress-value {
    margin-bottom: 0;
}
.progress-bx .progress {
    overflow: unset;
}
.progress-bx .progress .progress-bar {
    position: relative;
    overflow: unset;
}

.scroltop {
    background: var(--primary);
    border-color: var(--primary);
    border-radius: 50px;
    border-style: solid;
    border-width: 0;
    bottom: 15px;
    color: #fff !important;
    cursor: pointer;
    display: none;
    height: 50px;
    line-height: 50px;
    margin: 0;
    position: fixed;
    right: 15px;
    text-align: center;
    width: 50px;
    z-index: 999;
    box-shadow: -4px 4px 24px -10px;
    transition: all 0.8s;
    -moz-transition: all 0.8s;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    -o-transition: all 0.8s;
}
.scroltop:after {
    border: 1px dashed var(--primary);
    transform: scale(1.2);
    transition: all 0.5s;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    content: '';
    top: 0;
    left: 0;
    padding: 0;
    z-index: -1;
    -webkit-transition: -webkit-transform 0.2s, opacity 0.3s;
    -moz-transition: -moz-transform 0.2s, opacity 0.3s;
    transition: transform 0.2s, opacity 0.3s;
}
.scroltop:hover:after {
    -webkit-animation: spinAround 9s linear infinite;
    -moz-animation: spinAround 9s linear infinite;
    animation: spinAround 9s linear infinite;
}
@media only screen and (max-width: 575px) {
    .scroltop {
        height: 40px;
        width: 40px;
        line-height: 40px;
    }
}

@-webkit-keyframes spinAround {
    from {
        -webkit-transform: rotate(0deg) scale(1.2);
    }
    to {
        -webkit-transform: rotate(360deg) scale(1.2);
    }
}

@-moz-keyframes spinAround {
    from {
        -moz-transform: rotate(0deg) scale(1.2);
    }
    to {
        -moz-transform: rotate(360deg) scale(1.2);
    }
}

@keyframes spinAround {
    from {
        transform: rotate(0deg) scale(1.2);
    }
    to {
        transform: rotate(360deg) scale(1.2);
    }
}

.swiper-btn-center-lr .btn-next,
.swiper-btn-center-lr .btn-prev {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.swiper-btn-center-lr .btn-next.style-1,
.swiper-btn-center-lr .btn-prev.style-1 {
    font-size: 45px;
    color: #c5c5c5;
}
@media only screen and (max-width: 575px) {
    .swiper-btn-center-lr .btn-next.style-1,
    .swiper-btn-center-lr .btn-prev.style-1 {
        font-size: 20px;
    }
}

.swiper-btn-center-lr .btn-prev {
    left: -30px;
}
@media only screen and (max-width: 1280px) {
    .swiper-btn-center-lr .btn-prev {
        left: -0px;
    }
}
@media only screen and (max-width: 575px) {
    .swiper-btn-center-lr .btn-prev {
        left: 0px;
    }
}

.swiper-btn-center-lr .btn-next {
    right: -30px;
}
@media only screen and (max-width: 1280px) {
    .swiper-btn-center-lr .btn-next {
        right: -0px;
    }
}
@media only screen and (max-width: 575px) {
    .swiper-btn-center-lr .btn-next {
        right: 0px;
    }
}

.widget {
    margin-bottom: 50px;
}
@media only screen and (max-width: 767px) {
    .widget {
        margin-bottom: 30px;
    }
}

.widget-title {
    margin-bottom: 25px;
    padding-bottom: 15px;
    position: relative;
}
@media only screen and (max-width: 1199px) {
    .widget-title {
        margin-bottom: 20px;
    }
}
.widget-title:after,
.widget-title:before {
    content: '';
    position: absolute;
    bottom: 0;
    height: 5px;
    background: var(--primary);
}
@media only screen and (max-width: 1480px) {
    .widget-title:after,
    .widget-title:before {
        height: 4px;
    }
}
.widget-title:after {
    left: 0;
    width: 55px;
    border-radius: 0.6rem;
}
.widget-title:before {
    display: none;
    left: 60px;
    width: 7px;
}
.widget-title .title {
    position: relative;
    margin-bottom: 0px;
}
@media only screen and (max-width: 1199px) {
    .widget-title .title {
        font-size: 21px;
    }
}
@media only screen and (max-width: 767px) {
    .widget-title .title {
        font-size: 18px;
    }
}

.search-bx .input-group {
    background: var(--rgba-primary-1);
    border-radius: 0.6rem;
}

.search-bx form {
    margin-bottom: 10px;
}

.search-bx .form-control,
.search-bx .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .search-bx select,
.search-bx .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .search-bx select,
.search-bx .comment-respond .comment-form p.comment-form-author input,
.comment-respond .comment-form p.comment-form-author .search-bx input,
.search-bx .comment-respond .comment-form p.comment-form-email input,
.comment-respond .comment-form p.comment-form-email .search-bx input,
.search-bx .comment-respond .comment-form p.comment-form-url input,
.comment-respond .comment-form p.comment-form-url .search-bx input,
.search-bx .comment-respond .comment-form p textarea,
.comment-respond .comment-form p .search-bx textarea {
    border-radius: 0;
    background-color: transparent;
    border: 0;
    z-index: 0 !important;
    padding: 6px 50px 6px 20px;
}

.search-bx .input-group-btn {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
}

.search-bx button {
    height: 100%;
    width: 60px;
    font-size: 24px;
    padding: 0;
    justify-content: center;
    border-radius: 0 0.6rem 0.6rem 0;
}
.search-bx button i {
    font-size: 12px;
}

.download-file {
    background-image: var(--gradient);
    color: #ffffff;
    padding: 25px 25px 20px 20px;
    border-radius: 4px;
}
.download-file .title {
    color: #ffffff;
    margin-top: 0;
    margin-bottom: 20px;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
}
.download-file ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
.download-file ul li a {
    background-color: #ffffff;
    color: #000;
    display: block;
    margin-bottom: 10px;
    border-radius: 4px;
    width: 100%;
    padding: 15px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    align-items: center;
    display: flex;
    justify-content: space-between;
}
.download-file ul li a i {
    width: 18px;
    height: 18px;
    position: relative;
}
.download-file ul li a i:after,
.download-file ul li a i:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    left: 0;
    top: 0;
}
.download-file ul li a i:before {
    -webkit-animation: download1 2s ease infinite;
    animation: download1 2s ease infinite;
}
.download-file ul li a .text {
    display: inline;
    position: relative;
}
.download-file ul li a:hover .text {
    color: var(--primary);
}

@-webkit-keyframes download1 {
    0%,
    10%,
    20%,
    50%,
    80%,
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    40% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
    }
    60% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
    }
}

@keyframes download1 {
    0%,
    10%,
    20%,
    50%,
    80%,
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    40% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
    }
    60% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
    }
}

.widget_contact {
    position: relative;
    padding: 50px 45px 45px;
    background-size: cover;
    text-align: center;
    position: relative;
    z-index: 1;
    background-color: var(--primary);
    overflow: hidden;
}
.widget_contact:before {
    content: '';
    position: absolute;
    width: 45%;
    height: 65%;
    bottom: 0;
    right: 0;
    z-index: -1;
    background-size: cover;
    background-repeat: no-repeat;
}
.widget_contact .icon-bx i {
    font-size: 100px;
    color: var(--theme-text-color);
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 100px;
    margin-bottom: 15px;
}
.widget_contact img {
    margin-bottom: 20px;
}
.widget_contact h4,
.widget_contact .h4,
.widget_contact .comment-reply-title,
.widget_contact .wp-block-search .wp-block-search__label,
.wp-block-search .widget_contact .wp-block-search__label {
    font-size: 20px;
    font-weight: 600;
    color: var(--theme-text-color);
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    margin-bottom: 10px;
}
.widget_contact .phone-number {
    position: relative;
    font-size: 28px;
    color: var(--theme-text-color);
    font-weight: 600;
    margin-bottom: 5px;
}
.widget_contact .phone-number a {
    color: var(--theme-text-color);
}
.widget_contact .email {
    position: relative;
    color: var(--theme-text-color);
    font-weight: 600;
    margin-bottom: 30px;
}
.widget_contact .email a {
    color: var(--theme-text-color);
}

.service-carouse .item {
    overflow: hidden;
    border-radius: 6px;
}

.list-2 {
    display: table;
}
.list-2 li {
    width: 50% !important;
    float: left;
}
@media only screen and (max-width: 1200px) {
    .list-2 li {
        width: 100% !important;
    }
}
@media only screen and (max-width: 991px) {
    .list-2 li {
        width: 50% !important;
    }
}
@media only screen and (max-width: 575px) {
    .list-2 li {
        width: 100% !important;
    }
}

/* widget listing*/
.wp-block-latest-posts,
.wp-block-categories-list,
.wp-block-archives-list,
.widget_categories ul,
.widget_archive ul,
.widget_meta ul,
.widget_pages ul,
.widget_recent_comments ul,
.widget_nav_menu ul,
.widget_recent_entries ul,
.widget_services ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}
.wp-block-latest-posts li,
.wp-block-categories-list li,
.wp-block-archives-list li,
.widget_categories ul li,
.widget_archive ul li,
.widget_meta ul li,
.widget_pages ul li,
.widget_recent_comments ul li,
.widget_nav_menu ul li,
.widget_recent_entries ul li,
.widget_services ul li {
    padding-bottom: 1.125rem;
    margin-bottom: 0.8125rem;
    position: relative;
    padding: 0.5rem 0rem 0.5rem 0.9375rem;
    margin-bottom: 0;
    line-height: 1.25rem;
}
.wp-block-latest-posts li a,
.wp-block-categories-list li a,
.wp-block-archives-list li a,
.widget_categories ul li a,
.widget_archive ul li a,
.widget_meta ul li a,
.widget_pages ul li a,
.widget_recent_comments ul li a,
.widget_nav_menu ul li a,
.widget_recent_entries ul li a,
.widget_services ul li a {
    color: inherit;
    text-transform: capitalize;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
}
.wp-block-latest-posts li a:before,
.wp-block-categories-list li a:before,
.wp-block-archives-list li a:before,
.widget_categories ul li a:before,
.widget_archive ul li a:before,
.widget_meta ul li a:before,
.widget_pages ul li a:before,
.widget_recent_comments ul li a:before,
.widget_nav_menu ul li a:before,
.widget_recent_entries ul li a:before,
.widget_services ul li a:before {
    content: '\f0da';
    font-family: 'Font Awesome 5 Free';
    width: auto;
    height: auto;
    font-size: 14px;
    font-weight: 700;
    left: 0;
    top: 8px;
    color: var(--primary);
    position: absolute;
    background: transparent;
}
.wp-block-latest-posts li a:hover,
.wp-block-categories-list li a:hover,
.wp-block-archives-list li a:hover,
.widget_categories ul li a:hover,
.widget_archive ul li a:hover,
.widget_meta ul li a:hover,
.widget_pages ul li a:hover,
.widget_recent_comments ul li a:hover,
.widget_nav_menu ul li a:hover,
.widget_recent_entries ul li a:hover,
.widget_services ul li a:hover {
    color: var(--primary);
}
.wp-block-latest-posts li li,
.wp-block-categories-list li li,
.wp-block-archives-list li li,
.widget_categories ul li li,
.widget_archive ul li li,
.widget_meta ul li li,
.widget_pages ul li li,
.widget_recent_comments ul li li,
.widget_nav_menu ul li li,
.widget_recent_entries ul li li,
.widget_services ul li li {
    border-bottom: none;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    line-height: 1.375rem;
}
.wp-block-latest-posts li li:last-child,
.wp-block-categories-list li li:last-child,
.wp-block-archives-list li li:last-child,
.widget_categories ul li li:last-child,
.widget_archive ul li li:last-child,
.widget_meta ul li li:last-child,
.widget_pages ul li li:last-child,
.widget_recent_comments ul li li:last-child,
.widget_nav_menu ul li li:last-child,
.widget_recent_entries ul li li:last-child,
.widget_services ul li li:last-child {
    border-bottom: 0;
    padding-bottom: 0;
}
.wp-block-latest-posts li li:before,
.wp-block-categories-list li li:before,
.wp-block-archives-list li li:before,
.widget_categories ul li li:before,
.widget_archive ul li li:before,
.widget_meta ul li li:before,
.widget_pages ul li li:before,
.widget_recent_comments ul li li:before,
.widget_nav_menu ul li li:before,
.widget_recent_entries ul li li:before,
.widget_services ul li li:before {
    top: 0.5rem;
    left: 0;
}
.wp-block-latest-posts ul,
.wp-block-categories-list ul,
.wp-block-archives-list ul,
.widget_categories ul ul,
.widget_archive ul ul,
.widget_meta ul ul,
.widget_pages ul ul,
.widget_recent_comments ul ul,
.widget_nav_menu ul ul,
.widget_recent_entries ul ul,
.widget_services ul ul {
    padding-left: 1.125rem;
    margin-top: 0.5rem;
    margin-left: -0.9375rem;
}

/*widget categories*/
.widget_archive li ul,
.wp-block-categories-list li ul,
.wp-block-archives-list li ul,
.wp-block-latest-posts li ul,
.widget_categories li ul {
    margin-bottom: -0.625rem;
}

.widget_archive ul li,
.wp-block-categories-list li,
.wp-block-archives-list li,
.wp-block-latest-posts li,
.widget_categories ul li {
    text-align: right;
    display: table;
    width: 100%;
    padding: 0.5rem 0rem 0.5rem 1.2rem !important;
    line-height: 1.3 !important;
}

.wp-block-categories-list li:before,
.wp-block-archives-list li:before,
.wp-block-latest-posts li:before {
    content: none !important;
}

.widget_archive li a,
.wp-block-latest-posts li a,
.wp-block-categories-list li a,
.wp-block-archives-list li a,
.widget_categories li a {
    float: left;
    text-transform: capitalize;
    text-align: left;
}

.wp-block-latest-posts,
.wp-block-categories-list,
.wp-block-archives-list {
    padding: 0 !important;
}

.widget_recent_comments ul {
    margin-top: -0.625rem;
}
.widget_recent_comments ul li {
    padding-left: 1.875rem;
    line-height: 1.625rem;
}
.widget_recent_comments ul li a:hover {
    color: inherit;
}
.widget_recent_comments ul li a:before {
    content: none;
}
.widget_recent_comments ul li:before {
    content: '\f27a';
    font-family: 'Font Awesome 5 Free';
    background: transparent;
    width: auto;
    height: auto;
    position: absolute;
    left: 0;
    top: 0.625rem;
    font-size: 1.125rem;
    font-weight: 500;
}

.widget_meta ul li a abbr[title] {
    color: #333;
    border-bottom: none;
}

.widget_calendar th,
.widget_calendar td {
    text-align: center;
    border: 0.0625rem solid var(--rgba-primary-1);
    padding: 0.4em;
}

.widget_calendar th,
.widget_calendar tfoot td {
    border-color: rgba(0, 0, 0, 0.05);
}
.widget_calendar th:last-child,
.widget_calendar tfoot td:last-child {
    border: 0;
}

.widget_calendar tr {
    border-bottom: 0.0625rem solid var(--rgba-primary-1);
}
.widget_calendar tr:nth-child(2n + 2) {
    background-color: var(--rgba-primary-05);
}

.widget_calendar table {
    border-collapse: collapse;
    margin: 0;
    width: 100%;
}

.widget_calendar .calendar_wrap th,
.widget_calendar tfoot td a {
    color: #fff;
    background: var(--primary);
}

.widget_calendar .calendar_wrap td a {
    font-weight: 600;
    text-transform: uppercase;
    color: inherit;
}

.widget_calendar caption {
    text-align: center;
    font-weight: 600;
    caption-side: top;
    background: var(--primary);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    color: #fff;
    text-transform: uppercase;
    font-size: 0.9375rem;
}

.wp-block-calendar table th {
    font-weight: 400;
    background: var(--primary);
    color: #fff;
}

.wp-block-calendar table caption {
    color: #fff;
}

footer .widget_calendar th,
footer .widget_calendar td {
    border-color: rgba(255, 255, 255, 0.1);
}

footer .widget_calendar tr {
    border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.1);
}
footer .widget_calendar tr:nth-child(2n + 2) {
    background-color: rgba(255, 255, 255, 0.05);
}

.wp-calendar-nav {
    background: var(--primary);
    color: #fff;
    display: flex;
    justify-content: space-between;
    text-align: center;
}
.wp-calendar-nav span {
    padding: 0.3125rem 1.25rem;
    width: 44%;
    border-right: 0.0625rem solid rgba(255, 255, 255, 0.2);
}
.wp-calendar-nav span a {
    color: #fff;
}

.widget_archive select {
    width: 100%;
    padding: 0.3125rem;
    border: 0.0625rem solid #ccc;
}

.list-2-column ul {
    display: table;
}
.list-2-column ul li {
    width: 50%;
    float: left;
}

.widget_categories select,
.widget_archive select {
    width: 100%;
    border: 0.0625rem solid #ccc;
}

.widget_text select {
    width: 100%;
    border: 0.0625rem solid #ccc;
}
.widget_text select option {
    width: 100%;
}

.widget_text p {
    font-size: 0.9375rem;
    line-height: 1.75rem;
}

/*widget rss  */
.rsswidget img {
    display: inherit;
}

.widget-title .rsswidget {
    display: inline-block;
}

ul a.rsswidget,
ul cite {
    font-weight: 600;
    color: var(--title);
}

ul a.rsswidget {
    font-size: 1.125rem;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
}
ul a.rsswidget:hover {
    color: var(--primary);
}

.rssSummary {
    margin: 1.25rem 0;
    line-height: 1.7;
    font-size: 15px;
}

.rss-date {
    display: block;
    margin-top: 0.3125rem;
    font-weight: 400;
}

.widget_rss ul li {
    margin-bottom: 1.25rem;
    padding-bottom: 1.25rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 0;
}
.widget_rss ul li:last-child {
    margin-bottom: 0;
    border-bottom: 0;
    padding-bottom: 0;
}

.widget_gallery ul {
    padding-left: 0;
    display: flex;
    margin-left: -0.3rem;
    margin-right: -0.3rem;
}

.widget_gallery.gallery-grid-2 li {
    width: 50%;
}

.widget_gallery.gallery-grid-4 li {
    width: 25%;
}

.widget_gallery li {
    display: inline-block;
    width: 33.33%;
    float: left;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
    padding: 5px;
}
.widget_gallery li img {
    display: inline-block;
    width: 100%;
}
.widget_gallery li:nth-child(3n-3) {
    margin-right: 0;
}
.widget_gallery li:hover {
    opacity: 0.7;
}
.widget_gallery li a {
    display: inline-block;
}

.widget_tag_cloud .tagcloud {
    clear: both;
    display: table;
}
.widget_tag_cloud .tagcloud a {
    position: relative;
    padding: 8px 15px;
    display: inline-block;
    margin: 0 10px 10px 0;
    font-size: 14px;
    color: var(--primary);
    line-height: 1.4;
    border: 0;
    background: var(--rgba-primary-1);
    border-radius: 0.6rem;
}
@media only screen and (max-width: 1480px) {
    .widget_tag_cloud .tagcloud a {
        padding: 6px 18px;
        margin: 0 10px 10px 0;
    }
}
@media only screen and (max-width: 1199px) {
    .widget_tag_cloud .tagcloud a {
        padding: 6px 14px;
        margin: 0 8px 8px 0;
    }
}
.widget_tag_cloud .tagcloud a:hover {
    box-shadow: 0px 5px 12px var(--rgba-primary-4);
    background-color: var(--primary-hover);
    border-color: var(--primary-hover);
    color: #fff;
}
.widget_tag_cloud .tagcloud a span {
    display: inline-block;
}

.recent-posts-entry .widget-post-bx .widget-post {
    display: flex;
    align-items: center;
    border-radius: 0.375rem;
    margin-bottom: 20px;
    transition: all 0.5s;
}
.recent-posts-entry .widget-post-bx .widget-post .dz-media {
    width: 92px;
    min-width: 92px;
    margin-right: 15px;
    overflow: hidden;
    border-radius: var(--border-radius-base);
}
@media only screen and (max-width: 1199px) {
    .recent-posts-entry .widget-post-bx .widget-post .dz-media {
        width: 75px;
        min-width: 75px;
    }
}
.recent-posts-entry .widget-post-bx .widget-post .dz-meta {
    margin-bottom: 0;
}
.recent-posts-entry .widget-post-bx .widget-post .dz-meta ul {
    line-height: 1;
}
.recent-posts-entry .widget-post-bx .widget-post .dz-meta ul li {
    font-size: 15px;
}
.recent-posts-entry .widget-post-bx .widget-post .title {
    line-height: 1.3;
    font-family: 'Poppins';
    margin-bottom: 8px;
    display: block;
    font-weight: 600;
    font-size: 16px;
}
.recent-posts-entry .widget-post-bx .widget-post .post-date {
    font-size: 14px;
}
.recent-posts-entry .widget-post-bx .widget-post:not(:last-child) {
    margin-bottom: 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.service_menu_nav {
    background: #222222;
    position: relative;
    padding: 40px 45px;
    z-index: 1;
    box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.1);
}
@media only screen and (max-width: 767px) {
    .service_menu_nav {
        padding: 40px;
    }
}
.service_menu_nav:after,
.service_menu_nav:before {
    content: '';
    position: absolute;
    height: 50%;
    z-index: -1;
    bottom: 0;
    right: 0;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
    background-size: cover;
    background-repeat: no-repeat;
}
.service_menu_nav:after {
    width: 17%;
}
.service_menu_nav:before {
    width: 40%;
    opacity: 0.2;
}
.service_menu_nav ul li {
    padding: 0;
}
.service_menu_nav ul li a {
    display: block;
    position: relative;
    padding: 15px 0;
    margin-bottom: 10px;
    color: #fff;
    font-size: 18px;
    z-index: 1;
    font-weight: 500;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
}
@media only screen and (max-width: 767px) {
    .service_menu_nav ul li a {
        padding: 12px 0;
        margin-bottom: 5px;
        font-size: 16px;
    }
}
.service_menu_nav ul li a:before,
.service_menu_nav ul li a:after {
    content: '';
    position: absolute;
    top: 0;
    height: 100%;
    z-index: -1;
    background-color: var(--primary);
    opacity: 0;
    transform: translateX(15px);
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
}
.service_menu_nav ul li a:after {
    width: 100%;
    width: calc(100% + 70px);
    right: 0px;
}
@media only screen and (max-width: 767px) {
    .service_menu_nav ul li a:after {
        width: calc(100% + 50px);
    }
}
.service_menu_nav ul li a:before {
    width: 7px;
    right: -15px;
}
.service_menu_nav ul li:hover a,
.service_menu_nav ul li.current-menu-item a,
.service_menu_nav ul li.active a {
    color: var(--theme-text-color);
}
.service_menu_nav ul li:hover a:after,
.service_menu_nav ul li:hover a:before,
.service_menu_nav ul li.current-menu-item a:after,
.service_menu_nav ul li.current-menu-item a:before,
.service_menu_nav ul li.active a:after,
.service_menu_nav ul li.active a:before {
    transform: translateX(0px);
    opacity: 1;
}
.service_menu_nav svg {
    position: absolute;
    bottom: -23px;
    left: -20px;
    transform: rotateY(180deg);
    height: 50px;
    width: 175px;
}

.wp-block-categories-dropdown,
.wp-block-archives-dropdown {
    margin-bottom: 20px;
}
.wp-block-categories-dropdown select:after,
.wp-block-archives-dropdown select:after {
    content: '\f078';
    font-weight: 900;
    font-family: 'Line Awesome Free';
    position: absolute;
    right: 50px;
    left: auto;
    top: 50%;
}

.post-tags strong {
    font-weight: 700;
    color: #212529;
    margin-right: 10px;
}

.post-tags a {
    margin-right: 10px;
    display: inline-block;
}

.widget_profile .dz-media {
    width: 100%;
    min-width: 100%;
}
.widget_profile .dz-media img {
    border-radius: var(--border-radius-base);
}

.widget_profile p {
    font-size: 16px;
}

.widget-newslatter .form-control,
.widget-newslatter .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .widget-newslatter select,
.widget-newslatter .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .widget-newslatter select,
.widget-newslatter .comment-respond .comment-form p.comment-form-author input,
.comment-respond .comment-form p.comment-form-author .widget-newslatter input,
.widget-newslatter .comment-respond .comment-form p.comment-form-email input,
.comment-respond .comment-form p.comment-form-email .widget-newslatter input,
.widget-newslatter .comment-respond .comment-form p.comment-form-url input,
.comment-respond .comment-form p.comment-form-url .widget-newslatter input,
.widget-newslatter .comment-respond .comment-form p textarea,
.comment-respond .comment-form p .widget-newslatter textarea {
    background-color: #f3f4f6;
    border-radius: var(--border-radius);
    border: 0;
}

.widget-newslatter p {
    font-size: 16px;
}

.widget-newslatter button {
    width: 60px;
    height: 60px;
    padding: 0;
    justify-content: center;
}

.widget-newslatter .input-group {
    margin-bottom: 0;
}

.shop-widget,
.widget.style-1 {
    box-shadow: 0 0 60px 20px rgba(0, 0, 0, 0.1);
    padding: 35px;
    border-bottom: 4px solid;
    border-color: var(--primary);
}
.shop-widget .search-bx,
.widget.style-1 .search-bx {
    margin-bottom: 0;
}
.shop-widget .search-bx .form-control,
.shop-widget .search-bx .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .shop-widget .search-bx select,
.shop-widget .search-bx .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .shop-widget .search-bx select,
.shop-widget .search-bx .comment-respond .comment-form p.comment-form-author input,
.comment-respond .comment-form p.comment-form-author .shop-widget .search-bx input,
.shop-widget .search-bx .comment-respond .comment-form p.comment-form-email input,
.comment-respond .comment-form p.comment-form-email .shop-widget .search-bx input,
.shop-widget .search-bx .comment-respond .comment-form p.comment-form-url input,
.comment-respond .comment-form p.comment-form-url .shop-widget .search-bx input,
.shop-widget .search-bx .comment-respond .comment-form p textarea,
.comment-respond .comment-form p .shop-widget .search-bx textarea,
.widget.style-1 .search-bx .form-control,
.widget.style-1 .search-bx .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .widget.style-1 .search-bx select,
.widget.style-1 .search-bx .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .widget.style-1 .search-bx select,
.widget.style-1 .search-bx .comment-respond .comment-form p.comment-form-author input,
.comment-respond .comment-form p.comment-form-author .widget.style-1 .search-bx input,
.widget.style-1 .search-bx .comment-respond .comment-form p.comment-form-email input,
.comment-respond .comment-form p.comment-form-email .widget.style-1 .search-bx input,
.widget.style-1 .search-bx .comment-respond .comment-form p.comment-form-url input,
.comment-respond .comment-form p.comment-form-url .widget.style-1 .search-bx input,
.widget.style-1 .search-bx .comment-respond .comment-form p textarea,
.comment-respond .comment-form p .widget.style-1 .search-bx textarea {
    background-color: #f3f4f6;
    box-shadow: unset;
}
.shop-widget .search-bx .input-group,
.widget.style-1 .search-bx .input-group {
    margin-bottom: 0;
}
@media only screen and (max-width: 1280px) {
    .shop-widget,
    .widget.style-1 {
        padding: 25px;
    }
}

.side-bar h1,
.side-bar .h1 {
    font-size: 2rem;
}

.side-bar h2,
.side-bar .h2 {
    font-size: 1.625rem;
}

.side-bar h3,
.side-bar .h3 {
    font-size: 1.5rem;
}

.side-bar h4,
.side-bar .h4,
.side-bar .comment-reply-title,
.side-bar .wp-block-search .wp-block-search__label,
.wp-block-search .side-bar .wp-block-search__label {
    font-size: 1.25rem;
}

.side-bar h5,
.side-bar .h5 {
    font-size: 1rem;
}

.sidebar-widget .wp-block-woocommerce-attribute-filter > h1,
.sidebar-widget .wp-block-woocommerce-attribute-filter > .h1,
.sidebar-widget .wp-block-woocommerce-attribute-filter > h2,
.sidebar-widget .wp-block-woocommerce-attribute-filter > .h2,
.sidebar-widget .wp-block-woocommerce-attribute-filter > h3,
.sidebar-widget .wp-block-woocommerce-attribute-filter > .h3,
.sidebar-widget .wp-block-woocommerce-attribute-filter > h4,
.sidebar-widget .wp-block-woocommerce-attribute-filter > .h4,
.sidebar-widget .wp-block-woocommerce-attribute-filter > .comment-reply-title,
.sidebar-widget .wp-block-search .wp-block-woocommerce-attribute-filter > .wp-block-search__label,
.wp-block-search .sidebar-widget .wp-block-woocommerce-attribute-filter > .wp-block-search__label,
.sidebar-widget .wp-block-woocommerce-attribute-filter > h5,
.sidebar-widget .wp-block-woocommerce-attribute-filter > .h5,
.sidebar-widget .wp-block-woocommerce-attribute-filter > h6,
.sidebar-widget .wp-block-woocommerce-attribute-filter > .h6,
.sidebar-widget .wp-block-group__inner-container > h1,
.sidebar-widget .wp-block-group__inner-container > .h1,
.sidebar-widget .wp-block-group__inner-container > h2,
.sidebar-widget .wp-block-group__inner-container > .h2,
.sidebar-widget .wp-block-group__inner-container > h3,
.sidebar-widget .wp-block-group__inner-container > .h3,
.sidebar-widget .wp-block-group__inner-container > h4,
.sidebar-widget .wp-block-group__inner-container > .h4,
.sidebar-widget .wp-block-group__inner-container > .comment-reply-title,
.sidebar-widget .wp-block-search .wp-block-group__inner-container > .wp-block-search__label,
.wp-block-search .sidebar-widget .wp-block-group__inner-container > .wp-block-search__label,
.sidebar-widget .wp-block-group__inner-container > h5,
.sidebar-widget .wp-block-group__inner-container > .h5,
.sidebar-widget .wp-block-group__inner-container > h6,
.sidebar-widget .wp-block-group__inner-container > .h6,
.shop-widget .wp-block-woocommerce-attribute-filter > h1,
.shop-widget .wp-block-woocommerce-attribute-filter > .h1,
.shop-widget .wp-block-woocommerce-attribute-filter > h2,
.shop-widget .wp-block-woocommerce-attribute-filter > .h2,
.shop-widget .wp-block-woocommerce-attribute-filter > h3,
.shop-widget .wp-block-woocommerce-attribute-filter > .h3,
.shop-widget .wp-block-woocommerce-attribute-filter > h4,
.shop-widget .wp-block-woocommerce-attribute-filter > .h4,
.shop-widget .wp-block-woocommerce-attribute-filter > .comment-reply-title,
.shop-widget .wp-block-search .wp-block-woocommerce-attribute-filter > .wp-block-search__label,
.wp-block-search .shop-widget .wp-block-woocommerce-attribute-filter > .wp-block-search__label,
.shop-widget .wp-block-woocommerce-attribute-filter > h5,
.shop-widget .wp-block-woocommerce-attribute-filter > .h5,
.shop-widget .wp-block-woocommerce-attribute-filter > h6,
.shop-widget .wp-block-woocommerce-attribute-filter > .h6,
.shop-widget .wp-block-group__inner-container > h1,
.shop-widget .wp-block-group__inner-container > .h1,
.shop-widget .wp-block-group__inner-container > h2,
.shop-widget .wp-block-group__inner-container > .h2,
.shop-widget .wp-block-group__inner-container > h3,
.shop-widget .wp-block-group__inner-container > .h3,
.shop-widget .wp-block-group__inner-container > h4,
.shop-widget .wp-block-group__inner-container > .h4,
.shop-widget .wp-block-group__inner-container > .comment-reply-title,
.shop-widget .wp-block-search .wp-block-group__inner-container > .wp-block-search__label,
.wp-block-search .shop-widget .wp-block-group__inner-container > .wp-block-search__label,
.shop-widget .wp-block-group__inner-container > h5,
.shop-widget .wp-block-group__inner-container > .h5,
.shop-widget .wp-block-group__inner-container > h6,
.shop-widget .wp-block-group__inner-container > .h6 {
    position: relative;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 10px;
    margin-bottom: 30px;
}

.comment-reply-title,
.wp-block-search .wp-block-search__label {
    color: var(--title);
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 10px;
    margin-bottom: 30px;
}

.wp-block-group:where(.has-background) {
    padding: 1.25em 2.375em;
}

.widget_working ul li {
    display: block;
    margin-bottom: 15px;
}
.widget_working ul li .time {
    font-weight: 600;
    color: var(--primary);
    display: block;
}

.widget_getintuch ul li {
    position: relative;
    padding-left: 45px;
}
@media only screen and (max-width: 1280px) {
    .widget_getintuch ul li {
        padding-left: 35px;
    }
}
@media only screen and (max-width: 1199px) {
    .widget_getintuch ul li {
        margin-bottom: 25px;
        padding-left: 35px;
    }
}
.widget_getintuch ul li span {
    font-size: 20px;
    color: var(--title);
    font-weight: 500;
}
.widget_getintuch ul li h5,
.widget_getintuch ul li .h5 {
    font-size: 20px;
    line-height: 1.33;
    margin-bottom: 5px;
}
.widget_getintuch ul li svg {
    margin-right: 15px;
}
@media only screen and (max-width: 991px) {
    .widget_getintuch ul li p {
        font-size: 14px;
    }
}

.modal .close-btn {
    height: 40px;
    width: 40px;
    background-color: #eb5757;
    color: white;
    text-align: center;
    display: block;
    line-height: 40px;
    border-radius: 10px;
    position: fixed;
    top: 1rem;
    right: 15px;
    left: auto;
    cursor: pointer;
    font-size: 20px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out 0.3s;
}

.modal.show .close-btn {
    opacity: 1;
}

.modal .modal-content {
    border: 0;
    border-radius: 10px;
}

.modal .modal-header {
    padding-bottom: 0;
    padding-left: 40px;
    padding-right: 40px;
    border-bottom: 0;
    padding-top: 30px;
}
@media only screen and (max-width: 575px) {
    .modal .modal-header {
        padding-left: 25px;
        padding-right: 25px;
        padding-top: 20px;
    }
}
.modal .modal-header .modal-title {
    font-size: 18px;
}

.modal .modal-header + .modal-body {
    padding-top: 20px;
}

.modal .modal-footer {
    border-top: 0;
}

.modal .modal-body {
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 30px;
    padding-top: 30px;
}
@media only screen and (max-width: 575px) {
    .modal .modal-body {
        padding-left: 25px;
        padding-right: 25px;
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

.modal-backdrop {
    background-color: #1a2943;
}
.modal-backdrop.show {
    opacity: 0.7;
}

.modal-content {
    background-color: var(--card-bg);
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 412px;
    }
}

.modal-dialog .modal-body .form-group {
    margin-bottom: 20px;
}

.modal-dialog .btn {
    font-size: 18px;
    font-weight: 600;
    padding: 9.5px 20px;
}
@media only screen and (max-width: 575px) {
    .modal-dialog .btn {
        font-size: 16px;
    }
}

.nav-tabs.card-tabs {
    border: 0;
    margin: 0 -5px;
}
.nav-tabs.card-tabs .nav-item {
    flex: 1;
    padding: 0 5px;
}
.nav-tabs.card-tabs .nav-link {
    text-align: center;
    padding: 12px 20px;
    border: 0;
    font-size: 16px;
    text-transform: uppercase;
    border-radius: 10px;
    font-weight: 500;
}
.nav-tabs.card-tabs .nav-link.primary {
    background-color: var(--rgba-primary-1);
    color: var(--primary);
}
.nav-tabs.card-tabs .nav-link.danger {
    background-color: rgba(235, 87, 87, 0.1);
    color: #eb5757;
}
.nav-tabs.card-tabs .nav-link.active.primary {
    background-color: var(--primary);
    color: #ffffff;
    box-shadow: 0px 4px 10px var(--rgba-primary-2);
}
.nav-tabs.card-tabs .nav-link.active.danger {
    background-color: #eb5757;
    box-shadow: 0px 4px 10px #fad7d7;
    color: #ffffff;
}

.nav-tabs.style-1 {
    background-color: var(--rgba-primary-1);
    border-radius: 10px;
    border: 0;
    padding: 5px;
}
.nav-tabs.style-1 .nav-item {
    flex: 1;
}
.nav-tabs.style-1 .nav-link {
    border: 0;
    border-radius: 7px;
    font-size: 13px;
    color: var(--title);
    text-transform: uppercase;
    padding: 5px 12px;
    text-align: center;
    font-weight: 500;
}
@media only screen and (max-width: 1400px) {
    .nav-tabs.style-1 .nav-link {
        font-size: 11px;
    }
}
.nav-tabs.style-1 .nav-link.active {
    color: var(--primary);
    background-color: var(--card-bg);
}

.nav-tabs.border-tabs {
    border-bottom: 1px solid var(--border-color);
}
.nav-tabs.border-tabs .nav-link {
    border: 0;
    font-size: 15px;
    color: var(--body-color);
    margin-bottom: 0;
    padding: 15px 25px;
    position: relative;
    background-color: transparent;
    text-align: center;
    font-weight: 500;
}
.nav-tabs.border-tabs .nav-link:after {
    content: '';
    height: 4px;
    position: absolute;
    bottom: 0;
    width: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--primary);
    -webkit-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s;
}
.nav-tabs.border-tabs .nav-link.active {
    color: var(--primary);
}
.nav-tabs.border-tabs .nav-link.active:after {
    width: 100%;
}
.nav-tabs.border-tabs.wide .nav-item {
    flex: 1;
}
@media only screen and (max-width: 767px) {
    .nav-tabs.border-tabs .nav-link {
        padding: 10px 14px;
    }
}
@media only screen and (max-width: 767px) {
    .nav-tabs.border-tabs .nav-link {
        font-size: 12px;
        padding: 10px 9px;
    }
    .nav-tabs.border-tabs .nav-link::after {
        height: 2px;
    }
}

.nav-tabs.setting-tabs {
    border: 0;
    display: block;
}
.nav-tabs.setting-tabs label {
    font-size: 14px;
    color: var(--primary);
    text-transform: uppercase;
    border-bottom: 1px solid var(--border-color);
    display: block;
    padding: 10px 0;
    font-weight: 500;
    margin-bottom: 5px;
}
.nav-tabs.setting-tabs .nav-item {
    margin-bottom: 15px;
}
.nav-tabs.setting-tabs .nav-link {
    border: 0;
    border-radius: 10px;
    display: flex;
    margin: 0 -5px;
    font-weight: 500;
    color: var(--title);
    font-size: 1rem;
    align-items: center;
    position: relative;
    padding: 10px 10px;
}
.nav-tabs.setting-tabs .nav-link:after {
    content: '\f054';
    position: absolute;
    top: 50%;
    right: 0;
    right: 15px;
    transform: translateY(-50%);
    font-family: 'FontAwesome';
    color: var(--title);
}
.nav-tabs.setting-tabs .nav-link .tb-icon {
    height: 35px;
    width: 35px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    background-color: var(--rgba-primary-1);
}
.nav-tabs.setting-tabs .nav-link .tb-icon svg path {
    fill: var(--primary);
}
.nav-tabs.setting-tabs .nav-link.active {
    background-color: var(--primary);
    color: #ffffff;
    box-shadow: 0px 4px 10px var(--rgba-primary-2);
}
.nav-tabs.setting-tabs .nav-link.active::after {
    color: #ffffff;
}
.nav-tabs.setting-tabs .nav-link.active .tb-icon {
    background-color: #ffffff;
}

.nav-tabs.style-2 {
    border: 0;
}
.nav-tabs.style-2 .nav-item {
    margin: 0 4px;
}
.nav-tabs.style-2 .nav-link {
    border: 0;
    border-radius: 8px;
    font-size: 14px;
    color: var(--title);
    background-color: #f4f6fa;
}
.nav-tabs.style-2 .nav-link.active {
    color: #ffffff;
    background-color: var(--primary);
}
@media only screen and (max-width: 575px) {
    .nav-tabs.style-2 .nav-item {
        margin: 0 3px;
    }
    .nav-tabs.style-2 .nav-link {
        padding: 4px 8px;
        font-size: 12px;
        border-radius: 5px;
    }
}

.nav-tabs.style-3 {
    border: 0;
    margin: 0 -10px;
}
.nav-tabs.style-3 .nav-item {
    padding: 0 10px;
}
.nav-tabs.style-3 .nav-link {
    border: 2px solid var(--primary);
    font-size: 18px;
    border-radius: 10px;
    font-weight: 600;
    color: var(--title);
    margin-bottom: 0;
    padding: 14px 24px;
    box-shadow: 0px 15px 15px 0px #18a5941a;
}
.nav-tabs.style-3 .nav-link.active {
    background-color: var(--primary);
    color: #ffffff;
}
.nav-tabs.style-3.tab-sm .nav-link {
    font-size: 15px;
    padding: 10px 20px;
    border-width: 1px;
    border-radius: 8px;
    box-shadow: none;
}
@media only screen and (max-width: 991px) {
    .nav-tabs.style-3 .nav-link {
        padding: 8px 15px;
        font-size: 15px;
    }
}
@media only screen and (max-width: 767px) {
    .nav-tabs.style-3 {
        margin: 0 -5px;
    }
    .nav-tabs.style-3 .nav-item {
        padding: 0 5px;
    }
    .nav-tabs.style-3 .nav-link {
        padding: 8px 12px;
        font-size: 13px;
        border-radius: 8px;
    }
    .nav-tabs.style-3.tab-sm .nav-link {
        font-size: 13px;
        padding: 6px 15px;
    }
}
@media only screen and (max-width: 575px) {
    .nav-tabs.style-3 .nav-link {
        padding: 8px 8px;
        font-size: 12px;
    }
}

.nav-tabs-card .nav-tabs.border-tabs {
    margin-top: -10px;
}
@media only screen and (max-width: 575px) {
    .nav-tabs-card .nav-tabs.border-tabs {
        margin-left: -15px;
        margin-right: -15px;
    }
}
.nav-tabs-card .nav-tabs.border-tabs .nav-link {
    padding: 25px 20px;
}
@media only screen and (max-width: 1280px) {
    .nav-tabs-card .nav-tabs.border-tabs .nav-link {
        padding: 20px 15px;
    }
}
@media only screen and (max-width: 767px) {
    .nav-tabs-card .nav-tabs.border-tabs .nav-link {
        font-size: 14px;
        padding: 15px 12px;
    }
}
@media only screen and (max-width: 575px) {
    .nav-tabs-card .nav-tabs.border-tabs .nav-link {
        font-size: 13px;
        padding: 12px 10px;
    }
}

.counter-style-1 {
    z-index: 1;
    position: relative;
    padding-top: 50px;
}
.counter-style-1 .counter-num {
    color: #1e1e26;
    font-size: 80px;
    font-weight: 600;
    line-height: 1.4;
}
.counter-style-1 p {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 0;
}
@media only screen and (max-width: 575px) {
    .counter-style-1 {
        font-size: 1.875rem;
    }
    .counter-style-1 p {
        font-size: 14px;
    }
    .counter-style-1 .counter-num {
        font-size: 2.5rem;
    }
}
@media only screen and (max-width: 767px) {
    .counter-style-1 {
        padding-top: 30px;
    }
}

@-webkit-keyframes upToDown {
    49% {
        -webkit-transform: translateY(100%);
    }
    50% {
        opacity: 0;
        -webkit-transform: translateY(-100%);
    }
    51% {
        opacity: 1;
    }
}

@-moz-keyframes upToDown {
    49% {
        -moz-transform: translateY(100%);
    }
    50% {
        opacity: 0;
        -moz-transform: translateY(-100%);
    }
    51% {
        opacity: 1;
    }
}

@keyframes upToDown {
    49% {
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
    }
    50% {
        opacity: 0;
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
    }
    51% {
        opacity: 1;
    }
}

@-webkit-keyframes upToRight {
    49% {
        -webkit-transform: translateX(100%);
    }
    50% {
        opacity: 0;
        -webkit-transform: translateX(-100%);
    }
    51% {
        opacity: 1;
    }
}

@-moz-keyframes upToRight {
    49% {
        -moz-transform: translateX(100%);
    }
    50% {
        opacity: 0;
        -moz-transform: translateX(-100%);
    }
    51% {
        opacity: 1;
    }
}

@keyframes upToRight {
    49% {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
    50% {
        opacity: 0;
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
    51% {
        opacity: 1;
    }
}

@-webkit-keyframes leftToRight {
    49% {
        -webkit-transform: translateX(100%);
    }
    50% {
        opacity: 0;
        -webkit-transform: translateX(-100%);
    }
    51% {
        opacity: 1;
    }
}

@-moz-keyframes leftToRight {
    49% {
        -moz-transform: translateX(100%);
    }
    50% {
        opacity: 0;
        -moz-transform: translateX(-100%);
    }
    51% {
        opacity: 1;
    }
}

@keyframes leftToRight {
    49% {
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
    50% {
        opacity: 0;
        -webkit-transform: translateX(-100%);
        transform: translateX(-100%);
    }
    51% {
        opacity: 1;
    }
}

@keyframes movedelement {
    0% {
        -webkit-transform: translate(0);
        transform: translate(0);
    }
    25% {
        -webkit-transform: translate(10px, 10px);
        transform: translate(10px, 10px);
    }
    50% {
        -webkit-transform: translate(5px, 5px);
        transform: translate(5px, 5px);
    }
    75% {
        -webkit-transform: translate(10px, -5px);
        transform: translate(10px, -5px);
    }
    to {
        -webkit-transform: translate(0);
        transform: translate(0);
    }
}

@keyframes movedelement2 {
    0% {
        -webkit-transform: translate(0);
        transform: translate(0);
    }
    25% {
        -webkit-transform: translate(-10px, -10px);
        transform: translate(-10px, -10px);
    }
    50% {
        -webkit-transform: translate(-5px, -5px);
        transform: translate(-5px, -5px);
    }
    75% {
        -webkit-transform: translate(-10px, 5px);
        transform: translate(-10px, 5px);
    }
    to {
        -webkit-transform: translate(0);
        transform: translate(0);
    }
}

@keyframes movedelement3 {
    0% {
        -webkit-transform: translate(0);
        transform: translate(0);
    }
    25% {
        -webkit-transform: translate(15px, 15px);
        transform: translate(15px, 15px);
    }
    50% {
        -webkit-transform: translate(8px, 8px);
        transform: translate(8px, 8px);
    }
    75% {
        -webkit-transform: translate(15px, -8px);
        transform: translate(15px, -8px);
    }
    to {
        -webkit-transform: translate(0);
        transform: translate(0);
    }
}

@keyframes movedelement4 {
    0% {
        -webkit-transform: translate(0);
        transform: translate(0);
    }
    25% {
        -webkit-transform: translate(-15px, -15px);
        transform: translate(-15px, -15px);
    }
    50% {
        -webkit-transform: translate(-8px, -8px);
        transform: translate(-8px, -8px);
    }
    75% {
        -webkit-transform: translate(-15px, 8px);
        transform: translate(-15px, 8px);
    }
    to {
        -webkit-transform: translate(0);
        transform: translate(0);
    }
}

@keyframes movedelement5 {
    0% {
        -webkit-transform: translate(0px, -50%);
        transform: translate(0px, -50%);
    }
    25% {
        -webkit-transform: translate(10px, calc(-50% + 10px));
        transform: translate(10px, calc(-50% + 10px));
    }
    50% {
        -webkit-transform: translate(5px, calc(-50% + 5px));
        transform: translate(5px, calc(-50% + 5px));
    }
    75% {
        -webkit-transform: translate(10px, calc(-50% + -5px));
        transform: translate(10px, calc(-50% + -5px));
    }
    to {
        -webkit-transform: translate(0px, -50%);
        transform: translate(0px, -50%);
    }
}

@-moz-keyframes spin {
    100% {
        -moz-transform: translate(-50%, -50%) rotate(-45deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: translate(-50%, -50%) rotate(-45deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: translate(-50%, -50%) rotate(-45deg);
        transform: translate(-50%, -50%) rotate(-45deg);
    }
}

@-moz-keyframes spin2 {
    100% {
        -moz-transform: translate(-50%, -50%) rotate(45deg);
    }
}

@-webkit-keyframes spin2 {
    100% {
        -webkit-transform: translate(-50%, -50%) rotate(45deg);
    }
}

@keyframes spin2 {
    100% {
        -webkit-transform: translate(-50%, -50%) rotate(45deg);
        transform: translate(-50%, -50%) rotate(45deg);
    }
}

@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }
    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }
    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }
    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }
    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }
    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }
    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }
    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }
    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

.dz-divider {
    height: 1px;
    position: relative;
    margin: 30px 0;
}
.dz-divider.divider-2px {
    height: 2px;
}
.dz-divider.divider-3px {
    height: 2px;
}
.dz-divider.divider-4px {
    height: 2px;
}
.dz-divider i {
    position: absolute;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    display: block;
    border-radius: 100%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.dz-divider.icon-left {
    margin-left: 40px;
}
.dz-divider.icon-left i {
    left: -40px;
}
.dz-divider.icon-right {
    margin-right: 40px;
}
.dz-divider.icon-right i {
    right: -40px;
}
.dz-divider.icon-center i {
    left: 50%;
    margin-left: -5px;
}

.bg-gray-dark {
    background-color: #d3d3d3;
}

.dropdown {
    cursor: pointer;
}
.dropdown.show .dropdown-toggle {
    background-color: var(--primary);
}
.dropdown.show .dropdown-toggle svg path {
    fill: #fff;
}
.dropdown.profile-dropdown .dropdown-menu {
    margin-top: 15px;
    width: 200px;
}

.dropdown-menu {
    box-shadow: 0px 10px 40px rgba(21, 50, 93, 0.1);
    background-color: var(--card-bg);
    border: 2px solid var(--primary);
    border-radius: 10px;
}
.dropdown-menu .dropdown-item {
    padding: 8px 25px;
    font-size: 16px;
    color: var(--title);
}
@media only screen and (max-width: 991px) {
    .dropdown-menu .dropdown-item {
        padding: 8px 20px;
        font-size: 15px;
    }
}
.dropdown-menu .dropdown-item a {
    color: var(--title);
}
.dropdown-menu .dropdown-item:hover {
    background-color: var(--rgba-primary-1);
}
.dropdown-menu .dropdown-item:hover a {
    color: var(--primary);
}
.dropdown-menu .dropdown-item:active a {
    color: var(--primary);
}
.dropdown-menu .dropdown-item:active svg path {
    color: var(--primary);
}
.dropdown-menu .dropdown-footer {
    padding: 10px 20px 10px;
}
.dropdown-menu .dropdown-header {
    padding: 10px 20px 10px;
}

.dropdown-trade .dropdown-toggle {
    background-color: transparent !important;
    font-size: 20px;
    color: var(--primary) !important;
    font-weight: 500;
    border: 0;
    width: 100%;
    justify-content: space-between;
    border-right: 1px solid var(--border-color);
    border-radius: 0;
    padding: 10px 15px 10px 5px;
}
@media only screen and (max-width: 1191px) {
    .dropdown-trade .dropdown-toggle {
        border-right: 0;
        border-bottom: 1px solid var(--border-color);
        margin-bottom: 20px;
    }
}
@media only screen and (max-width: 575px) {
    .dropdown-trade .dropdown-toggle {
        font-size: 16px;
        padding: 10px 0 10px 0;
    }
}
.dropdown-trade .dropdown-toggle:hover,
.dropdown-trade .dropdown-toggle:focus,
.dropdown-trade .dropdown-toggle:active {
    background-color: transparent !important;
    color: var(--primary);
}
.dropdown-trade .dropdown-toggle::after {
    background-color: transparent;
    border-top: 0.4em solid var(--body-color);
    border-right: 0.4em solid transparent;
    border-bottom: 0;
    border-left: 0.4em solid transparent;
}

.dropdown-trade .dropdown-menu {
    width: 590px;
    padding: 15px;
    border: 0;
    margin-top: 18px;
    left: -30px !important;
}
@media only screen and (max-width: 1191px) {
    .dropdown-trade .dropdown-menu {
        margin-top: 0;
        left: 0 !important;
    }
}
@media only screen and (max-width: 767px) {
    .dropdown-trade .dropdown-menu {
        width: 450px;
    }
}
@media only screen and (max-width: 575px) {
    .dropdown-trade .dropdown-menu {
        width: 330px;
        left: -15px !important;
    }
}

.dropdown-trade .table-style-1 tbody tr td:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.dropdown-trade .table-style-1 tbody tr td:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.dropdown-trade .table-style-1 tbody td {
    padding: 7px 15px;
    font-size: 14px;
}
@media only screen and (max-width: 575px) {
    .dropdown-trade .table-style-1 tbody td {
        padding: 7px 8px;
        font-size: 12px;
    }
}

.dropdown-trade .table-style-1 thead th {
    font-size: 14px;
    padding: 7px 15px;
    color: #ffffff;
    background-color: var(--primary);
}
.dropdown-trade .table-style-1 thead th:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}
.dropdown-trade .table-style-1 thead th:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.notifications-dropdown .notify {
    width: 50px;
    min-width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    background-color: var(--rgba-primary-1);
    border-radius: 10px;
    margin-right: 12px;
}
.notifications-dropdown .notify svg path {
    fill: var(--primary);
}

.notifications-dropdown .dropdown-menu {
    width: 400px;
    margin-top: 15px;
}
.notifications-dropdown .dropdown-menu .dropdown-item {
    padding: 8px 30px;
    white-space: unset;
}
.notifications-dropdown .dropdown-menu .dropdown-item a {
    word-break: break-all;
}
.notifications-dropdown .dropdown-menu .dropdown-footer {
    padding: 10px 30px 20px;
}
.notifications-dropdown .dropdown-menu .dropdown-header {
    padding: 20px 30px 10px;
}

@media only screen and (max-width: 991px) {
    .notifications-dropdown .dropdown-menu {
        width: 320px;
    }
    .notifications-dropdown .dropdown-menu .dropdown-item {
        padding: 8px 15px;
    }
    .notifications-dropdown .dropdown-menu .dropdown-footer,
    .notifications-dropdown .dropdown-menu .dropdown-header {
        padding: 10px 15px;
    }
}

.drop-button .dropdown-toggle.btn {
    background-color: var(--card-bg) !important;
    color: var(--body-color);
    border-color: var(--border-color);
    padding: 12px 10px;
    box-shadow: 0px 7px 15px 0px rgba(61, 70, 83, 0.1);
}
@media only screen and (max-width: 575px) {
    .drop-button .dropdown-toggle.btn {
        padding: 6px 8px;
    }
}
.drop-button .dropdown-toggle.btn i {
    margin-left: 20px;
    color: var(--title);
}
.drop-button .dropdown-toggle.btn:after {
    content: none;
}

.custom-tooltip {
    display: inline-block;
    position: relative;
}
.custom-tooltip .tooltip-content {
    position: absolute;
    width: 230px;
    background-color: #fff;
    border-radius: var(--border-radius-base);
    border: 1px solid var(--border-color);
    padding: 15px;
    font-size: 12px;
    margin-bottom: 0;
    right: 0;
    z-index: 2;
    box-shadow: 0px 7px 15px rgba(61, 70, 83, 0.1);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s;
}
.custom-tooltip .tooltip-content p {
    margin-bottom: 0;
}
.custom-tooltip:hover .tooltip-content {
    opacity: 1;
    visibility: visible;
}

.icon-bx-xl {
    display: inline-block;
    text-align: center;
    border-radius: 3px;
    width: 150px;
    height: 150px;
    line-height: 150px;
}
.icon-bx-xl.radius {
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
}
.icon-bx-xl i {
    font-size: 80px;
    vertical-align: middle;
}
.icon-bx-xl img {
    vertical-align: middle;
    max-width: 100%;
    width: auto;
    height: 80px;
}

.icon-bx-lg {
    display: inline-block;
    text-align: center;
    border-radius: 3px;
    width: 120px;
    height: 120px;
    line-height: 120px;
}
.icon-bx-lg.radius {
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
}
.icon-bx-lg i {
    font-size: 60px;
    vertical-align: middle;
}
.icon-bx-lg img {
    vertical-align: middle;
    max-width: 100%;
    width: auto;
    height: 55px;
}

.icon-bx-md {
    display: inline-block;
    text-align: center;
    border-radius: 3px;
    width: 100px;
    height: 100px;
    line-height: 100px;
}
.icon-bx-md.radius {
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
}
.icon-bx-md i {
    font-size: 45px;
    vertical-align: middle;
}
.icon-bx-md img {
    vertical-align: middle;
    max-width: 100%;
    width: auto;
    height: 40px;
}

.icon-bx-sm {
    display: inline-block;
    text-align: center;
    border-radius: 3px;
    width: 65px;
    height: 65px;
    min-width: 65px;
    line-height: 65px;
}
.icon-bx-sm.radius {
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
}
.icon-bx-sm i {
    font-size: 35px;
    vertical-align: middle;
}
.icon-bx-sm img {
    vertical-align: middle;
    max-width: 100%;
    width: auto;
    height: 35px;
}

.icon-bx-xs {
    display: inline-block;
    text-align: center;
    border-radius: 3px;
    width: 60px;
    height: 60px;
    line-height: 60px;
}
.icon-bx-xs.radius {
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
}
.icon-bx-xs i {
    font-size: 30px;
    vertical-align: middle;
}
.icon-bx-xs img {
    vertical-align: middle;
    max-width: 100%;
    width: auto;
    height: 20px;
}

.icon-xl {
    display: inline-block;
    text-align: center;
    width: 100px;
    line-height: 1;
}
.icon-xl i {
    vertical-align: middle;
    font-size: 80px;
}
.icon-xl img {
    vertical-align: middle;
    max-width: 100%;
    width: auto;
    height: 80px;
}

.icon-lg {
    display: inline-block;
    text-align: center;
    line-height: 1;
}
.icon-lg i {
    vertical-align: middle;
    font-size: 60px;
}
@media only screen and (max-width: 767px) {
    .icon-lg i {
        font-size: 50px;
        line-height: 1.2;
    }
}
.icon-lg img {
    vertical-align: middle;
    max-width: 100%;
    width: auto;
    height: 70px;
}

.icon-md {
    display: inline-block;
    text-align: center;
    line-height: 1;
}
.icon-md i {
    vertical-align: middle;
    font-size: 45px;
}
.icon-md img {
    vertical-align: middle;
    max-width: 100%;
    width: auto;
    height: 50px;
}

.icon-sm {
    display: inline-block;
    text-align: center;
    width: 40px;
    line-height: 1;
}
.icon-sm i {
    vertical-align: middle;
    font-size: 30px;
}
.icon-sm img {
    vertical-align: middle;
    max-width: 100%;
    width: auto;
    height: 30px;
}

.icon-xs {
    display: inline-block;
    text-align: center;
    width: 30px;
    line-height: 1;
}
.icon-xs i {
    vertical-align: middle;
    font-size: 20px;
}
.icon-xs img {
    vertical-align: middle;
    max-width: 100%;
    width: auto;
    height: 20px;
}

.icon-bx-wraper {
    position: relative;
}
.icon-bx-wraper .dz-tilte {
    margin-top: 0;
    font-weight: 600;
}
.icon-bx-wraper .after-titile-line {
    margin-bottom: 10px;
}
.icon-bx-wraper p:last-child {
    margin: 0;
}
.icon-bx-wraper.center {
    text-align: center;
}
.icon-bx-wraper.left .icon-bx-xl,
.icon-bx-wraper.left .icon-bx-lg,
.icon-bx-wraper.left .icon-bx-md,
.icon-bx-wraper.left .icon-bx-sm,
.icon-bx-wraper.left .icon-bx-xs {
    float: left;
    margin-right: 15px;
}
.icon-bx-wraper.left .icon-xl,
.icon-bx-wraper.left .icon-lg,
.icon-bx-wraper.left .icon-md,
.icon-bx-wraper.left .icon-sm,
.icon-bx-wraper.left .icon-xs {
    line-height: 1;
    float: left;
    margin-right: 10px;
}
.icon-bx-wraper.right {
    text-align: right;
}
.icon-bx-wraper.right .icon-bx-xl,
.icon-bx-wraper.right .icon-bx-lg,
.icon-bx-wraper.right .icon-bx-md,
.icon-bx-wraper.right .icon-bx-sm,
.icon-bx-wraper.right .icon-bx-xs {
    float: right;
    margin-left: 20px;
}
.icon-bx-wraper.right .icon-xl,
.icon-bx-wraper.right .icon-lg,
.icon-bx-wraper.right .icon-md,
.icon-bx-wraper.right .icon-sm,
.icon-bx-wraper.right .icon-xs {
    line-height: 1;
    float: right;
    margin-left: 10px;
}
.icon-bx-wraper.bx-style-1,
.icon-bx-wraper.bx-style-2 {
    border-width: 1px;
    border-style: solid;
    border-color: #ebedf2;
}
.icon-bx-wraper.bx-style-2.center [class*='icon-bx-'] {
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}
.icon-bx-wraper.bx-style-2.center .icon-bx-xl {
    margin-left: -75px;
}
.icon-bx-wraper.bx-style-2.center .icon-bx-lg {
    margin-left: -60px;
}
.icon-bx-wraper.bx-style-2.center .icon-bx-md {
    margin-left: -50px;
}
.icon-bx-wraper.bx-style-2.center .icon-bx-sm {
    margin-left: -40px;
}
.icon-bx-wraper.bx-style-2.center .icon-bx-xs {
    margin-left: -20px;
}
.icon-bx-wraper.bx-style-2.left [class*='icon-bx-'] {
    position: absolute;
    top: auto;
    left: 0;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}
.icon-bx-wraper.bx-style-2.right [class*='icon-bx-'] {
    position: absolute;
    top: auto;
    right: 0;
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(50%);
}

.icon-content {
    overflow: hidden;
}

[class*='icon-bx-'][class*='bg-'] a,
[class*='icon-bx-'][class*='bg-'] span {
    color: #ffffff;
}

[class*='icon-bx-'].bg-white a {
    color: inherit;
}

[class*='icon-bx-'][class*='border-'] {
    display: table;
    line-height: normal;
    margin-left: auto;
    margin-right: auto;
}

[class*='icon-bx-'][class*='border-'] .icon-cell {
    display: table-cell;
    vertical-align: middle;
}

.dz-box,
.dz-info,
.dz-tilte,
.dz-tilte-inner {
    position: relative;
}

.dz-tilte-inner {
    display: inline-block;
}

.dz-box[class*='border-'],
.dz-info[class*='border-'] {
    border-color: #eee;
}

.dz-info.border-1,
.dz-info.border-2,
.dz-info.border-3,
.dz-info.border-4,
.dz-info.border-5 {
    border-top: none;
}

.border-1,
.border-2,
.border-3,
.border-4,
.border-5 {
    border-style: solid;
}

.border-1 {
    border-width: 1px;
}

.border-2 {
    border-width: 2px;
}

.border-3 {
    border-width: 3px;
}

.border-4 {
    border-width: 4px;
}

.border-5 {
    border-width: 5px;
}

.left-border,
.right-border {
    position: relative;
}
.left-border:before,
.right-border:before {
    content: '';
    position: absolute;
    top: 5px;
    width: 1px;
    height: 90%;
    background: #ccc;
}

.right-border:before {
    right: 0;
}

.dz-media,
.dz-post-media {
    position: relative;
    overflow: hidden;
}
.dz-media img,
.dz-post-media img {
    width: 100%;
    height: auto;
}

.dz-box-bg {
    overflow: hidden;
    background-size: cover;
}
.dz-box-bg .btn {
    overflow: unset;
}
.dz-box-bg .glyph-icon {
    font-size: 50px;
}
.dz-box-bg .icon-bx-wraper {
    background-color: #f8f8f8;
}
.dz-box-bg .text-primary,
.dz-box-bg .icon-content .dz-tilte,
.dz-box-bg .icon-content p,
.dz-box-bg .icon-box-btn .btn {
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
}
.dz-box-bg.active .icon-bx-wraper {
    background-color: rgba(0, 0, 0, 0.7);
}
.dz-box-bg.active .text-primary,
.dz-box-bg.active .icon-content .dz-tilte,
.dz-box-bg.active .icon-content p,
.dz-box-bg.active .icon-box-btn .btn {
    color: #fff;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
}
.dz-box-bg .icon-box-btn .btn {
    background-color: #eeeeee;
    padding: 15px 20px;
    text-transform: uppercase;
    font-size: 15px;
    border-radius: 0;
    color: #1abc9c;
    font-weight: 600;
    margin-bottom: 0;
}

.icon-bx-wraper.style-1 {
    padding: 35px 40px 30px;
    border-radius: 20px;
    background: linear-gradient(180deg, #fff5eb 0%, rgba(9, 9, 21, 0.05) 0.01%, rgba(9, 9, 21, 0) 100%);
    margin-top: 90px;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
}
.icon-bx-wraper.style-1 p {
    color: #333333;
    margin-bottom: 8px;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
}
.icon-bx-wraper.style-1 .title {
    font-size: 28px;
    margin-bottom: 12px;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
}
.icon-bx-wraper.style-1 .icon-media {
    width: 180px;
    height: 180px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -125px auto 20px;
}
.icon-bx-wraper.style-1 .icon-media img {
    z-index: 1;
    position: relative;
    width: 72px;
}
.icon-bx-wraper.style-1 .icon-media:after {
    content: '';
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center 2px;
    background-image: url(../images/shape/polygon6.svg);
}
.icon-bx-wraper.style-1 .icon-media:before {
    content: '';
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    opacity: 0;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center 2px;
    background-image: url(../images/shape/polygon5.svg);
}
@media only screen and (max-width: 1280px) {
    .icon-bx-wraper.style-1 p br {
        display: none;
    }
}
@media only screen and (max-width: 1480px) {
    .icon-bx-wraper.style-1 {
        margin-top: 70px;
    }
    .icon-bx-wraper.style-1 .title {
        font-size: 24px;
    }
    .icon-bx-wraper.style-1 .icon-media {
        width: 150px;
        height: 150px;
        margin: -110px auto 20px;
    }
    .icon-bx-wraper.style-1 .icon-media img {
        width: 55px;
    }
}
@media only screen and (max-width: 575px) {
    .icon-bx-wraper.style-1 {
        padding: 30px 25px 25px;
        margin-top: 70px;
    }
    .icon-bx-wraper.style-1 .title {
        font-size: 20px;
    }
    .icon-bx-wraper.style-1 .icon-media {
        width: 135px;
        height: 135px;
        margin: -100px auto 15px;
    }
    .icon-bx-wraper.style-1 .icon-media img {
        width: 48px;
    }
}
.icon-bx-wraper.style-1.active .icon-media:after,
.icon-bx-wraper.style-1:hover .icon-media:after {
    opacity: 0;
}
.icon-bx-wraper.style-1.active .icon-media:before,
.icon-bx-wraper.style-1:hover .icon-media:before {
    opacity: 1;
}
.icon-bx-wraper.style-1:hover {
    background: linear-gradient(180deg, #e9f7ff 0%, rgba(233, 247, 255, 0.1) 100%);
}
.icon-bx-wraper.style-1:hover p {
    color: #000;
    opacity: 0.85;
}
.icon-bx-wraper.style-1:hover .title {
    color: #000;
}

.icon-bx-wraper.style-2 {
    padding: 35px 40px 30px;
    border-radius: 10px;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
    background: #1e1e26;
    z-index: 1;
    position: relative;
    overflow: hidden;
}
.icon-bx-wraper.style-2:after {
    content: '';
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
    background-repeat: no-repeat;
    background-position: center 2px;
    background: -moz-linear-gradient(-45deg, rgba(30, 87, 153, 0) 0%, #02a3fe 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, rgba(30, 87, 153, 0) 0%, #02a3fe 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, rgba(30, 87, 153, 0) 0%, #02a3fe 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#001e5799', endColorstr='#02a3fe',GradientType=1 );
    /* IE6-9 fallback on horizontal gradient */
    z-index: -1;
    opacity: 0.2;
}
.icon-bx-wraper.style-2 p {
    color: #d3efff;
    margin-bottom: 8px;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
}
.icon-bx-wraper.style-2 .title {
    color: #ffffff;
    margin-bottom: 12px;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
}
.icon-bx-wraper.style-2.active,
.icon-bx-wraper.style-2:hover {
    background: #02a3fe;
}
.icon-bx-wraper.style-2.active p,
.icon-bx-wraper.style-2:hover p {
    color: #fff;
    opacity: 0.85;
}
.icon-bx-wraper.style-2.active .title,
.icon-bx-wraper.style-2:hover .title {
    color: #fff;
}

@-webkit-keyframes toTopFromBottom {
    49% {
        -webkit-transform: translateY(-100%);
    }
    50% {
        opacity: 0;
        -webkit-transform: translateY(100%);
    }
    51% {
        opacity: 1;
    }
}

@-moz-keyframes toTopFromBottom {
    49% {
        -moz-transform: translateY(-100%);
    }
    50% {
        opacity: 0;
        -moz-transform: translateY(100%);
    }
    51% {
        opacity: 1;
    }
}

@keyframes toTopFromBottom {
    49% {
        transform: translateY(-100%);
    }
    50% {
        opacity: 0;
        transform: translateY(100%);
    }
    51% {
        opacity: 1;
    }
}

.blog-swiper {
    margin: -15px;
    width: auto;
}
.blog-swiper .swiper-slide > div {
    margin: 15px;
    height: 100%;
}

.dz-card {
    position: relative;
}
.dz-card .dz-info {
    padding: 30px;
    position: relative;
}
.dz-card .dz-title {
    margin-bottom: 15px;
    word-break: break-word;
}
@media only screen and (max-width: 575px) {
    .dz-card .dz-title {
        margin-bottom: 15px;
    }
}
.dz-card.blog-half {
    display: flex;
    margin-bottom: 30px;
}
.dz-card.blog-half .dz-info {
    padding: 30px;
    position: relative;
    flex: 1;
}
.dz-card.blog-half .dz-media {
    margin-bottom: 0;
    max-width: 300px;
    min-width: 300px;
}
.dz-card.blog-half .dz-media img {
    height: 100%;
    object-fit: cover;
    min-height: 235px;
}
@media only screen and (max-width: 575px) {
    .dz-card.blog-half .dz-media img {
        min-height: auto;
    }
}
.dz-card.blog-half.post-video .post-video-icon {
    width: 60px;
    height: 60px;
    font-size: 20px;
}
.dz-card.blog-half .swiper-container {
    height: 100%;
}
@media only screen and (max-width: 767px) {
    .dz-card.blog-half {
        display: block;
    }
    .dz-card.blog-half .dz-media {
        max-width: 100%;
        min-width: 100%;
    }
}

.dz-media.height-sm {
    height: 220px;
}
.dz-media.height-sm img {
    height: 100%;
    object-fit: cover;
}

.dz-media.height-md {
    height: 265px;
}
.dz-media.height-md img {
    height: 100%;
    object-fit: cover;
}
@media only screen and (max-width: 1199px) {
    .dz-media.height-md {
        height: 220px;
    }
}
@media only screen and (max-width: 767px) {
    .dz-media.height-md {
        height: 200px;
    }
}

.dz-media.height-lg {
    height: 350px;
}
@media only screen and (max-width: 1199px) {
    .dz-media.height-lg {
        height: 300px;
    }
}
@media only screen and (max-width: 767px) {
    .dz-media.height-lg {
        height: 220px;
    }
    .dz-media.height-lg img {
        height: 100%;
        object-fit: cover;
    }
}

.dz-meta {
    margin-bottom: 10px;
}
.dz-meta ul {
    margin: 0;
    padding: 0;
}
.dz-meta ul li {
    display: inline-block;
    color: #777;
    margin-right: 20px;
}
.dz-meta ul li:last-child {
    margin-right: 0;
}
.dz-meta ul li i {
    margin-right: 5px;
    color: var(--primary);
}
.dz-meta ul li a {
    color: #777;
}

.dz-badge {
    background-color: var(--primary);
    color: #fff;
    border-radius: 3px;
    padding: 6px 15px;
    font-size: 15px;
}
.dz-badge:hover {
    color: white;
}

.post-video .post-video-icon {
    width: 100px;
    height: 100px;
    line-height: 100px;
    border-radius: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    color: var(--primary);
    font-size: 30px;
    margin: 0 auto;
    background-color: #ffffff;
    transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -webkit-transition: all 1s;
    -ms-transition: all 1s;
    transition: all 1s;
    box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.5);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 5px;
}
@media only screen and (max-width: 575px) {
    .post-video .post-video-icon {
        height: 50px;
        width: 50px;
        line-height: 50px;
        font-size: 14px;
    }
}
.post-video .post-video-icon:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    border: 1px solid #fff;
    -webkit-border-radius: 50%;
    -khtml-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border-radius: 100%;
    animation: animationSignal1;
    animation-iteration-count: infinite;
    animation-duration: 3s;
    -webkit-animation: animationSignal1;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-duration: 3s;
    z-index: -1;
    transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    -moz-transition: all 0.5;
    -o-transition: all 0.5;
    -webkit-transition: all 0.5;
    -ms-transition: all 0.5;
    transition: all 0.5;
}
.post-video .post-video-icon:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    border: 1px solid #fff;
    -webkit-border-radius: 100%;
    -khtml-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    border-radius: 100%;
    animation: animationSignal2;
    animation-iteration-count: infinite;
    animation-duration: 3s;
    -webkit-animation: animationSignal2;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-duration: 3s;
    z-index: -1;
    transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    -moz-transition: all 0.5;
    -o-transition: all 0.5;
    -webkit-transition: all 0.5;
    -ms-transition: all 0.5;
    transition: all 0.5;
}

.dz-card.style-1 {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: #fff;
    border-radius: var(--border-radius-base);
    position: relative;
    margin-top: 20px;
    z-index: 0;
}
.dz-card.style-1:after {
    background-color: #1e1e26;
    border-radius: var(--border-radius-base);
    content: '';
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    -webkit-transition: all 1s;
    -ms-transition: all 1s;
    transition: all 1s;
    border: 2px solid var(--dark);
}
.dz-card.style-1 .dz-meta {
    margin-bottom: 20px;
}
.dz-card.style-1 .dz-meta ul li {
    color: #fff;
    position: relative;
    font-size: 15px;
    margin-right: 30px;
}
.dz-card.style-1 .dz-meta ul li:after {
    content: '';
    height: 6px;
    width: 6px;
    border-radius: 6px;
    background-color: #fff;
    right: -20px;
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
}
.dz-card.style-1 .dz-meta ul li:nth-last-child(2),
.dz-card.style-1 .dz-meta ul li:last-child {
    margin-right: 0;
}
.dz-card.style-1 .dz-meta ul li:nth-last-child(2):after,
.dz-card.style-1 .dz-meta ul li:last-child:after {
    content: none;
}
.dz-card.style-1 .dz-meta .post-author img {
    margin-right: 10px;
    width: 35px;
    height: 35px;
}
.dz-card.style-1 .dz-meta .post-date {
    background-color: var(--primary);
    padding: 10px;
    display: inline-block;
    text-align: center;
    border-radius: var(--border-radius-base);
    position: absolute;
    left: 25px;
    top: -15px;
}
.dz-card.style-1 .dz-meta .post-date .day {
    font-size: 45px;
    color: #fff;
    font-weight: 700;
    display: block;
    line-height: 45px;
}
.dz-card.style-1 .dz-meta .post-date .month {
    color: #fff;
    margin-bottom: 0;
    font-size: 14px;
}
@media only screen and (max-width: 991px) {
    .dz-card.style-1 .dz-meta {
        margin-bottom: 10px;
    }
    .dz-card.style-1 .dz-meta .post-author img {
        display: none;
    }
}
@media only screen and (max-width: 575px) {
    .dz-card.style-1 .dz-meta .post-date .day {
        font-size: 35px;
        line-height: 35px;
    }
}
.dz-card.style-1 .dz-info {
    border-radius: var(--border-radius-base);
    padding: 105px 25px 25px;
    z-index: 1;
    -webkit-transition: all 1s;
    -ms-transition: all 1s;
    transition: all 1s;
}
.dz-card.style-1 .dz-info p {
    color: #fff;
    margin-bottom: 25px;
    opacity: 0.7;
}
.dz-card.style-1 .dz-info .dz-title {
    margin-bottom: 10px;
}
.dz-card.style-1 .dz-info .dz-title a {
    color: #fff;
}
@media only screen and (max-width: 575px) {
    .dz-card.style-1 .dz-info {
        padding: 85px 25px 25px;
    }
}
.dz-card.style-1:hover:after {
    opacity: 0.8;
    border-color: var(--primary);
}

.dz-card.style-2 {
    background: white;
    box-shadow: 0px 20px 40px rgba(1, 18, 111, 0.1);
    border-radius: var(--border-radius-base);
}
.dz-card.style-2 .dz-media {
    border-radius: var(--border-radius-base) var(--border-radius-base) 0 0;
    position: relative;
    overflow: hidden;
    transition: 1s;
}
.dz-card.style-2 .dz-media::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    z-index: 2;
    display: block;
    width: 50%;
    height: 100%;
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
    -webkit-transition: all 1s;
    -ms-transition: all 1s;
    transition: all 1s;
}
.dz-card.style-2 .dz-media img {
    border-radius: var(--border-radius-base) var(--border-radius-base) 0 0;
}
.dz-card.style-2:hover .dz-media::before {
    left: 100%;
    right: 0;
}
.dz-card.style-2 .dz-info {
    padding: 30px;
}
@media only screen and (max-width: 575px) {
    .dz-card.style-2 .dz-info {
        padding: 20px;
    }
}
.dz-card.style-2 .dz-info .dz-meta {
    margin-bottom: 10px;
}
.dz-card.style-2 .dz-info .dz-meta ul li {
    display: inline-block;
    position: relative;
    font-size: 15px;
    margin-right: 12px;
    padding-right: 12px;
}
.dz-card.style-2 .dz-info .dz-meta ul li a {
    color: inherit;
}
.dz-card.style-2 .dz-info .dz-meta ul li a img {
    height: 35px;
    width: 35px;
    border-radius: 100%;
    margin-right: 5px;
}
.dz-card.style-2 .dz-info .dz-meta ul li:last-child {
    padding-right: 0;
    margin-right: 0;
}
.dz-card.style-2 .dz-info .dz-meta ul .post-author::after {
    content: '';
    height: 6px;
    width: 6px;
    background-color: var(--primary);
    position: absolute;
    top: 50%;
    right: -5px;
    border-radius: 50%;
    transform: translateY(-50%);
}
.dz-card.style-2 .dz-info .dz-title {
    margin-bottom: 5px;
}
.dz-card.style-2 .dz-info p {
    margin-bottom: 12px;
}
.dz-card.style-2.blog-half {
    overflow: hidden;
    -webkit-transition: all 1s;
    -ms-transition: all 1s;
    transition: all 1s;
    display: flex;
    position: relative;
}
@media only screen and (max-width: 767px) {
    .dz-card.style-2.blog-half {
        display: block;
    }
}
.dz-card.style-2.blog-half .dz-media {
    overflow: hidden;
    position: relative;
    border-radius: unset;
    margin-bottom: 0;
    max-width: 300px;
    min-width: 300px;
}
.dz-card.style-2.blog-half .dz-media img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 0;
}
@media only screen and (max-width: 767px) {
    .dz-card.style-2.blog-half .dz-media {
        max-width: 100%;
        min-width: 100%;
    }
}

.swiper-container .dz-card.style-1 {
    margin: 15px;
}

.main-bnr {
    height: 920px;
    position: relative;
    background-image: url(../images/background/bg.jpg);
    display: flex;
    align-items: center;
    overflow: hidden;
    padding-top: 100px;
    padding-bottom: 30px;
}
.main-bnr .container,
.main-bnr .container-fluid,
.main-bnr .container-sm,
.main-bnr .container-md,
.main-bnr .container-lg,
.main-bnr .container-xl {
    position: relative;
    z-index: 3;
}
.main-bnr h1,
.main-bnr .h1 {
    font-size: 2.5rem;
    color: #fff;
    line-height: 1.4;
    margin-bottom: 10px;
    position: relative;
}
.main-bnr h1 .text-line,
.main-bnr .h1 .text-line {
    position: relative;
}
.main-bnr h1 .text-line:after,
.main-bnr .h1 .text-line:after {
    content: '';
    top: 60px;
    z-index: -1;
    right: 5px;
    height: 34px;
    width: 380px;
    position: absolute;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: left top;
    background-image: url(../images/home-banner/shape2.svg);
}
.main-bnr .text {
    font-size: 24px;
    font-weight: 300;
    margin-bottom: 2rem;
    line-height: 1.7;
}
@media only screen and (max-width: 1191px) {
    .main-bnr .text {
        font-size: 18px;
        margin-bottom: 50px;
    }
}
@media only screen and (max-width: 991px) {
    .main-bnr .text {
        font-size: 15px;
        margin-bottom: 30px;
    }
}
@media only screen and (max-width: 767px) {
    .main-bnr .text {
        max-width: 375px;
    }
}
.main-bnr .shape1 {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 0;
}
@media only screen and (max-width: 1480px) {
    .main-bnr .shape1 {
        width: 200px;
    }
}
@media only screen and (max-width: 1191px) {
    .main-bnr .shape1 {
        width: 150px;
    }
}
@media only screen and (max-width: 575px) {
    .main-bnr .shape1 {
        width: 100px;
    }
}
@media only screen and (max-width: 1280px) {
    .main-bnr {
        height: 820px;
    }
    .main-bnr h1,
    .main-bnr .h1 {
        font-size: 62px;
    }
    .main-bnr h1 .text-line:after,
    .main-bnr .h1 .text-line:after {
        top: 65px;
        width: 335px;
    }
}
@media only screen and (max-width: 1191px) {
    .main-bnr {
        height: 720px;
    }
    .main-bnr h1,
    .main-bnr .h1 {
        font-size: 45px;
    }
    .main-bnr h1 .text-line:after,
    .main-bnr .h1 .text-line:after {
        top: 48px;
        width: 245px;
    }
}
@media only screen and (max-width: 991px) {
    .main-bnr {
        height: 580px;
        padding-top: 70px;
    }
    .main-bnr h1,
    .main-bnr .h1 {
        font-size: 34px;
        margin-bottom: 5px;
    }
    .main-bnr h1 .text-line:after,
    .main-bnr .h1 .text-line:after {
        top: 37px;
        right: 0px;
        width: 190px;
    }
    .main-bnr .video-btn {
        display: none;
    }
}
@media only screen and (max-width: 767px) {
    .main-bnr {
        height: auto;
        padding-top: 135px;
        padding-bottom: 50px;
    }
}
@media only screen and (max-width: 575px) {
    .main-bnr {
        padding-top: 100px;
    }
    .main-bnr h1,
    .main-bnr .h1 {
        font-size: 30px;
    }
    .main-bnr h1 .text-line:after,
    .main-bnr .h1 .text-line:after {
        top: 32px;
        width: 170px;
    }
}

.dz-bnr-inr .shape1 {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 0;
}
@media only screen and (max-width: 1480px) {
    .dz-bnr-inr .shape1 {
        width: 150px;
    }
}
@media only screen and (max-width: 1191px) {
    .dz-bnr-inr .shape1 {
        width: 150px;
    }
}
@media only screen and (max-width: 575px) {
    .dz-bnr-inr .shape1 {
        width: 100px;
    }
}

.banner-media {
    position: relative;
    width: 610px;
    min-width: 610px;
    height: 585px;
    -webkit-animation: aniBnrMedia 5s infinite;
    animation: aniBnrMedia 5s infinite;
}
@media only screen and (max-width: 1480px) {
    .banner-media {
        width: 580px;
        min-width: 580px;
        height: 555px;
    }
}
@media only screen and (max-width: 1380px) {
    .banner-media {
        width: 525px;
        min-width: 525px;
        height: 500px;
        margin-left: -30px;
    }
}
@media only screen and (max-width: 1191px) {
    .banner-media {
        width: 475px;
        min-width: 475px;
        height: 450px;
        margin-left: -10px;
    }
}
@media only screen and (max-width: 991px) {
    .banner-media {
        width: 275px;
        min-width: 275px;
        height: 270px;
        margin-left: 20px;
    }
}
@media only screen and (max-width: 767px) {
    .banner-media {
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;
    }
}
.banner-media .media {
    width: 100%;
    min-width: 100%;
}
.banner-media [class*='shape'] {
    position: absolute;
}
.banner-media .shape1 {
    width: 90px;
    top: 40px;
    right: 15px;
    left: auto;
}
@media only screen and (max-width: 1380px) {
    .banner-media .shape1 {
        width: 50px;
    }
}
@media only screen and (max-width: 991px) {
    .banner-media .shape1 {
        top: 25px;
        width: 35px;
    }
}
.banner-media .shape2 {
    left: 210px;
    top: 0px;
    width: 80px;
    -webkit-animation: dZwobble 4s infinite;
    animation: dZwobble 4s infinite;
}
@media only screen and (max-width: 1380px) {
    .banner-media .shape2 {
        left: 200px;
        width: 50px;
    }
}
@media only screen and (max-width: 991px) {
    .banner-media .shape2 {
        left: 100px;
        width: 25px;
    }
}
.banner-media .shape3 {
    left: 0;
    top: 230px;
    width: 100px;
    -webkit-animation: dZwobble 5s infinite;
    animation: dZwobble 5s infinite;
}
@media only screen and (max-width: 1380px) {
    .banner-media .shape3 {
        width: 50px;
        left: 30px;
    }
}
@media only screen and (max-width: 991px) {
    .banner-media .shape3 {
        width: 25px;
        left: 30px;
        top: 120px;
    }
}
.banner-media .shape4 {
    top: 0;
    left: 360px;
    width: 60px;
    -webkit-animation: aniBnrShape4 5s infinite;
    animation: aniBnrShape4 5s infinite;
}
@media only screen and (max-width: 1380px) {
    .banner-media .shape4 {
        left: 320px;
        width: 40px;
    }
}
@media only screen and (max-width: 991px) {
    .banner-media .shape4 {
        left: 175px;
        width: 20px;
    }
}
.banner-media .shape5 {
    width: 35px;
    top: 180px;
    right: 0;
    -webkit-animation: aniBnrShape5 3s infinite linear;
    animation: aniBnrShape5 3s infinite linear;
}
@media only screen and (max-width: 1380px) {
    .banner-media .shape5 {
        width: 25px;
    }
}
@media only screen and (max-width: 991px) {
    .banner-media .shape5 {
        width: 15px;
        top: 90px;
    }
}
.banner-media .shape6 {
    left: 40px;
    bottom: 90px;
    width: 60px;
    -webkit-animation: aniBnrShape7 12s infinite linear;
    animation: aniBnrShape7 12s infinite linear;
}
@media only screen and (max-width: 1380px) {
    .banner-media .shape6 {
        width: 40px;
    }
}
@media only screen and (max-width: 991px) {
    .banner-media .shape6 {
        width: 25px;
        left: 30px;
        bottom: 15px;
    }
}
.banner-media .shape7 {
    bottom: 210px;
    right: 0;
    width: 35px;
    -webkit-animation: aniBnrShape7 12s infinite linear;
    animation: aniBnrShape7 12s infinite linear;
}
@media only screen and (max-width: 1380px) {
    .banner-media .shape7 {
        width: 25px;
    }
}
@media only screen and (max-width: 991px) {
    .banner-media .shape7 {
        bottom: 95px;
        width: 20px;
    }
}

@keyframes dZwobble {
    0% {
        -webkit-transform: rotate(-1deg) translate(-5px, -5px);
        transform: rotate(-1deg) translate(-5px, -5px);
    }
    50% {
        -webkit-transform: rotate(1deg) translate(5px, 5px);
        transform: rotate(1deg) translate(5px, 5px);
    }
    100% {
        -webkit-transform: rotate(-1deg) translate(-5px, -5px);
        transform: rotate(-1deg) translate(-5px, -5px);
    }
}

@keyframes aniBnrShape2 {
    100% {
        -webkit-transform: rotateY(360deg);
        transform: rotateY(360deg);
    }
}

@keyframes aniBnrShape3 {
    100% {
        -webkit-transform: rotateX(-360deg);
        transform: rotateX(-360deg);
    }
}

@keyframes aniBnrShape4 {
    0% {
        -webkit-transform: rotate(0) translate(2px, 2px) scale(1);
        transform: rotate(0) translate(2px, 2px) scale(1);
    }
    50% {
        -webkit-transform: rotate(50deg) translate(2px, 2px) scale(1.2);
        transform: rotate(50deg) translate(2px, 2px) scale(1.2);
    }
    100% {
        -webkit-transform: rotate(0) translate(2px, 2px) scale(1);
        transform: rotate(0) translate(2px, 2px) scale(1);
    }
}

@keyframes aniBnrShape5 {
    0% {
        -webkit-transform: skewY(0);
        transform: skewY(0);
    }
    50% {
        -webkit-transform: skewY(20deg);
        transform: skewY(20deg);
    }
    100% {
        -webkit-transform: skewY(0);
        transform: skewY(0);
    }
}

@keyframes aniBnrShape7 {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes aniBnrMedia {
    0% {
        -webkit-transform: rotate(1deg) translate(2px, 2px);
        transform: rotate(1deg) translate(2px, 2px);
    }
    50% {
        -webkit-transform: rotate(-1deg) translate(-2px, -2px);
        transform: rotate(-1deg) translate(-2px, -2px);
    }
    100% {
        -webkit-transform: rotate(1deg) translate(2px, 2px);
        transform: rotate(1deg) translate(2px, 2px);
    }
}

.list-style-1 {
    counter-reset: li;
}
.list-style-1 li {
    display: block;
    position: relative;
    font-size: 20px;
    font-weight: 500;
    color: #527d95;
    padding: 6px 10px 6px 50px;
}
@media only screen and (max-width: 767px) {
    .list-style-1 li {
        font-size: 18px;
    }
}
@media only screen and (max-width: 575px) {
    .list-style-1 li {
        font-size: 16px;
        padding: 6px 10px 6px 45px;
    }
}
.list-style-1 li:after {
    content: counter(li);
    background-image: url(../images/shape/polygon7.svg);
    height: 35px;
    width: 35px;
    position: absolute;
    left: 0;
    background-position: center -0.5px;
    counter-increment: li;
    text-align: center;
    line-height: 35px;
    color: #fff;
    font-size: 16px;
}
@media only screen and (max-width: 575px) {
    .list-style-1 li:after {
        top: 2px;
    }
}

.coins-wrapper .list-style-1 {
    margin-bottom: 2rem !important;
}

@media only screen and (max-width: 575px) {
    .coins-wrapper .list-style-1 {
        margin-bottom: 30px;
    }
}

.coins-wrapper .sub-title {
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 12px;
}
@media only screen and (max-width: 767px) {
    .coins-wrapper .sub-title {
        font-size: 20px;
    }
}

.coins-wrapper .flex-coin1,
.coins-wrapper .flex-coin2,
.coins-wrapper .flex-coin3,
.coins-wrapper .flex-coin4,
.coins-wrapper .flex-coin5 {
    position: absolute;
    z-index: -1;
}
.coins-wrapper .flex-coin1 img,
.coins-wrapper .flex-coin2 img,
.coins-wrapper .flex-coin3 img,
.coins-wrapper .flex-coin4 img,
.coins-wrapper .flex-coin5 img {
    width: 48px;
}
.coins-wrapper .flex-coin1:after,
.coins-wrapper .flex-coin2:after,
.coins-wrapper .flex-coin3:after,
.coins-wrapper .flex-coin4:after,
.coins-wrapper .flex-coin5:after {
    content: '';
    height: 136px;
    width: 45px;
    position: absolute;
    left: 2px;
    top: 5px;
    z-index: -1;
    border-radius: 30px 30px 0 0;
    opacity: 0.4;
}

.coins-wrapper .flex-coin1 {
    top: 200px;
    left: 5%;
}
.coins-wrapper .flex-coin1:after {
    background: linear-gradient(180deg, #85ea09 0%, rgba(133, 233, 9, 0) 100%);
}

.coins-wrapper .flex-coin2 {
    bottom: 250px;
    left: 10%;
}
.coins-wrapper .flex-coin2:after {
    background: linear-gradient(180deg, #f9a922 0%, rgba(250, 171, 35, 0) 100%);
}
@media only screen and (max-width: 1700px) {
    .coins-wrapper .flex-coin2 {
        left: 4%;
    }
}

.coins-wrapper .flex-coin3 {
    bottom: 160px;
    right: 12%;
}
.coins-wrapper .flex-coin3:after {
    background: linear-gradient(180deg, #33b994 0%, rgba(50, 183, 147, 0) 100%);
}

.coins-wrapper .flex-coin4 {
    top: 65px;
    right: 10%;
}
.coins-wrapper .flex-coin4:after {
    background: linear-gradient(180deg, #7895f1 0%, rgba(117, 146, 240, 0) 100%);
}

.coins-wrapper .flex-coin5 {
    top: 50%;
    right: 4%;
    transform: translateY(-50%);
}
.coins-wrapper .flex-coin5:after {
    background: linear-gradient(180deg, #14a7fa 0%, rgba(10, 125, 246, 0) 100%);
}

@media only screen and (max-width: 1700px) {
    .coins-wrapper .coin-wave {
        display: none;
    }
}

.coins-media-wrapper {
    position: relative;
    display: block;
    width: 537px;
    height: 529px;
    margin-left: auto;
    margin-right: auto;
}
@media only screen and (max-width: 1280px) {
    .coins-media-wrapper {
        width: 450px;
        height: 442px;
    }
}
@media only screen and (max-width: 991px) {
    .coins-media-wrapper {
        width: 537px;
        height: 529px;
    }
}
@media only screen and (max-width: 767px) {
    .coins-media-wrapper {
        width: 450px;
        height: 442px;
    }
}
@media only screen and (max-width: 575px) {
    .coins-media-wrapper {
        width: 320px;
        height: 315px;
    }
}
.coins-media-wrapper .main-circle1 {
    z-index: -1;
    height: 100%;
    width: 100%;
    position: relative;
}
.coins-media-wrapper .circle-box {
    background-image: url(../images/shape/polygon2.svg);
    background-position: center;
    background-size: 98%;
    background-repeat: no-repeat;
    position: absolute;
    height: 100%;
    width: 100%;
}
.coins-media-wrapper .center-media {
    width: 190px;
    height: 190px;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
}
.coins-media-wrapper .coin-list {
    height: 100%;
    width: 100%;
}
.coins-media-wrapper .coin-list li {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -29px;
    margin-top: -29px;
    width: 58px;
    height: 58px;
}
.coins-media-wrapper .coin-list li img {
    width: 100%;
}
@media only screen and (max-width: 575px) {
    .coins-media-wrapper .coin-list li img {
        transform: scale(0.75);
    }
}

.contact-wrapper .form-wrapper-box {
    margin-bottom: -180px;
    margin-top: 0;
}

.contact-wrapper .section-head {
    margin-bottom: 40px;
}
.contact-wrapper .section-head .title {
    margin-bottom: 12px;
}
.contact-wrapper .section-head p {
    font-size: 18px;
    line-height: 1.6;
}
@media only screen and (max-width: 575px) {
    .contact-wrapper .section-head {
        margin-bottom: 30px;
    }
    .contact-wrapper .section-head p {
        font-size: 16px;
    }
}

@media only screen and (max-width: 991px) {
    .contact-wrapper .form-wrapper-box {
        margin-bottom: 0;
    }
}

.form-wrapper-box {
    border-radius: 20px;
    box-shadow: 0px 25px 50px rgba(9, 9, 21, 0.1);
    padding: 50px;
    background-color: #fff;
}
@media only screen and (max-width: 1480px) {
    .form-wrapper-box {
        padding: 40px;
    }
}
@media only screen and (max-width: 575px) {
    .form-wrapper-box {
        padding: 30px;
    }
}
.form-wrapper-box .section-head {
    margin-bottom: 30px;
}
.form-wrapper-box .section-head .title {
    margin-bottom: 5px;
}
.form-wrapper-box .section-head p {
    font-weight: 400;
    font-size: 20px;
    color: #444444;
}
@media only screen and (max-width: 1480px) {
    .form-wrapper-box .section-head p {
        font-size: 18px;
    }
}
@media only screen and (max-width: 1280px) {
    .form-wrapper-box .section-head p {
        font-size: 16px;
    }
}
@media only screen and (max-width: 575px) {
    .form-wrapper-box .section-head {
        margin-bottom: 25px;
    }
}
.form-wrapper-box .form-control,
.form-wrapper-box .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .form-wrapper-box select,
.form-wrapper-box .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .form-wrapper-box select,
.form-wrapper-box .comment-respond .comment-form p.comment-form-author input,
.comment-respond .comment-form p.comment-form-author .form-wrapper-box input,
.form-wrapper-box .comment-respond .comment-form p.comment-form-email input,
.comment-respond .comment-form p.comment-form-email .form-wrapper-box input,
.form-wrapper-box .comment-respond .comment-form p.comment-form-url input,
.comment-respond .comment-form p.comment-form-url .form-wrapper-box input,
.form-wrapper-box .comment-respond .comment-form p textarea,
.comment-respond .comment-form p .form-wrapper-box textarea {
    background-color: transparent;
    z-index: 0 !important;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
}
.form-wrapper-box textarea.form-control,
.form-wrapper-box .comment-respond .comment-form p textarea,
.comment-respond .comment-form p .form-wrapper-box textarea {
    height: 150px;
    padding: 20px 20px;
}
.form-wrapper-box .input-group {
    margin-bottom: 30px;
}
@media only screen and (max-width: 575px) {
    .form-wrapper-box .input-group {
        margin-bottom: 20px;
    }
}
.form-wrapper-box .input-area {
    position: relative;
}
.form-wrapper-box .input-area label {
    position: absolute;
    font-size: 16px;
    font-weight: 400;
    top: 15px;
    left: 15px;
    color: #555555;
    -webkit-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s;
    background-color: #fff;
    display: block;
    padding: 0 10px;
}
@media only screen and (max-width: 1280px) {
    .form-wrapper-box .input-area label {
        font-size: 14px;
    }
}
.form-wrapper-box .input-area.focused label {
    position: absolute;
    font-size: 13px;
    top: -8px;
    color: #02a3fe;
    z-index: 2;
}
@media only screen and (max-width: 575px) {
    .form-wrapper-box .input-area label {
        top: 12px;
    }
}

.content-bx.style-1 .dz-media {
    position: relative;
    z-index: 1;
    margin-left: 60px;
    overflow: unset !important;
}
.content-bx.style-1 .dz-media .content-box {
    width: fit-content;
    padding: 15px 25px;
    border-radius: 8px;
    background: #fff;
    position: absolute;
    bottom: 70px;
    left: -60px;
    box-shadow: 0px 10px 20px rgba(1, 18, 111, 0.15);
    z-index: 2;
}
@media only screen and (max-width: 575px) {
    .content-bx.style-1 .dz-media .content-box {
        bottom: 15px;
        left: 15px;
    }
}
@media only screen and (max-width: 575px) {
    .content-bx.style-1 .dz-media {
        margin-left: 0px;
    }
}

.content-bx.style-1 .inner-content {
    margin-left: 20px;
}
@media only screen and (max-width: 575px) {
    .content-bx.style-1 .inner-content {
        margin-left: 0px;
    }
}

.content-bx.style-2 {
    overflow: hidden;
}
.content-bx.style-2 .dz-media {
    width: 50vw;
    float: left;
}
@media only screen and (max-width: 991px) {
    .content-bx.style-2 .dz-media {
        width: 100%;
    }
}

.crypto-box {
    align-items: center;
    display: flex;
    background: #fff;
    padding: 28px 30px;
    border-radius: 10px;
    box-shadow: 0px 10px 20px rgba(1, 18, 111, 0.15);
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
    margin-right: 20px;
    position: relative;
}
.crypto-box .crypto-media {
    width: 65px;
    height: 65px;
    overflow: hidden;
    border-radius: 50px;
}
.crypto-box .crypto-media img {
    width: 100%;
}
.crypto-box .crypto-info {
    padding-left: 15px;
}
.crypto-box .crypto-info h5,
.crypto-box .crypto-info .h5 {
    font-weight: 500;
    margin: 0;
    line-height: 1.2;
    margin-bottom: 8px;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
    font-size: 20px;
}
.crypto-box .crypto-info h5 span,
.crypto-box .crypto-info .h5 span {
    color: #8ea5c8;
    font-size: 16px;
    font-weight: 400;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
}
.crypto-box .crypto-info .price {
    color: #1e1e26;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
}
.crypto-box .crypto-info .price span {
    font-size: 15px;
    margin-left: 5px;
    font-weight: 500;
}
.crypto-box .btn-square {
    width: 48px;
    height: 48px;
    padding: 0;
    position: absolute;
    right: -20px;
    top: 50%;
    transform: translateY(-50%);
}
@media only screen and (max-width: 1280px) {
    .crypto-box {
        padding: 20px 20px;
        margin-right: 15px;
    }
    .crypto-box .crypto-media {
        width: 50px;
        height: 50px;
    }
    .crypto-box .crypto-info {
        padding-left: 12px;
    }
    .crypto-box .crypto-info .price {
        font-size: 15px;
    }
    .crypto-box .crypto-info .price span {
        font-size: 14px;
    }
    .crypto-box .crypto-info h5,
    .crypto-box .crypto-info .h5 {
        font-size: 18px;
    }
    .crypto-box .btn-square {
        width: 40px;
        height: 40px;
        right: -15px;
    }
}

.crypto-wrapper {
    margin-top: -62px;
}
@media only screen and (max-width: 1280px) {
    .crypto-wrapper {
        margin-top: -42px;
    }
}

.call-to-action .title {
    font-size: 55px;
}
@media only screen and (max-width: 1480px) {
    .call-to-action .title {
        font-size: 52px;
    }
}
@media only screen and (max-width: 1280px) {
    .call-to-action .title {
        font-size: 42px;
    }
}
@media only screen and (max-width: 575px) {
    .call-to-action .title {
        font-size: 24px;
    }
}

.clients-logo .logo-main {
    opacity: 0.2;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
}
.clients-logo .logo-main:hover {
    opacity: 1;
}

.dz-meta .post-author img {
    border: 2px solid #fff;
    border-radius: 100%;
    width: 50px;
}

.info-box.style-1 {
    color: #fff !important;
    padding: 75px;
    background: var(--primary);
}
@media only screen and (max-width: 575px) {
    .info-box.style-1 {
        padding: 65px;
    }
}
@media only screen and (max-width: 1480px) {
    .info-box.style-1 {
        padding: 50px;
    }
}
@media only screen and (max-width: 575px) {
    .info-box.style-1 {
        padding: 20px;
    }
}

.info-box .widget ul li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    width: 80%;
    padding: 20px 0;
}
.info-box .widget ul li p {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    opacity: 0.6;
    margin-bottom: 5px;
}
.info-box .widget ul li h6,
.info-box .widget ul li .h6 {
    font-size: 18px;
    font-weight: 500;
}

.dz-social.style-1 {
    padding-top: 4rem;
}
.dz-social.style-1 .social-icon {
    display: flex;
    align-items: center;
    gap: 15px;
}
.dz-social.style-1 .social-icon li {
    background: rgba(255, 255, 255, 0.1);
    width: 40px;
    height: 40px;
    border-radius: 8px;
    text-align: center;
    position: relative;
    z-index: 1;
}
.dz-social.style-1 .social-icon li a {
    color: #fff;
    text-align: center;
    color: #fff;
    text-align: center;
    line-height: 40px;
}
.dz-social.style-1 .social-icon li:after {
    content: '';
    position: absolute;
    height: 40px;
    width: 0;
    background-color: var(--secondary);
    left: 0px;
    top: 0;
    z-index: -1;
    border-radius: var(--border-radius-base);
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
    z-index: -1;
}
.dz-social.style-1 .social-icon li:hover:after {
    width: 100%;
}

.form-wrapper.style-1 {
    padding: 6rem;
}
@media only screen and (max-width: 991px) {
    .form-wrapper.style-1 {
        padding: 50px;
    }
}
@media only screen and (max-width: 575px) {
    .form-wrapper.style-1 {
        padding: 20px;
    }
}
@media only screen and (max-width: 1480px) {
    .form-wrapper.style-1 {
        padding: 75px;
    }
}
@media only screen and (max-width: 1280px) {
    .form-wrapper.style-1 {
        padding: 50px;
    }
}
@media only screen and (max-width: 767px) {
    .form-wrapper.style-1 {
        padding: 20px;
    }
}

.content-inner.style-1 {
    padding: 85px 0 0 0 !important;
}

.dz-meta {
    margin-bottom: 10px;
}
.dz-meta ul {
    margin: 0;
    padding: 0;
}
.dz-meta ul li {
    display: inline-block;
    position: relative;
    font-size: 15px;
    margin-right: 30px;
}
.dz-meta ul li:after {
    content: '';
    height: 6px;
    width: 6px;
    background-color: var(--primary);
    position: absolute;
    top: 50%;
    right: -20px;
    border-radius: 50%;
    transform: translateY(-50%);
}
.dz-meta ul li:last-child {
    margin-right: 0;
    padding-right: 0;
}
.dz-meta ul li:last-child:after {
    content: none;
}
.dz-meta ul li i {
    font-size: 16px;
    margin-right: 2px;
    color: var(--primary);
    transform: scale(1.3);
    position: relative;
    top: 2px;
    padding-right: 0.2rem;
}
.dz-meta ul li a {
    color: inherit;
}
@media only screen and (max-width: 575px) {
    .dz-meta ul li {
        margin-right: 10px;
        padding-right: 10px;
    }
}

button.btn.btn-primary.style-1 {
    position: absolute;
    top: 5px;
    right: 5px;
    border-radius: 8px !important;
    padding: 13px;
    z-index: 9;
}

.contant-box.style-1 {
    display: flex;
    align-items: center;
}
@media only screen and (max-width: 575px) {
    .contant-box.style-1 {
        display: unset;
    }
}

.cursor {
    position: fixed;
    width: 30px;
    height: 30px;
    border: 1px solid var(--primary);
    border-radius: 50%;
    left: 0;
    top: 0;
    z-index: 999998;
    pointer-events: none;
    transform: translate(-50%, -50%);
    transition: 0.1s;
}

.cursor2 {
    position: fixed;
    width: 12px;
    height: 12px;
    border: 1px solid #c6c6c6;
    border-radius: 50%;
    left: 0;
    top: 0;
    z-index: 999998;
    pointer-events: none;
    transform: translate(-50%, -50%);
    background: #333;
    transition: 0.05s;
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2);
}

.blog-single {
    margin-bottom: 3.125rem;
}
.blog-single .dz-post-meta {
    margin-bottom: 1.25rem;
}
.blog-single .dz-post-text {
    padding-bottom: 5px;
}
.blog-single .dz-post-text:after {
    content: '';
    display: block;
    width: 100%;
    clear: both;
}
.blog-single .dz-post-tags {
    margin-top: 1.25rem;
}
.blog-single .dz-post-media {
    border-radius: 0.25rem;
}
.blog-single.dz-card .post-tags {
    margin-bottom: 0;
}
.blog-single .dz-media img,
.blog-single .dz-post-media img {
    border-radius: var(--border-radius-base);
}
.blog-single .dz-title {
    font-size: 36px;
}
@media only screen and (max-width: 767px) {
    .blog-single .dz-title {
        font-size: 28px;
    }
}
@media only screen and (max-width: 575px) {
    .blog-single .dz-title {
        font-size: 24px;
    }
}
.blog-single.style-1 .dz-title {
    margin: 10px 0;
    font-weight: 700;
}
.blog-single.style-1 .btn-link {
    color: black;
    font-weight: 600;
    font-size: 15px;
}
.blog-single.style-1 .dz-media img {
    border-radius: var(--border-radius-base);
}
.blog-single.style-1 .dz-media .dz-badge-list {
    position: absolute;
    top: 20px;
    left: 20px;
}
.blog-single.style-1 .dz-media .dz-badge {
    background-color: var(--primary);
    color: #fff;
    border-radius: var(--border-radius-base);
    padding: 8px 15px;
    font-size: 12px;
    text-transform: uppercase;
}
.blog-single.style-1:hover .dz-media {
    border-color: var(--primary);
}
.blog-single.style-1:hover .btn-link {
    color: var(--primary);
}

.dz-post-text a {
    text-decoration: underline;
    box-shadow: inset 0 -10px 0 var(--rgba-primary-1);
}

.dz-post-text .widget_archive li a,
.dz-post-text .wp-block-latest-posts li a,
.dz-post-text .wp-block-categories-list li a,
.dz-post-text .wp-block-archives-list li a,
.dz-post-text .widget_categories li a,
.dz-post-text blockquote a,
.dz-post-text .wp-block-button__link {
    box-shadow: none;
    text-decoration: none;
}

.post-header {
    position: relative;
    overflow: hidden;
}
@media only screen and (max-width: 575px) {
    .post-header {
        margin-bottom: 1.25rem;
    }
}
.post-header .dz-media img {
    min-height: 250px;
    object-fit: cover;
}
.post-header .dz-info {
    position: absolute;
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.6) 100%);
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.6) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 1%, rgba(0, 0, 0, 0.6) 100%);
    position: absolute;
    bottom: 0;
    text-align: center;
    padding: 100px 30px 30px 30px !important;
    width: 100%;
}
@media only screen and (max-width: 575px) {
    .post-header .dz-info {
        padding: 40px 15px 15px 15px !important;
    }
}
.post-header .dz-info .dz-title {
    color: #fff;
}
@media only screen and (max-width: 575px) {
    .post-header .dz-info .dz-title {
        font-size: 1.125rem;
    }
}
.post-header .dz-info .dz-meta > ul {
    justify-content: center;
}
.post-header .dz-info .dz-meta > ul > li {
    color: #fff;
}

.post-link-in {
    padding: 15px 50px;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    font-size: 22px;
    font-weight: 400;
    position: absolute;
    bottom: 0;
    left: 0;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
}
.post-link-in:hover {
    background: var(--primary);
    color: #fff;
}

.side-bar {
    padding-bottom: 1px;
}
@media only screen and (max-width: 991px) {
    .side-bar {
        padding-left: 0;
        padding-right: 0;
    }
}
.side-bar.left {
    padding-left: 0;
    padding-right: 20px;
}
@media only screen and (max-width: 991px) {
    .side-bar.left {
        padding-right: 0;
        padding-left: 0;
    }
}

.alignnone {
    margin: 0.3125rem 0 1.563rem 0;
}
.alignnoneimg,
.alignnone.wp-caption,
.alignnone.is-resized {
    margin: 0.3125rem 0 1.563rem 0;
}

.aligncenter {
    display: block;
    margin: 0.3125rem auto 0.938rem;
    text-align: center;
}
.aligncenterimg,
.aligncenter.wp-caption,
.aligncenter.is-resized {
    display: block;
    margin: 0.3125rem auto 0.938rem;
    text-align: center;
}

.alignright {
    float: right;
    margin: 0.3125rem 0 1.563rem 1.563rem;
}
.alignrightimg,
.alignright.wp-caption,
.alignright.is-resized {
    margin: 0.3125rem 0 1.563rem 1.563rem;
    float: right;
}

.alignleft {
    float: left;
    margin: 0.3125rem 1.563rem 1.563rem 0;
}
.alignleftimg,
.alignleft.wp-caption,
.alignleft.is-resized {
    margin: 0.3125rem 1.563rem 1.563rem 0;
    float: left;
}

.wp-caption {
    max-width: 100%;
    text-align: center;
}
.wp-caption img[class*='wp-image-'] {
    display: block;
    margin: 0;
}
.wp-caption img {
    border: 0 none;
    height: auto;
    margin: 0;
    max-width: 100%;
    padding: 0;
    width: auto;
}
.wp-caption p.wp-caption-text {
    font-size: 0.813rem;
    line-height: 1.125rem;
    margin: 0;
    padding: 0.625rem 0;
    text-align: left;
}

.wp-block-quote.is-large,
.wp-block-quote.is-style-large,
blockquote.wp-block-quote,
blockquote.wp-block-pullquote,
blockquote.wp-block-pullquote.alignright,
blockquote.wp-block-pullquote.alignleft,
blockquote {
    padding: 1.25rem 2rem 1.25rem 3rem;
    font-size: 0.938rem;
    color: var(--title);
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
    line-height: 1.875rem;
    position: relative;
    clear: both;
    font-weight: 700;
    z-index: 1;
    border: 0;
}
@media only screen and (max-width: 991px) {
    .wp-block-quote.is-large,
    .wp-block-quote.is-style-large,
    blockquote.wp-block-quote,
    blockquote.wp-block-pullquote,
    blockquote.wp-block-pullquote.alignright,
    blockquote.wp-block-pullquote.alignleft,
    blockquote {
        padding: 1.25rem 1.25rem 0.938rem 1.25rem;
        font-size: 0.813rem;
    }
}
.wp-block-quote.is-large cite,
.wp-block-quote.is-style-large cite,
blockquote.wp-block-quote cite,
blockquote.wp-block-pullquote cite,
blockquote.wp-block-pullquote.alignright cite,
blockquote.wp-block-pullquote.alignleft cite,
blockquote cite {
    font-style: normal;
    position: relative;
    display: block;
    margin-top: 10px;
    font-weight: 600;
    color: var(--primary);
    line-height: 1.3;
    padding-left: 60px;
    font-family: var(--font-family-title);
}
.wp-block-quote.is-large cite:before,
.wp-block-quote.is-style-large cite:before,
blockquote.wp-block-quote cite:before,
blockquote.wp-block-pullquote cite:before,
blockquote.wp-block-pullquote.alignright cite:before,
blockquote.wp-block-pullquote.alignleft cite:before,
blockquote cite:before {
    position: absolute;
    content: '';
    width: 50px;
    height: 0.1px;
    top: 50%;
    transform: translateY(50%);
    left: 0;
    background: var(--primary);
}
.wp-block-quote.is-large b,
.wp-block-quote.is-large strong,
.wp-block-quote.is-large .strong,
.wp-block-quote.is-style-large b,
.wp-block-quote.is-style-large strong,
.wp-block-quote.is-style-large .strong,
blockquote.wp-block-quote b,
blockquote.wp-block-quote strong,
blockquote.wp-block-quote .strong,
blockquote.wp-block-pullquote b,
blockquote.wp-block-pullquote strong,
blockquote.wp-block-pullquote .strong,
blockquote.wp-block-pullquote.alignright b,
blockquote.wp-block-pullquote.alignright strong,
blockquote.wp-block-pullquote.alignright .strong,
blockquote.wp-block-pullquote.alignleft b,
blockquote.wp-block-pullquote.alignleft strong,
blockquote.wp-block-pullquote.alignleft .strong,
blockquote b,
blockquote strong,
blockquote .strong {
    color: inherit;
}
.wp-block-quote.is-large.wp-block-pullquote.alignleft,
.wp-block-quote.is-style-large.wp-block-pullquote.alignleft,
blockquote.wp-block-quote.wp-block-pullquote.alignleft,
blockquote.wp-block-pullquote.wp-block-pullquote.alignleft,
blockquote.wp-block-pullquote.alignright.wp-block-pullquote.alignleft,
blockquote.wp-block-pullquote.alignleft.wp-block-pullquote.alignleft,
blockquote.wp-block-pullquote.alignleft {
    margin: 0rem 1.563rem 1.563rem 0rem;
}
.wp-block-quote.is-large.wp-block-pullquote.alignright,
.wp-block-quote.is-style-large.wp-block-pullquote.alignright,
blockquote.wp-block-quote.wp-block-pullquote.alignright,
blockquote.wp-block-pullquote.wp-block-pullquote.alignright,
blockquote.wp-block-pullquote.alignright.wp-block-pullquote.alignright,
blockquote.wp-block-pullquote.alignleft.wp-block-pullquote.alignright,
blockquote.wp-block-pullquote.alignright {
    margin: 0rem 0 1.563rem 1.563rem;
}
.wp-block-quote.is-large.style-1,
.wp-block-quote.is-style-large.style-1,
blockquote.wp-block-quote.style-1,
blockquote.wp-block-pullquote.style-1,
blockquote.wp-block-pullquote.alignright.style-1,
blockquote.wp-block-pullquote.alignleft.style-1,
blockquote.style-1 {
    background: #f3f4f8;
    border-radius: var(--border-radius-base);
    color: var(--primary);
    padding: 25px 40px;
    overflow: hidden;
}
@media only screen and (max-width: 575px) {
    .wp-block-quote.is-large.style-1,
    .wp-block-quote.is-style-large.style-1,
    blockquote.wp-block-quote.style-1,
    blockquote.wp-block-pullquote.style-1,
    blockquote.wp-block-pullquote.alignright.style-1,
    blockquote.wp-block-pullquote.alignleft.style-1,
    blockquote.style-1 {
        padding: 25px 30px;
    }
}
.wp-block-quote.is-large.style-1:after,
.wp-block-quote.is-style-large.style-1:after,
blockquote.wp-block-quote.style-1:after,
blockquote.wp-block-pullquote.style-1:after,
blockquote.wp-block-pullquote.alignright.style-1:after,
blockquote.wp-block-pullquote.alignleft.style-1:after,
blockquote.style-1:after {
    content: '';
    width: 8px;
    height: 100%;
    left: 0;
    top: 0;
    background: var(--primary);
    position: absolute;
}
@media only screen and (max-width: 767px) {
    .wp-block-quote.is-large.style-1:after,
    .wp-block-quote.is-style-large.style-1:after,
    blockquote.wp-block-quote.style-1:after,
    blockquote.wp-block-pullquote.style-1:after,
    blockquote.wp-block-pullquote.alignright.style-1:after,
    blockquote.wp-block-pullquote.alignleft.style-1:after,
    blockquote.style-1:after {
        width: 5px;
    }
}
.wp-block-quote.is-large.style-1:before,
.wp-block-quote.is-style-large.style-1:before,
blockquote.wp-block-quote.style-1:before,
blockquote.wp-block-pullquote.style-1:before,
blockquote.wp-block-pullquote.alignright.style-1:before,
blockquote.wp-block-pullquote.alignleft.style-1:before,
blockquote.style-1:before {
    position: absolute;
    content: '\f10c';
    font-family: Flaticon;
    color: var(--primary);
    bottom: 15px;
    right: 25px;
    line-height: 1;
    opacity: 0.2;
    font-size: 60px;
    transform: rotate(180deg);
}
@media only screen and (max-width: 767px) {
    .wp-block-quote.is-large.style-1:before,
    .wp-block-quote.is-style-large.style-1:before,
    blockquote.wp-block-quote.style-1:before,
    blockquote.wp-block-pullquote.style-1:before,
    blockquote.wp-block-pullquote.alignright.style-1:before,
    blockquote.wp-block-pullquote.alignleft.style-1:before,
    blockquote.style-1:before {
        font-size: 45px;
        bottom: 20px;
        right: 20px;
    }
}

blockquote.wp-block-pullquote:not(.is-style-solid-color) {
    background: #5608e0;
}

.wp-caption-text {
    font-size: 0.875rem;
    line-height: 1.3;
}

.dz-card.blog-single .dz-post-text blockquote p,
.dz-page-text blockquote p,
blockquote p {
    font-size: 20px;
    line-height: 1.6;
    color: var(--title);
    margin-bottom: 1rem;
    font-weight: 600;
    font-style: italic;
}
.dz-card.blog-single .dz-post-text blockquote p cite,
.dz-page-text blockquote p cite,
blockquote p cite {
    margin-top: 20px;
}
@media only screen and (max-width: 991px) {
    .dz-card.blog-single .dz-post-text blockquote p,
    .dz-page-text blockquote p,
    blockquote p {
        font-size: 1.125rem;
    }
}
@media only screen and (max-width: 575px) {
    .dz-card.blog-single .dz-post-text blockquote p,
    .dz-page-text blockquote p,
    blockquote p {
        font-size: 1rem;
    }
}

.dz-page-text {
    padding-bottom: 30px !important;
}

.size-auto,
.size-full,
.size-large,
.size-medium,
.size-thumbnail {
    max-width: 100%;
    height: auto;
}

.min-container,
.dz-page-text,
.dz-page-text + #comment-list,
.dz-page-text + #comment-list + .paginate-links {
    max-width: 51.875rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0.938rem;
    padding-right: 0.938rem;
}

.dz-card.blog-single.sidebar + #comment-list {
    padding: 0;
    max-width: 100%;
}

.max-container {
    max-width: 93.75rem;
    margin-left: auto;
    margin-right: auto;
    padding-left: 3.4375rem;
    padding-right: 3.4375rem;
}

.dz-page-text + #comment-list + .paginate-links,
.dz-page-text + #comment-list,
.dz-page-text {
    padding: 0;
}

.dz-page-text.sidebar {
    width: 100%;
    padding: 0;
    max-width: 100%;
}

.dz-page-text > ol,
.dz-page-text > ul,
.dz-page-text > .wp-block-gallery,
.dz-card.blog-single .dz-post-text > .wp-block-gallery,
.dz-card.blog-single .dz-post-text > ol,
.dz-card.blog-single .dz-post-text > ul {
    list-style: none;
    margin-bottom: 1.875rem;
}

.dz-page-text > ol,
.dz-page-text > ul,
.dz-card.blog-single .dz-post-text > ol,
.dz-card.blog-single .dz-post-text > ul,
.dz-card.blog-single .dz-post-text.text > ol,
.dz-card.blog-single .dz-post-text.text > ul {
    padding-left: 1.25rem;
}
.dz-page-text > ol ol,
.dz-page-text > ol ul,
.dz-page-text > ul ol,
.dz-page-text > ul ul,
.dz-card.blog-single .dz-post-text > ol ol,
.dz-card.blog-single .dz-post-text > ol ul,
.dz-card.blog-single .dz-post-text > ul ol,
.dz-card.blog-single .dz-post-text > ul ul,
.dz-card.blog-single .dz-post-text.text > ol ol,
.dz-card.blog-single .dz-post-text.text > ol ul,
.dz-card.blog-single .dz-post-text.text > ul ol,
.dz-card.blog-single .dz-post-text.text > ul ul {
    padding-left: 1.563rem;
}

.dz-page-text > ol li,
.dz-page-text > ul li,
.dz-card.blog-single .dz-post-text > ol li,
.dz-card.blog-single .dz-post-text > ul li,
.dz-card.blog-single .dz-post-text > ol li,
.dz-card.blog-single .dz-post-text > ul li,
.dz-card.blog-single .dz-post-text.text > ol li,
.dz-card.blog-single .dz-post-text.text > ul li {
    padding: 0.5rem 0.5rem;
    position: relative;
    list-style: inherit;
}

.dz-page-text > .wp-block-gallery,
.dz-card.blog-single .dz-post-text > .wp-block-gallery,
.dz-card.blog-single .dz-post-text.text > .wp-block-gallery {
    padding: 0;
    list-style: none;
}

.dz-page-text > .wp-block-gallery li,
.dz-card.blog-single .dz-post-text > .wp-block-gallery li,
.dz-card.blog-single .dz-post-text.text > .wp-block-gallery li {
    padding: 0;
    list-style: none;
    margin: 0.25rem;
}

.dz-page-text > .wp-block-gallery li:before,
.dz-card.blog-single .dz-post-text > .wp-block-gallery li:before,
.dz-card.blog-single .dz-post-text.text > .wp-block-gallery li:before {
    content: none;
}

.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
    margin: 0 0.5rem 0.5rem 0;
}

.wp-block-gallery.columns-4 .blocks-gallery-image,
.wp-block-gallery.columns-4 .blocks-gallery-item,
.wp-block-gallery.columns-3 .blocks-gallery-image,
.wp-block-gallery.columns-3 .blocks-gallery-item {
    margin-right: 0.5rem;
}

.content-spacious-full .wp-block-gallery {
    margin-left: calc(-12.5rem - (0.313rem / 2));
    margin-right: calc(-12.5rem - (0.313rem / 2));
    width: calc(100% + 25rem + 0.313rem);
    max-width: initial;
}

.alignfull,
.alignwide {
    margin-left: calc(-6.25rem - (0.313rem / 2));
    margin-right: calc(-6.25rem - (0.313rem / 2));
    width: calc(100% + 12.5rem + 0.313rem);
    max-width: initial;
}

.alignfull,
.alignfullwide {
    margin-left: calc(50% - 50vw - (var(--content-share-ml, 0px) / 2));
    width: 100vw;
    max-width: 100vw;
    box-sizing: border-box;
}

.dz-page-text h1,
.dz-page-text .h1,
.dz-post-text h1,
.dz-post-text .h1,
.thm-unit-test .dz-post-text h1,
.thm-unit-test .dz-post-text .h1 {
    margin-bottom: 1rem;
}

.dz-page-text h2,
.dz-page-text .h2,
.dz-post-text h2,
.dz-post-text .h2,
.thm-unit-test .dz-post-text h2,
.thm-unit-test .dz-post-text .h2 {
    margin-bottom: 1rem;
}

.dz-page-text h3,
.dz-page-text .h3,
.dz-post-text h3,
.dz-post-text .h3,
.thm-unit-test .dz-post-text h3,
.thm-unit-test .dz-post-text .h3 {
    margin-bottom: 1rem;
    font-weight: 700;
}

.dz-page-text h4,
.dz-page-text .h4,
.dz-page-text .comment-reply-title,
.dz-page-text .wp-block-search .wp-block-search__label,
.wp-block-search .dz-page-text .wp-block-search__label,
.dz-post-text h4,
.dz-post-text .h4,
.dz-post-text .comment-reply-title,
.dz-post-text .wp-block-search .wp-block-search__label,
.wp-block-search .dz-post-text .wp-block-search__label,
.thm-unit-test .dz-post-text h4,
.thm-unit-test .dz-post-text .h4,
.thm-unit-test .dz-post-text .comment-reply-title,
.thm-unit-test .dz-post-text .wp-block-search .wp-block-search__label,
.wp-block-search .thm-unit-test .dz-post-text .wp-block-search__label {
    margin-bottom: 0.75rem;
    font-weight: 600;
}

.dz-page-text h5,
.dz-page-text .h5,
.dz-post-text h5,
.dz-post-text .h5,
.thm-unit-test .dz-post-text h5,
.thm-unit-test .dz-post-text .h5 {
    margin-bottom: 0.75rem;
}

.dz-page-text h6,
.dz-page-text .h6,
.dz-post-text h6,
.dz-post-text .h6,
.thm-unit-test .dz-post-text h6,
.thm-unit-test .dz-post-text .h6 {
    margin-bottom: 0.625rem;
}

.wp-block-code,
pre.wp-block-code,
pre {
    margin: 0 0 1.563rem;
    padding: 1.25rem;
    color: #fff;
    background-color: #212326;
    white-space: pre;
    font-size: 0.938rem;
    border-radius: 0.1875rem;
}

.dz-page-text,
.dz-page-text ul li,
.dz-page-text ol li,
.dz-card.blog-single .dz-post-text,
.dz-card.blog-single .dz-post-text ul li,
.dz-card.blog-single .dz-post-text ol li,
.dz-card.blog-single
    .dz-card.blog-single
    .dz-post-text
    p:not(.has-text-color):not(.has-text-align-center):not(.has-text-align-left):not(.has-text-align-right) {
    font-size: 1rem;
    line-height: 1.7;
}

.wp-block-cover,
.wp-block-cover-image {
    color: #fff;
}

.wp-block-cover p:last-child,
.wp-block-cover-image p:last-child {
    margin-bottom: 0;
}

.wp-block-quote.has-text-align-right {
    border-right: 0;
}

.dz-page-text ul li,
.dz-page-text ol li,
.dz-card.blog-single .dz-post-text ul li,
.dz-card.blog-single .dz-post-text ol li {
    position: relative;
}

.dz-page-text ul > li:before,
.dz-page-text ol > li:before,
.dz-card.blog-single .dz-post-text ul > li:before,
.dz-card.blog-single .dz-post-text ol > li:before {
    content: '';
    display: inline-block;
    width: 0.375rem;
    height: 0.375rem;
    background: #212529;
    left: -0.938rem;
    position: absolute;
    top: 1.1rem;
    border-radius: 4px;
}

.dz-page-text ul > li li:before,
.dz-page-text ol > li li:before,
.dz-card.blog-single .dz-post-text ul > li li:before,
.dz-card.blog-single .dz-post-text ol > li li:before {
    content: none;
}

.dz-page-text p,
.dz-card.blog-single .dz-post-text p {
    margin-bottom: 1.65rem;
}

.paginate-links {
    display: flex;
    align-items: center;
    margin: 1.5rem 0 4rem 0;
}

.paginate-links a,
.paginate-links > span {
    margin: 0 0 0 0.625rem;
    position: relative;
    border: 0rem solid #6cc000;
    color: #1f2471;
    display: inline-block;
    font-size: 1rem;
    font-weight: 500;
    line-height: 2.5rem;
    min-width: 2.5rem;
    height: 2.5rem;
    text-align: center;
    text-transform: capitalize;
    transition: all 500ms ease 0s;
    background: #e0e0e0;
    border-radius: 0.1875rem;
}

.wp-block-columns {
    margin-bottom: 0;
}

.wp-block-gallery {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;
}

.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    margin: 0 1rem 1rem 0;
    position: relative;
}

.wp-block-gallery .blocks-gallery-image figure,
.wp-block-gallery .blocks-gallery-item figure {
    height: 100%;
    margin: 0;
}

@supports (position: -webkit-sticky) or (position: sticky) {
    .wp-block-gallery .blocks-gallery-image figure,
    .wp-block-gallery .blocks-gallery-item figure {
        align-items: flex-end;
        display: flex;
        justify-content: flex-start;
    }
}

.wp-block-gallery .blocks-gallery-image img,
.wp-block-gallery .blocks-gallery-item img {
    display: block;
    height: auto;
    max-width: 100%;
    width: 100%;
}

@supports (position: -webkit-sticky) or (position: sticky) {
    .wp-block-gallery .blocks-gallery-image img,
    .wp-block-gallery .blocks-gallery-item img {
        width: auto;
    }
}

.wp-block-gallery .blocks-gallery-image figcaption,
.wp-block-gallery .blocks-gallery-item figcaption {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3) 60%, transparent);
    bottom: 0;
    color: #fff;
    font-size: 0.813rem;
    max-height: 100%;
    overflow: auto;
    padding: 2.5rem 0.625rem 0.3125rem;
    position: absolute;
    text-align: center;
    width: 100%;
}

.wp-block-gallery .blocks-gallery-image figcaption img,
.wp-block-gallery .blocks-gallery-item figcaption img {
    display: inline;
}

.wp-block-gallery.is-cropped .blocks-gallery-image a,
.wp-block-gallery.is-cropped .blocks-gallery-image img,
.wp-block-gallery.is-cropped .blocks-gallery-item a,
.wp-block-gallery.is-cropped .blocks-gallery-item img {
    width: 100%;
}

@supports (position: -webkit-sticky) or (position: sticky) {
    .wp-block-gallery.is-cropped .blocks-gallery-image a,
    .wp-block-gallery.is-cropped .blocks-gallery-image img,
    .wp-block-gallery.is-cropped .blocks-gallery-item a,
    .wp-block-gallery.is-cropped .blocks-gallery-item img {
        -o-object-fit: cover;
        flex: 1;
        height: 100%;
        object-fit: cover;
    }
}

.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
    width: calc(50% - 0.5rem);
}

.wp-block-gallery .blocks-gallery-image:nth-of-type(2n),
.wp-block-gallery .blocks-gallery-item:nth-of-type(2n) {
    margin-right: 0;
}

.wp-block-gallery.columns-1 .blocks-gallery-image,
.wp-block-gallery.columns-1 .blocks-gallery-item {
    margin-right: 0;
    width: 100%;
}

@media only screen and (max-width: 64rem) {
    .alignwide {
        margin-left: 0;
        margin-right: 0;
        width: 100% !important;
        max-width: 100% !important;
    }
    .alignwide .alignleft img {
        width: 100%;
    }
    .alignwide .alignleft {
        margin: 0.3125rem 0rem 1.563rem 0;
        float: none;
    }
}

@media (min-width: 37.5rem) {
    .wp-block-gallery.columns-3 .blocks-gallery-image,
    .wp-block-gallery.columns-3 .blocks-gallery-item {
        margin-right: 1rem;
        width: calc(33.33333% - 0.666rem);
    }
    .wp-block-gallery.columns-4 .blocks-gallery-image,
    .wp-block-gallery.columns-4 .blocks-gallery-item {
        margin-right: 1rem;
        width: calc(25% - 0.75rem);
    }
    .wp-block-gallery.columns-5 .blocks-gallery-image,
    .wp-block-gallery.columns-5 .blocks-gallery-item {
        margin-right: 1rem;
        width: calc(20% - 0.8rem);
    }
    .wp-block-gallery.columns-6 .blocks-gallery-image,
    .wp-block-gallery.columns-6 .blocks-gallery-item {
        margin-right: 1rem;
        width: calc(16.66667% - 0.833rem);
    }
    .wp-block-gallery.columns-7 .blocks-gallery-image,
    .wp-block-gallery.columns-7 .blocks-gallery-item {
        margin-right: 1rem;
        width: calc(14.28571% - 0.857rem);
    }
    .wp-block-gallery.columns-8 .blocks-gallery-image,
    .wp-block-gallery.columns-8 .blocks-gallery-item {
        margin-right: 1rem;
        width: calc(12.5% - 0.875rem);
    }
    .wp-block-gallery.columns-1 .blocks-gallery-image:nth-of-type(1n),
    .wp-block-gallery.columns-1 .blocks-gallery-item:nth-of-type(1n),
    .wp-block-gallery.columns-2 .blocks-gallery-image:nth-of-type(2n),
    .wp-block-gallery.columns-2 .blocks-gallery-item:nth-of-type(2n),
    .wp-block-gallery.columns-3 .blocks-gallery-image:nth-of-type(3n),
    .wp-block-gallery.columns-3 .blocks-gallery-item:nth-of-type(3n),
    .wp-block-gallery.columns-4 .blocks-gallery-image:nth-of-type(4n),
    .wp-block-gallery.columns-4 .blocks-gallery-item:nth-of-type(4n),
    .wp-block-gallery.columns-5 .blocks-gallery-image:nth-of-type(5n),
    .wp-block-gallery.columns-5 .blocks-gallery-item:nth-of-type(5n),
    .wp-block-gallery.columns-6 .blocks-gallery-image:nth-of-type(6n),
    .wp-block-gallery.columns-6 .blocks-gallery-item:nth-of-type(6n),
    .wp-block-gallery.columns-7 .blocks-gallery-image:nth-of-type(7n),
    .wp-block-gallery.columns-7 .blocks-gallery-item:nth-of-type(7n),
    .wp-block-gallery.columns-8 .blocks-gallery-image:nth-of-type(8n),
    .wp-block-gallery.columns-8 .blocks-gallery-item:nth-of-type(8n) {
        margin-right: 0;
    }
}

.wp-block-gallery .blocks-gallery-image:last-child,
.wp-block-gallery .blocks-gallery-item:last-child {
    margin-right: 0;
}

.wp-block-gallery .blocks-gallery-item.has-add-item-button {
    width: 100%;
}

.wp-block-image.alignfullwide img {
    border-radius: 0;
}

.wp-block-image img {
    border-radius: 0.625rem;
}

.wp-block-cover,
.wp-block-cover-image,
.wp-block-embed,
.wp-block-image {
    margin-bottom: 1.875rem;
    margin-top: 1.875rem;
}

.wp-block-gallery.alignleft,
.wp-block-gallery.alignright {
    max-width: 19.0625rem;
    width: 100%;
}

.wp-block-gallery.aligncenter,
.wp-block-gallery.alignleft,
.wp-block-gallery.alignright {
    display: flex;
}

.wp-block-gallery.aligncenter .blocks-gallery-item figure {
    justify-content: center;
}

.dz-page-text.sidebar .alignfull,
.dz-page-text.sidebar .alignfullwide,
.dz-page-text.sidebar .alignwide,
.dz-page-text.sidebar .wp-block-gallery,
.blog-single.dz-card.sidebar .alignfullwide,
.blog-single.dz-card.sidebar .alignwide,
.blog-single.dz-card.sidebar .dz-post-text .wp-block-gallery {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
    max-width: initial;
}

.blog-overlap {
    background: #fff;
    margin-top: -8.75rem;
    padding: 1.25rem 1.25rem 0;
    border-radius: 0.25rem 0.25rem 0rem 0rem;
}

.blog-single.dz-card.sidebar {
    width: 100%;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
}

.dz-post-text table,
.dz-page-text table,
.wp-block-table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
    font-size: 1rem;
    margin-bottom: 1.875rem;
}

.wp-block-image figcaption,
.dz-post-text figcaption,
.dz-page-text figcaption {
    font-size: 0.875rem;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    margin-top: 0.625rem;
}

.dz-post-text table tbody tr:nth-of-type(odd),
.dz-page-text table tbody tr:nth-of-type(odd),
.wp-block-table tbody tr:nth-of-type(odd) {
    background-color: #fafafa;
}

.dz-post-text td,
.dz-post-text th,
.dz-page-text td,
.dz-page-text th,
.wp-block-table td,
.wp-block-table th {
    padding: 0.625rem 0.938rem;
    border: 0.0625rem solid #e4e4e4;
    border-right: 0;
    border-left: 0;
}

.wp-block-media-text {
    margin-bottom: 30px;
}
.wp-block-media-text .wp-block-media-text__content {
    padding: 0 1.875rem;
}

.wp-block-column ol li:before,
.wp-block-column ul li:before {
    content: none !important;
}

.wp-block-pullquote {
    padding: 1em 0;
    border-top: 0;
    border-bottom: 0;
}
.wp-block-pullquote cite {
    color: #fff;
}
.wp-block-pullquote.alignright,
.wp-block-pullquote.alignleft {
    padding: 0;
    border-top: 0;
    border-bottom: 0;
}
.wp-block-pullquote.alignright blockquote,
.wp-block-pullquote.alignleft blockquote {
    margin: 0;
}

.dz-post-text .wp-block-cover-image-text,
.dz-post-text .wp-block-cover-text,
.dz-post-text section.wp-block-cover-image h2,
.dz-post-text section.wp-block-cover-image .h2,
.dz-page-text .wp-block-cover-image-text,
.dz-page-text .wp-block-cover-text,
.dz-page-text section.wp-block-cover-image h2,
.dz-page-text section.wp-block-cover-image .h2 {
    color: #fff;
}

.dz-post-text .bootstrap-select .dropdown-menu li a,
.dz-page-text .bootstrap-select .dropdown-menu li a {
    box-shadow: none;
    font-size: 0.875rem;
    font-family: 'Poppins', sans-serif;
}

.dz-post-text .wp-block-calendar a {
    box-shadow: none !important;
    text-decoration: unset;
}

.wp-block-search,
.wp-block-archives,
.wp-block-latest-posts,
.wp-block-latest-comments,
.wp-block-categories,
.wp-block-calendar {
    margin-bottom: 2.5rem;
}

.admin-bar .is-fixed .main-bar {
    top: 1.875rem;
}

.wp-block-quote.is-style-large cite:before {
    display: none;
}

.post-password-form label {
    font-size: 1rem;
}

.post-password-form input[type='submit'] {
    padding: 0.625rem 1.563rem;
    background-color: var(--primary);
    border: none;
    height: 2.8125rem;
    font-weight: 600;
    font-size: 0.875rem;
    outline: none;
}

.post-password-form input[type='submit']:hover {
    background-color: var(--primary);
}

.post-password-form input[type='password'] {
    height: 2.8125rem;
    border: 0.0625rem solid #ced4da;
}

.wp-block-search .wp-block-search__button {
    background: var(--primary);
    border: 0;
    color: #fff;
    font-size: 0.875rem;
    text-transform: uppercase;
    font-weight: 600;
    padding: 0.625rem 1.563rem;
    -webkit-transition: all 1s;
    -ms-transition: all 1s;
    transition: all 1s;
}

.wp-block-search .wp-block-search__input {
    border: 0.0625rem solid #e1e6eb;
    height: 2.8125rem;
    padding: 0.625rem 1.25rem;
    font-size: 0.875rem;
    outline: none;
}

.wp-block-tag-cloud a {
    padding: 0.5rem 0.625rem;
    background: var(--primary);
    font-size: 0.75rem;
    display: inline-block;
    margin: 0 0 0.3125rem;
    color: #fff;
    font-weight: 600;
    transition: all 0.5s;
    -moz-transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    text-decoration: none;
    box-shadow: none;
}
.wp-block-tag-cloud a:hover {
    background: var(--primary-hover);
}

.wp-block-latest-comments {
    padding: 0 !important;
}
.wp-block-latest-comments .avatar {
    width: 3.4375rem;
    height: 3.4375rem;
    border-radius: 3.4375rem;
}
.wp-block-latest-comments .wp-block-latest-comments__comment .wp-block-latest-comments__comment-excerpt {
    margin-left: 4.375rem;
}
.wp-block-latest-comments .wp-block-latest-comments__comment .wp-block-latest-comments__comment-meta {
    color: var(--title);
    font-family: 'Poppins', sans-serif;
    font-size: 1.063rem;
    font-weight: 500;
    line-height: 1.7;
    margin-bottom: 0.625rem;
}
.wp-block-latest-comments .wp-block-latest-comments__comment .wp-block-latest-comments__comment-meta a {
    color: var(--title);
    box-shadow: none;
    text-decoration: none;
}
.wp-block-latest-comments img + article .wp-block-latest-comments__comment .wp-block-latest-comments__comment-meta {
    margin-left: 4.375rem;
}
.wp-block-latest-comments .wp-block-latest-comments__comment-meta time {
    display: block;
    width: 100%;
    color: #9fa1a4;
    font-size: 0.813rem;
    font-weight: 400;
}
.wp-block-latest-comments .wp-block-latest-comments__comment-excerpt p {
    font-size: 1rem !important;
    line-height: 1.5 !important;
    margin-bottom: 0.625rem !important;
}
.wp-block-latest-comments li {
    padding: 0 !important;
    border-bottom: 0.0625rem solid #eee;
    padding-bottom: 0.625rem !important;
}
.wp-block-latest-comments li:before {
    content: none !important;
}

.wp-block-latest-comments__comment-avatar {
    width: 3.4375rem;
    height: 3.4375rem;
    border-radius: 3.4375rem;
}

.comment-content.dz-page-text {
    max-width: 100%;
}

.wp-block-rss {
    padding: 0 !important;
}
.wp-block-rss .wp-block-rss__item {
    padding: 0.3125rem 0 !important;
    border-bottom: 0.0625rem solid #eee;
}
.wp-block-rss .wp-block-rss__item:before {
    content: none !important;
}
.wp-block-rss .wp-block-rss__item a {
    font-family: 'Poppins', sans-serif;
    font-size: 1.125rem;
    box-shadow: unset !important;
    font-weight: 600;
    color: var(--title);
    text-decoration: none;
}

@media only screen and (max-width: 75rem) {
    .dz-page-text .wp-block-gallery,
    .dz-card.blog-single .dz-post-text .wp-block-gallery {
        margin-left: calc(-6.25rem - (0.625rem / 2));
        margin-right: calc(-6.25rem - (0.625rem / 2));
        width: calc(100% + 12.5rem + 0.625rem);
    }
}

@media only screen and (max-width: 61.9375rem) {
    .admin-bar .mo-left .header-nav {
        top: 1.875rem;
        height: calc(100vh - 1.875rem) !important;
    }
    .dz-page-text .wp-block-gallery,
    .dz-card.blog-single .dz-post-text .wp-block-gallery {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
    }
    .dz-page-text,
    .dz-page-text ul li,
    .dz-page-text ol li,
    .dz-page-text p,
    .dz-card.blog-single .dz-post-text,
    .dz-card.blog-single .dz-post-text ul li,
    .dz-card.blog-single .dz-post-text ol li,
    .dz-card.blog-single .dz-post-text p {
        font-size: 1rem;
    }
}

@media only screen and (max-width: 48.875rem) {
    .admin-bar .is-fixed .main-bar {
        top: 2.8125rem;
    }
    .admin-bar .mo-left .header-nav {
        top: 2.8125rem;
        height: calc(100vh - 2.812rem) !important;
    }
    .wp-block-media-text {
        display: block;
    }
    .wp-block-media-text .wp-block-media-text__media {
        margin-bottom: 0.938rem;
    }
    .wp-block-media-text .wp-block-media-text__content {
        padding: 0;
    }
}

@media only screen and (max-width: 37.5rem) {
    .admin-bar .is-fixed .main-bar {
        top: 0;
    }
    .admin-bar .mo-left .header-nav {
        top: 2.8125rem;
        height: calc(100vh - 2.812rem) !important;
    }
    .admin-bar .mo-left .is-fixed .header-nav {
        top: 0;
        height: 100vh !important;
    }
}

.post-footer {
    border-top: 0.0625rem solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.938rem 0 0;
}
.post-footer .dz-meta .tag-list {
    padding-bottom: 0;
    text-align: left;
    margin: 0;
    padding: 0;
    list-style: none;
    text-transform: capitalize;
    display: inline;
}
.post-footer .dz-meta ul li {
    margin-right: 0.3125rem;
    padding: 0;
    display: inline-block;
    color: #333333;
    font-weight: 500;
    font-size: 0.938rem;
    font-style: italic;
}
.post-footer .post-tag a {
    text-transform: capitalize;
    font-size: 0.938rem;
    color: #999;
    background: transparent;
    padding: 0;
    border: 0;
    line-height: 0.875rem;
    font-weight: 500;
}

.share-post li {
    display: inline-block;
}

.share-post a {
    width: 2.1875rem;
    height: 2.1875rem;
    border-radius: 2.1875rem;
    border: 0.0625rem solid #eee;
    display: block;
    text-align: center;
    line-height: 2.0625rem;
    color: #212529;
}

.extra-blog {
    margin-bottom: 30px;
}

@media only screen and (max-width: 575px) {
    .comment-respond.style-1 .comment-reply-title {
        margin-bottom: 20px;
    }
}

.default-form.comment-respond .comment-reply-title,
.comments-area .comments-title,
.extra-blog .blog-title {
    font-size: 23px;
    font-weight: 800;
}

.comments-area .comment-list {
    margin-bottom: 60px;
    padding: 0;
}
@media only screen and (max-width: 767px) {
    .comments-area .comment-list {
        margin-bottom: 40px;
    }
}
.comments-area .comment-list .default-form {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
}
.comments-area .comment-list .default-form small,
.comments-area .comment-list .default-form .small {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 1px;
}
.comments-area .comment-list .default-form small a,
.comments-area .comment-list .default-form .small a {
    color: #e10000;
}
.comments-area .comment-list .dz-page-text {
    padding-bottom: 0 !important;
    margin: 0;
}
.comments-area .comment-list > .comment .comment-body {
    position: relative;
    padding-left: 120px;
    margin-bottom: 30px;
    min-height: 95px;
    border-bottom: 1px solid #d3d3d3;
    padding-bottom: 25px;
}
@media only screen and (max-width: 767px) {
    .comments-area .comment-list > .comment .comment-body {
        padding-bottom: 15px;
        padding-left: 75px;
        margin-bottom: 20px;
    }
}
.comments-area .comment-list > .comment .comment-body .comment-author .avatar {
    position: absolute;
    left: 0;
    height: 100px;
    width: 100px;
    border-radius: var(--border-radius-base);
}
@media only screen and (max-width: 767px) {
    .comments-area .comment-list > .comment .comment-body .comment-author .avatar {
        height: 60px;
        width: 60px;
    }
}
.comments-area .comment-list > .comment .comment-body .comment-author .fn {
    font-size: 1.25rem;
    line-height: 1.2;
    font-family: var(--font-family-title);
    color: var(--title);
    font-weight: 600;
    font-style: normal;
    margin-bottom: 5px;
    display: block;
}
@media only screen and (max-width: 767px) {
    .comments-area .comment-list > .comment .comment-body .comment-author .fn {
        font-size: 1.125rem;
    }
}
.comments-area .comment-list > .comment .comment-body .comment-meta {
    margin-bottom: 5px;
}
.comments-area .comment-list > .comment .comment-body .comment-meta a {
    color: var(--secondary);
}
.comments-area .comment-list > .comment .comment-body p {
    margin-bottom: 10px;
}
.comments-area .comment-list > .comment .comment-body .reply .comment-reply-link {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    color: var(--primary);
    display: inline-block;
    line-height: 1.3;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
}
.comments-area .comment-list > .comment .comment-body .reply .comment-reply-link i {
    margin-right: 8px;
}
.comments-area .comment-list > .comment .children {
    padding-left: 120px;
}
@media only screen and (max-width: 767px) {
    .comments-area .comment-list > .comment .children {
        padding-left: 75px;
    }
}
@media only screen and (max-width: 575px) {
    .comments-area .comment-list > .comment .children {
        padding-left: 30px;
    }
}

.comment-reply-title a {
    font-size: 16px;
    font-weight: 600;
}

.comment-respond .comment-form {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
}
.comment-respond .comment-form p {
    margin-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
}
.comment-respond .comment-form p label {
    display: none;
}
.comment-respond .comment-form p textarea {
    height: 120px !important;
}
.comment-respond .comment-form .comment-form-comment {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
}
.comment-respond .comment-form .comment-form-author,
.comment-respond .comment-form .comment-form-email,
.comment-respond .comment-form .comment-form-url {
    width: 50%;
}

@media only screen and (max-width: 575px) {
    .comment-respond .comment-form .comment-form-author,
    .comment-respond .comment-form .comment-form-email,
    .comment-respond .comment-form .comment-form-url {
        width: 100% !important;
    }
}

.dz-page-text [class*='galleryid-'],
.dz-post-text [class*='galleryid-'] {
    clear: both;
    margin: 0 auto;
    overflow: hidden;
}

.gallery .gallery-item {
    width: 100%;
    display: inline-block;
    vertical-align: top;
    text-align: center;
    padding: 0.3125rem;
    position: relative;
    box-sizing: border-box;
}
.gallery .gallery-item img {
    float: left;
    padding: 0 0rem;
    width: 100%;
    border: none !important;
}

.gallery .gallery-caption {
    font-size: 0.813rem;
    color: #707070;
    display: block;
    font-family: 'Noto Sans', sans-serif;
    line-height: 1.5;
    padding: 0.5em 0;
    clear: both;
}

.gallery-columns-6 .gallery-caption,
.gallery-columns-7 .gallery-caption,
.gallery-columns-8 .gallery-caption,
.gallery-columns-9 .gallery-caption {
    display: none;
}

.gallery-columns-2 .gallery-item {
    max-width: 50%;
}

.gallery-columns-3 .gallery-item {
    max-width: 33.33%;
}

.gallery-columns-4 .gallery-item {
    max-width: 25%;
}

.gallery-columns-5 .gallery-item {
    max-width: 20%;
}

.gallery-columns-6 .gallery-item {
    max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
    max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
    max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
    max-width: 11.11%;
}

.gallery-icon img {
    margin: 0 auto;
}

.post-password-form {
    position: relative;
    clear: both;
}
.post-password-form label {
    display: block;
    font-size: 1rem;
}
.post-password-form input[type='password'] {
    width: 100%;
    border: 0.0625rem solid #ebedf2;
    padding: 0.625rem 6.25rem 0.625rem 0.938rem;
    height: 2.8125rem;
    border: 0.0625rem solid #ced4da;
}
.post-password-form input[type='submit'] {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0.625rem 1.25rem;
    background: var(--primary);
    color: #fff;
    border: none;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.875rem;
    outline: none;
    height: 2.8125rem;
}
.post-password-form input[type='submit']:hover {
    background: var(--primary-hover);
}

@media only screen and (max-width: 991px) {
    .side-bar.sticky-top {
        position: unset;
    }
}

.blog-post-banner {
    min-height: 300px;
    padding-top: 70px;
    padding-bottom: 70px;
}
.blog-post-banner .dz-title {
    color: #fff;
    font-size: 32px;
    margin-bottom: 20px;
}
.blog-post-banner .dz-meta {
    color: #fff;
}
.blog-post-banner .dz-meta ul {
    display: flex;
    opacity: 0.7;
}
.blog-post-banner .dz-meta ul li {
    margin-right: 20px;
}
.blog-post-banner .dz-meta ul li a {
    color: #fff;
}

.post-outside {
    margin-top: -120px;
}

.single-post .main-bar {
    border-bottom: 1px solid #eee;
}

.dz-load-more i:before,
.loadmore-btn i:before {
    -webkit-animation: unset !important;
    -moz-animation: unset !important;
    animation: unset !important;
}

.dz-share-post {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0px;
    border-top: 1px dashed #cccccc;
}
.dz-share-post .post-tags {
    display: flex;
    align-items: center;
}
.dz-share-post .post-tags .title {
    font-size: 18px;
    font-weight: 500;
    color: var(--title);
    margin-right: 10px;
    font-family: var(--font-family-title);
}
.dz-share-post .post-tags a {
    border-radius: var(--border-radius-base);
    padding: 6px 12px;
    border: 1px solid;
    line-height: 1.4;
    display: inline-block;
    color: inherit;
    font-size: 14px;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
}
.dz-share-post .post-tags a i {
    color: var(--primary);
}
.dz-share-post .post-tags a:last-child {
    margin-right: 0;
}
.dz-share-post .post-tags a:hover {
    background-color: var(--primary);
    border-color: var(--primary);
    box-shadow: 0px 12px 20px 0px var(--rgba-primary-3);
    color: #fff;
}
.dz-share-post .post-tags a:hover i {
    color: #fff;
}
.dz-share-post .dz-social-icon {
    display: flex;
    align-items: center;
}
@media only screen and (max-width: 991px) {
    .dz-share-post .dz-social-icon {
        margin-bottom: 15px;
    }
}
.dz-share-post .dz-social-icon .title {
    margin-right: 20px;
    margin-bottom: 0;
}
.dz-share-post .dz-social-icon ul {
    margin-left: -3px;
    margin-right: -3px;
    display: flex;
}
.dz-share-post .dz-social-icon ul li {
    padding: 0 3px;
}
.dz-share-post .dz-social-icon ul li a {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 16px;
    display: block;
    background-color: var(--primary);
    color: #fff;
    text-align: center;
    line-height: 35px;
    border-radius: var(--border-radius-base);
}
.dz-share-post .dz-social-icon ul li a:hover {
    color: #fff;
    background-color: var(--primary-hover);
}
@media only screen and (max-width: 1280px) {
    .dz-share-post .dz-social-icon {
        padding-top: 20px;
    }
}
@media only screen and (max-width: 991px) {
    .dz-share-post {
        display: block;
    }
}
@media only screen and (max-width: 767px) {
    .dz-share-post {
        display: block;
    }
    .dz-share-post .post-tags {
        margin-bottom: 20px;
    }
}

#comment-list:empty + .paginate-links {
    margin-top: -30px;
}

.post-swiper {
    position: relative;
}
.post-swiper .prev-post-swiper-btn,
.post-swiper .next-post-swiper-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 40px;
    width: 40px;
    border-radius: var(--border-radius-base);
    text-align: center;
    line-height: 40px;
    font-size: 20px;
    background-color: #fff;
    color: var(--primary);
    z-index: 1;
    -webkit-transition: all 0.8s;
    -ms-transition: all 0.8s;
    transition: all 0.8s;
}
.post-swiper .prev-post-swiper-btn:hover,
.post-swiper .next-post-swiper-btn:hover {
    background-color: var(--primary);
    color: #fff;
}
.post-swiper .prev-post-swiper-btn {
    left: 10px;
}
.post-swiper .next-post-swiper-btn {
    right: 10px;
}

.blog-single .dz-media + .dz-info {
    padding: 30px 0 0;
}

.author-box {
    padding: 35px;
    background: #f3f4f8;
    border-radius: var(--border-radius-base);
}
.author-box .author-profile-info {
    display: flex;
}
.author-box .author-profile-info .author-profile-content {
    padding-left: 25px;
}
.author-box .author-profile-info .author-profile-content p {
    margin-bottom: 25px;
}
.author-box .author-profile-info .author-profile-content ul {
    padding: 0;
    margin: 0;
    margin-right: 5px;
    display: table;
    float: left;
}
.author-box .author-profile-info .author-profile-content ul li {
    padding: 0;
    margin: 0;
    margin-right: 10px;
    float: left;
}
.author-box .author-profile-info .author-profile-content ul li a {
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 16px;
    display: block;
    background-color: var(--primary);
    color: #fff;
    text-align: center;
    line-height: 35px;
    border-radius: var(--border-radius-base);
}
.author-box .author-profile-info .author-profile-content ul li a:hover {
    color: #fff;
    background-color: var(--primary-hover);
}
.author-box .author-profile-info .author-profile-pic {
    width: 120px;
    height: 120px;
    overflow: hidden;
    min-width: 120px;
    border-radius: var(--border-radius-base);
}
.author-box .author-profile-info .author-profile-pic img {
    width: 100%;
}
@media only screen and (max-width: 575px) {
    .author-box .author-profile-info {
        display: block;
        text-align: center;
    }
    .author-box .author-profile-info .author-profile-pic {
        width: 80px;
        height: 80px;
        min-width: 80px;
        margin-bottom: 20px;
        margin-left: auto;
        margin-right: auto;
    }
    .author-box .author-profile-info .author-profile-content {
        padding-left: 0;
        padding-right: 0;
    }
    .author-box .author-profile-info .author-profile-content ul {
        display: inline-block;
        float: none;
        margin-right: 0;
    }
    .author-box .author-profile-info .author-profile-content ul li {
        margin-right: 5px;
        margin-left: 5px;
    }
    .author-box .author-profile-info .author-profile-content ul li a {
        height: 40px;
        width: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 14px;
        background: var(--primary);
        color: #fff;
        vertical-align: middle;
        display: block;
        -webkit-transition: all 0.8s;
        -ms-transition: all 0.8s;
        transition: all 0.8s;
    }
    .author-box .author-profile-info .author-profile-content ul li a:hover {
        color: #fff;
        background: var(--primary-hover);
    }
}

@media only screen and (max-width: 991px) {
    .fixed-width-mobile {
        width: 100px !important;
    }
}

@media (min-width: 992px) {
    .sub-list-mobile {
        display: none;
    }
}

.footer-app-logo {
    width: 200px;
}

.footer-copyright {
    margin-top: 1rem;
}

.fs-1 {
    font-size: 1rem !important;
}

.logo-section-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-list {
    padding-left: 0 !important;
}

.coins-media-wrapper .coin-list {
    padding-left: 0 !important;
}

.coins-media-wrapper .coin-list li {
    list-style: none !important;
}

.header-subtext-wrapper {
    margin-top: 2rem;
}

.header-margin-1 {
    margin-bottom: 1rem;
}

.footer-wrapper {
    text-align: justify;
    font-size: 0.7rem;
}

.footer-social {
    list-style: none;
    display: flex;
}

.footer-social li {
    margin-right: 10px;
}

.footer-social li:hover {
    scale: 1.1;
}

.store-link img {
    width: 130px;
}

.risk-banner {
    position: fixed;
    top: 0;
    color: white;
    background-color: #090915;
    width: 100%;
    height: 65px;
    z-index: 10;
    padding: 20px;
    color: #9595b1;
    font-size: 14px;
    text-align: center;
}

.is-risk-banner {
    margin-top: 65px;
}

@media screen and (max-width: 1380px) {
    .risk-banner {
        height: 85px;
    }
    .is-risk-banner {
        margin-top: 85px;
    }
    .page-content {
        margin-top: 85px;
    }
    .is-fixed .main-bar {
        top: 85px;
    }
}

@media screen and (max-width: 740px) {
    .risk-banner {
        height: 130px;
    }
    .is-risk-banner {
        margin-top: 130px;
    }
    .page-content {
        margin-top: 130px;
    }
    .is-fixed .main-bar {
        top: 130px;
    }
}

@media screen and (max-width: 391px) {
    .risk-banner {
        height: 172px;
    }
    .is-risk-banner {
        margin-top: 172px;
    }
    .page-content {
        margin-top: 172px;
    }
    .is-fixed .main-bar {
        top: 172px;
    }
}

.registration-section {
    padding: 40px 0 !important;
}

.registration-header {
    font-size: 30px !important;
}

.registration-subheader {
    font-size: 19px !important;
}

.home-title-header {
    font-size: 2.5rem !important;
}

